import { HomeViewSet } from 'pages/Main/Home/components/HomeViewSet';

/**
 * Home page view for the user with Admin role
 * @returns Functional Component for the home
 */
const AdminHome: React.FC = () => {
    return (
        <HomeViewSet
            views={[
                {
                    key: 'admin-home',
                    buttons: [
                        'add-file',
                        'add-officialDocument',
                        'search-target',
                    ],
                    tabs: [
                        'authorizationConfigs',
                        'officialDocuments',
                        'tags:pirate-brand',
                        'targets',
                        'documents',
                    ],
                },
            ]}
        />
    );
};
export default AdminHome;
