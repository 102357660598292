import GoBackButton from 'components/misc/GoBackButton';
import LoadingMessage from 'components/misc/LoadingMessage';
import MonitoringStatusBadge from 'components/models/Monitor/MonitorStatusBadge';
import CMBreadCrumb from 'components/page/CMBreadCrumb';
import useListMonitoringQuery from 'hooks/queries/monitoring/useListMonitoringQuery';
import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import { useProject } from 'hooks/context/project/useProject';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useCallback, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { getFilterData } from 'utils/datatable';
import { FilterMatchMode } from 'primereact/api';

// TODO: [MONITOR] Remove all fake logs and simulation
const fakeLogs = [
    '[{formattedDate}] Establishing connection...',
    '[{formattedDate}] Network Capture URL: {url1}',
    '[{formattedDate}] Video Streaming URL: {url2}',
    '[{formattedDate}] Network Stream Connection Established',
    '[{formattedDate}] Video Stream Connection Established',
    '[{formattedDate}] Request Sent to {url1}. Captured IPs: 192.168.0.1, 10.0.0.5, 172.16.20.30',
    '[{formattedDate}] Processing video frame',
    '[{formattedDate}] Request Sent to {url1}. Captured IPs: 192.168.0.2, 172.16.20.34',
    '[{formattedDate}] Processing video frame',
    '[{formattedDate}] Request Sent to {url1}. Captured IPs: 172.16.22.48, 192.168.4.2',
    '[{formattedDate}] Request Sent to {url1}. Captured IPs: 133.28.13.112,  168.44.23.48',
    '[{formattedDate}] Stream Connection Closed for {url1}',
    '[{formattedDate}] Stream Connection Closed for {url2}',
];

const deltaMap = [
    [0, 3],
    [4000, 1],
    [500, 1],
    [2000, 1],
    [1500, 1],
    [1000, 1],
    [1000, 1],
    [1200, 1],
    [1000, 1],
    [1000, 2],
];

const DetailRealTimeMonitoring = () => {
    const { permissions } = useAuth();
    const project = useProject();
    const params = useParams<{
        monitorId: string;
    }>();

    const monitorQuery = useListMonitoringQuery(
        project._id,
        {
            filters: {
                _id: getFilterData(FilterMatchMode.EQUALS, params.monitorId),
            },
        },
        {
            enabled: !!params.monitorId,
        }
    );

    const [localization] = useLocalization();
    const monitor = monitorQuery.data?.[0];

    const getLogs = useCallback(() => {
        if (!monitor) return [];
        const now = Date.now();
        const delta = now - monitor.created_at.getTime();
        const logs: string[] = [];

        let deltaAcummulator = 0;
        let indexAcummulator = 0;

        deltaMap.find(([d, indexesCount]) => {
            if (d == null || indexesCount == null) return true;
            deltaAcummulator += Number(d);
            if (delta < deltaAcummulator) return true;

            const paddedDate = new Date(
                monitor.created_at.getTime() + Number(d)
            );

            const formattedDate = `${paddedDate.getFullYear()}-${(
                paddedDate.getMonth() + 1
            )
                .toString()
                .padStart(2, '0')}-${paddedDate
                .getDate()
                .toString()
                .padStart(2, '0')} ${paddedDate
                .getHours()
                .toString()
                .padStart(2, '0')}:${paddedDate
                .getMinutes()
                .toString()
                .padStart(2, '0')}:${paddedDate
                .getSeconds()
                .toString()
                .padStart(2, '0')}`;
            while (indexesCount > 0) {
                const formattedStr = (fakeLogs[indexAcummulator] as string)
                    .replaceAll('{formattedDate}', formattedDate)
                    .replaceAll('{url1}', monitor.data.network_url)
                    .replaceAll('{url2}', monitor.data.video_url);
                logs.push(formattedStr);
                indexesCount -= 1;
                indexAcummulator += 1;
            }
            return false;
        });
        return logs;
    }, [monitor]);

    const [logs, setLogs] = useState<string[]>(getLogs());

    useEffect(() => {
        if (logs.length === fakeLogs.length) return;
        const handler = setTimeout(() => {
            setLogs(getLogs());
        }, 250);
        return () => clearTimeout(handler);
    }, [logs, getLogs]);

    if (!permissions.viewMonitors) return <Navigate to='/' />;
    if (monitorQuery.isLoading)
        return (
            <LoadingMessage>
                {localization.common.loading}
            </LoadingMessage>
        );
    if (!monitor) return <Navigate to='/' />;

    const streamingFinished = logs.length >= fakeLogs.length;

    return (
        <section>
            <CMBreadCrumb
                projectLabel={project.name}
                monitoringLabel={monitor.name}
            />
            <GoBackButton />
            <h2>
                {localization.models.monitoring.singular} - {monitor.name}
            </h2>
            <MonitoringStatusBadge key={logs.length} monitor={monitor} />
            <div className='bg-gray-950 rounded-lg p-4 mt-4'>
                <pre>{logs.join('\n')}</pre>
                {!streamingFinished && (
                    <ProgressSpinner
                        strokeWidth='10'
                        style={{
                            width: '16px',
                            height: '16px',
                        }}
                    />
                )}
            </div>
        </section>
    );
};

export default DetailRealTimeMonitoring;
