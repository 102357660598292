import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { Column } from 'primereact/column';
import { DateBadge } from 'components/ethercity-primereact';
import { useAuth } from 'hooks/context/useAuth';
import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import { useLocalization } from 'hooks/context/useLocalization';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';
import { MenuItem } from 'primereact/menuitem';
import { Button } from 'primereact/button';
import { getUserDisplayName } from 'utils/models/user';
import { Badge } from 'primereact/badge';
import useOperatorColumnFields from 'hooks/columns/models/useOperatorColumnFields';
import useShowObjectDisplayModal from 'hooks/dialogs/general/useShowObjectDisplayModal';

// import OperatorsblockOrderEmailStatus from '../OperatorEmailStatus';

type DetailedModel = Ether.CaseManager.Operator.Detailed;

const RowMenu: React.FC<{
    item: DetailedModel;
    onShowDetail: (operator: DetailedModel) => void;
}> = ({ item, onShowDetail }) => {
    const { permissions } = useAuth();
    const [localization] = useLocalization();
    const menuRef = useRef<Menu>(null);

    const menuItems: MenuItem[] = [
        ...(permissions.debugOperators
            ? [
                  {
                      id: 'debug',
                      label: localization.components.common.datatable
                          .menuDetailItem,
                      command: () => onShowDetail(item),
                  },
              ]
            : []),
    ];
    if (menuItems.length <= 0) return null;
    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

const OperatorDataTable: React.FC<
    CMDataTableProps<DetailedModel> & {
        targetAnswerModel?: 'block_order' | 'unblock_order';
    }
> = ({ targetAnswerModel, ...props }) => {
    const [localization] = useLocalization();

    const { columnFields, filterOptions } = useOperatorColumnFields();
    const { dialog: objectDisplayDialog, show: showObjectDisplay } =
        useShowObjectDisplayModal();

    return (
        <>
            {objectDisplayDialog}
            <CMDataTable
                columnConfigName='operators'
                lazy
                emptyMessage={
                    localization.components.models.operator.datatable.empty
                }
                filterOptions={filterOptions}
                {...props}
            >
                <Column
                    field={columnFields['_cm_name_id'].name}
                    body={(data: DetailedModel) => {
                        return (
                            <NameIdAggregator
                                name={data.contact.email}
                                id={data._id}
                            />
                        );
                    }}
                />
                <Column
                    field={columnFields['tags'].name}
                    header={localization.models.operatorTag.plural}
                    body={(data: DetailedModel) => {
                        return data.tags.join(', ');
                    }}
                />
                <Column
                    field={
                        columnFields[
                            'last_notification_data.last_sent_notification'
                        ].name
                    }
                    header={localization.fields.operator.lastNotificationAt}
                    body={(data: DetailedModel) => {
                        const date =
                            data.last_notification_data?.last_sent_notification;
                        return date ? <DateBadge value={date} /> : '-';
                    }}
                />
                {targetAnswerModel && (
                    <Column
                        field='target_searched_answer'
                        header={localization.fields.operator.targetResponse}
                        body={(data: DetailedModel) => {
                            const responses =
                                data.target_searched?.order_responses;
                            if (!responses) return '-';
                            const selfResponse = responses.find(
                                (r) =>
                                    r.user_id === data._id &&
                                    r.model === targetAnswerModel
                            );
                            if (selfResponse?.blocked == null) return '-';
                            return selfResponse.blocked ? (
                                <Badge
                                    value={
                                        localization.components.models.target
                                            .badge.approval.blocked
                                    }
                                    severity='danger'
                                />
                            ) : (
                                <Badge
                                    value={
                                        localization.components.models.target
                                            .badge.approval.notBlocked
                                    }
                                    severity='success'
                                />
                            );
                        }}
                    />
                )}
                {targetAnswerModel && (
                    <Column
                        field='target_searched_answer_at'
                        header={localization.fields.operator.targetRespondedAt}
                        body={(data: DetailedModel) => {
                            const responses =
                                data.target_searched?.order_responses;
                            if (!responses) return '-';
                            const selfResponse = responses.find(
                                (r) =>
                                    r.user_id === data._id &&
                                    r.model === targetAnswerModel
                            );
                            if (!selfResponse?.response_date) return '-';
                            return (
                                <DateBadge value={selfResponse.response_date} />
                            );
                        }}
                    />
                )}
                <Column
                    field='actions'
                    body={(data: DetailedModel) => (
                        <RowMenu
                            item={data}
                            onShowDetail={(item) =>
                                showObjectDisplay({
                                    header: getUserDisplayName(
                                        item.users_data?.[0]
                                    ),
                                    data: item,
                                })
                            }
                        />
                    )}
                />
            </CMDataTable>
        </>
    );
};

export default OperatorDataTable;
