import { useState } from 'react';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { DateBadge, IPaginator } from 'components/ethercity-primereact';
import { Link, useSearchParams } from 'react-router-dom';
import { TabMenu } from 'primereact/tabmenu';
import DisplayCountBox from 'components/display/DisplayCountBox';
import { useProjectMaybe } from 'hooks/context/project/useProject';
import TargetsDataTable from 'components/datatable/models/target/TargetsDataTable';
import BlockOrderStatusBadge from '../BlockOrderStatusBadge';
import AuthorizationDataTable from 'components/datatable/models/authorization/AuthorizationDataTable';
import { getUserDisplayName } from 'utils/models/user';
import { useLocalization } from 'hooks/context/useLocalization';
import OperatorsOrderDataTable from 'components/datatable/models/operator/OperatorsOrderDataTable';
import CellGroup from 'components/display/CellGroup';
import DocumentDataTable from 'components/datatable/models/document/DocumentDataTable';
import { useAuth } from 'hooks/context/useAuth';
import InsertUnblockOrderModal from 'components/models/UnblockOrder/InsertUnblockOrderModal';
import BlockOrderHistoryViewer from 'components/display/BlockOrderHistoryViewer';
import LoadingMessage from 'components/misc/LoadingMessage';
import useExportTarget from 'hooks/queries/target/useExportTarget';
import AuthorizationConfigDataTable from 'components/datatable/models/authorizationConfig/AuthorizationConfigDataTable';
import {
    isBlockOrderRespondable,
    isTokenBlockOrderRespondable,
} from 'utils/models/blockOrder';
import useExportBlockOrderFiles from 'hooks/queries/block-order/useExportBlockOrderFiles';
import GoBackButton from 'components/misc/GoBackButton';
import InsertBlockOrderModal from '../InsertBlockOrderModal';
import NotFound from 'components/page/NotFound';

type ExpandedBlockOrder = {
    _id: string;
    name: string;
    tags: Ether.CaseManager.Tag[];
    operators: Ether.CaseManager.OperatorsOrder.Detailed[];
    targets: Ether.CaseManager.Target.Detailed[];
    authorizations: Ether.CaseManager.Authorization.Detailed[];
    documents: Ether.CaseManager.Document.Detailed[];
    config: Ether.CaseManager.AuthorizationConfig.Detailed[];
};

const TabViews = {
    AUTHORIZATIONS: 'authorizations',
    TARGETS: 'targets',
    OPERATORS: 'operators',
    DOCUMENTS: 'documents',
    CONFIG: 'config',
    HISTORY: 'history',
};

const DetailedBlockOrderViewSync: React.FC<{
    blockOrder: Ether.CaseManager.BlockOrder.Detailed | null | undefined;
    isLoading: boolean;
    error: Error | null;

    onRefresh: () => void;
    hideBackButton?: boolean;
    hideViewAllButton?: boolean;
    hideInsertButton?: boolean;

    handleAccept?: (blockOrder: Ether.CaseManager.BlockOrder) => void;
    isSubmitting?: boolean;

    tokenData?: CaseManagerApp.TokenInfo;
}> = ({
    blockOrder,
    isLoading,
    error,

    onRefresh,
    hideBackButton,
    hideViewAllButton,
    hideInsertButton,

    handleAccept,
    isSubmitting,

    tokenData,
}) => {
    const [localization] = useLocalization();
    const [searchParams] = useSearchParams();
    const { user, permissions } = useAuth();

    const userRole = tokenData?.userRole ?? user?.role;

    const notOperator = userRole !== 'operator';

    const views = {
        [TabViews.TARGETS]: {
            label: localization.models.target.plural,
        },
        [TabViews.AUTHORIZATIONS]: {
            label: localization.models.authorization.plural,
        },
        [TabViews.OPERATORS]: notOperator
            ? {
                  label: localization.models.operator.plural,
              }
            : null,
        [TabViews.DOCUMENTS]: {
            label: localization.models.document.plural,
        },
        [TabViews.CONFIG]: notOperator
            ? {
                  label: localization.models.authorizationConfig.plural,
              }
            : null,
        [TabViews.HISTORY]: notOperator
            ? {
                  label: localization.components.models.blockOrder.views.history
                      .title,
              }
            : null,
    };
    const validViews = Object.entries(views)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => ({ ...(value as { label: string }), id: key }));

    const [openModal, setOpenModal] = useState<'unblock-order' | 'edit' | null>(
        null
    );

    const startView = searchParams.get('start_view');

    const [pageOptions, setPageOptions] = useState({ page: 1, rows: 20 });

    const [selectedIndex, setSelectedIndex] = useState(() => {
        let index: number;
        if (!notOperator)
            index = validViews.findIndex((v) => v.id === TabViews.TARGETS);
        else index = validViews.findIndex((v) => v.id === startView);
        return index === -1 ? 0 : index;
    });

    const { exportFile: exportTargets, isExporting: isExportingTargets } =
        useExportTarget();
    const { exportFile: exportBlockOrderFiles, isExporting: isExportingFiles } =
        useExportBlockOrderFiles(blockOrder?._id ?? '');

    const projectContext = useProjectMaybe();
    const project = tokenData?.project ?? projectContext;
    if (!project) throw new Error('missing project');

    if (isLoading)
        return <LoadingMessage>{localization.common.loading}</LoadingMessage>;
    if (error) return <h2>{localization.validations.generic.unhandled}</h2>;
    if (!blockOrder) return <NotFound />;

    const exBlockOrder: ExpandedBlockOrder = {
        _id: blockOrder._id,
        name: blockOrder.name,
        authorizations: [],
        operators: [],
        targets: [],
        tags: [],
        documents: [],
        config: [],
    };
    const map: {
        targets: { [key: string]: true };
        tags: { [key: string]: true };
        documents: { [key: string]: true };
    } = {
        targets: {},
        tags: {},
        documents: {},
    };

    exBlockOrder.operators = blockOrder.operators_order_data ?? [];
    exBlockOrder.targets = blockOrder.targets_data ?? [];
    blockOrder.authorizations_data?.forEach((auth) => {
        exBlockOrder.authorizations.push(auth);
        exBlockOrder.config = [
            ...exBlockOrder.config,
            // ...(auth.authorization_configs_data ?? []),
        ];
        // auth.targets_data?.forEach((target) => {
        //     if (!(target._id in map.targets)) {
        //         map.targets[target._id] = true;
        //         exBlockOrder.targets.push(target);
        //     }
        //     target.tags_data?.forEach((tag) => {
        //         if (!(tag._id in map.tags)) {
        //             map.tags[tag._id] = true;
        //             exBlockOrder.tags.push(tag);
        //         }
        //     });
        // });
        auth.documents_data?.forEach((doc) => {
            if (!(doc._id in map.documents)) {
                map.documents[doc._id] = true;
                exBlockOrder.documents.push({
                    ...doc,
                    authorizations_data: [auth],
                });
            }
        });
    });

    const currentView = validViews[selectedIndex]?.id;

    const dataMap = {
        [TabViews.AUTHORIZATIONS]: exBlockOrder.authorizations,
        [TabViews.CONFIG]: exBlockOrder.config,
        [TabViews.DOCUMENTS]: exBlockOrder.documents,
        [TabViews.OPERATORS]: exBlockOrder.operators,
        [TabViews.TARGETS]: exBlockOrder.targets,
        [TabViews.HISTORY]: [],
    };
    const selectedData = dataMap[currentView ?? TabViews.TARGETS];
    if (selectedData == null)
        throw new Error('unexpected: selectedData is undefined');

    const offset = (pageOptions.page - 1) * pageOptions.rows;

    const paginatorProps: IPaginator = {
        page: pageOptions.page,
        rows: pageOptions.rows,
        onPageChange: (e) => setPageOptions(e),
        onRefresh: onRefresh,
        maxPages: Math.ceil(selectedData.length / pageOptions.rows),
        rowsPerPageOptions: [20, 50, 100],
    };

    const openNewUnblockOrderModal = () => setOpenModal('unblock-order');
    const openEditBlockOrderModal = () => setOpenModal('edit');
    const hideModal = () => setOpenModal(null);

    const canCreateUnblock =
        permissions.insertUnblockOrders &&
        blockOrder.block_checker?.sync_status === 'done';

    const isRespondable = tokenData?.value
        ? isTokenBlockOrderRespondable({
              blockOrder: blockOrder,
              userId: tokenData.userId,
          })
        : isBlockOrderRespondable({
              blockOrder: blockOrder,
              userId: user.data?._id,
              permissions: permissions,
          });

    return (
        <section style={{ width: '100%' }}>
            {openModal === 'edit' && (
                <InsertBlockOrderModal
                    visible={openModal === 'edit'}
                    blockOrderToEdit={blockOrder}
                    onHide={hideModal}
                    onInsert={() => {
                        onRefresh();
                        hideModal();
                    }}
                />
            )}
            {openModal === 'unblock-order' && (
                <InsertUnblockOrderModal
                    visible={openModal === 'unblock-order'}
                    onHide={hideModal}
                    onInsert={() => {
                        onRefresh();
                        hideModal();
                    }}
                    startingBlockOrder={blockOrder}
                />
            )}
            <div style={{ display: 'flex', marginTop: '8px' }}>
                {!hideBackButton && <GoBackButton />}

                <div
                    style={{ display: 'flex', marginLeft: 'auto', gap: '8px' }}
                >
                    {isRespondable && handleAccept && (
                        <>
                            <Button
                                icon='pi pi-check'
                                onClick={() => handleAccept(blockOrder)}
                                severity='success'
                                label={
                                    localization.components.common.button
                                        .confirm
                                }
                                disabled={isSubmitting}
                            />
                            <Button
                                icon='pi pi-times'
                                severity='danger'
                                label={
                                    localization.components.common.button.reject
                                }
                                disabled
                            />
                            <Button
                                icon='pi pi-question-circle'
                                severity='warning'
                                label={
                                    localization.components.common.button
                                        .contest
                                }
                                disabled
                            />
                        </>
                    )}
                    {!hideViewAllButton && (
                        <Link to='..'>
                            <Button
                                label={
                                    localization.components.models.blockOrder
                                        .button.viewAll
                                }
                            />
                        </Link>
                    )}
                    {!hideInsertButton && canCreateUnblock && (
                        <Button
                            icon='pi pi-plus'
                            label={
                                localization.components.models.unblockOrder
                                    .button.new
                            }
                            onClick={openNewUnblockOrderModal}
                        />
                    )}
                    {permissions.insertBlockOrders &&
                        blockOrder.status === 'draft' && (
                            <Button
                                icon='pi pi-pencil'
                                label={
                                    localization.components.common.button.edit
                                }
                                onClick={openEditBlockOrderModal}
                            />
                        )}
                    {permissions.exportTargets && (
                        <>
                            <Button
                                icon='pi pi-download'
                                label={
                                    localization.components.models.target.button
                                        .export
                                }
                                onClick={() =>
                                    exportTargets({
                                        params: {
                                            fields: {
                                                blockOrderId: blockOrder._id,
                                            },
                                        },
                                    })
                                }
                                loading={isExportingTargets}
                            />
                        </>
                    )}
                    {permissions.exportBlockOrderFiles && (
                        <Button
                            icon='pi pi-download'
                            onClick={exportBlockOrderFiles}
                            label={
                                localization.components.common.button
                                    .exportFiles
                            }
                            loading={isExportingFiles}
                        />
                    )}
                </div>
            </div>
            <section
                style={{
                    display: 'grid',
                    gridTemplateColumns: '3fr 2fr',
                    gap: '64px',
                    alignItems: 'start',
                    marginTop: '20px',
                }}
            >
                <div>
                    <span>{project.name}</span>
                    <h2 style={{ marginTop: '4px' }}>{exBlockOrder.name}</h2>
                    <div style={{ display: 'flex', gap: '8px' }}>
                        {exBlockOrder.tags.map((t) => (
                            <Badge
                                key={`${t.category}/${t.name}`}
                                value={
                                    t.name
                                        ? `${t.category}/${t.name}`
                                        : t.category
                                }
                            />
                        ))}
                    </div>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(3, 1fr)',
                            gap: '16px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            marginTop: '16px',
                        }}
                    >
                        <CellGroup
                            element1={
                                <span>
                                    {localization.fields.blockOrder.status}:
                                </span>
                            }
                            element2={
                                <BlockOrderStatusBadge
                                    blockOrder={blockOrder}
                                />
                            }
                            span={3}
                        />
                        <CellGroup
                            element1={
                                <span>
                                    {localization.fields.blockOrder.created}:
                                </span>
                            }
                            element2={
                                <DateBadge value={blockOrder.created_at} />
                            }
                        />
                        <CellGroup
                            element1={
                                <span>
                                    {localization.fields.blockOrder.createdBy}:
                                </span>
                            }
                            element2={
                                <span>
                                    {getUserDisplayName(
                                        blockOrder.created_by_data?.[0]
                                    )}
                                </span>
                            }
                            span={2}
                        />
                        <CellGroup
                            element1={
                                <span>
                                    {localization.fields.blockOrder.doneAt}:
                                </span>
                            }
                            element2={
                                blockOrder.block_checker?.synced_at ? (
                                    <DateBadge
                                        value={
                                            blockOrder.block_checker.synced_at
                                        }
                                    />
                                ) : (
                                    <span>-</span>
                                )
                            }
                        />
                        <CellGroup
                            element1={
                                <span>
                                    {
                                        localization.fields.blockOrder
                                            .registeredAt
                                    }
                                    :
                                </span>
                            }
                            element2={
                                blockOrder.registered_at ? (
                                    <DateBadge
                                        value={blockOrder.registered_at}
                                    />
                                ) : (
                                    <span>-</span>
                                )
                            }
                        />
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '16px',
                    }}
                >
                    {views[TabViews.AUTHORIZATIONS] && (
                        <DisplayCountBox
                            label={localization.models.authorization.plural.toUpperCase()}
                            count={exBlockOrder.authorizations.length}
                        />
                    )}
                    {views[TabViews.TARGETS] && (
                        <DisplayCountBox
                            label={localization.models.target.plural.toUpperCase()}
                            count={exBlockOrder.targets.length}
                        />
                    )}
                    {views[TabViews.OPERATORS] && (
                        <DisplayCountBox
                            label={localization.models.operator.plural.toUpperCase()}
                            count={exBlockOrder.operators.length}
                        />
                    )}
                    {views[TabViews.DOCUMENTS] && (
                        <DisplayCountBox
                            label={localization.models.document.plural.toUpperCase()}
                            count={exBlockOrder.documents.length}
                        />
                    )}
                    {views[TabViews.CONFIG] && (
                        <DisplayCountBox
                            label={localization.models.authorizationConfig.plural.toUpperCase()}
                            count={exBlockOrder.config.length}
                        />
                    )}
                </div>
            </section>
            <TabMenu
                activeIndex={selectedIndex}
                onTabChange={(e) => setSelectedIndex(e.index)}
                model={validViews}
                style={{ marginTop: '20px' }}
            />
            {currentView === TabViews.AUTHORIZATIONS && (
                <AuthorizationDataTable
                    value={exBlockOrder.authorizations.slice(
                        offset,
                        offset + pageOptions.rows
                    )}
                    showApproved
                    paginatorProps={paginatorProps}
                />
            )}
            {currentView === TabViews.TARGETS && (
                <TargetsDataTable
                    value={exBlockOrder.targets.slice(
                        offset,
                        offset + pageOptions.rows
                    )}
                    paginatorProps={paginatorProps}
                    refBlockOrder={blockOrder}
                    tokenData={tokenData}
                />
            )}
            {currentView === TabViews.OPERATORS && (
                <OperatorsOrderDataTable
                    value={exBlockOrder.operators.slice(
                        offset,
                        offset + pageOptions.rows
                    )}
                    paginatorProps={paginatorProps}
                />
            )}
            {currentView === TabViews.DOCUMENTS && (
                <DocumentDataTable
                    value={exBlockOrder.documents.slice(
                        offset,
                        offset + pageOptions.rows
                    )}
                    paginatorProps={paginatorProps}
                    documentType='document'
                />
            )}
            {currentView === TabViews.CONFIG && (
                <AuthorizationConfigDataTable
                    value={exBlockOrder.config.slice(
                        offset,
                        offset + pageOptions.rows
                    )}
                    paginatorProps={paginatorProps}
                />
            )}
            {currentView === TabViews.HISTORY && (
                <BlockOrderHistoryViewer blockOrderId={exBlockOrder._id} />
            )}
        </section>
    );
};

export default DetailedBlockOrderViewSync;
