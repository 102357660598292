import {
    FileUpload,
    FileUploadHandlerEvent,
    FileUploadHeaderTemplateOptions,
    FileUploadProps,
} from 'primereact/fileupload';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { handleXHRResponseError } from 'services/ether/utils';

import './style.css';
import { filesize } from 'filesize';
import { Button } from 'primereact/button';
import { useRef } from 'react';
import { CMLocalization } from 'static/language';

type BaseFile = CaseManagerApp.LocalFile.BaseDetails;
type UploadedFile = CaseManagerApp.LocalFile.Uploaded;
type UploadingFile = CaseManagerApp.LocalFile.Uploading;
export type CMFileType = UploadedFile | UploadingFile;

const DragAndDropTemplate: React.FC<{
    localization: CMLocalization.Localization;
}> = ({ localization }) => {
    return (
        <div
            className='flex items-center justify-center flex-row gap-2 border-dashed border-1 rounded-md p-4 w-auto'
            style={{
                background: 'none',
                color: 'var(--dark-pale-grey)',
                borderColor: 'var(--dark-pale-grey)',
            }}
        >
            <i
                className='pi pi-upload'
                style={{
                    fontSize: '1em',
                    color: 'var(--surface-d)',
                }}
            />
            <span>{localization.components.views.file.dragAndDrop}</span>
        </div>
    );
};

export const FileItem: React.FC<{
    file: CMFileType;
    showRemove?: boolean;
    onRemove?: (file: CMFileType) => void;
    disabled?: boolean;
}> = ({ file, showRemove, onRemove, disabled }) => {
    return (
        <div className='flex flex-row justify-between gap-4 mb-4'>
            <div
                style={{
                    borderColor:
                        file.status === 'done'
                            ? 'var(--semaphore-green)'
                            : 'var(--duck-yellow)',
                    flexGrow: 1,
                }}
                className='flex flex-row justify-between py-1 border-t-0 border-r-0 border-l-0 border-b-6 rounded-sm border-solid'
            >
                <div className='flex flex-row items-end'>
                    <span className='mr-2'>
                        <i className='pi pi-file' />
                    </span>
                    <div>
                        <strong className='mr-2'>{file.meta.name}</strong>
                        <span>{filesize(file.meta.size)}</span>
                    </div>
                </div>
                <span
                    className='flex items-center justify-center'
                    style={{
                        background:
                            file.status === 'done'
                                ? 'var(--semaphore-green)'
                                : 'var(--duck-yellow)',
                        padding: '4px',
                        borderRadius: '50%',
                    }}
                >
                    <i
                        className={
                            file.status === 'done'
                                ? 'pi pi-check'
                                : 'pi pi-ellipsis-h'
                        }
                        style={{
                            color: 'var(--white)',
                            minWidth: '18px',
                            textAlign: 'center',
                        }}
                    />
                </span>
            </div>
            {showRemove && (
                <Button
                    icon='pi pi-trash'
                    size='small'
                    rounded
                    severity='danger'
                    onClick={() => onRemove && onRemove(file)}
                    disabled={disabled}
                />
            )}
        </div>
    );
};

type ExcludedFileUploadTypes =
    | 'style'
    | 'ref'
    | 'auto'
    | 'headerTemplate'
    | 'progressBarTemplate'
    | 'itemTemplate'
    | 'chooseOptions'
    | 'uploadHandler'
    | 'onError';

const CMFileUpload: React.FC<
    Omit<FileUploadProps, ExcludedFileUploadTypes> & {
        value: CMFileType[] | CMFileType | File | File[] | null | undefined;
        removeFile?: (file: BaseFile) => void;
        hideUploadButton?: boolean;
        hideDragAndDrop?: boolean;
        onFileSelection?: (params: FileUploadHandlerEvent) => void;
    }
> = ({
    value,
    removeFile,
    hideDragAndDrop,
    hideUploadButton,
    onFileSelection,
    ...props
}) => {
    const [localization] = useLocalization();
    const toast = useToast();
    const fileRef = useRef<FileUpload>(null);

    const onFileUploadError = (xhr: XMLHttpRequest) => {
        toast.show({
            severity: 'error',
            summary: localization.validations.generic.fileUpload,
            detail: handleXHRResponseError(xhr.statusText, localization),
        });
    };

    const headerTemplate = (options: FileUploadHeaderTemplateOptions) =>
        options.chooseButton;

    const files = Array.isArray(value) ? value : !value ? [] : [value];

    return (
        <span className='cm-fileupload-custom'>
            <FileUpload
                {...props}
                auto
                ref={fileRef}
                style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    gap: '8px',
                }}
                headerTemplate={headerTemplate}
                emptyTemplate={
                    hideDragAndDrop ||
                    (props.multiple === false && files.length > 0) ? (
                        <></>
                    ) : (
                        <DragAndDropTemplate localization={localization} />
                    )
                }
                progressBarTemplate={<></>}
                itemTemplate={<></>}
                chooseOptions={{
                    icon: 'pi pi-upload',
                    style: {
                        minWidth: '120px',
                        display: hideUploadButton ? 'none' : undefined,
                    },
                }}
                uploadHandler={(params) => {
                    if (onFileSelection) onFileSelection(params);
                    fileRef.current?.clear();
                }}
                onError={({ xhr }) => onFileUploadError(xhr)}
            />
            {files.map((e, i) => {
                const key =
                    'custom_identifier' in e
                        ? e._id + e.custom_identifier + '_' + i
                        : e.name + e.size + i;
                const file =
                    'custom_identifier' in e
                        ? e
                        : {
                              _id: '',
                              status: 'done' as 'done',
                              custom_identifier: '',
                              meta: {
                                  name: e.name,
                                  size: e.size,
                              },
                          };
                return (
                    <FileItem
                        key={key}
                        file={file}
                        onRemove={removeFile}
                        showRemove
                        disabled={props.disabled}
                    />
                );
            })}
        </span>
    );
};

export default CMFileUpload;
