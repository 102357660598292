import AdaptableTooltip from 'components/misc/AdaptableTooltip';
import {
    EnrichDisplayError,
    EnrichDisplayPending,
} from '../TargetEnrichmentField';
import { useLocalization } from 'hooks/context/useLocalization';
import { useSharedVariables } from 'hooks/context/useSharedVariables';

const TargetSimilarWebVisits: React.FC<{
    target: Ether.CaseManager.Target;
}> = ({ target }) => {
    const [localization] = useLocalization();

    const { values } = useSharedVariables();
    const country = values.target_similarweb_country;

    const data = target.external_services_data?.similarweb?.find(
        (d) => d.country === country
    );
    if (!data) return '-';
    if (data.status === 'pending') return <EnrichDisplayPending />;
    if (data.status === 'error' || !data.values) return <EnrichDisplayError />;

    const { visits, trend } = data.values;
    const formatter = Intl.NumberFormat(localization.getLanguage(), {
        notation: 'compact',
    });
    const formattedVisits = formatter.format(visits);
    const formattedTrend = (trend * 100).toFixed(2) + '%';

    const trendClassname =
        trend === 0 ? '' : trend > 0 ? 'text-green-500' : 'text-red-500';

    const trendElement = (
        <div className={`flex flex-row gap-2 font-bold ${trendClassname}`}>
            {trend !== null && <span>{formattedTrend}</span>}
            {trend !== null &&
                trend !== 0 &&
                (trend > 0 ? (
                    <i className='pi pi-arrow-up-right' />
                ) : (
                    <i className='pi pi-arrow-down-right' />
                ))}
        </div>
    );

    const tooltipClass = `similar-${target._id}`;

    return (
        <div
            className={`max-w-full w-max flex flex-col gap-1 text-left ${tooltipClass}`}
        >
            <AdaptableTooltip target={'.' + tooltipClass}>{`${
                localization.components.models.target.views.similarWebVisits
                    .total
            }: ${new Intl.NumberFormat().format(visits)}`}</AdaptableTooltip>
            {formattedVisits}
            {trendElement}
        </div>
    );
};

export default TargetSimilarWebVisits;
