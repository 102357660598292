/**
 * Returns an object value given its stringified path. Will handle dots (.) as nested entry
 * @param object Given object which will be resolved
 * @param path Path to the object's value
 * @returns value Resolved value
 */
export const getObjectProperty = (object: any, path: string) => {
    if (typeof object !== 'object') return null;
    const value = path.split('.').reduce((a, b) => (a ?? {})[b], object);
    return value;
};

/**
 * Gets all string that are valid following a additive OR excluding operators
 * Operators that does not start with ! will be considered additive, and will generate a new values array
 * Operators starting with ! will exclude filter value from the values array
 * Both exclusion and inclusion operations are mutuable exclusive, and the additive operation will override the excluding operator
 * @param operators Strings that will work as a operators
 * @param values Default string values that will be handled by the excluding operator
 * @returns object.values All valid filtered values
 * @returns object.operation Which operation was considered in filtering the array list
 */
export const filterCreateOrExcludeArrayList = (
    operators: string[],
    values: string[]
): {
    values: string[];
    operation: 'allow' | 'exclude' | 'unfiltered';
} => {
    const allowedValues: string[] = [];
    const excludedValues: string[] = [];

    if (operators?.length) {
        operators.forEach((operator) => {
            if (operator.startsWith('!'))
                excludedValues.push(operator.replace('!', ''));
            else allowedValues.push(operator);
        });
    }

    if (!allowedValues.length && !excludedValues.length)
        return {
            values: values,
            operation: 'unfiltered',
        };
    if (allowedValues.length)
        return {
            values: allowedValues,
            operation: 'allow',
        };
    return {
        values: values.filter((value) => !excludedValues.includes(value)),
        operation: 'exclude',
    };
};

/**
 * Function that handles and grab the first key from many matching the URL
 * It tries to match the URL using regex, a key will have a following format: key{regex}
 * If none of the keys has any matching regex, grabs first non-regex key as default
 * @param key
 * @returns Matching key
 */
export function grabValidKeyMatchingUrl<KeyType extends string>(
    keys: string[]
): KeyType | undefined {
    // Conditions to be default key: the first one from list to have no regex
    const defaultKey = keys.find(
        (possibleKey) => !possibleKey.includes('{')
    ) as KeyType | undefined;
    const keysWithRegex = keys.filter((possibleKey) =>
        possibleKey.includes('{')
    );

    if (!keysWithRegex.length) return defaultKey;

    // Try to match the key with the page URL
    const url = window.location.href;
    const targetKey = keysWithRegex.find((key) => {
        const targetRegexResult = key.match(/\{(.+)\}/);
        const targetRegex = targetRegexResult?.[1];
        if (!targetRegex) return false;

        const pageRegex = new RegExp(targetRegex);
        return pageRegex.test(url);
    });

    if (targetKey) {
        const nonRegexKey = targetKey.replace(/\{.+\}/, '') as KeyType;
        return nonRegexKey;
    }

    return defaultKey;
}

/**
 * Parse an object that might be a single object or an array int oa single array
 * @param item Item to be parsed as an array
 * @returns Array of the item's type
 */
export function parseSingleOrArrayIntoArray<T>(item: T | T[]) {
    if (Array.isArray(item)) return item;
    return [item];
}

/**
 * Through a string, recursively navigates in an object and gets the matching
 * If it exceeds the object depth, it will return null
 * If it reaches an array and the current level is not interpreted as an index number, it will return null
 * @param item Object to be transversed
 * @param path Given path to the object. Each level is separated by a dot, and numbers will attempt to check if it's an array
 * @returns Array of the item's type
 */
export function getObjectPathValue(item: any, path: string | string[]) {
    if (path === '' || !path.length) return item;
    if (typeof item !== 'object' || item == null) return null;
    const [currentLevel, ...remainingPath] = Array.isArray(path)
        ? path
        : path.split('.');
    if (!currentLevel) return null;
    const index = Number(currentLevel);
    if (!isNaN(index) && Array.isArray(item))
        return getObjectPathValue(item[index], remainingPath);
    if (!Array.isArray(item))
        return getObjectPathValue(item[currentLevel], remainingPath);
    return null;
}
