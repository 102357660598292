import { useLocalization } from 'hooks/context/useLocalization';

/**
 * Retrieves the target valid options with localized labels
 */
const useTargetTypeSelectOptions = () => {
    const [localization] = useLocalization();
    return [
        {
            label: localization.components.models.target.badge.type.domain,
            value: 'domain',
        },
        {
            label: localization.components.models.target.badge.type.ipv4,
            value: 'ipv4',
        },
        {
            label: localization.components.models.target.badge.type.ipv6,
            value: 'ipv6',
        },
        {
            label: localization.components.models.target.badge.type.other,
            value: 'other',
        },
    ];
};

export default useTargetTypeSelectOptions;