import {
    CMDataTableColumnFields,
    CMDataTableFiltersFields,
} from 'components/datatable/CMDataTable/types';
import { useLocalization } from 'hooks/context/useLocalization';
import { useMemo } from 'react';
import { getFilterData } from 'utils/datatable';
import useGenerateFilters from 'hooks/columns/useGenerateFilters';
import { FilterMatchMode } from 'primereact/api';
import { grabValidKeyMatchingUrl } from 'utils/object';
import { checkTagFilterMatching } from 'utils/tag';
import useAppConfig from 'hooks/appConfig/useAppConfig';

type ModelColumns =
    | keyof CaseManagerApp.ModelColumns['blockOrder']
    | CaseManagerApp.DefaultNameColumn;
type ModelFilters =
    | keyof CaseManagerApp.ModelFilters['blockOrder']
    | CaseManagerApp.DefaultNameColumn;

/**
 * Hook to get all available columns and filters for the BlockOrder DataTable model
 * @returns object.columnFields - The object describing each column by its field name and available filters (if existing)
 * @returns object.filterOptions - All available filter options to be used by the CMDataTable model
 * @returns object.filterMetaData - All available filter metadata, each value is a DataTableOperatorFilterMetaData for the primereact DataTable component
 */
const useBlockOrderColumnFields = () => {
    const [localization] = useLocalization();

    const config = useAppConfig().block_order;

    const columnFields = useMemo(() => {
        const columnFields: CMDataTableColumnFields<ModelColumns> = {
            _cm_name_id: {
                name: '_cm_name_id',
            },
            authorizations_count: {
                name: 'authorizations_count',
            },
            status: {
                name: 'status',
            },
            validation_status: {
                name: 'validation_status',
            },
            notification_status: {
                name: 'notification_status',
                field: 'notification.status',
            },
            'block_checker.synced_at': {
                name: 'block_checker.synced_at',
            },
            created_at: {
                name: 'created_at',
            },
            created_by: {
                name: 'created_by',
            },
            targets_count: {
                name: 'targets_count',
            },
            registered_at: {
                name: 'registered_at',
            },
        };
        return columnFields;
    }, []);

    const filterFields = useMemo(() => {
        const datatableLocale =
            localization.components.models.blockOrder.datatable;
        const filterConfig = config?.filters;

        const defaultFilters: string[] = [
            '_cm_name_id',
            'status',
            'created_at',
            '_cmapp_tag_target:pirate-brand:multiselect',
        ];

        const filterFields: CMDataTableFiltersFields<ModelFilters> = {
            _cm_name_id: {
                meta: getFilterData(),
                options: {
                    placeholder: datatableLocale.selectName,
                    type: 'string',
                    filterKey: '_cm_name_id',
                },
            },
            status: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.fields.blockOrder.status,
                    type: 'dropdown',
                    selectOptions: [
                        {
                            label: localization.components.models.blockOrder
                                .badge.status.done,
                            value: 'done',
                        },
                        {
                            label: localization.components.models.blockOrder
                                .badge.status.draft,
                            value: 'draft',
                        },
                    ],
                    filterKey: 'status',
                },
            },
            created_at: {
                meta: getFilterData(FilterMatchMode.DATE_IS),
                options: {
                    label: localization.fields.blockOrder.created,
                    type: 'date',
                    filterKey: 'created_at',
                },
            },
        };

        const configFilters = filterConfig ?? defaultFilters;

        const visibleFilterFields: CMDataTableFiltersFields<ModelFilters> = {};

        configFilters.forEach((filter) => {
            const matchedFilter = grabValidKeyMatchingUrl<ModelFilters>(
                filter.split(',')
            );
            if (!matchedFilter) return;
            const tagMatchingObj = checkTagFilterMatching(
                matchedFilter,
                localization
            );
            if (tagMatchingObj.matches) {
                visibleFilterFields[matchedFilter] = tagMatchingObj.filter;
                return;
            }
            if (!filterFields[matchedFilter]) return;
            if (matchedFilter in visibleFilterFields) return;
            visibleFilterFields[matchedFilter] = filterFields[matchedFilter];
        });

        return visibleFilterFields;
    }, [localization, config]);

    const { filterOptions, filterMetaData } =
        useGenerateFilters<ModelFilters>(filterFields);

    return { columnFields, filterOptions, filterMetaData };
};
export default useBlockOrderColumnFields;
