import { CMDataTableColumnFields } from 'components/datatable/CMDataTable/types';
import { renderUnidentifiedDataType } from 'components/datatable/utils';
import NoWrapBadge from 'components/display/NoWrapBadge';
import YesNoBadge from 'components/display/YesNoBadge';
import { DateBadge } from 'components/ethercity-primereact';
import AuthoritySignStatusBadge from 'components/models/Authorization/AuthoritySignStatusBadge';
import AuthorizationNotificationStatusBadge from 'components/models/Authorization/AuthorizationNotificationStatusBadge';
import AuthorizationStatusBadge from 'components/models/Authorization/AuthorizationStatusBadge';
import { Column, ColumnProps } from 'primereact/column';
import { CMLocalization } from 'static/language';
import { getUserDisplayName } from 'utils/models/user';
import { stringifyPercentage } from 'utils/number';
import { getObjectProperty } from 'utils/object';

type DetailedModel = Ether.CaseManager.Authorization.Detailed;
type ModelColumns =
    | keyof CaseManagerApp.ModelColumns['authorization']
    | CaseManagerApp.DefaultNameColumn;
type KeyColumnProps = ColumnProps & { key: string };

/**
 * Generates columns dynamically for the AuthorizationDataTable
 * @param object.columns - The available columns to transform into elements.
 * @param object.tableConfig - The authorizations datatable configuration, listing all visible columns. If not provided or omitted, will default to a generic column rendering
 * @param object.localization - The localization object
 * @param object.sortable - If the table is sortable
 * @param object.hideSignedTab - Whether the signed tab (authority signed status) should be hidden
 * @param object.showApproved - If should show the approved columns
 * @returns columns - All columns in a JSX element format
 */
export const generateAuthorizationColumns = ({
    columns,
    tableConfig,
    localization,
    sortable,
    hideSignedTab,
    showApproved,
}: {
    columns: CMDataTableColumnFields<ModelColumns>;
    tableConfig: CaseManagerApp.AppConfig.Authorization.DataTable | null;
    localization: CMLocalization.Localization;
    sortable: boolean;
    hideSignedTab: boolean;
    showApproved: boolean;
}) => {
    const localeFields = localization.fields.authorization;
    const mapLocale: Record<
        CaseManagerApp.AppConfig.Authorization.DataTable.LocaleFields,
        string
    > = {
        authorization_flow: localization.models.authorizationFlow.singular,
        authorization_config: localization.models.authorizationConfig.singular,
        evidences: localization.models.evidence.plural,
        targets: localization.models.target.plural,
        approved: localeFields.approval,
        approved_by: localeFields.approvedBy,
        authority_status: localeFields.authorityStatus,
        created_at: localeFields.created,
        created_by: localeFields.createdBy,
        notification_status: localeFields.notification,
        notified: localeFields.notified,
        notifieds: localeFields.notifieds,
        notified_at: localeFields.notifiedAt,
        registered_at: localeFields.registeredAt,
        status: localeFields.status,
        submitted: localeFields.submitted,
        offline_percentage: localeFields.offlinePercentage,
        'pirate-brand': localization.models.tag.types['pirate-brand'].plural,
        source: localization.models.tag.types.source.plural,
    };

    // Overrides to default table config
    if (!tableConfig)
        tableConfig = {
            authorization_flow: 'authorization_flow',
            authorization_config: 'authorization_config',
            status: 'status',
            notified: 'notified',
            notified_at: 'notified_at',
            authority_status: 'authority_status',
            targets: 'targets_count',
            evidences: 'evidences_count',
            created_at: 'created_at',
            created_by: 'created_by',
            registered_at: 'registered_at',
            approved: 'approved_at',
            approved_by: 'approved_by',
        };

    const renderedColumns: JSX.Element[] = Object.entries(tableConfig)
        .map(([localeEntry, fieldEntry]) => {
            const localeKey =
                localeEntry as CaseManagerApp.AppConfig.Authorization.DataTable.LocaleFields;
            const columnKey = fieldEntry.startsWith('tag_count:')
                ? fieldEntry
                : columns[
                      fieldEntry as CaseManagerApp.AppConfig.Authorization.DataTable.Fields
                  ]?.name;
            if (!columnKey) return null;
            const header = mapLocale[localeKey];

            let props: KeyColumnProps = {
                key: columnKey,
                field: columnKey,
                header: header,
                body: (data: any) => {
                    const value = getObjectProperty(data, columnKey);
                    return renderUnidentifiedDataType(value);
                },
                sortable: false,
            };
            if (columnKey.startsWith('tag_count:')) {
                const targetTag = columnKey.split('tag_count:')[1];
                if (!targetTag) return null;
                props.body = (data: DetailedModel) =>
                    data.compiled_targets_tags_count?.[targetTag] ?? '-';
            } else {
                switch (columnKey) {
                    case columns.authorization_flow.name:
                        props.body = (data: DetailedModel) => (
                            <span className='whitespace-nowrap'>
                                {data.authorization_flows_data?.[0]?.name ??
                                    '-'}
                            </span>
                        );
                        break;
                    case columns.authorization_config.name:
                        props.body = (data: DetailedModel) => (
                            <span className='whitespace-nowrap'>
                                {data.authorization_configs_data?.[0]?.name ??
                                    '-'}
                            </span>
                        );
                        break;
                    case columns.status.name:
                        props.body = (data: DetailedModel) => (
                            <AuthorizationStatusBadge authorization={data} />
                        );
                        break;
                    case columns.notification_status.name:
                        props.body = (data: DetailedModel) => (
                            <AuthorizationNotificationStatusBadge
                                authorization={data}
                            />
                        );
                        break;
                    case columns.notified.name:
                        props.body = (data: DetailedModel) => (
                            <YesNoBadge value={!!data.block_orders?.length} />
                        );
                        break;
                    case columns.notified_count.name:
                        props.body = (data: DetailedModel) =>
                            data.meta?.notification_data?.notified_count ?? '-';
                        break;
                    case columns.notified_at.name:
                        props.body = (data: DetailedModel) => {
                            const latest = data.block_orders.sort((a, b) =>
                                a.added_at > b.added_at ? 1 : -1
                            )[0];
                            if (!latest) return '-';
                            return (
                                <DateBadge value={new Date(latest.added_at)} />
                            );
                        };
                        break;
                    case columns.authority_status.name:
                        if (hideSignedTab) return null;
                        props.body = (data: DetailedModel) => {
                            const processType =
                                data.authorization_configs_data?.[0]
                                    ?.process_type;
                            if (processType !== 'judicial') return '-';
                            return (
                                <AuthoritySignStatusBadge
                                    authorization={data}
                                />
                            );
                        };
                        break;
                    case columns.targets_count.name:
                        props.body = (data: DetailedModel) =>
                            data.count_data.targets_count ?? '-';
                        break;
                    case columns.evidences_count.name:
                        props.body = (data: DetailedModel) =>
                            data.count_data.evidences_count ?? '-';
                        break;
                    case columns.created_at.name:
                        props.sortable = sortable;
                        props.dataType = 'date';
                        break;
                    case columns.created_by.name:
                        props.body = (data: DetailedModel) => {
                            const user = data.created_by_data?.[0];
                            if (!user) return '-';
                            return getUserDisplayName(user);
                        };
                        break;
                    case columns.registered_at.name:
                        props.sortable = sortable;
                        break;
                    case columns.approved_at.name:
                        if (!showApproved) return null;
                        props.sortable = sortable;
                        props.dataType = 'date';
                        break;
                    case columns.approved_by.name:
                        if (!showApproved) return null;
                        props.body = (data: DetailedModel) => {
                            const approvedBy =
                                data.authorizer_associations_data?.find(
                                    (auth) => auth.response?.accepted
                                );
                            if (!approvedBy) return '-';
                            const approvedByUser = approvedBy.users_data?.find(
                                (u) => !!u._id
                            );
                            if (!approvedByUser) return '-';
                            return getUserDisplayName(approvedByUser);
                        };
                        break;
                    case columns.offline_percentage.name:
                        props.body = (data: DetailedModel) => {
                            const offlinePercentage =
                                data?.meta?.onoff_data?.offline_percentage;
                            if (offlinePercentage == null) return '-';
                            const value =
                                stringifyPercentage(offlinePercentage);
                            return (
                                <NoWrapBadge
                                    value={value}
                                    severity={
                                        offlinePercentage === 0
                                            ? 'danger'
                                            : offlinePercentage < 1
                                            ? 'warning'
                                            : 'success'
                                    }
                                />
                            );
                        };
                        break;
                }
            }
            return <Column {...props} />;
        })
        .filter<JSX.Element>((c): c is JSX.Element => !!c);
    return renderedColumns;
};
