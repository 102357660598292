import { DateBadge } from 'components/ethercity-primereact';
import LabelValuePair from 'components/views/LabelValuePair';
import { getTargetValidAuthorization } from 'utils/models/target';

const DetailTargetNoticeHistory: React.FC<{
    target: Ether.CaseManager.Target;
    refAuthorization: Ether.CaseManager.Authorization | null;
}> = ({ target, refAuthorization }) => {
    const targetAuth = getTargetValidAuthorization({
        target,
        authorizationId: refAuthorization?._id ?? null,
    });
    const meta = targetAuth?.meta;

    const inputDate = meta?.['input-date'];
    const reviewDate = meta?.['review-date'];
    let notices = meta?.notices;
    if (notices && !Array.isArray(notices)) notices = [notices];
    const appeal = {
        id: meta?.['appeal-id'],
        date: meta?.['appeal-date'],
        status: meta?.['appeal-status'],
    };

    return (
        <section>
            <h2>History</h2>
            <div className='grid grid-cols-2 gap-2'>
                <LabelValuePair
                    label='Input date'
                    value={inputDate ? <DateBadge value={inputDate} /> : '-'}
                />
                <LabelValuePair
                    label='Review date'
                    value={reviewDate ? <DateBadge value={reviewDate} /> : '-'}
                />
            </div>
            <h2>Notices</h2>
            {notices
                .filter((n: any) => !!n.status)
                .map((n: any) => (
                    <div className='ml-8 mr-4 p-4 border border-gray-blue-300 border-solid rounded-md'>
                        <div className='flex flex-row gap-2 items-center mb-4'>
                            <b>{n.id}</b>
                        </div>
                        <div className='grid grid-cols-3 gap-2'>
                            <LabelValuePair
                                label='Status'
                                value={n.status ?? '-'}
                            />
                            <LabelValuePair label='Method' value={n.method} />
                            <LabelValuePair
                                label='Date'
                                value={
                                    n.date ? <DateBadge value={n.date} /> : '-'
                                }
                            />
                        </div>
                    </div>
                ))}
            <h2>Appeal</h2>
            <div className='grid grid-cols-3 gap-2'>
                <LabelValuePair label='Appeal ID' value={appeal.id ?? '-'} />
                <LabelValuePair
                    label='Appeal date'
                    value={
                        appeal.date ? <DateBadge value={appeal.date} /> : '-'
                    }
                />
                <LabelValuePair
                    label='Appeal status'
                    value={appeal.status ?? '-'}
                />
            </div>
        </section>
    );
};

export default DetailTargetNoticeHistory;
