import ButtonMenu, { MenuItemWithLoading } from 'components/misc/ButtonMenu';
import GoBackButton from 'components/misc/GoBackButton';
import useAppConfig from 'hooks/appConfig/useAppConfig';
import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import useChangeAuthorizationFlowDialog from 'hooks/dialogs/authorization/useChangeAuthorizationFlowDialog';
import useDuplicateAuthorizationDialog from 'hooks/dialogs/authorization/useDuplicateAuthorizationDialog';
import useSendAuthorizationToAuthorityDialog from 'hooks/dialogs/authorization/useSendAuthorizationToAuthorityDialog';
import useShowAuthorizationStats from 'hooks/dialogs/authorization/useShowAuthorizationStats';
import useInsertDocumentDialog from 'hooks/dialogs/document/useInsertDocumentDialog';
import useInsertDocumentSimpleDialog from 'hooks/dialogs/document/useInsertDocumentSimpleDialog';
import useUpdateOperationDialog from 'hooks/dialogs/operation/useUpdateOperationDialog';
import { useAuthorizationRespond } from 'hooks/models/authorization/useAuthorizationRespond';
import useUpsertAuthorizationDialog from 'hooks/models/authorization/useUpsertAuthorizationDialog';
import useUpsertBlockOrderDialog from 'hooks/models/blockOrder/useUpsertBlockOrderDialog';
import useChangeAuthorizationStatus from 'hooks/mutations/authorization/useChangeAuthorizationStatus';
import useExportAuthorizationFiles from 'hooks/queries/authorization/useExportAuthorizationFiles';
import useDownloadEvidencesFromAuthorization from 'hooks/queries/evidence/useDownloadEvidencesFromAuthorization';
import useExportTarget from 'hooks/queries/target/useExportTarget';
import { Button } from 'primereact/button';
import { DataTableFilterMeta, SortOrder } from 'primereact/datatable';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ChangeAuthorizationStatus } from 'services/ether/case-manager/authorizations/types';
import {
    isAuthorizationFilesExportable,
    isAuthorizationRespondable,
} from 'utils/models/authorization';

const AuthorizationViewButtons: React.FC<{
    authorization: Ether.CaseManager.Authorization.Detailed;
    isAuthorizationSignable: boolean;
    canAuthorizationProceed: boolean;
    temporaryToken?: string;
    hideBackButton?: boolean;
    hideViewAllAuthorizationButton?: boolean;
    targets: {
        filters: DataTableFilterMeta;
        sort: {
            field: string;
            order: SortOrder | undefined;
        } | null;
    };
}> = ({
    authorization,
    isAuthorizationSignable,
    canAuthorizationProceed,
    temporaryToken,
    hideBackButton,
    hideViewAllAuthorizationButton,
    targets,
}) => {
    const [localization] = useLocalization();
    const { permissions, user } = useAuth();
    const userId = user.data?._id;
    const userRole = user.role;
    const config = useAppConfig();

    const authorizationFlow = authorization.authorization_flows_data?.[0];

    const { element: UpsertDialog, showEdit } = useUpsertAuthorizationDialog();
    const { dialog: authorizationStatsDialog, show: showAuthorizationStats } =
        useShowAuthorizationStats();
    const {
        element: UpsertBlockOrderDialog,
        showCreate: showCreateBlockOrder,
    } = useUpsertBlockOrderDialog();
    const {
        exportFile: exportTargets,
        isExporting: isExportingTargets,
        // params: exportingTargetsParams,
    } = useExportTarget();
    const { exportFile: exportAuthFiles, isExporting: isExportingAuthFiles } =
        useExportAuthorizationFiles();
    const {
        downloadFile: downloadEvidences,
        isDownloading: isDownloadingEvidences,
    } = useDownloadEvidencesFromAuthorization({
        authorization_id: authorization?._id ?? '',
    });
    const {
        RespondDialog,
        respondAuthorization,
        isLoading: isRespondLoading,
    } = useAuthorizationRespond();
    const { dialog: duplicateDialog, show: showDuplicateDialog } =
        useDuplicateAuthorizationDialog();
    const { dialog: sendToSignDialog, show: showSendToSignDialog } =
        useSendAuthorizationToAuthorityDialog();
    const { dialog: insertDocumentDialog, showInsert: showInsertDocument } =
        useInsertDocumentDialog({
            type: 'document',
            props: {
                hideBlockOrder: true,
                requiredAuthorization: true,
            },
        });
    const {
        show: showChangeAuthorizationFlow,
        dialog: changeAuthorizationFlowDialog,
    } = useChangeAuthorizationFlowDialog();
    const { dialog: updateOperationDialog, show: showUpdateOperation } =
        useUpdateOperationDialog();
    const [updateStatus, { isLoading: isStatusUpdating }] =
        useChangeAuthorizationStatus();
    const {
        dialog: insertDocumentSimpleDialog,
        show: showInsertDocumentSimple,
    } = useInsertDocumentSimpleDialog();

    const isFilesExportable = isAuthorizationFilesExportable({
        authorization: authorization,
        permissions: permissions,
        token: temporaryToken,
    });
    const isFinish = authorizationFlow?.user_approve_authorization?.find(
        (t) => t === user.role
    );
    const isRespondable = isAuthorizationRespondable({
        permissions: permissions,
        authorization: authorization,
        userId: user.data?._id,
        userRole: user.role,
    });

    const handleSendForApproval = () => {
        if (!authorization) return;
        const status = 'pending';
        const finalData: ChangeAuthorizationStatus.Data = {
            authorization_id: authorization._id,
            status: status,
        };
        if (finalData.authorization_id !== null) updateStatus(finalData);
    };

    const canSendForApproval =
        permissions.changeAuthorizationStatus &&
        authorization.status === 'draft';
    const canSendToAuthority =
        permissions.sendAuthorizationToAuthoritySign &&
        authorization.authority_data?.status === 'available';
    const canInsertBlockOrder =
        permissions.insertBlockOrders &&
        authorization.status === 'approved' &&
        authorization.block_orders.length <= 0;
    const canCreatePartialOrder =
        user.role &&
        config?.block_order?.can_create_partial &&
        permissions.insertBlockOrders &&
        ((authorization.count_data.targets_approved_count ?? 0) >
            (authorization.count_data.blocked_targets_count ?? 0) ||
            authorization.notification_status === 'pending');

    const dialogs = (
        <>
            <RespondDialog />
            <UpsertDialog />
            <UpsertBlockOrderDialog />
            {duplicateDialog}
            {sendToSignDialog}
            {insertDocumentDialog}
            {authorizationStatsDialog}
            {changeAuthorizationFlowDialog}
            {updateOperationDialog}
            {insertDocumentSimpleDialog}
            {!hideBackButton && <GoBackButton />}
        </>
    );

    const authorizationsActionsMenu = useMemo(() => {
        const isEditable =
            authorization.status === 'draft' && permissions.editAuthorizations;
        const canChangeFlow =
            authorization && permissions.changeAuthorizationFlow;
        const canChangeOperation =
            (userRole === 'authorizer-user'
                ? authorization?.status === 'pending' ||
                  authorization?.status === 'draft'
                : true) && permissions.updateOperationAuthorization;
        const canRevertToDraft =
            permissions.changeAuthorizationStatus &&
            (userRole === 'admin' || userRole === 'analyst') &&
            authorization.created_by === userId &&
            authorization.status !== 'draft';
        const canSetToIgnored =
            permissions.changeAuthorizationStatus && userRole === 'admin';
        const menu: MenuItemWithLoading[] = [];
        if (isEditable)
            menu.push({
                command: () => showEdit(authorization),
                icon: 'pi pi-pencil',
                label: localization.components.common.button.edit,
            });
        if (permissions.duplicateAuthorization)
            menu.push({
                label: localization.components.models.authorization.button
                    .duplicateAuthorization,

                command: () =>
                    showDuplicateDialog({
                        authorization: authorization,
                    }),
                icon: 'pi pi-clone',
            });
        if (canChangeFlow)
            menu.push({
                label: localization.components.models.authorization.button
                    .changeFlow,
                command: () =>
                    showChangeAuthorizationFlow({
                        authorizationConfigId:
                            authorization.authorization_config_id,
                        authorizationId: authorization._id,
                        authorizationName: authorization.name,
                        startingFlow: authorization.authorization_flow_id,
                    }),
                icon: 'pi pi-pencil',
            });
        if (canChangeOperation)
            menu.push({
                label: localization.components.models.operation.views
                    .updateOperation.title,
                command: () =>
                    showUpdateOperation({
                        data: {
                            authorization_id: authorization._id,
                        },
                        startingOperationId: authorization.operation_id ?? null,
                        authorizationFlowId:
                            authorization.authorization_flow_id,
                    }),
                icon: 'pi pi-flag-fill',
            });
        if (canRevertToDraft)
            menu.push({
                label: localization.components.models.authorization.button
                    .revertToDraft,
                command: () =>
                    updateStatus({
                        authorization_id: authorization._id,
                        status: 'draft',
                    }),
                loading: isStatusUpdating,
                icon: 'pi pi-undo',
            });
        if (canSetToIgnored)
            menu.push({
                label: localization.components.models.authorization.button
                    .setToIgnored,
                command: () =>
                    updateStatus({
                        authorization_id: authorization._id,
                        status: 'ignored',
                    }),
                loading: isStatusUpdating,
                icon: 'pi pi-ban',
            });
        return menu;
    }, [
        localization,
        authorization,
        permissions,
        userRole,
        userId,
        isStatusUpdating,
        showChangeAuthorizationFlow,
        showDuplicateDialog,
        showEdit,
        showUpdateOperation,
        updateStatus,
    ]);
    const blockOrderActionsMenu: MenuItemWithLoading[] = [
        ...(canInsertBlockOrder
            ? [
                  {
                      label: localization.components.models.blockOrder.button
                          .new,
                      command: () =>
                          showCreateBlockOrder({
                              startingData: {
                                  authorization_flow_id:
                                      authorization.authorization_flow_id,
                                  authorization_config_ids: [
                                      authorization.authorization_config_id,
                                  ],
                                  name: authorization.name,
                                  authorizations: [
                                      {
                                          _id: authorization._id,
                                          name: authorization.name,
                                          config_id:
                                              authorization.authorization_config_id,
                                      },
                                  ],
                              },
                              hideFields: {
                                  authorization_flow: true,
                                  authorization_config_ids: true,
                                  authorizations: true,
                              },
                          }),
                      icon: 'pi pi-plus',
                  },
              ]
            : []),
        ...(canCreatePartialOrder
            ? [
                  {
                      label: localization.components.models.target.button
                          .blockApproved,
                      command: () =>
                          showCreateBlockOrder({
                              startingData: {
                                  authorization_flow_id:
                                      authorization.authorization_flow_id,
                                  authorization_config_ids: [
                                      authorization.authorization_config_id,
                                  ],
                                  name: authorization.name,
                                  authorizations: [
                                      {
                                          _id: authorization._id,
                                          name: authorization.name,
                                          config_id:
                                              authorization.authorization_config_id,
                                      },
                                  ],
                                  operation_id: authorization.operation_id,
                                  type: 'judicial',
                              },
                              hideFields: {
                                  authorization_flow: true,
                                  authorization_config_ids: true,
                                  authorizations: true,
                                  representatives: true,
                                  type: true,
                                  dates: true,
                                  operation: true,
                              },
                          }),
                      icon: 'pi pi-ban',
                  },
              ]
            : []),
    ];
    const filesActionsMenu: MenuItemWithLoading[] = [
        ...(user.role === 'admin'
            ? [
                  {
                      label: localization.components.models.document.button.add,
                      command: () =>
                          showInsertDocument({
                              authorizationConfigId:
                                  authorization.authorization_config_id,
                              authorizationId: authorization._id,
                          }),
                      icon: 'pi pi-file-plus',
                  },
              ]
            : []),
    ];

    const actionsMenu: MenuItemWithLoading[] = [
        {
            label: localization.models.authorization.singular,
            items: authorizationsActionsMenu,
        },
        {
            label: localization.models.blockOrder.singular,
            items: blockOrderActionsMenu,
        },
        {
            label: localization.models.file.singular,
            items: filesActionsMenu,
        },
    ].filter((item) => item.items.length > 0);

    const exportMenu: MenuItemWithLoading[] = [
        ...(permissions.exportTargets
            ? [
                  {
                      icon: 'pi pi-download',
                      command: () =>
                          exportTargets({
                              params: {
                                  fields: {
                                      authorizationId: authorization._id,
                                      isRemoved: false,
                                  },
                                  options: {
                                      rawFilters: targets.filters,
                                      sort: targets.sort,
                                  },
                              },
                          }),
                      label: localization.components.models.target.button
                          .export,
                      //   loading: !!(
                      //       isExportingTargets &&
                      //       exportingTargetsParams &&
                      //       'isRemoved' in exportingTargetsParams &&
                      //       !exportingTargetsParams.isRemoved
                      //   ),
                      loading: isExportingTargets,
                  },
                  {
                      icon: 'pi pi-download',
                      command: () =>
                          exportTargets({
                              params: {
                                  fields: {
                                      authorizationId: authorization._id,
                                      isRemoved: true,
                                  },
                                  options: {
                                      rawFilters: targets.filters,
                                      sort: targets.sort,
                                  },
                              },
                          }),
                      label: localization.components.models.target.button
                          .exportRejected,
                      //   loading: !!(
                      //       isExportingTargets &&
                      //       exportingTargetsParams &&
                      //       'isRemoved' in exportingTargetsParams &&
                      //       exportingTargetsParams.isRemoved
                      //   ),
                      loading: isExportingTargets,
                  },
              ]
            : []),
        ...(isFilesExportable
            ? [
                  {
                      icon: 'pi pi-download',
                      command: () =>
                          exportAuthFiles({
                              filename: null,
                              authorization_id: authorization?._id ?? '',
                          }),
                      label: localization.components.common.button.exportFiles,
                      loading: isExportingAuthFiles,
                  },
              ]
            : []),
        ...(permissions.downloadEvidencesAuthorization &&
        (authorization.count_data.evidences_count ?? 0) > 0
            ? [
                  {
                      icon: 'pi pi-download',
                      command: () => downloadEvidences(),
                      label: localization.components.models.evidence.button
                          .downloadMany,
                      loading: isDownloadingEvidences,
                  },
              ]
            : []),
    ];

    const actionsMenuLoading = !!actionsMenu.find((t) => !!t.disabled);
    const exportMenuLoading = !!exportMenu.find((t) => !!t.disabled);

    return (
        <div className='flex mt-2 items-start'>
            {dialogs}
            <div className='inline-flex ml-auto gap-2 max-w-[70%] flex-wrap justify-end'>
                {/* Important quick actions buttons */}
                {canSendForApproval && (
                    <Button
                        label={
                            isFinish
                                ? localization.components.models.authorization
                                      .button.finish
                                : localization.components.models.authorization
                                      .button.sendForApproval
                        }
                        severity='success'
                        disabled={!canAuthorizationProceed}
                        onClick={handleSendForApproval}
                        loading={isStatusUpdating}
                    />
                )}
                {isRespondable && (
                    <Button
                        icon='pi pi-check'
                        onClick={() =>
                            respondAuthorization(authorization, true)
                        }
                        severity='success'
                        label={
                            !user.role?.startsWith('operator')
                                ? localization.components.common.button.approve
                                : localization.components.common.button.confirm
                        }
                        disabled={isRespondLoading || !canAuthorizationProceed}
                    />
                )}
                {canSendToAuthority && (
                    <Button
                        label={
                            localization.components.models.authorization.button
                                .sendToAuthority
                        }
                        onClick={() =>
                            showSendToSignDialog({
                                authorization: authorization,
                            })
                        }
                        icon='pi pi-envelope'
                    />
                )}
                {isAuthorizationSignable && (
                    <Button
                        onClick={() =>
                            showInsertDocumentSimple({
                                authorization: authorization,
                            })
                        }
                        label={
                            localization.components.models.authorization.button
                                .sendSignedDoc
                        }
                        severity='danger'
                        disabled={!canAuthorizationProceed}
                    />
                )}
                <ButtonMenu
                    buttonProps={{
                        label: localization.common.actions,
                        icon: 'pi pi-pencil',
                        loading: actionsMenuLoading,
                    }}
                    items={actionsMenu}
                />
                <ButtonMenu
                    buttonProps={{
                        label: localization.common.export,
                        icon: 'pi pi-download',
                        loading: exportMenuLoading,
                    }}
                    items={exportMenu}
                />
                {permissions.getAuthorizationStats && (
                    <Button
                        icon='pi pi-chart-bar'
                        label={localization.components.common.stats.header}
                        onClick={() =>
                            showAuthorizationStats({
                                id: authorization._id,
                                name: authorization.name,
                            })
                        }
                    />
                )}
                {!hideViewAllAuthorizationButton &&
                    user.role !== 'authorizer-strict' && (
                        <Link to='/authorizations'>
                            <Button
                                label={
                                    localization.components.models.authorization
                                        .button.viewAll
                                }
                                icon='pi pi-list'
                            />
                        </Link>
                    )}
            </div>
        </div>
    );
};

export default AuthorizationViewButtons;
