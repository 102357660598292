import DetailTargetModal from 'components/models/Targets/DetailTargetModal';
import { useMemo, useState } from 'react';

type ShowParams = {
    target: Ether.CaseManager.Target.Detailed;
    refAuthorization: Ether.CaseManager.Authorization | null;
    refBlockOrder: Ether.CaseManager.BlockOrder | null;
    refUnblockOrder: Ether.CaseManager.UnblockOrder | null;
};

/**
 * A modal/dialog to display the target's details, which is the menu that includes various options to inpesct the target
 * @returns object.dialog - The rendered element for the dialog. Must be supplied on the page to be used or it won't show
 * @returns object.show - Function to show the dialog
 */
const useShowDetailTargetDialog = () => {
    const [visible, setVisible] = useState<boolean>(false);
    const [data, setData] = useState<ShowParams | null>(null);

    const hide = () => setVisible(false);
    const show = (data: ShowParams) => {
        setData(data);
        setVisible(true);
    };

    const dialog = useMemo(
        () => (
            <DetailTargetModal
                style={{ width: '50%' }}
                visible={visible}
                onHide={hide}
                target={data?.target}
                refAuthorization={data?.refAuthorization ?? null}
                refBlockOrder={data?.refBlockOrder ?? null}
                refUnblockOrder={data?.refUnblockOrder ?? null}
            />
        ),
        [visible, data]
    );

    return {
        dialog,
        show,
    };
};

export default useShowDetailTargetDialog;
