import SelectOneOperation from '../SelectOneOperation';
import SetAsDefaultCheckbox from 'components/form/SetAsDefaultCheckbox';
import { SelectOrCreateOperationProps } from './types';
import { useAuth } from 'hooks/context/useAuth';
import InsertOperationButton from '../InsertOperationButton';
import { UseUpsertOperationDialogOptions } from 'hooks/models/operation/useUpsertOperationDialog';
import { useCallback, useState } from 'react';

const SelectOrCreateOperation: React.FC<
    SelectOrCreateOperationProps &
        Omit<UseUpsertOperationDialogOptions, 'onCreate'>
> = ({
    operationId,
    setOperation,
    isDefaultOperation,
    setIsDefaultOperation,
    ignoreDefaultInit,
    required,
    disabled,
    hidden,
    fixedAuthorizationFlowType,
    startingOperationId,
}) => {
    const { user } = useAuth();

    const loadDefaultOperation = useCallback(
        (operations: Ether.CaseManager.Operation[]) => {
            const userOpId = user.data?.data?.default_operation_id;
            if (!!operationId || !userOpId) return;
            const targetOp = operations.find(
                (op) =>
                    op._id === userOpId &&
                    (!fixedAuthorizationFlowType ||
                        fixedAuthorizationFlowType ===
                            op.authorization_flow_type)
            );
            if (targetOp) setOperation(targetOp);
        },
        [
            fixedAuthorizationFlowType,
            operationId,
            setOperation,
            user.data?.data?.default_operation_id,
        ]
    );

    // TODO: Seems like making a select component is a bad habit, separate fetch values into a hook, and select elements to only receive the list
    // tip: this is a big refactor considering how badly i made this
    // thinking about this through, sometimes it will not work as expected, for instance:
    // in this case its best to have the fetch done in this component, and while its good to have convenience, some issues were found when trying to
    // migrate from it, where did it go wrong?
    const [fetchedOperations, setFetchedOperations] = useState<
        Ether.CaseManager.Operation[]
    >([]);

    return (
        <div className='flex flex-col gap-2'>
            <SelectOneOperation
                value={operationId}
                queryOptions={{
                    devFilters: {
                        authorization_flow_type: fixedAuthorizationFlowType,
                    },
                }}
                onChange={(op) => setOperation(op ?? null)}
                onLoad={(operations) => {
                    setFetchedOperations(operations);
                    if (startingOperationId)
                        setOperation(
                            operations.find(
                                (op) => op._id === startingOperationId
                            ) ?? null
                        );
                    else if (ignoreDefaultInit) return;
                    else loadDefaultOperation(operations);
                }}
                hidden={hidden}
                showClear
                required={required}
                disabled={disabled}
            />
            {!hidden && (
                <InsertOperationButton
                    fixedAuthorizationFlowType={fixedAuthorizationFlowType}
                    onCreate={(id) =>
                        setOperation(
                            fetchedOperations.find((op) => op._id === id) ??
                                null
                        )
                    }
                    disabled={disabled}
                />
            )}
            {!hidden &&
                setIsDefaultOperation &&
                isDefaultOperation !== undefined && (
                    <SetAsDefaultCheckbox
                        checked={isDefaultOperation}
                        onChange={(e) =>
                            setIsDefaultOperation(!!e.target.checked)
                        }
                        disabled={disabled}
                    />
                )}
        </div>
    );
};

export default SelectOrCreateOperation;
