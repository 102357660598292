import { useState } from 'react';
import GoBackButton from 'components/misc/GoBackButton';
import UploadOperatorsCsvModal from 'components/models/Operators/UploadOperatorsCsvModal';
import CMBreadCrumb from 'components/page/CMBreadCrumb';
import CacheControl from 'controller/cache/cacheController';
import { useAuth } from 'hooks/context/useAuth';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import { useLocalization } from 'hooks/context/useLocalization';
import { useProject } from 'hooks/context/project/useProject';
import { Button } from 'primereact/button';
import useDetailOperatorCompany from 'hooks/queries/company/useDetailOperatorCompany';
import CompanyDataTable from 'components/datatable/models/company/CompanyDataTable';

const ListOperatorsCompany = () => {
    const [localization] = useLocalization();
    const project = useProject();
    const { permissions } = useAuth();

    const [uploadCsvModalVisible, setUploadCsvModalVisible] = useState(false);

    const { filters, pageOptions, setFilters, setPageOptions } =
        useInitDataTableState({
            // filters: operatorFilters,
            pageOptions: {
                startingRow:
                    CacheControl.UserConfig.get()['paginatorRows']?.company,
            },
        });

    const companyQuery = useDetailOperatorCompany({
        project_id: project._id,
        options: {
            rawFilters : filters,
            limit: pageOptions.rows,
            offset: pageOptions.offset,
        },
    });

    return (
        <section>
            <CMBreadCrumb projectLabel={project.name} />
            <GoBackButton />
            <div className='flex flex-row justify-between'>
                <h2>
                    {project.name} - {localization.models.operator.plural}
                </h2>
                <div className='flex flex-col justify-center'>
                    {permissions.importOperatorsCSV && (
                        <Button
                            className='ml-auto'
                            label={
                                localization.components.models.operator.views
                                    .insertCsv.title
                            }
                            icon='pi pi-plus'
                            onClick={() => setUploadCsvModalVisible(true)}
                        />
                    )}
                </div>
            </div>
            <UploadOperatorsCsvModal
                visible={uploadCsvModalVisible}
                onHide={() => setUploadCsvModalVisible(false)}
            />
            <CompanyDataTable
                loading={companyQuery.isFetching}
                value={companyQuery.data?.payload}
                filters={filters}
                setFilters={(e) => setFilters(e)}
                paginatorProps={{
                    page: pageOptions.page,
                    rows: pageOptions.rows,
                    onPageChange: (options) => setPageOptions(options),
                    disableNext:
                        (companyQuery.data?.payload.length ?? 9999) < pageOptions.rows,
                    onRefresh: companyQuery.refresh,
                }}
                showOperatorCount
                showExpand='operator'
            />
        </section>
    );
};

export default ListOperatorsCompany;
