import { useProject } from 'hooks/context/project/useProject';
import { OperatorsTargetSummaryProps } from './types';
import useDetailOperators from 'hooks/queries/operator/useDetailOperators';
import OperatorDataTable from '../../../datatable/models/operator/OperatorsDataTable';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import useOperatorColumnFields from 'hooks/columns/models/useOperatorColumnFields';

const OperatorsTargetSummary: React.FC<OperatorsTargetSummaryProps> = ({
    targetValue,
    model,
    modelId,
}) => {
    const project = useProject();

    const key = model === 'block_order' ? 'block_order_id' : 'unblock_order_id';

    const { filterMetaData: operatorFilters } = useOperatorColumnFields();

    const { filters, setFilters, pageOptions, setPageOptions } =
        useInitDataTableState({
            filters: operatorFilters,
            pageOptions: {
                startingRow: 5,
            },
        });

    const operatorsQuery = useDetailOperators({
        project_id: project._id,
        options: {
            rawFilters: filters,
            limit: pageOptions.rows,
            offset: pageOptions.offset,
            devFilters: {
                [key]: modelId,
                target_value: targetValue,
            },
        },
    });

    return (
        <OperatorDataTable
            value={operatorsQuery.data?.payload}
            loading={operatorsQuery.isFetching}
            filters={filters}
            setFilters={setFilters}
            paginatorProps={{
                hideRowsPerPage: true,
                onPageChange: (e) => setPageOptions(e),
                page: pageOptions.page,
                rows: pageOptions.rows,
                onRefresh: operatorsQuery.refresh,
                disableNext:
                    !operatorsQuery.data?.payload.length ||
                    operatorsQuery.data.payload.length < pageOptions.rows,
            }}
            targetAnswerModel={model}
        />
    );
};

export default OperatorsTargetSummary;
