import { useProject } from 'hooks/context/project/useProject';
import CMBreadCrumb from 'components/page/CMBreadCrumb';
import { useLocalization } from 'hooks/context/useLocalization';
import GoBackButton from 'components/misc/GoBackButton';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import CacheControl from 'controller/cache/cacheController';
import useTagColumnFields from 'hooks/columns/models/useTagColumnFields';
import TagDataTable from 'components/datatable/models/tag/TagDataTable';
import { useParams } from 'react-router-dom';
import useDetailTag from 'hooks/queries/tag/useDetailTag';
import { ValidTagCategory, ValidTagModelType } from 'services/ether/case-manager/tags/types';

const ListTagWithCategory = () => {
    const project = useProject();
    const [localization] = useLocalization();

    const params = useParams<{ modelType: string; category: string }>();

    const modelType = params.modelType as ValidTagModelType;
    const category = params.category as ValidTagCategory;

    const { filterMetaData } = useTagColumnFields();

    const { filters, pageOptions, setFilters, setPageOptions, sort, setSort } =
        useInitDataTableState({
            filters: filterMetaData,
            pageOptions: {
                startingRow:
                    CacheControl.UserConfig.get()['paginatorRows']?.tag,
            },
        });

    const query = useDetailTag({
        project_id : project._id,
        options: {
            rawFilters: filters,
            limit: pageOptions.rows,
            offset: pageOptions.offset,
            sort,
            devFilters : {
                category : category,
                model_type : modelType,
            },
        },
    });

    return (
        <section>
            <CMBreadCrumb projectLabel={project.name} />
            <GoBackButton />
            <div className='flex gap-2 items-center'>
                <h2>
                    {project.name} -{' '}
                    {localization.models.tag.types[category]?.plural}
                </h2>
            </div>
            <TagDataTable
                loading={query.isLoading}
                value={query.data?.payload}
                filters={filters}
                setFilters={(e) => setFilters(e)}
                sortField={sort?.field}
                sortOrder={sort?.order}
                onSort={(e) =>
                    setSort({
                        field: e.sortField,
                        order: e.sortOrder,
                    })
                }
                paginatorProps={{
                    page: pageOptions.page,
                    rows: pageOptions.rows,
                    onPageChange: (options) => setPageOptions(options),
                    disableNext:
                        !query.data ||
                        query.data.payload.length < pageOptions.rows,
                    onRefresh: query.refresh,
                }}
            />
        </section>
    );
};

export default ListTagWithCategory;
