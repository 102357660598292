import { useState } from 'react';
import SideVerticalMenu, {
    MenuItemProps,
} from 'components/page/SideVerticalMenu';
import { Dialog, DialogProps } from 'primereact/dialog';

import './style.css';

export type DetailedMenuItem = MenuItemProps & {
    element: React.FC;
}

type DetailedMenuProps = {
    overhead?: JSX.Element;
    menuItems: DetailedMenuItem[];
};

const DetailedMenu: React.FC<DetailedMenuProps> = ({ overhead, menuItems }) => {
    const [activeId, setActiveId] = useState(menuItems[0]?.id);

    return (
        <section className='grid grid-cols-10 gap-4 h-full'>
            <div className='col-span-2'>
                <SideVerticalMenu
                    menuItems={menuItems}
                    activeId={activeId}
                    onChange={({ id }) => setActiveId(id)}
                />
            </div>
            <div className='col-span-8 overflow-scroll h-full flex flex-col'>
                {overhead}
                {menuItems.map(
                    (t) => activeId === t.id && <t.element key={t.id} />
                )}
            </div>
        </section>
    );
};

const DetailedModalWithMenu: React.FC<
    DialogProps & DetailedMenuProps & {
        header?: string;
        headerLink?: string | null;
        overrideView?: JSX.Element;
    }
> = ({ header, headerLink, overrideView, menuItems, overhead, ...props }) => {
    const headerElement = headerLink ? (
        <a
            href={headerLink}
            rel='noreferrer noopener'
            target='_blank'
            className='flex flex-row gap-2 items-center justify-start'
        >
            <span>{header}</span>
            <i className='pi pi-external-link' />
        </a>
    ) : (
        <span>{header}</span>
    );

    let item = overrideView ?? (
        <DetailedMenu menuItems={menuItems} overhead={overhead} />
    );

    return (
        <Dialog
            header={<div className='flex flex-row gap-2'>{headerElement}</div>}
            {...props}
            className={[props.className, 'h-[88vh]', 'min-w-[80vw]'].join(' ')}
            contentClassName={[props.contentClassName, 'h-full', 'w-full'].join(
                ' '
            )}
        >
            {item}
        </Dialog>
    );
};

export default DetailedModalWithMenu;
