import { Dialog } from 'primereact/dialog';
import { IObjectDisplayModalProps } from 'components/ethercity-primereact';
import LocalizedObjectDisplay from '../LocalizedObjectDisplay';

export const LocalizedObjectDisplayModal: React.FC<
    IObjectDisplayModalProps
> = ({ displayData, sortKeys, style, ...rest }) => {
    return (
        <Dialog style={{ width: '70vw', ...style }} {...rest}>
            {displayData && (
                <LocalizedObjectDisplay
                    displayData={displayData}
                    sortKeys={sortKeys}
                />
            )}
        </Dialog>
    );
};

export default LocalizedObjectDisplayModal;
