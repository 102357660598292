import { Logo } from 'components/ethercity-primereact';
import { ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import { Menubar } from 'primereact/menubar';
import { MenuItem, MenuItemOptions } from 'primereact/menuitem';
import MenuTemplate from 'components/misc/MenuTemplate';
import { useAuth } from 'hooks/context/useAuth';

interface INavbar {
    useNoRedirectLogo?: boolean;
    navigations?: MenuItem[];
    endContent?: ReactElement;
}

const Navbar: React.FC<INavbar> = ({
    navigations,
    useNoRedirectLogo,
    endContent,
}) => {
    const { user } = useAuth();

    const navigation = useMemo(() => {
        if (!navigations) return null;
        return (
            <Menubar
                model={navigations.map((nav) => {
                    if (!nav.items)
                        return {
                            ...nav,
                            template: (
                                item: MenuItem,
                                options: MenuItemOptions
                            ) => (
                                <MenuTemplate
                                    item={item}
                                    options={options}
                                />
                            ),
                        };
                    return nav;
                })}
            />
        );
    }, [navigations])

    const gradientStart =
        user.role === 'admin'
            ? 'var(--darkish-red)'
            : user.role === 'analyst'
            ? 'var(--sky-blue-dark)'
            : user.role?.startsWith('authorizer')
            ? 'var(--duck-yellow)'
            : user.role?.includes('visitor')
            ? 'var(--purple-grey)'
            : null;

    return (
        <section
            className='navbar'
            style={{
                background: gradientStart
                    ? `linear-gradient(${gradientStart}, 2%, var(--darkish-blue))`
                    : undefined,
            }}
        >
            <div className='navbar-content'>
                {useNoRedirectLogo ? (
                    <Logo
                        label='Case Manager'
                        labelRatio={0.9}
                        size={16}
                        enableHover={false}
                    />
                ) : (
                    <Link to='/'>
                        <Logo
                            label='Case Manager'
                            labelRatio={0.9}
                            size={16}
                            enableHover={true}
                        />
                    </Link>
                )}
                {navigation}
            </div>
            <div className='navbar-end'>{endContent}</div>
        </section>
    );
};

export default Navbar;
