import { useMemo } from 'react';
import { HomeButtons, HomeButtonSet } from '../HomeButtonSet';
import useHomeViews, { ValidHomeViews } from 'hooks/home/useHomeViews';
import ViewTablesWithTabs from 'components/display/ViewTablesWithTabs';
import { TableWithTabView } from 'components/display/ViewTablesWithTabs/types';

/**
 * Component to render the home view with multiple layers of tables, using the ViewTablesWithTabs alongside of HomeButtonSet
 * Each entry of array will include its set of ViewTablesWithTabs and buttons
 * Each entry will be on top of eachother, following the provided order
 * @param props.views The list of views to be assigned
 * @param props.views[].key Unique key that identifies that component
 * @param props.views[].tabs List of tabs by its keys to be provided, following the 'useHomeViews' keys
 * @param props.views[].buttons Optional buttons that may appear 
 * @param props.views[].header Optional header to display the name of the section
 */
export const HomeViewSet: React.FC<{
    views: {
        key: string;
        tabs: ValidHomeViews[];
        buttons?: HomeButtons[];
        header?: string;
    }[];
}> = ({ views }) => {
    const { views: availableViews } = useHomeViews();

    const elementViews = useMemo(() => {
        const elements = views.map(({ key, header, buttons, tabs }) => {
            const filteredViews: TableWithTabView[] = [];

            tabs.forEach((tabKey) => {
                if (!(tabKey in availableViews)) return;
                const view = availableViews[tabKey];
                if (!view) return;
                filteredViews.push(view);
            });

            if (!filteredViews.length) return null;

            return (
                <section key={key}>
                    {header && <p>{header}</p>}
                    {buttons && (
                        <div className='flex flex-row w-full justify-end gap-2 mb-1'>
                            <HomeButtonSet buttons={buttons} />
                        </div>
                    )}
                    <ViewTablesWithTabs
                        mainModel={null}
                        uniqueQueryKey={key}
                        views={filteredViews}
                    />
                </section>
            );
        });
        return elements.filter<JSX.Element>((e): e is JSX.Element => !!e);
    }, [views, availableViews]);

    return elementViews;
};
