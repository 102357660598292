import { useLocalization } from 'hooks/context/useLocalization';
import { Badge, BadgeProps } from 'primereact/badge';

const OperationFlowTypeBadge: React.FC<{
    item: Ether.CaseManager.Operation;
    badgeProps?: BadgeProps;
}> = ({ item, badgeProps }) => {
    const type = item.authorization_flow_type;
    const [localization] = useLocalization();
    if (!type) return '-';
    badgeProps = {
        ...badgeProps,
        severity: 'info',
        value: type.toUpperCase(),
    };
    switch (type) {
        case 'standard':
            badgeProps.value =
                localization.components.models.authorizationFlow.badge.type.standard.toLocaleUpperCase();
            break;
        case 'duty':
            badgeProps.value =
                localization.components.models.authorizationFlow.badge.type.duty.toLocaleUpperCase();
            break;
    }
    return <Badge {...badgeProps} />;
};

export default OperationFlowTypeBadge;
