import { createContext, useCallback, useContext, useState } from 'react';

type SharedVariables = {
    target_similarweb_country: string;
};
type SharedVariablesContextDefinition = {
    updateValue: <K extends keyof SharedVariables>(
        key: K,
        value: SharedVariables[K]
    ) => void;
    values: SharedVariables;
};

const initialValue = {
    target_similarweb_country: 'global',
};

const SharedVariablesContext =
    createContext<SharedVariablesContextDefinition | null>(null);

const SharedVariablesProvider: React.FC<{
    children?: React.ReactNode;
}> = ({ children }) => {
    const [variables, setVariables] = useState<SharedVariables>(initialValue);

    const updateValue: <K extends keyof SharedVariables>(
        key: K,
        value: SharedVariables[K]
    ) => void = useCallback((key, value) => {
        if (variables[key] === value) return;
        setVariables((old) => ({
            ...old,
            [key]: value,
        }));
    }, [variables]);

    return (
        <>
            <SharedVariablesContext.Provider
                value={{
                    updateValue: updateValue,
                    values: variables,
                }}
            >
                {children}
            </SharedVariablesContext.Provider>
        </>
    );
};

const useSharedVariables = () => {
    const context = useContext(SharedVariablesContext);
    if (!context)
        throw new Error(
            'SharedVariablesContext must be used inside a SharedVariablesContext.Provider'
        );
    return context;
};

export { SharedVariablesProvider, useSharedVariables };
