import ObjectDisplay, {
    IObjectDisplayProps,
} from 'components/ethercity-primereact/components/utils/ObjectDisplay';
import { useLocalization } from 'hooks/context/useLocalization';

const LocalizedObjectDisplay: React.FC<IObjectDisplayProps> = (props) => {
    const [localization] = useLocalization();
    return (
        <ObjectDisplay
            {...props}
            keyLabel={localization.common.key}
            valueLabel={localization.common.value}
            emptyLabel={localization.common.empty}
            showAllLabel={
                localization.components.common.objectJsonDisplay.showAll
            }
            hideLabel={localization.components.common.objectJsonDisplay.hideAll}
        />
    );
};

export default LocalizedObjectDisplay;
