import { useLocalization } from 'hooks/context/useLocalization';
import { SelectManyItemsProps } from 'components/form/SelectItems/types';
import SelectManyItems from 'components/form/SelectItems/SelectManyItems';

/**
 * Component to select various representatives
 * @param props - General filters for the SelectMany element
 */
const SelectManyRepresentatives: React.FC<
    | SelectManyItemsProps<Ether.CaseManager.Representative> & {
          representatives: Ether.CaseManager.Representative[];
      }
> = ({ representatives, ...props }) => {
    const [localization] = useLocalization();

    return (
        <SelectManyItems
            title={localization.models.representative.plural}
            label={localization.models.representative.plural}
            placeholder={
                localization.components.models.representative.form
                    .selectManyPlaceholder
            }
            optionLabel='name'
            optionValue='id'
            items={representatives}
            itemsLoading={false}
            {...props}
        />
    );
};

export default SelectManyRepresentatives;
