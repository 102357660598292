import { useEffect, useMemo, useState } from 'react';

import { Button } from 'primereact/button';
import { Datepicker, InputText } from 'components/ethercity-primereact';
import { useProject } from 'hooks/context/project/useProject';
import { useLocalization } from 'hooks/context/useLocalization';
import { Divider } from 'primereact/divider';
import { InsertBlockOrderForm, InsertBlockOrderModalProps } from './types';
import SelectManyOperatorTags from 'components/models/Operators/SelectManyOperatorTags';
import useUpsertBlockOrder from 'hooks/mutations/blockOrder/useUpsertBlockOrder';
import SelectManyAuthorizations from 'components/models/Authorization/SelectManyAuthorizations';
import SelectOneAuthorizationFlow from 'components/models/AuthorizationFlow/SelectOneAuthorizationFlow';
import { UpsertBlockOrderEP } from 'services/ether/case-manager/block-orders/types';
import SelectManyAuthorizationConfigs from 'components/models/AuthorizationConfig/SelectManyAuthorizationConfigs';
import SelectOneBlockOrderType from 'components/models/BlockOrder/SelectOneBlockOrderType';
import { updateUserDefaultOperation } from 'services/ether/case-manager/operation';
import SelectOrCreateOperation from 'components/models/Operation/SelectOrCreateOperation';
import { CheckActionTargetWarningEP } from 'services/ether/case-manager/targets/types';
import { checkActionTargetWarning } from 'services/ether/case-manager/targets';
import useShowTargetCheckWarning from 'hooks/dialogs/target/useShowTargetCheckWarning';
import { useToast } from 'hooks/context/useToast';
import { getErrorToast } from 'utils/errorHandler';
import LightDialog from 'components/display/LightDialog';
import SelectManyRepresentatives from 'components/models/Representative/SelectManyRepresentatives';
import { getOperationRepresentatives } from 'utils/models/operation';
import useAppConfig from 'hooks/appConfig/useAppConfig';

const InsertBlockOrderModal: React.FC<InsertBlockOrderModalProps> = ({
    blockOrderToEdit,
    onInsert,
    hideFields,
    blockFields,
    startingData,
    ...props
}) => {
    const project = useProject();
    const config = useAppConfig();

    // TODO: All queries should be done top-level here, one issue is trying to sync all selects...

    const [formData, setFormData] = useState<InsertBlockOrderForm>({
        _id: blockOrderToEdit?._id ?? null,
        authorization_flow: startingData?.authorization_flow ?? null,
        authorization_config_ids:
            startingData?.authorization_config_ids ??
            blockOrderToEdit?.authorization_config_ids ??
            [],
        name: startingData?.name ?? blockOrderToEdit?.name ?? '',
        type: startingData?.type ?? blockOrderToEdit?.type ?? null,
        authorizations:
            startingData?.authorizations ??
            (blockOrderToEdit?.authorizations_data
                ? blockOrderToEdit.authorizations_data.map((a) => ({
                      _id: a._id,
                      name: a.name,
                      config_id: a.authorization_config_id,
                  }))
                : []),
        operator_tags:
            startingData?.operator_tags ??
            blockOrderToEdit?.operator_tags ??
            [],
        // start_date -> Date that the operation starts
        // start_hour -> (Optional) time which should be selected alongside complete_hour
        // complete_hour -> Time which the operation ends on the same day as the start date
        // end_date -> (Optional) field which indicates the unblocking date
        start_date:
            startingData?.start_date ?? blockOrderToEdit?.start_date ?? null,
        start_hour:
            startingData?.start_hour ?? blockOrderToEdit?.start_date ?? null,
        complete_hour:
            startingData?.complete_hour ??
            blockOrderToEdit?.complete_date ??
            null,
        end_date: startingData?.end_date ?? blockOrderToEdit?.end_date ?? null,
        operation: startingData?.operation ?? null,
        representatives: blockOrderToEdit?.representatives ?? [],
    });

    const [representativesOptions, setRepresentativeOptions] = useState<
        Ether.CaseManager.Representative[]
    >([]);

    const currentOperation = formData.operation;
    const currentAuthorizationFlowId = formData.authorization_flow?._id;

    useEffect(() => {
        if (!currentOperation) return;
        if (!currentAuthorizationFlowId) return;
        const options = getOperationRepresentatives({
            operation: currentOperation,
            flowId: currentAuthorizationFlowId,
        });
        setFormData((old) => ({
            ...old,
            representatives: options.representatives.filter((repr) =>
                options.defaultRepresentatives.includes(repr.id)
            ),
        }));
        setRepresentativeOptions(options.representatives);
    }, [currentOperation, currentAuthorizationFlowId]);

    const fieldsRequirement = useMemo(() => {
        const defaultFields: Record<
            keyof Omit<
                InsertBlockOrderForm,
                | '_id'
                | 'start_hour'
                | 'authorization_flow'
                | 'authorization_config_ids'
                | 'authorizations'
            >,
            boolean | undefined
        > = {
            name: true,
            operation: true,
            start_date: true,
            end_date: true,
            complete_hour: true,
            type: true,
            operator_tags: undefined,
            representatives:
                !!representativesOptions.length && !hideFields?.representatives,
        };
        if (!config?.block_order?.create_form?.fields_requirement)
            return defaultFields;
        Object.entries(
            config.block_order?.create_form.fields_requirement
        ).forEach(([key, value]) => {
            defaultFields[
                key as keyof Required<
                    typeof config.block_order.create_form.fields_requirement
                >
            ] = value ?? undefined;
        });
        return defaultFields;
    }, [config, hideFields, representativesOptions]);

    const toast = useToast();
    const [localization] = useLocalization();
    const [loadingReviewTargets, setLoadingReviewTargets] = useState(false);

    const isEdit = !!blockOrderToEdit;

    const startingFlowId =
        startingData?.authorization_flow_id ??
        startingData?.authorization_flow?._id ??
        blockOrderToEdit?.authorization_flow_id ??
        null;
    const startingOperationId =
        startingData?.operation_id ??
        startingData?.operation?._id ??
        blockOrderToEdit?.operation_id ??
        null;

    const onUpsertOrder = () => {
        if (onInsert) onInsert();
        props.onHide();
    };

    const {
        insertBlockOrder,
        updateBlockOrder,
        status: upsertStatus,
    } = useUpsertBlockOrder({ onInsert: onUpsertOrder });

    const { dialog: reviewTargetsDialog, show: showReviewTargetsWarning } =
        useShowTargetCheckWarning();

    const allowHourSelection =
        !!formData.authorization_flow &&
        !formData.authorization_flow.block_template_config?.complete_time &&
        !formData.authorization_flow.block_template_config?.start_time;

    const validateInsert = async (
        data: InsertBlockOrderForm,
        status: 'draft' | 'done'
    ) => {
        const shouldVerifyReview =
            status === 'done' &&
            (blockOrderToEdit?.total_targets_review == null ||
                blockOrderToEdit.total_targets_review > 0);
        if (shouldVerifyReview) {
            setLoadingReviewTargets(true);

            const filters: CheckActionTargetWarningEP.Data =
                blockOrderToEdit?._id
                    ? {
                          block_order_id: blockOrderToEdit._id,
                          action: 'finish_block_order',
                      }
                    : {
                          authorization_ids: data.authorizations.map(
                              (a) => a._id
                          ),
                          operation_id: data.operation?._id ?? null,
                          action: 'finish_block_order',
                      };

            try {
                const targets = await checkActionTargetWarning({
                    ...filters,
                });
                setLoadingReviewTargets(false);
                if (targets.length > 0) {
                    showReviewTargetsWarning({
                        targets,
                        onContinue: () => handleOnInsert(data, status),
                        action: 'finishBlockOrder',
                    });
                    return;
                } else handleOnInsert(data, status);
            } catch (err) {
                toast.show(getErrorToast((err as Error).message, localization));
                setLoadingReviewTargets(false);
            }
        } else {
            handleOnInsert(data, status);
        }
    };

    const handleOnInsert = (
        data: InsertBlockOrderForm,
        status: 'draft' | 'done'
    ) => {
        if (!data.authorization_flow) return;

        if (isDefaultOperation && formData.operation)
            updateUserDefaultOperation({
                operation_id: formData.operation._id,
            });

        const today = new Date();
        const start_date = formData.start_date
            ? new Date(formData.start_date)
            : null;
        let complete_date = null;
        if (allowHourSelection) {
            const { start_hour, complete_hour } = formData;
            complete_date = new Date(start_date ?? today);
            if (start_hour)
                start_date?.setHours(
                    start_hour.getHours(),
                    start_hour.getMinutes(),
                    0,
                    0
                );
            if (complete_hour)
                complete_date.setHours(
                    complete_hour.getHours(),
                    complete_hour.getMinutes(),
                    0,
                    0
                );
        }

        const uploadData: UpsertBlockOrderEP.InsertData = {
            authorization_flow_id: data.authorization_flow._id,
            authorization_config_ids: data.authorization_config_ids,
            project_id: project._id,
            name: data.name,
            type: data.type,
            authorization_ids: formData.authorizations.map((a) => a._id),
            operator_tags: formData.operator_tags,
            status: status,
            start_date: start_date,
            complete_date: complete_date,
            end_date: formData.end_date,
            operation_id: formData.operation?._id ?? null,
            representatives: formData.representatives,
        };
        if (data._id) {
            updateBlockOrder({
                _id: data._id,
                ...uploadData,
            });
        } else insertBlockOrder(uploadData);
    };

    const [isDefaultOperation, setIsDefaultOperation] = useState(false);

    const invalidTime =
        !!formData.start_hour &&
        !!formData.complete_hour &&
        formData.start_hour > formData.complete_hour;

    const isFormValid = useMemo(() => {
        // Static fields
        if (
            !formData.authorization_flow ||
            formData.authorization_config_ids.length <= 0 ||
            formData.authorizations.length <= 0
        )
            return false;

        if (invalidTime) return false;
        if (
            allowHourSelection &&
            fieldsRequirement.complete_hour &&
            (!formData.start_hour || !formData.complete_hour)
        )
            return false;
        return Object.entries(fieldsRequirement).every(([key, required]) => {
            if (key === 'complete_hour') return true;
            if (!required) return true;
            const fieldKey = key as keyof InsertBlockOrderForm;
            if (!(fieldKey in formData)) return true;
            const value = formData[fieldKey];
            if (Array.isArray(value) && value.length <= 0) return false;
            if (value === '' || value == null) return false;
            return true;
        });
    }, [invalidTime, allowHourSelection, fieldsRequirement, formData]);

    const upsertLoading = upsertStatus === 'pending' || loadingReviewTargets;

    const nameForm = (
        <InputText
            placeholder={
                localization.components.models.blockOrder.views.insert
                    .inputBlockOrderName
            }
            required={fieldsRequirement.name}
            label={localization.fields.blockOrder.name}
            value={formData.name}
            onChange={(e) =>
                setFormData((old) => ({
                    ...old,
                    name: e.target.value,
                }))
            }
            validations={[
                {
                    validationError:
                        localization.validations.generic.requiredName,
                    validate: (value) => {
                        if (!value || value === '') return false;
                        return true;
                    },
                },
            ]}
            disabled={upsertLoading || blockFields?.name}
            hidden={hideFields?.name}
        />
    );

    const authorizationFlowForm = (
        <>
            {!hideFields?.authorization_flow && <Divider />}
            <SelectOneAuthorizationFlow
                required
                disabled={upsertLoading}
                value={formData.authorization_flow?._id}
                onLoad={(flows) => {
                    if (!startingFlowId) return;
                    setFormData((old) => ({
                        ...old,
                        authorization_flow:
                            flows.find((f) => f._id === startingFlowId) ?? null,
                    }));
                }}
                onChange={(flow) => {
                    setFormData((old) => ({
                        ...old,
                        authorization_flow: flow ?? null,
                        authorization_config_ids: [],
                        authorizations: [],
                        operation: null,
                    }));
                }}
                hidden={
                    hideFields?.authorization_flow ||
                    blockFields?.authorization_flow
                }
            />
        </>
    );

    const operationForm = !!formData.authorization_flow && (
        <>
            {!hideFields?.operation && <Divider />}
            <SelectOrCreateOperation
                operationId={formData.operation?._id}
                startingOperationId={startingOperationId}
                setOperation={(op) =>
                    setFormData((old) => ({
                        ...old,
                        operation: op,
                    }))
                }
                isDefaultOperation={isDefaultOperation}
                setIsDefaultOperation={(def) => setIsDefaultOperation(def)}
                ignoreDefaultInit={isEdit}
                required={fieldsRequirement.operation}
                fixedAuthorizationFlowType={formData.authorization_flow.type}
                hidden={hideFields?.operation}
                disabled={blockFields?.operation}
            />
            {!hideFields?.operation && !hideFields?.dates && (
                <>
                    <Divider />
                    <span>
                        {
                            localization.components.models.blockOrder.views
                                .insert.selectDates
                        }
                    </span>
                    <h3>{localization.fields.blockOrder.operationStartDate}</h3>
                    <div className='flex flex-row gap-2'>
                        <Datepicker
                            label={localization.fields.blockOrder.operationDay}
                            value={formData.start_date}
                            onChange={(value) =>
                                setFormData((old) => ({
                                    ...old,
                                    start_date: value,
                                }))
                            }
                            type='date'
                            required={fieldsRequirement.start_date}
                            disabled={blockFields?.dates}
                        />
                        {allowHourSelection && (
                            <>
                                <Datepicker
                                    label={
                                        localization.fields.blockOrder
                                            .operationStartHour
                                    }
                                    value={formData.start_hour}
                                    onChange={(value) =>
                                        setFormData((old) => ({
                                            ...old,
                                            start_hour: value,
                                        }))
                                    }
                                    type='time'
                                    required={fieldsRequirement.start_date}
                                    error={invalidTime}
                                    disabled={blockFields?.dates}
                                />
                                <span>-</span>
                                <Datepicker
                                    label={
                                        localization.fields.blockOrder
                                            .operationEndHour
                                    }
                                    value={formData.complete_hour}
                                    onChange={(value) =>
                                        setFormData((old) => ({
                                            ...old,
                                            complete_hour: value,
                                        }))
                                    }
                                    type='time'
                                    required={fieldsRequirement.complete_hour}
                                    error={invalidTime}
                                    disabled={blockFields?.dates}
                                />
                            </>
                        )}
                    </div>
                    {invalidTime && (
                        <span className='text-red-500'>
                            {
                                localization.components.models.blockOrder.views
                                    .insert.invalidDate
                            }
                        </span>
                    )}
                    {!allowHourSelection && (
                        <span>
                            {localization.components.models.blockOrder.views.insert.noDateToSelect.replace(
                                '{name}',
                                formData.authorization_flow?.name ?? ''
                            )}
                        </span>
                    )}
                    <h3>
                        {localization.fields.blockOrder.operationCompletionDate}
                    </h3>
                    <Datepicker
                        label={localization.fields.blockOrder.operationDay}
                        value={formData.end_date}
                        onChange={(value) =>
                            setFormData((old) => ({
                                ...old,
                                end_date: value,
                            }))
                        }
                        type='datetime-local'
                        required={fieldsRequirement.end_date}
                        disabled={blockFields?.dates}
                    />
                </>
            )}
        </>
    );

    const representativesForm = !!formData.operation && (
        <>
            {!hideFields?.representatives && !hideFields?.operation && (
                <Divider />
            )}
            <SelectManyRepresentatives
                value={formData.representatives.map((r) => r.id)}
                onChange={(r) =>
                    setFormData((old) => ({
                        ...old,
                        representatives: r,
                    }))
                }
                required={fieldsRequirement.representatives}
                representatives={representativesOptions}
                hidden={hideFields?.representatives || hideFields?.operation}
                disabled={blockFields?.representatives}
            />
        </>
    );

    const authorizationsConfigForm = formData.authorization_flow && (
        <>
            {!hideFields?.authorization_config_ids && <Divider />}
            <SelectManyAuthorizationConfigs
                value={formData.authorization_config_ids}
                onChange={(configs) => {
                    const authorizations = formData.authorizations.filter(
                        (a) => !!configs.find((c) => c._id === a.config_id)
                    );
                    setFormData((old) => ({
                        ...old,
                        authorization_config_ids: configs.map((c) => c._id),
                        authorizations: authorizations,
                    }));
                }}
                disabled={
                    upsertLoading || blockFields?.authorization_config_ids
                }
                queryOptions={{
                    devFilters: {
                        authorization_flow_ids: formData.authorization_flow._id,
                    },
                }}
                required
                hidden={hideFields?.authorization_config_ids}
            />
        </>
    );

    const typeForm = formData.authorization_config_ids.length > 0 && (
        <>
            {!hideFields?.type && <Divider />}
            <SelectOneBlockOrderType
                placeholder={
                    localization.components.models.blockOrder.views.insert
                        .selectTypePlaceholder
                }
                value={formData.type}
                onChange={(e) =>
                    setFormData((old) => ({
                        ...old,
                        type: e?.value ?? 'judicial',
                    }))
                }
                disabled={upsertLoading || blockFields?.type}
                required={fieldsRequirement.type}
                hidden={hideFields?.type}
            />
        </>
    );

    const authorizationsForm = formData.authorization_flow &&
        formData.authorization_config_ids.length > 0 &&
        formData.operation && (
            <>
                {!hideFields?.authorizations && <Divider />}
                <SelectManyAuthorizations
                    value={formData.authorizations.map((a) => a._id)}
                    onChange={(authorizations) =>
                        setFormData((old) => ({
                            ...old,
                            authorizations: authorizations.map((a) => ({
                                _id: a._id,
                                name: a.name,
                                config_id: a.authorization_config_id,
                            })),
                        }))
                    }
                    queryOptions={{
                        devFilters: {
                            authorization_config_id:
                                formData.authorization_config_ids,
                            authorization_flow_id:
                                formData.authorization_flow._id,
                        },
                        withoutBlockOrder: true,
                    }}
                    disabled={upsertLoading || blockFields?.authorizations}
                    required
                    additionalItems={blockOrderToEdit?.authorizations_data?.filter(
                        (a) =>
                            !!formData.authorization_config_ids.find(
                                (config) => config === a.authorization_config_id
                            )
                    )}
                    hidden={hideFields?.authorizations}
                />
            </>
        );

    const operatorTagsForm = formData.authorization_config_ids.length > 0 && (
        <>
            {!hideFields?.operator_tags && <Divider />}
            <SelectManyOperatorTags
                value={formData.operator_tags}
                onChange={(tags) =>
                    setFormData((old) => ({
                        ...old,
                        operator_tags: tags.map((t) => t.value),
                    }))
                }
                disabled={upsertLoading || blockFields?.operator_tags}
                hidden={hideFields?.operator_tags}
                required={fieldsRequirement.operator_tags}
            />
        </>
    );

    return (
        <LightDialog
            {...props}
            header={
                !!blockOrderToEdit
                    ? localization.components.models.blockOrder.button.edit
                    : localization.components.models.blockOrder.button.new
            }
            style={{ minWidth: '50%' }}
            footer={
                <>
                    <Button
                        label={
                            localization.components.common.button.saveAndSubmit
                        }
                        severity='success'
                        onClick={() => validateInsert(formData, 'done')}
                        disabled={!isFormValid || upsertLoading}
                    />
                    <Button
                        label={
                            localization.components.common.button.saveAndDraft
                        }
                        onClick={() => handleOnInsert(formData, 'draft')}
                        disabled={!isFormValid || upsertLoading}
                    />
                    <Button
                        label={localization.components.common.button.cancel}
                        severity='danger'
                        onClick={props.onHide}
                    />
                </>
            }
        >
            <div className='grid gap-2 grid-cols-1'>
                {reviewTargetsDialog}
                <h3>{localization.models.blockOrder.singular}</h3>
                {nameForm}
                {authorizationFlowForm}
                {operationForm}
                {authorizationsConfigForm}
                {representativesForm}
                {typeForm}
                {authorizationsForm}
                {operatorTagsForm}
            </div>
        </LightDialog>
    );
};

export default InsertBlockOrderModal;
