import { useMutation } from '@tanstack/react-query';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { useState } from 'react';
import { upsertDocument } from 'services/ether/case-manager/documents';
import { UpsertDocumentEP } from 'services/ether/case-manager/documents/types';
import { getFileErrors, localizeError } from 'utils/errorHandler';

const useUpsertDocument = (params?: {
    showSuccess?: boolean;
    onUpload?: (data: {
        okDocuments: UpsertDocumentEP.Result[];
        errorDocuments: UpsertDocumentEP.Error[];
    }) => void;
    onSettled?: () => void;
}) => {
    const { hardRefresh, softRefresh } = useQueryRefresh();

    const toast = useToast();
    const [localization] = useLocalization();
    const [abortController, setAbortController] = useState(
        new AbortController()
    );

    const upsertDocumentMutation = useMutation<
        UpsertDocumentEP.ApiResponse,
        Error,
        UpsertDocumentEP.UpsertData
    >({
        mutationFn: (data) => {
            return upsertDocument({
                ...data,
                signal: abortController.signal,
            });
        },
        onSuccess: (res, uploadParams) => {
            if (uploadParams.status === 'using') {
                hardRefresh(['document']);
                softRefresh([
                    'authorization',
                    'block-order',
                    'unblock-order',
                    'target',
                    'metrics',
                ]);
            }

            const okDocuments = res.filter(
                (r) => r.status === 'created' || r.status === 'exists'
            ) as UpsertDocumentEP.Result[];
            const errorDocuments = res.filter(
                (r) => r.status === 'error'
            ) as UpsertDocumentEP.Error[];
            if (errorDocuments.length > 0) {
                toast.show({
                    severity: okDocuments.length === 0 ? 'error' : 'warn',
                    summary:
                        okDocuments.length === 0
                            ? localization.endpoints.document.upsert_document
                                  .insertFailed
                            : localization.endpoints.document.upsert_document
                                  .insertPartial,
                    detail: getFileErrors(
                        errorDocuments.map(({ error }, index) => [
                            index,
                            error,
                        ]),
                        localization
                    ),
                    life: 20000,
                });
            } else if (params?.showSuccess) {
                toast.show({
                    summary:
                        localization.endpoints.document.upsert_document
                            .insertSuccess,
                    severity: 'success',
                    life: 5000,
                });
            }
            if (params?.onUpload) {
                params.onUpload({
                    okDocuments,
                    errorDocuments,
                });
            }
        },
        onError: (e) => {
            if (e.message === 'canceled') return;
            const localizedError = localizeError(e.message, localization);
            toast.show({
                severity: 'error',
                summary: localization.validations.generic.fileUpload,
                detail: localizedError.summary,
            });
        },
        onSettled: params?.onSettled,
    });

    return {
        insertDocuments: (data: UpsertDocumentEP.InsertData) =>
            upsertDocumentMutation.mutate(data),
        updateDocuments: (data: UpsertDocumentEP.UpdateData) =>
            upsertDocumentMutation.mutate(data),
        uploadStatus: upsertDocumentMutation.status,
        cancelUpload: () => {
            abortController.abort();
            setAbortController(new AbortController());
            upsertDocumentMutation.reset();
        },
    };
};

export default useUpsertDocument;
