import { CMDataTableColumnFields } from 'components/datatable/CMDataTable/types';
import { renderUnidentifiedDataType } from 'components/datatable/utils';
import { Column, ColumnProps } from 'primereact/column';
import { CMLocalization } from 'static/language';
import { getObjectProperty } from 'utils/object';

type DetailedModel = Ether.CaseManager.Tag.Detailed;
type ModelColumns =
    | keyof CaseManagerApp.ModelColumns['tag']
    | CaseManagerApp.DefaultNameColumn;
type DataTable = CaseManagerApp.AppConfig.Tag.DataTable;
type ColumnField = CaseManagerApp.AppConfig.Tag.DataTable.Fields;
type LocaleField = CaseManagerApp.AppConfig.Tag.DataTable.LocaleFields;
type KeyColumnProps = ColumnProps & { key: string };

/**
 * Generates columns dynamically for the AuthorizationDataTable
 * @param object.columns - The available columns to transform into elements.
 * @param object.tableConfig - The tags datatable configuration, listing all visible columns. If not provided or omitted, will default to a generic column rendering
 * @param object.localization - The localization object
 * @param object.sortable - If the table is sortable
 * @returns columns - All columns in a JSX element format
 */
export const generateTagColumns = ({
    columns,
    tableConfig,
    localization,
    sortable,
}: {
    columns: CMDataTableColumnFields<ModelColumns>;
    tableConfig: DataTable | null;
    localization: CMLocalization.Localization;
    sortable: boolean;
}) => {
    const mapLocale: Record<LocaleField, string> = {
        targets: localization.models.target.plural,
        official_documents: localization.models.oficio.plural,
    };

    // Overrides to default table config
    if (!tableConfig)
        tableConfig = {
            targets: 'targets_count',
            official_documents: 'official_documents',
        };

    const renderedColumns: JSX.Element[] = Object.entries(tableConfig)
        .map(([localeEntry, fieldEntry]) => {
            const localeKey = localeEntry as LocaleField;
            const columnKey = fieldEntry.startsWith('tag_count:')
                ? fieldEntry
                : columns[fieldEntry as ColumnField]?.name;
            if (!columnKey) return null;
            const header = mapLocale[localeKey];

            let props: KeyColumnProps = {
                key: columnKey,
                field: columnKey,
                header: header,
                body: (data: any) => {
                    const value = getObjectProperty(data, columnKey);
                    return renderUnidentifiedDataType(value);
                },
                sortable: false,
            };
            switch (columnKey) {
                case columns.official_documents.name:
                    props.body = (data: DetailedModel) =>
                        (data.documents_data?.length ?? 0) <= 0 ? (
                            '-'
                        ) : (
                            <div className='flex flex-col'>
                                {data.documents_data?.map((d) => (
                                    <span key={d._id}>{d.file.name}</span>
                                ))}
                            </div>
                        );
                    break;
                case columns.targets_count.name:
                    props.body = (data: DetailedModel) =>
                        data.total_targets ?? '-';
                    break;
            }
            return <Column {...props} />;
        })
        .filter<JSX.Element>((c): c is JSX.Element => !!c);
    return renderedColumns;
};
