import CacheControl from 'controller/cache/cacheController';
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    handleUserDirectoryCallback,
    isTemporaryTokenValid,
    isTokenValid,
} from 'services/ether/base-api';
import {
    clearAuthorizationData,
    setupAuthorizationData,
} from 'services/ether/api';
import { defaultPermissions, getUserPermissions } from 'utils/permissions';
import { useLocalization } from 'hooks/context/useLocalization';
import useMatomo from 'hooks/matomo/useMatomo';
import { hardRedirect } from 'router/utils';

type FetchStatus = 'loading' | 'error' | 'success' | 'unauthenticated';

type AuthData = {
    token: string | null;
};

type UseAuth = AuthData & {
    user: {
        role: Ether.CaseManager.Role | undefined;
        isAdmin: boolean;
        data:
            | (Ether.UserInfo & {
                  operator_data: {
                      _id: string;
                  } | null;
              })
            | null;
        relatedCompany?: Ether.CaseManager.Company;
    };
    temporaryToken?: string;
    status: FetchStatus;
    error: string | null;
    permissions: CaseManagerApp.Permissions;
    refreshUser: (mode: 'hard' | 'soft') => void;
    getUDLoginHref: () => string;
    redirectToUdLogin: () => void;
    signOut?(): void;
};

const AuthContext = createContext<UseAuth | null>(null);

const buildUser = (
    data: Ether.MeInfo
): {
    user: UseAuth['user'];
    permissions: UseAuth['permissions'];
} => {
    const item = {
        user: {
            data: {
                ...data.user,
                operator_data: data.operator_data ?? null,
            },
            role: data.user.data?.auth?.level,
            isAdmin:
                data.user.data?.auth?.level === 'admin' ||
                data.user.is_superadmin,
            relatedCompany: data.company ?? undefined,
        },
        permissions: getUserPermissions(
            data.user?.data?.auth?.level,
            data.permissions,
            data.user.is_superadmin
        ),
    };
    return item;
};

const nullablePermissions: Partial<CaseManagerApp.Permissions> = {};
Object.keys(defaultPermissions).forEach((key) => {
    nullablePermissions[key as keyof CaseManagerApp.Permissions] = false;
});
const initPerms = nullablePermissions as CaseManagerApp.Permissions;

const udUri = window.EXTERNAL_URI_USERDIRECTORY;

const AuthProvider: React.FC<{
    temporaryToken?: string;
    children?: React.ReactNode;
}> = ({ temporaryToken, children }) => {
    const matomo = useMatomo();

    const [localization] = useLocalization();
    const [error, setError] = useState<string | null>(null);
    const [authStatus, setAuthStatus] = useState<FetchStatus>('loading');
    const [user, setUser] = useState<UseAuth['user']>({
        data: null,
        role: undefined,
        isAdmin: false,
    });

    const handleError = useCallback((message: string) => {
        setError(message);
        setAuthStatus('error');
        clearCacheAndData({ clearUser: true });
    }, []);

    const credentials = useMemo<AuthData>(() => {
        const data = CacheControl.Auth.get();
        if (!data) {
            return {
                token: null,
            };
        }
        return data;
    }, []);

    const validateToken = useCallback(
        async ({
            token,
            isTemporary,
        }: {
            token: string | null;
            isTemporary: boolean;
        }) => {
            if (!token)
                return {
                    valid: false,
                    data: null,
                };
            return isTemporary
                ? isTemporaryTokenValid(token)
                : isTokenValid(token);
        },
        []
    );

    const refreshUser = useCallback(
        (mode: 'hard' | 'soft') => {
            if (mode === 'hard') {
                clearCacheAndData({ clearUser: true });
                setAuthStatus('loading');
            }
            return new Promise<Ether.MeInfo | null>((resolve) => {
                validateToken({
                    token: temporaryToken ?? credentials.token ?? null,
                    isTemporary: !!temporaryToken,
                }).then(({ valid, data }) => {
                    resolve(!valid ? null : data ?? null);
                });
            });
        },
        [credentials.token, temporaryToken, validateToken]
    );

    const setupUserAuthentication = useCallback(() => {
        if (credentials.token == null && !temporaryToken) {
            setAuthStatus('unauthenticated');
            return;
        }
        // Load from cache and look ahead
        const data = CacheControl.AuthUser.get();
        if (data) {
            const userItem = buildUser(data);

            if (userItem?.user) {
                if (temporaryToken)
                    setupAuthorizationData('temporary-token', temporaryToken);
                else setupAuthorizationData('access-token', credentials.token);
                setUser(userItem.user);
                setAuthStatus('success');
            }
        }
        // Check credentials correctly after
        refreshUser('soft').then((data) => {
            if (!data) {
                handleError(localization.validations.generic.invalidToken);
                setAuthStatus('unauthenticated');
                return;
            }
            CacheControl.AuthUser.save(data);
            const userItem = buildUser(data);
            setPermissions(userItem.permissions);
            setUser(userItem.user);
            if (temporaryToken)
                setupAuthorizationData('temporary-token', temporaryToken);
            else setupAuthorizationData('access-token', credentials.token);
            setAuthStatus('success');
            matomo.initialize(data.user);
            return userItem;
        });
    }, [
        matomo,
        handleError,
        localization,
        refreshUser,
        credentials.token,
        temporaryToken,
    ]);

    const [permissions, setPermissions] = useState<CaseManagerApp.Permissions>(
        () => {
            const data = CacheControl.AuthUser.get();
            if (data) {
                const userData = buildUser(data);
                return userData.permissions;
            }
            return initPerms;
        }
    );

    const clearCacheAndData = ({ clearUser }: { clearUser: boolean }) => {
        CacheControl.Auth.delete();
        CacheControl.AuthUser.delete();
        CacheControl.AuthProject.delete();
        clearAuthorizationData();
        if (clearUser)
            setUser({
                data: null,
                role: undefined,
                isAdmin: false,
            });
    };

    const signOut = () => {
        CacheControl.Auth.delete();
        CacheControl.SelectedProject.delete();
        const callback = window.location.origin + '/login';

        const url = new URL(udUri);
        url.pathname = '/logout';
        url.searchParams.append('redirect', callback);

        hardRedirect(url.toString());
    };

    const getUDLoginHref = () => {
        const callback =
            new URLSearchParams(window.location.search).get('callback') ??
            window.location.href.replace('/login', '/');
        const parsedCallback = new URL(callback);
        parsedCallback.searchParams.delete('hash');

        const url = new URL(udUri);
        url.pathname = '/app-login/case-manager';
        url.searchParams.append('callback', parsedCallback.toString());

        return url.toString();
    };

    const redirectToUdLogin = () => {
        const url = getUDLoginHref();
        clearCacheAndData({ clearUser: false });
        hardRedirect(url);
    };

    /**
     * Handles initial page load for hash: if it includes the hash, it will redirect and validate on the UD
     */
    useEffect(() => {
        const url = new URL(window.location.href);
        const hash = url.searchParams.get('hash');
        if (!hash) {
            setupUserAuthentication();
            return;
        }
        handleUserDirectoryCallback(hash)
            .then(({ access_token }) => {
                const userData: AuthData = {
                    token: access_token.token,
                };
                CacheControl.Auth.save(userData);
                setTimeout(() => {
                    url.searchParams.delete('hash');
                    window.location.href = url.toString();
                }, 500);
                return;
            })
            .catch((err) => {
                console.error(err);
                handleError('Failed to validate hash on UserDirectory.');
            });
    }, [credentials, handleError, setupUserAuthentication]);

    return (
        <AuthContext.Provider
            value={{
                ...credentials,
                temporaryToken,
                status: authStatus,
                error,
                signOut,
                permissions,
                user,
                refreshUser,
                getUDLoginHref,
                redirectToUdLogin,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context)
        throw new Error(
            'AuthContext must be used inside a AuthContext.Provider'
        );
    return context;
};

export { AuthProvider, useAuth };
