import { useLocalization } from 'hooks/context/useLocalization';
import { Accordion, AccordionTab } from 'primereact/accordion';

const SafeIPRules = () => {
    const [localization] = useLocalization();

    const { safeIpRules } = localization.components.models.target.views;

    return (
        <Accordion>
            <AccordionTab className='w-full' header={safeIpRules.title}>
                <h3>{safeIpRules.brief}</h3>
                <h4>Grade 5</h4>
                <ul>
                    <li>{safeIpRules.grade5.rule01}</li>
                    <li>{safeIpRules.grade5.rule02}</li>
                    <li>{safeIpRules.grade5.rule03}</li>
                    <li>{safeIpRules.grade5.rule04}</li>
                    <li>{safeIpRules.grade5.rule05}</li>
                    <li>{safeIpRules.grade5.rule06}</li>
                    <li>{safeIpRules.grade5.rule07}</li>
                    <li>{safeIpRules.grade5.rule08}</li>
                    <li>{safeIpRules.grade5.rule09}</li>
                    <li>{safeIpRules.grade5.rule10}</li>
                    <li>{safeIpRules.grade5.rule11}</li>
                </ul>
                <h4>Grade 4</h4>
                <ul>
                    <li>{safeIpRules.grade4.rule01}</li>
                    <li>{safeIpRules.grade4.rule02}</li>
                    <li>{safeIpRules.grade4.rule03}</li>
                    <li>{safeIpRules.grade4.rule04}</li>
                </ul>
                <h4>Grade 3</h4>
                <ul>
                    <li>{safeIpRules.grade3.rule01}</li>
                </ul>
                <h4>Grade 2</h4>
                <ul>
                    <li>{safeIpRules.grade2.rule01}</li>
                    <li>{safeIpRules.grade2.rule02}</li>
                </ul>
            </AccordionTab>
        </Accordion>
    );
};

export default SafeIPRules;
