import {
    CMDataTableColumnFields,
    CMDataTableFiltersFields,
} from 'components/datatable/CMDataTable/types';
import { useLocalization } from 'hooks/context/useLocalization';
import { FilterMatchMode } from 'primereact/api';
import { useMemo } from 'react';
import { getFilterData } from 'utils/datatable';
import useGenerateFilters from 'hooks/columns/useGenerateFilters';
import useYesNoSelectOptions from 'hooks/selectOptions/generic/useYesNoSelectOptions';
import useAuthorizationStatusSelectOptions from 'hooks/selectOptions/authorization/useAuthorizationStatusSelectOptions';
import FilterBoxMultiselectAuthorizationConfig from 'components/datatable/FilterBox/components/customFilters/FilterBoxMultiSelectAuthorizationConfig';
import FilterBoxDropdownAuthorizationFlow from 'components/datatable/FilterBox/components/customFilters/FilterBoxDropdownAuthorizationFlow';
import { grabValidKeyMatchingUrl } from 'utils/object';
import { checkTagFilterMatching } from 'utils/tag';
import useAppConfig from 'hooks/appConfig/useAppConfig';

type ModelColumns =
    | keyof CaseManagerApp.ModelColumns['authorization']
    | CaseManagerApp.DefaultNameColumn;
type ModelFilters =
    | keyof CaseManagerApp.ModelFilters['authorization']
    | CaseManagerApp.DefaultNameColumn;

/**
 * Hook to get all available columns and filters for the Authorization DataTable model
 * @returns object.columnFields - The object describing each column by its field name and available filters (if existing)
 * @returns object.filterOptions - All available filter options to be used by the CMDataTable model
 * @returns object.filterMetaData - All available filter metadata, each value is a DataTableOperatorFilterMetaData for the primereact DataTable component
 */
const useAuthorizationColumnFields = () => {
    const [localization] = useLocalization();

    const yesNoSelectOptions = useYesNoSelectOptions();
    const statusSelectOptions = useAuthorizationStatusSelectOptions();

    const config = useAppConfig().authorization;

    const columnFields = useMemo(() => {
        const columnFields: CMDataTableColumnFields<ModelColumns> = {
            _cm_name_id: {
                name: '_cm_name_id',
            },
            approved_at: {
                name: 'approved_at',
            },
            approved_by: {
                name: 'approved_by',
            },
            created_at: {
                name: 'created_at',
            },
            created_by: {
                name: 'created_by_data.display_name',
            },
            status: {
                name: 'status',
            },
            evidences_count: {
                name: 'evidences_count',
            },
            targets_count: {
                name: 'targets_count',
            },
            registered_at: {
                name: 'registered_at',
            },
            authorization_config: {
                name: 'authorization_config_id',
            },
            authorization_flow: {
                name: 'authorization_flow_id',
            },
            notified: {
                name: 'block_orders.0|isnull',
            },
            notified_count: {
                name: 'meta.notification_data.notified_count',
            },
            notification_status: {
                name: 'notification_status',
            },
            notified_at: {
                name: 'block_orders.added_at',
            },
            authority_status: {
                name: 'authority_data.status',
            },
            offline_percentage: {
                name: 'meta.onoff_data.offline_percentage',
            },
        };
        return columnFields;
    }, []);

    const filterFields = useMemo(() => {
        const datatableLocale =
            localization.components.models.authorization.datatable;
        const filterConfig = config?.filters;

        const defaultFilters: string[] = [
            '_cm_name_id',
            'approved_at',
            'created_at',
            'created_by',
            'status',
            'authorization_flow',
            'authorization_config',
            'notified',
            'notified_at',
            'authority_status',
            '_cmapp_tag_target:pirate-brand:multiselect',
        ];

        const configFilters = filterConfig ?? defaultFilters;

        const filterFields: CMDataTableFiltersFields<ModelFilters> = {
            _cm_name_id: {
                meta: getFilterData(),
                options: {
                    placeholder: datatableLocale.selectName,
                    type: 'string',
                    filterKey: columnFields._cm_name_id.name,
                },
            },
            approved_at: {
                meta: getFilterData(FilterMatchMode.DATE_IS),
                options: {
                    label: localization.fields.authorization.approval,
                    type: 'date',
                    filterKey: columnFields.approved_at.name,
                },
            },
            created_at: {
                meta: getFilterData(FilterMatchMode.DATE_IS),
                options: {
                    label: localization.fields.authorization.created,
                    type: 'date',
                    filterKey: columnFields.created_at.name,
                },
            },
            created_by: {
                meta: getFilterData(FilterMatchMode.CONTAINS),
                options: {
                    label: localization.fields.authorization.createdBy,
                    type: 'string',
                    filterKey: columnFields.created_by.name,
                },
            },
            status: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.fields.authorization.status,
                    placeholder: datatableLocale.selectStatus,
                    type: 'dropdown',
                    selectOptions: statusSelectOptions,
                    filterKey: columnFields.status.name,
                },
            },
            authorization_flow: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.models.authorizationFlow.singular,
                    type: 'custom',
                    element: FilterBoxDropdownAuthorizationFlow,
                    initialValue: null,
                    filterKey: columnFields.authorization_flow.name,
                },
            },
            authorization_config: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.models.authorizationConfig.singular,
                    type: 'custom',
                    element: FilterBoxMultiselectAuthorizationConfig,
                    initialValue: [],
                    filterKey: columnFields.authorization_config.name,
                },
            },
            notified: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.fields.authorization.notified,
                    type: 'dropdown',
                    selectOptions: yesNoSelectOptions,
                    filterKey: columnFields.notified.name,
                },
            },
            notified_at: {
                meta: getFilterData(FilterMatchMode.DATE_IS),
                options: {
                    label: localization.fields.authorization.notifiedAt,
                    type: 'date',
                    filterKey: columnFields.notified_at.name,
                    renderWithColumn: columnFields.notified_at.name,
                },
            },
            authority_status: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.fields.authorization.authorityStatus,
                    type: 'dropdown',
                    selectOptions: ['new', 'pending', 'done', 'available'].map(
                        (a) => ({
                            label: localization.components.models.authorization
                                .badge.authorityStatus[
                                a as keyof typeof localization.components.models.authorization.badge.authorityStatus
                            ],
                            value: a,
                        })
                    ),
                    filterKey: columnFields.authority_status.name,
                },
            },
            _cmapp_target_is_notified : {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.fields.authorization.notified,
                    type: 'dropdown',
                    selectOptions: yesNoSelectOptions,
                    filterKey: '_cmapp_target_is_notified',
                    renderWithColumn: [columnFields.notified_count.name],
                },
            }
        };

        const visibleFilterFields: CMDataTableFiltersFields<ModelFilters> = {};

        configFilters.forEach((filter) => {
            const matchedFilter = grabValidKeyMatchingUrl<ModelFilters>(
                filter.split(',')
            );
            if (!matchedFilter) return;
            const tagMatchingObj = checkTagFilterMatching(
                matchedFilter,
                localization
            );
            if (tagMatchingObj.matches) {
                visibleFilterFields[matchedFilter] = tagMatchingObj.filter;
                return;
            }
            if (!filterFields[matchedFilter]) return;
            if (matchedFilter in visibleFilterFields) return;
            visibleFilterFields[matchedFilter] = filterFields[matchedFilter];
        });

        return visibleFilterFields;
    }, [
        config,
        localization,
        columnFields,
        statusSelectOptions,
        yesNoSelectOptions,
    ]);

    const { filterOptions, filterMetaData } =
        useGenerateFilters<ModelFilters>(filterFields);

    return { columnFields, filterOptions, filterMetaData };
};
export default useAuthorizationColumnFields;
