/**
 * A simple component to show a label and value group. Classname is customizable.
 * @param object.label - The left-side label
 * @param object.value - The right-side value, may be a Element
 * @returns LabelValuePair component
 */
const LabelValuePair: React.FC<{
    label: string;
    value: string | JSX.Element;
    className?: string;
}> = ({ label, value, className }) => {
    const finalClass = ['flex flex-row gap-2 items-center', className].join(
        ' '
    );
    return (
        <div className={finalClass}>
            <span className='font-bold'>{label}: </span>
            <span className='break-all'>{value}</span>
        </div>
    );
};

export default LabelValuePair;
