import SelectOneItem from 'components/form/SelectItems/SelectOneItem';
import useListTag from 'hooks/queries/tag/useListTag';
import {
    ValidTagCategory,
    ValidTagModelType,
} from 'services/ether/case-manager/tags/types';
import { useProject } from 'hooks/context/project/useProject';
import { SortOrder } from 'primereact/api';
import { useLocalization } from 'hooks/context/useLocalization';
import { CustomFilterElementProps } from '../../FilterBoxCustomElementFilter';
import { getTagLocalizationValues } from 'utils/tag';
import { useMemo } from 'react';

/**
 * Custom filter wrapping the Tag Select for the FilterBox
 * @param props.value - The current selected value
 * @param props.onChange - What value and label to change to
 * @param props.category - The tag's category
 * @param props.modelType - The tag's model_type
 * @returns A custom dropdown filter element
 */
const FilterBoxDropdownTag: React.FC<
    CustomFilterElementProps & {
        category: ValidTagCategory;
        modelType: ValidTagModelType;
    }
> = ({ value, onChange, category, modelType }) => {
    const project = useProject();
    const [localization] = useLocalization();

    const query = useListTag({
        project_id: project._id,
        options: {
            limit: 0,
            sort: {
                field: 'name',
                order: SortOrder.ASC,
            },
            devFilters: {
                category: category,
                model_type: modelType,
            },
        },
    });

    const valueLocalizationObj = useMemo(
        () =>
            getTagLocalizationValues({
                localization,
                modelType,
                category,
            }),
        [localization, modelType, category]
    );

    return (
        <SelectOneItem
            placeholder={
                localization.components.models.tag.form.selectOnePlaceholder
            }
            items={query.data?.payload.map((t) => ({
                ...t,
                name: valueLocalizationObj?.[t.name] ?? t.name,
            }))}
            itemsLoading={query.isFetching}
            optionLabel='name'
            value={value}
            onChange={(item, options) =>
                onChange({
                    value: item?._id ?? null,
                    selectOptions: options.map((i) => ({
                        label: valueLocalizationObj?.[i.name] ?? i.name,
                        value: i._id,
                    })),
                })
            }
            onLoad={(options) => {
                const item = options.find((o) => o._id === value);
                onChange({
                    value: item?._id ?? null,
                    selectOptions: options.map((i) => ({
                        label: valueLocalizationObj?.[i.name] ?? i.name,
                        value: i._id,
                    })),
                });
            }}
        />
    );
};

export default FilterBoxDropdownTag;
