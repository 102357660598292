import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { useMemo, useRef } from 'react';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';

import 'components/datatable/models/target/TargetsDataTable/styles.css';

import useCopyToClipboard from 'hooks/helpers/useCopyToClipboard';
import AdaptableTooltip from 'components/misc/AdaptableTooltip';
import { localizeDateOnly } from 'utils/dateUtils';
import useSimplifiedTargetColumnFields from 'hooks/columns/models/useSimplifiedTargetColumnFields';
import { generateTargetColumnsAndFilters } from 'components/datatable/models/target/utils';
import { useProject } from 'hooks/context/project/useProject';
import {
    getPreRejectedReasons,
    getTargetValueHrefLink,
    truncateTargetName,
} from 'utils/models/target';
import useShowDetailTargetDialog from 'hooks/dialogs/target/useShowDetailTargetDialog';
import useShowObjectDisplayModal from 'hooks/dialogs/general/useShowObjectDisplayModal';

type DetailedModel = Ether.CaseManager.Target.Detailed;

const RowMenu: React.FC<{
    item: DetailedModel;
    onShowDetail: (item: DetailedModel) => void;
}> = ({ item, onShowDetail }) => {
    const { permissions } = useAuth();
    const [localization] = useLocalization();

    const menuRef = useRef<Menu>(null);

    const menuItems: MenuItem[] = [
        ...(permissions.debugAuthorizations
            ? [
                  {
                      id: 'debug',
                      label: localization.components.common.menu.detailData,
                      command: () => onShowDetail(item),
                  },
              ]
            : []),
    ];

    if (menuItems.length <= 0) return null;
    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

const SimplifiedTargetsDataTable: React.FC<
    CMDataTableProps<DetailedModel> & {
        targetsTableConfig: Partial<
            Record<
                CaseManagerApp.AppConfig.Target.DataTable.LocaleFields,
                CaseManagerApp.AppConfig.Target.DataTable.Fields
            >
        >;
    }
> = ({ targetsTableConfig, ...props }) => {
    const [localization, { currentLanguage }] = useLocalization();
    const project = useProject();

    const sortable = 'sortField' in props;

    const { columnFields, filterOptions } = useSimplifiedTargetColumnFields();

    const columnElements = useMemo(
        () =>
            generateTargetColumnsAndFilters({
                columns: columnFields,
                project: project,
                tableConfig: targetsTableConfig,
                localization: localization,
                refAuthorization: undefined,
                sortable: sortable,
            }),
        [project, columnFields, localization, sortable, targetsTableConfig]
    );

    const copyToClipboard = useCopyToClipboard();

    const datatableLocale = localization.components.models.target.datatable;

    const { dialog: objectDisplayDialog, show: showObjectDisplay } =
        useShowObjectDisplayModal();
    const { dialog: detailTargetDialog, show: showTargetDetail } =
        useShowDetailTargetDialog();

    return (
        <>
            {objectDisplayDialog}
            {detailTargetDialog}
            <CMDataTable
                columnConfigName='targets'
                lazy
                emptyMessage={datatableLocale.empty}
                rowClassName={([data]: [DetailedModel]) => {
                    if (data.list_data?.safe_ip === false)
                        return [
                            'cm-isreview-row',
                            `grade-${data.list_data.highest_grading}`,
                        ].join(' ');
                }}
                filterOptions={filterOptions}
                {...props}
            >
                <Column
                    field='_cm_name_id'
                    body={(data: DetailedModel) => {
                        const prerejectedReasons = getPreRejectedReasons({
                            authorizationId: undefined,
                            target: data,
                            localization: localization,
                        });

                        const name = truncateTargetName(data.value);

                        const safeIpReasonsTooltipClass = `span-safeip-target-${data._id}`;
                        const alreadyBlockedTooltipClass = `span-alreadyblocked-target-${data._id}`;
                        const showReasons =
                            (prerejectedReasons?.length ?? 0) > 0;

                        const prerejectedMessages: string[] = [];

                        const latestBlockOrder = data.last_block_order;
                        const recentTimeDelta = 72 * 60 * 60 * 1000;
                        const isRecentOperation = latestBlockOrder?.created_at
                            ? Date.now() -
                                  latestBlockOrder.created_at.getTime() <=
                              recentTimeDelta
                            : false;
                        const localeOperation =
                            localization.components.models.target.text[
                                isRecentOperation
                                    ? 'alreadyBlockedRecent'
                                    : 'alreadyBlockedOld'
                            ];
                        if (latestBlockOrder)
                            prerejectedMessages.push(
                                localeOperation.replace(
                                    '{date}',
                                    localizeDateOnly(
                                        latestBlockOrder.created_at,
                                        currentLanguage
                                    )
                                )
                            );

                        const showDangerIcon = prerejectedMessages.length > 0;

                        return (
                            <div className='flex flex-col gap-2'>
                                <div className='flex flex-row justify-start items-center gap-2'>
                                    {showReasons && (
                                        <AdaptableTooltip
                                            target={
                                                '.' + safeIpReasonsTooltipClass
                                            }
                                        >
                                            <div className='flex flex-col gap-1'>
                                                {prerejectedReasons.map((r) => (
                                                    <span key={r}>{r}</span>
                                                ))}
                                            </div>
                                        </AdaptableTooltip>
                                    )}
                                    {!!showDangerIcon && (
                                        <AdaptableTooltip
                                            target={
                                                '.' + alreadyBlockedTooltipClass
                                            }
                                        >
                                            <div className='flex flex-col'>
                                                {prerejectedMessages.map(
                                                    (m) => (
                                                        <span key={m}>{m}</span>
                                                    )
                                                )}
                                            </div>
                                        </AdaptableTooltip>
                                    )}
                                    <Button
                                        icon='pi pi-info-circle'
                                        size='small'
                                        tooltip={localization.common.details}
                                        onClick={() =>
                                            showTargetDetail({
                                                target: data,
                                                refAuthorization: null,
                                                refBlockOrder: null,
                                                refUnblockOrder: null,
                                            })
                                        }
                                    />
                                    <a
                                        href={getTargetValueHrefLink(
                                            data.value
                                        )}
                                        rel='noreferrer noopener'
                                        target='_blank'
                                    >
                                        <Button
                                            icon='pi pi-external-link'
                                            size='small'
                                        />
                                    </a>
                                    <div
                                        className={
                                            'flex flex-row gap-2 items-center'
                                        }
                                    >
                                        <span title={data.value}>
                                            <NameIdAggregator
                                                name={name}
                                                id={data._id}
                                                onClick={() =>
                                                    copyToClipboard(data.value)
                                                }
                                            />
                                        </span>
                                        {showReasons && (
                                            <i
                                                className={
                                                    safeIpReasonsTooltipClass +
                                                    ' pi pi-question-circle text-yellow-500'
                                                }
                                            />
                                        )}
                                        {showDangerIcon && (
                                            <i
                                                className={
                                                    alreadyBlockedTooltipClass +
                                                    ' pi pi-exclamation-triangle text-red-500'
                                                }
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                    sortable={sortable}
                />
                {columnElements}
                <Column
                    field='actions'
                    body={(data: DetailedModel) => (
                        <RowMenu
                            item={data}
                            onShowDetail={(item) =>
                                showObjectDisplay({
                                    data: item,
                                    header: item.value,
                                })
                            }
                        />
                    )}
                />
            </CMDataTable>
        </>
    );
};

export default SimplifiedTargetsDataTable;
