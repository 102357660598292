import { ReactNode, useCallback, useMemo, useState } from 'react';
import { DateBadge } from '../../resources/DateBadge';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

export interface IObjectDisplayProps {
    displayData: any;
    sortKeys?: boolean;
    keyLabel?: string;
    valueLabel?: string;
    emptyLabel?: string;
    showAllLabel?: string;
    hideLabel?: string;
}

const LargeObjectView: React.FC<{
    children?: React.ReactNode;
    showAllLabel?: string;
    hideLabel?: string;
}> = ({ children, showAllLabel, hideLabel }) => {
    const [expand, setExpand] = useState(false);
    const content = JSON.stringify(children, null, 4);

    return (
        <div>
            {expand && (
                <Button
                    style={{ fontSize: '14px' }}
                    label={hideLabel ?? 'Hide'}
                    icon='pi pi-minus'
                    onClick={() => setExpand(false)}
                />
            )}
            <pre
                style={{
                    fontSize: '12px',
                    lineHeight: '16px',
                    maxHeight: expand ? '100%' : '160px',
                    overflowY: expand ? 'auto' : 'hidden',
                }}
            >
                {content}
            </pre>
            {content.split('\n').length > 10 && !expand && (
                <Button
                    style={{ fontSize: '14px' }}
                    label={showAllLabel ?? 'Show all'}
                    icon='pi pi-plus'
                    onClick={() => setExpand(true)}
                />
            )}
        </div>
    );
};

export const ObjectDisplay: React.FC<IObjectDisplayProps> = ({
    displayData,
    sortKeys = true,
    keyLabel,
    valueLabel,
    emptyLabel,
    showAllLabel,
    hideLabel,
}) => {
    const getFieldBody = useCallback(
        (value: unknown): unknown => {
            if (value == null) return <Badge value='-' />;
            switch (typeof value) {
                case 'string':
                    try {
                        JSON.parse(value);
                        return (
                            <LargeObjectView
                                showAllLabel={showAllLabel}
                                hideLabel={hideLabel}
                            >
                                {value as ReactNode}
                            </LargeObjectView>
                        );
                    } catch {
                        return value;
                    }
                case 'object':
                    if (value instanceof Date)
                        return <DateBadge value={value as Date} />;
                    return (
                        <LargeObjectView
                            showAllLabel={showAllLabel}
                            hideLabel={hideLabel}
                        >
                            {value as ReactNode}
                        </LargeObjectView>
                    );
                case 'boolean':
                    return (
                        <Badge
                            value={
                                value ? 'true' : value == null ? '-' : 'false'
                            }
                            severity={
                                value
                                    ? 'success'
                                    : value != null
                                    ? 'danger'
                                    : undefined
                            }
                        />
                    );
                default:
                    return value;
            }
        },
        [hideLabel, showAllLabel]
    );

    const rowData: { field: string; value: unknown }[] = useMemo(() => {
        if (!displayData) return [];
        const newRowData: {
            field: string;
            value: unknown;
        }[] = [];
        Object.keys(displayData).forEach((key) => {
            newRowData.push({
                field: key,
                value: getFieldBody(displayData[key]),
            });
        });
        if (sortKeys) {
            newRowData.sort((a, b) => {
                if (a.field > b.field) return 1;
                if (b.field > a.field) return -1;
                return 0;
            });
        }
        return newRowData;
    }, [displayData, getFieldBody, sortKeys]);

    return (
        <DataTable value={rowData} emptyMessage={emptyLabel}>
            <Column
                style={{ maxWidth: '10ch', wordWrap: 'break-word' }}
                field='field'
                header={keyLabel ?? 'Field'}
            />
            <Column
                style={{ maxWidth: '20ch' }}
                field='value'
                header={valueLabel ?? 'Value'}
            />
        </DataTable>
    );
};

export default ObjectDisplay;
