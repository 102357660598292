import {
    Checkbox as PrimeReactCheckbox,
    CheckboxProps as PrimeReactCheckboxProps,
} from 'primereact/checkbox';

import './style.css';

interface ICheckbox extends PrimeReactCheckboxProps {
    label?: string;
    labelStyle?: React.CSSProperties;
    error?: string | null;
    wrapperStyle?: React.CSSProperties;
}

export const Checkbox: React.FC<ICheckbox> = ({
    label,
    name,
    labelStyle,
    error,
    wrapperStyle,
    ...rest
}) => {
    return (
        <div className='custom-input-checkbox' style={wrapperStyle}>
            <span>
                <label
                    htmlFor={name}
                    style={{
                        marginRight: '8px',
                        display: label ? 'inherit' : 'none',
                        ...labelStyle,
                    }}
                >
                    {label}
                </label>
                <PrimeReactCheckbox
                    className={
                        (rest.className ?? '') +
                        (error ? '  p-invalid' : '') +
                        (rest.disabled ? '  p-disabled' : '')
                    }
                    {...rest}
                />
                {error && (
                    <small
                        id={`${name}-help`}
                        className='p-error block input-checkbox-error'
                    >
                        {error}
                    </small>
                )}
            </span>
        </div>
    );
};
