import { useNavigate } from 'react-router-dom';

import Navbar from 'components/page/Navbar';
import ContentWrap from 'components/page/ContentWrap';
import { Button } from 'primereact/button';
import { useAuth } from 'hooks/context/useAuth';
import { MenuItem, MenuItemOptions } from 'primereact/menuitem';
import { useMemo, useRef } from 'react';
import { Menu } from 'primereact/menu';
import { useQuery } from '@tanstack/react-query';
import { listProjects } from 'services/ether/case-manager/projects';
import { useProjectMaybe } from 'hooks/context/project/useProject';
import { getUserDisplayName } from 'utils/models/user';
import { useLocalization } from 'hooks/context/useLocalization';
import useGoToSupport from 'hooks/helpers/useSupportPage';
import { useProjectSelect } from 'hooks/context/project/useProjectSelect';
import MenuTemplate from 'components/misc/MenuTemplate';
import useUserDashboards from 'hooks/models/dashboard/useUserDashboards';
import {
    ValidTagCategory,
    ValidTagModelType,
} from 'services/ether/case-manager/tags/types';
import useAppConfig from 'hooks/appConfig/useAppConfig';
import { getTagLocalizationNames } from 'utils/tag';
import { getObjectPathValue } from 'utils/object';

const PageWithNavbar: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { signOut, redirectToUdLogin, permissions, user } = useAuth();
    const { role } = user;
    const navigate = useNavigate();
    const menuRef = useRef<Menu>(null);
    const project = useProjectMaybe();
    const [localization, { currentLanguage, switchLanguage }] =
        useLocalization();
    const goToSupport = useGoToSupport();
    const projectSelect = useProjectSelect();

    const config = useAppConfig();

    const { dashboards } = useUserDashboards();

    const projectsQuery = useQuery({
        queryKey: ['list-project-all'],
        queryFn: ({ signal }) =>
            listProjects({
                options: {
                    limit: 0,
                },
                signal,
            }),
    });

    const projectsLength = projectsQuery.data?.payload.length ?? -1;
    const availableDashboardSlugs = dashboards?.filter(
        (d) => d.key === 'dashboard-menu'
    );

    const availableNavigations = useMemo(() => {
        const navigations: Partial<Record<string, boolean>> = {};
        navigations['tags'] = permissions.viewTags;
        navigations['documents'] = permissions.viewDocuments;
        navigations['evidences'] = permissions.viewEvidences;
        navigations['authorizations'] = permissions.viewAuthorizations;
        navigations['targets'] = permissions.viewTargets;
        navigations['authorization-targets'] = permissions.viewTargets;
        navigations['authorization-targets-pending'] = permissions.viewTargets;
        navigations['block-orders'] = permissions.viewBlockOrders;
        navigations['unblock-orders'] = permissions.viewUnblockOrders;
        navigations['operators'] =
            permissions.viewOperators &&
            permissions.detailOperatorCompany &&
            role !== 'operator';
        navigations['operations'] = permissions.viewOperations;
        navigations['dashboards'] =
            permissions.getDashboard && !!availableDashboardSlugs?.length;
        return navigations;
    }, [permissions, availableDashboardSlugs, role]);

    const navigations = useMemo(() => {
        const navigations: MenuItem[] = [];

        const showNavigations = user.role !== 'authorizer-strict';

        if (!showNavigations) return navigations;

        const fileNavigations: MenuItem[] = [];

        const fileMenuObject: MenuItem = {};
        config.navbar?.forEach((route) => {
            let finalRoute: string | null = null;
            const locale =
                config.menu_names?.[
                    route as Ether.CaseManager.AppConfig.NavbarMenuItem
                ];
            let finalLocale: string =
                (locale ? getObjectPathValue(localization, locale) : null) ??
                'LOCALE-ERROR';
            if (route.startsWith('@tags:')) {
                if (!availableNavigations['tags']) return;
                try {
                    const navSplit = route.split('@tags:')?.[1]?.split(':');
                    const modelType = navSplit?.[0] as ValidTagModelType;
                    const category = navSplit?.[1] as ValidTagCategory;
                    if (!modelType || !category) return;
                    finalLocale =
                        getTagLocalizationNames({
                            category,
                            modelType,
                            localization,
                        })?.plural ?? 'LOCALE-ERROR';
                    finalRoute = `tags/${modelType}/${category}`;
                } catch (err) {
                    console.error('Failed to parse tags navigation');
                    return;
                }
            } else if (route.startsWith('@files')) {
                if (route === '@files') {
                    fileMenuObject['label'] = finalLocale;
                    navigations.push(fileMenuObject);
                    return;
                }
                const validatedRoute = route.split('@files:')[1];
                if (!validatedRoute) return;
                if (!availableNavigations[validatedRoute]) return;
                const newLocale =
                    config.menu_names?.[
                        validatedRoute as Ether.CaseManager.AppConfig.NavbarMenuItem
                    ];
                finalLocale =
                    (newLocale
                        ? getObjectPathValue(localization, newLocale)
                        : null) ?? 'LOCALE-ERROR';
                fileNavigations.push({
                    label: finalLocale,
                    url: '/' + validatedRoute,
                });
                return;
            } else if (route === '@dashboards') {
                if (!availableNavigations['dashboards']) return;
                navigations.push({
                    label: finalLocale,
                    items: availableDashboardSlugs?.map((dash) => ({
                        label:
                            localization.components.models.dashboard.badge.slug[
                                dash.slug
                            ] ?? dash.slug,
                        url: `/dashboard/${dash.slug}`,
                        template: (
                            item: MenuItem,
                            options: MenuItemOptions
                        ) => <MenuTemplate item={item} options={options} />,
                    })),
                });
                return;
            } else if (availableNavigations[route]) finalRoute = '/' + route;
            if (!finalRoute) return;
            navigations.push({
                label: finalLocale,
                url: finalRoute,
            });
        });

        if (fileNavigations.length) {
            if (fileNavigations.length === 1) {
                const firstItem = fileNavigations[0];
                fileMenuObject['label'] = firstItem?.label;
                fileMenuObject['url'] = firstItem?.url;
            } else
                fileMenuObject['items'] = fileNavigations.map((item) => ({
                    ...item,
                    template: (item: MenuItem, options: MenuItemOptions) => (
                        <MenuTemplate item={item} options={options} />
                    ),
                }));
        }

        return navigations;
    }, [
        availableDashboardSlugs,
        localization,
        availableNavigations,
        config,
        user.role,
    ]);

    const menuItems = useMemo(() => {
        const menuItems: Record<string, Omit<MenuItem, 'id'>> = {};
        if (projectsLength > 1)
            menuItems['changeproject'] = {
                icon: 'pi pi-list',
                label: localization.components.navbar.changeProject,
                command: () => projectSelect?.enableChangeProject(),
            };
        if (permissions.accessAdminPanel)
            menuItems['adminpanel'] = {
                icon: 'pi pi-database',
                label: localization.components.views.adminPanel.title,
                command: () => {
                    navigate('/admin');
                },
            };
        menuItems['block-checker'] = {
            icon: 'pi pi-map-marker',
            label: localization.components.common.button.navigateBlockchecker,
            url: window.EXTERNAL_URI_BLOCKCHECKER,
        };
        menuItems['support'] = {
            icon: 'pi pi-question-circle',
            label: localization.components.common.button.navigateSupport,
            command: goToSupport,
        };

        menuItems['switch-language'] = {
            icon: 'pi pi-globe',
            label:
                currentLanguage === 'en-US'
                    ? 'Alterar para pt-BR'
                    : 'Switch to en-US',
            command: () =>
                setTimeout(
                    () =>
                        switchLanguage(
                            currentLanguage === 'en-US' ? 'pt-BR' : 'en-US'
                        ),
                    100
                ),
        };
        menuItems['switch-profile'] = {
            icon: 'pi pi-user',
            label: localization.components.common.button.changeAccount,
            command: () => redirectToUdLogin(),
        };
        menuItems['signout'] = {
            icon: 'pi pi-sign-out',
            label: localization.components.common.button.signOut,
            command: () => {
                if (signOut) signOut();
            },
        };
        return menuItems;
    }, [
        localization,
        permissions,
        currentLanguage,
        goToSupport,
        navigate,
        projectSelect,
        projectsLength,
        redirectToUdLogin,
        signOut,
        switchLanguage,
    ]);

    const visibleMenuItems = useMemo(() => {
        const visibleMenuItems: MenuItem[] = [];

        config.usermenu?.forEach((menuId) => {
            const menu = menuItems[menuId];
            if (!menu) return;
            visibleMenuItems.push({
                id: menuId,
                ...menu,
            });
        });

        // These are always required menuitems
        ['switch-profile', 'signout'].forEach((reqMenu) => {
            if (config.usermenu?.includes(reqMenu)) return;
            const menu = menuItems[reqMenu];
            if (!menu) return;
            visibleMenuItems.push({
                id: reqMenu,
                ...menu,
            });
        });

        return visibleMenuItems;
    }, [config, menuItems]);

    const endContent = useMemo(
        () => (
            <div className='flex items-center gap-4'>
                <div className='flex flex-col gap-0 text-s text-right'>
                    <strong className='whitespace-nowrap'>
                        {getUserDisplayName(user.data) +
                            (user.relatedCompany
                                ? ` - ${user.relatedCompany?.fantasy_name}`
                                : '')}
                    </strong>
                    {project?.name && (
                        <span className='whitespace-nowrap'>
                            {project.name}
                        </span>
                    )}
                </div>
                {/* <Button label='Sign out' onClick={signOut} /> */}
                <Button
                    icon='pi pi-ellipsis-h'
                    onClick={(e) => menuRef?.current?.toggle(e)}
                />
            </div>
        ),
        [project?.name, user.relatedCompany, user.data]
    );

    return (
        <>
            <Menu popup ref={menuRef} model={visibleMenuItems} />
            <Navbar navigations={navigations} endContent={endContent} />
            <ContentWrap>{children}</ContentWrap>
        </>
    );
};

export default PageWithNavbar;
