import { InputMask } from 'components/ethercity-primereact';
import { useLocalization } from 'hooks/context/useLocalization';
import { InputMaskProps } from 'primereact/inputmask';

const CMInputMask: React.FC<
    InputMaskProps & { mask: string; placeholder: string; label: string }
> = ({ label, mask, placeholder, ...rest }) => {
    const [localization] = useLocalization();

    return (
        <InputMask
            label={label}
            {...rest}
            placeholder={placeholder}
            value={rest.value ?? undefined}
            mask={mask}
            validations={[
                {
                    validate: (s) => !s?.includes('_'),
                    validationError: localization.validations.generic.fillMask,
                },
            ]}
        />
    );
};

export default CMInputMask;
