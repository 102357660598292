import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { DateBadge } from 'components/ethercity-primereact';
import { Column } from 'primereact/column';
import { useAuth } from 'hooks/context/useAuth';
import { useRef } from 'react';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { Button } from 'primereact/button';
import { useLocalization } from 'hooks/context/useLocalization';
import CMDataTable from 'components/datatable/CMDataTable';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import { getUserDisplayName } from 'utils/models/user';
import UnblockOrderStatusBadge from '../../../../models/UnblockOrder/UnblockOrderStatusBadge';
import useUpsertUnblockOrderDialog from 'hooks/models/unblockOrder/useUpsertUnblockOrderDialog';
import UnblockOrderValidationStatusBadge from '../../../../models/UnblockOrder/UnblockOrderValidationStatusBadge';
import useUnblockOrderColumnFields from 'hooks/columns/models/useUnblockOrderColumnFields';
import useShowObjectDisplayModal from 'hooks/dialogs/general/useShowObjectDisplayModal';
import useExportUnblockOrderFiles from 'hooks/queries/unblock-order/useExportUnblockOrderFiles';

type DetailedModel = Ether.CaseManager.UnblockOrder.Detailed;

const RowMenu: React.FC<{
    item: DetailedModel;
    onShowDetail: (item: DetailedModel) => void;
    onShowEdit?: (id: string) => void;
}> = ({ item, onShowDetail, onShowEdit }) => {
    const { permissions } = useAuth();
    const [localization] = useLocalization();

    const menuRef = useRef<Menu>(null);

    const {
        exportFile: exportUnblockOrderFiles,
        isExporting: isExportingUnblockOrderFiles,
    } = useExportUnblockOrderFiles();

    const menuItems: MenuItem[] = [
        ...(permissions.insertUnblockOrders &&
        item.status === 'draft' &&
        onShowEdit
            ? [
                  {
                      id: 'edit',
                      label: localization.components.common.button.edit,
                      command: () => onShowEdit(item._id),
                      icon: 'pi pi-pencil',
                  },
              ]
            : []),
        ...(permissions.exportUnblockOrderFiles
            ? [
                  {
                      id: 'export-files',
                      icon: 'pi pi-download',
                      label: localization.components.common.button.exportFiles,
                      command: () =>
                          exportUnblockOrderFiles({
                              params: {
                                  unblock_order_id: item._id,
                                  cumulative: false,
                              },
                          }),
                      disabled: isExportingUnblockOrderFiles,
                  },
                  {
                      id: 'export-files',
                      icon: 'pi pi-download',
                      label: localization.components.models.blockOrder.button
                          .exportCumulative,
                      command: () =>
                          exportUnblockOrderFiles({
                              params: {
                                  unblock_order_id: item._id,
                                  cumulative: true,
                              },
                          }),
                      disabled: isExportingUnblockOrderFiles,
                  },
              ]
            : []),
        ...(permissions.debugUnblockOrders
            ? [
                  {
                      id: 'debug',
                      label: 'Detail data',
                      command: () => onShowDetail(item),
                  },
              ]
            : []),
    ];
    if (menuItems.length <= 0) return null;
    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

const UnblockOrderDataTable: React.FC<CMDataTableProps<DetailedModel>> = ({
    ...props
}) => {
    const [localization] = useLocalization();
    const { user } = useAuth();
    const { columnFields, filterOptions } = useUnblockOrderColumnFields();

    const { UpsertDialog, showEdit } = useUpsertUnblockOrderDialog();

    const { dialog: objectDisplayDialog, show: showObjectDisplay } =
        useShowObjectDisplayModal();

    const sortable = 'sortField' in props;
    const datatableLocale =
        localization.components.models.unblockOrder.datatable;

    const hideStatusColumn = user.role === 'operator';
    const showValidationStatusColumn = user.role === 'operator';

    return (
        <>
            <UpsertDialog />
            {objectDisplayDialog}
            <CMDataTable
                lazy
                emptyMessage={datatableLocale.empty}
                columnConfigName='unblockOrder'
                filterOptions={filterOptions}
                {...props}
            >
                <Column
                    field={columnFields['_cm_name_id'].name}
                    body={(data: Ether.CaseManager.UnblockOrder) => (
                        <NameIdAggregator
                            name={data.name}
                            id={data._id}
                            navigateTo={`/unblock-orders/${data._id}`}
                        />
                    )}
                />
                {!hideStatusColumn && (
                    <Column
                        field={columnFields['status'].name}
                        header={localization.fields.unblockOrder.status}
                        body={(data: DetailedModel) => (
                            <UnblockOrderStatusBadge unblockOrder={data} />
                        )}
                    />
                )}
                {showValidationStatusColumn && (
                    <Column
                        field={columnFields['validation_status'].name}
                        header={localization.fields.blockOrder.validationStatus}
                        body={(data: DetailedModel) => (
                            <UnblockOrderValidationStatusBadge
                                unblockOrder={data}
                            />
                        )}
                    />
                )}
                <Column
                    field={columnFields['targets_count'].name}
                    header={localization.models.target.plural}
                    body={(data: DetailedModel) =>
                        data.count_data?.targets_count ?? '-'
                    }
                />
                <Column
                    field={columnFields['created_at'].name}
                    header={localization.fields.unblockOrder.created}
                    dataType='date'
                    body={(data: DetailedModel) => (
                        <DateBadge value={data.created_at} />
                    )}
                    sortable={sortable}
                />
                <Column
                    field={columnFields['created_by'].name}
                    header={localization.fields.unblockOrder.createdBy}
                    body={(data: DetailedModel) =>
                        getUserDisplayName(data.created_by_data?.[0])
                    }
                />
                <Column
                    field='actions'
                    body={(data: DetailedModel) => (
                        <RowMenu
                            item={data}
                            onShowDetail={(item) =>
                                showObjectDisplay({
                                    data: item,
                                    header: item.name,
                                })
                            }
                            onShowEdit={(id) => showEdit(id)}
                        />
                    )}
                />
            </CMDataTable>
        </>
    );
};

export default UnblockOrderDataTable;
