import { useLocalization } from 'hooks/context/useLocalization';
import { HomeViewSet } from 'pages/Main/Home/components/HomeViewSet';

/**
 * Home page view for the user with Analyst role, as known as Analista/Lab
 * @returns Functional Component for the home
 */
const AnalystHome = () => {
    const [localization] = useLocalization();
    return (
        <HomeViewSet
            views={[
                {
                    key: 'analyst-home',
                    header: localization.components.views.homeDashboard
                        .analystDescription,
                    buttons: [
                        'insert-authorization',
                        'list-authorization',
                        'search-target',
                    ],
                    tabs: [
                        'recentAuthorizations',
                        'recentAuthorizations:draft',
                        'recentAuthorizations:pending',
                        'recentAuthorizations:approved',
                    ],
                },
            ]}
        />
    );
};

export default AnalystHome;
