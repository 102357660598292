import { useAuth } from '../../context/useAuth';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useMutation } from '@tanstack/react-query';
import { respondAuthorization } from 'services/ether/case-manager/authorizations';
import { useToast } from '../../context/useToast';
import { useLocalization } from '../../context/useLocalization';
import { useCallback, useState } from 'react';
import { getErrorToast } from 'utils/errorHandler';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';

type RespondData = {
    authorization: Ether.CaseManager.Authorization.Detailed;
    accept: boolean;
};

const RespondDialog: React.FC<{
    data: RespondData | null;
    onRespond: (data: { id: string; accepted: boolean }) => void;
    onHide: () => void;
}> = ({ data, onRespond, onHide }) => {
    const [localization] = useLocalization();
    const { user } = useAuth();

    const hide = () => onHide();

    if (!data) return null;

    const respondLocale =
        localization.components.models.authorization.views.respond;

    const { accept, authorization } = data;

    const userRole = user.role;

    const answer = !userRole?.startsWith('operator')
        ? accept
            ? localization.components.common.button.approve
            : localization.components.common.button.reject
        : accept
        ? localization.components.common.button.confirm
        : localization.components.common.button.reject;
    return (
        <ConfirmDialog
            visible={true}
            header={respondLocale.header.replace('{answer}', answer)}
            accept={() => {
                onRespond({
                    id: authorization._id,
                    accepted: accept,
                });
            }}
            acceptLabel={answer}
            rejectLabel={localization.components.common.button.cancel}
            message={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <span>
                        {respondLocale.summary.replace(
                            '{authorizationName}',
                            authorization.name
                        )}
                    </span>
                    <span>{respondLocale.detail}</span>
                    <br />
                </div>
            }
            onHide={hide}
        />
    );
};

const useAuthorizationRespond = (options?: {
    token?: string;
    onRespond?: () => void;
}) => {
    const { token, onRespond } = options ?? {};

    const [localization] = useLocalization();
    const toast = useToast();
    const respondLocale =
        localization.endpoints.authorization.respond_authorization;

    const { hardRefresh, softRefresh } = useQueryRefresh();

    const [visible, setVisible] = useState<null | RespondData>(null);

    const show = (data: RespondData) => setVisible(data);
    const hide = () => setVisible(null);

    const authorizationRespondMutation = useMutation<
        Ether.CaseManager.Authorization,
        Error,
        {
            authorizationId?: string;
            accepted: boolean;
        }
    >({
        mutationFn: (data) => {
            if (token)
                return respondAuthorization({
                    accepted: data.accepted,
                    token: token,
                });
            if (data.authorizationId)
                return respondAuthorization({
                    accepted: data.accepted,
                    authorizationId: data.authorizationId,
                });
            throw new Error('Must supply token or authorizationId');
        },
        onSuccess: (_, params) => {
            hardRefresh(['authorization']);
            softRefresh(['metrics']);
            toast.show({
                summary: params.accepted
                    ? respondLocale.approved
                    : respondLocale.rejected,
                severity: 'success',
                life: 5000,
            });
            if (onRespond) onRespond();
        },
        onError: (err) => toast.show(getErrorToast(err.message, localization)),
        onSettled: () => hide(),
    });

    const Dialog = useCallback(
        () => (
            <RespondDialog
                data={visible}
                onRespond={(data) =>
                    authorizationRespondMutation.mutate({
                        authorizationId: data.id,
                        accepted: data.accepted,
                    })
                }
                onHide={hide}
            />
        ),
        [authorizationRespondMutation, visible]
    );

    const onRespondAuthorization = (
        authorization: Ether.CaseManager.Authorization.Detailed,
        accept: boolean
    ) =>
        show({
            accept,
            authorization,
        });

    return {
        RespondDialog: Dialog,
        respondAuthorization: onRespondAuthorization,
        isLoading: authorizationRespondMutation.isPending,
        error: authorizationRespondMutation.error,
    };
};

export { useAuthorizationRespond };
