const useCompanyRoleSelectOptions = () => {
    return [
        // TODO : GET FROM API
        { label: 'Operadora', value: 'operator' },
        { label: 'Entidade', value: 'entity' },
        { label: 'Parceiro', value: 'partner' },
        { label: 'Labs', value: 'lab' },
    ];
};

export default useCompanyRoleSelectOptions;
