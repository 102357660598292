import { useEffect, useState } from 'react';

import { Button } from 'primereact/button';

import { InputText } from 'components/ethercity-primereact';
import { Divider } from 'primereact/divider';
import { useProject } from 'hooks/context/project/useProject';
import { useLocalization } from 'hooks/context/useLocalization';

import { FormDataInsert, InsertAuthorizationDialogProps } from './types';
import { Accordion } from 'primereact/accordion';
import { AccordionTab } from 'primereact/accordion';
import { ObjectId } from 'bson';

import useAuthorizationMutation from 'hooks/mutations/authorization/useAuthorizationMutation';
import {
    EditAuthorizationEP,
    InsertAuthorizationEP,
} from 'services/ether/case-manager/authorizations/types';
import useDetailOneAuthorization from 'hooks/queries/authorization/useDetailOneAuthorization';
import LoadingMessage from 'components/misc/LoadingMessage';
import useUpsertDocument from 'hooks/mutations/document/useUpsertDocument';

import './style.css';
import SelectOneAuthorizationConfig from 'components/models/AuthorizationConfig/SelectOneAuthorizationConfig';
import SelectOneAuthorizationFlow from 'components/models/AuthorizationFlow/SelectOneAuthorizationFlow';
import CMFileUpload from 'components/form/CMFileUpload';
import { detailManyTargets } from 'services/ether/case-manager/targets';
import { detailManyEvidences } from 'services/ether/case-manager/evidences';
import useTargetParseZipDialog from 'hooks/dialogs/target/useTargetParseZipDialog';
import TargetGroup from 'components/models/Targets/TargetGroup';
import LightDialog from 'components/display/LightDialog';
import SelectOrCreateOperation from 'components/models/Operation/SelectOrCreateOperation';
import { ProgressBar } from 'primereact/progressbar';
import SelectOneTag from 'components/models/Tag/SelectOneTag';
import { ListTagEP } from 'services/ether/case-manager/tags/types';
import SelectManyTags from 'components/models/Tag/SelectManyTags';
import _ from 'lodash';
import { parseSingleOrArrayIntoArray } from 'utils/object';
import useAppConfig from 'hooks/appConfig/useAppConfig';

type BaseFile = CaseManagerApp.LocalFile.BaseDetails;
type UploadedFile = CaseManagerApp.LocalFile.Uploaded;
type UploadingFile = CaseManagerApp.LocalFile.Uploading;
type EitherFile = UploadedFile | UploadingFile;

const getAllEvidences = async ({
    project_id,
    authorization_id,
}: {
    project_id: string;
    authorization_id: string;
}) => {
    let offset = 0;
    const limit = 50;

    let isFinished = false;

    const evidences: Ether.CaseManager.Evidence[] = [];

    while (!isFinished) {
        const { payload: evidencesData } = await detailManyEvidences({
            project_id,
            options: {
                limit,
                offset,
                devFilters: {
                    authorization_id: authorization_id,
                },
            },
        });
        evidencesData?.forEach((evidence) => evidences.push(evidence));
        if (evidencesData.length < limit) isFinished = true;
        offset += limit;
    }
    return evidences;
};

const getAllTargets = async ({
    project_id,
    authorization_id,
}: {
    project_id: string;
    authorization_id: string;
}) => {
    let offset = 0;
    const limit = 50;

    let isFinished = false;

    const targets: Ether.CaseManager.Target.Detailed[] = [];

    while (!isFinished) {
        const { payload: targetsData } = await detailManyTargets({
            project_id,
            options: {
                devFilters: {
                    authorization_ids: authorization_id,
                },
                limit,
                offset,
            },
        });
        targetsData?.forEach((target) => targets.push(target));
        if (targetsData.length < limit) isFinished = true;
        offset += limit;
    }
    return targets;
};

const populateTargetsAndEvidences = async ({
    project_id,
    authorization_id,
    groups_ids,
}: {
    project_id: string;
    authorization_id: string;
    groups_ids: string[];
}) => {
    const targetsPromise = getAllTargets({
        authorization_id,
        project_id,
    });
    const evidencesPromise = getAllEvidences({
        authorization_id,
        project_id,
    });

    const [targets, evidences] = await Promise.all([
        targetsPromise,
        evidencesPromise,
    ]);

    const groups: { [key: string]: FormDataInsert.TargetGroup } = {};

    groups_ids.forEach(
        (gId) =>
            (groups[gId] = {
                evidences: [],
                targets: [],
            })
    );

    targets?.forEach((target) => {
        const targetAuth = parseSingleOrArrayIntoArray(
            target.authorizations
        )?.find((a) => a?._id === authorization_id);
        if (!targetAuth) return;
        if (!(targetAuth.group_index in groups)) {
            groups[targetAuth.group_index] = {
                evidences: [],
                targets: [],
            };
        }
        groups[targetAuth.group_index]?.targets.push({
            meta: targetAuth.meta ?? {},
            value: target.value,
            tags: targetAuth.tags ?? {},
            target_file_id: null,
        });
    });
    evidences?.forEach((evidence) => {
        const group = evidence.target_group_id;
        if (!group) return;
        if (!(group in groups)) {
            groups[group] = {
                evidences: [],
                targets: [],
            };
        }
        groups[group]?.evidences.push({
            _id: evidence._id,
            custom_identifier: evidence._id,
            status: 'done',
            meta: {
                name: evidence.file.name ?? '',
                size: evidence.file.size,
            },
        });
    });
    return groups;
};

const getNewGroup = () => {
    return {
        identifier: new ObjectId().toHexString(),
        evidences: [],
        targets: [],
        uploading: false,
        targetFileId: null,
    };
};

const InsertAuthorizationDialog: React.FC<InsertAuthorizationDialogProps> = ({
    authorizationToEdit,
    onUpsert,
    ...props
}) => {
    const project = useProject();
    const [localization] = useLocalization();

    const config = useAppConfig().authorization?.create_form;

    const tagConfig = config?.tags_select;

    const isEdit = !!authorizationToEdit;

    const [targetGroups, setTargetGroups] = useState<
        (FormDataInsert.TargetGroup & {
            identifier: string;
            uploading: boolean;
        })[]
    >([getNewGroup()]);
    const [authorization, setAuthorization] =
        useState<FormDataInsert.Authorization>({
            _id: authorizationToEdit?._id ?? null,
            name: authorizationToEdit?.name ?? '',
        });

    const startingFlowId = authorizationToEdit?.authorization_flow_id ?? null;
    const [authorizationFlow, setAuthorizationFlow] =
        useState<Ether.CaseManager.AuthorizationFlow | null>(null);
    const [authorizationConfig, setAuthorizationConfig] =
        useState<Ether.CaseManager.AuthorizationConfig.Simple | null>(null);
    const [operation, setOperation] = useState<string | null>(
        authorizationToEdit?.operation_id ?? null
    );
    const [isDefaultOperation, setIsDefaultOperation] =
        useState<boolean>(false);
    const [documents, setDocuments] = useState<FormDataInsert.Documents>([]);
    const [tags, setTags] = useState<FormDataInsert.Tags>(() => {
        const tags: FormDataInsert.Tags = {};
        Object.entries(tagConfig ?? {}).forEach(([key]) => {
            tags[key] = [];
        });
        // if (isEmeaProject) {
        //     tags.country = [];
        //     tags.flag = [];
        // }
        return tags;
    });

    const [evidencesToExclude, setEvidencesToExclude] = useState<string[]>([]);

    const [isDocsUploading, setIsDocsUploading] = useState(false);

    const { data: detailedAuthorization, isFetching: isFetchingAuthorization } =
        useDetailOneAuthorization(
            {
                _id: authorizationToEdit?._id || '',
                project_id: project._id,
            },
            {
                enabled: isEdit,
            }
        );
    const {
        openUploadDialog,
        reviewDialog: reviewZipDialog,
        uploadDialog: uploadZipDialog,
    } = useTargetParseZipDialog({
        params: {
            authorization_config_id: authorizationConfig?._id ?? '',
            project_id: project._id,
        },
        onCommit: (data) => {
            const groups: Record<
                string,
                FormDataInsert.TargetGroup & {
                    identifier: string;
                }
            > = {};
            data.formatted.forEach((t) => {
                if (!t.group_index) return;
                if (!(t.group_index in groups))
                    groups[t.group_index] = {
                        targets: [],
                        evidences: [],
                        identifier: t.group_index,
                    };
                const g = groups[t.group_index];
                if (!g) return;
                g.targets.push(t);
            });
            Object.entries(data.evidences).forEach(
                ([group_index, evidence_ids]) => {
                    if (!(group_index in groups)) return;
                    evidence_ids.forEach((evidence) => {
                        groups[group_index]?.evidences.push({
                            _id: evidence._id,
                            custom_identifier: evidence._id,
                            meta: {
                                name: evidence.file_data.file.name ?? '',
                                size: evidence.file_data.file.size,
                            },
                            status: 'done',
                        });
                    });
                }
            );

            setTargetGroups(
                Object.entries(groups).map(([identifier, tg]) => ({
                    ...tg,
                    uploading: false,
                    identifier,
                }))
            );
        },
    });

    const [editSynced, setEditSynced] = useState(!isFetchingAuthorization);

    useEffect(() => {
        if (!isEdit || !detailedAuthorization) return;

        setAuthorizationConfig(
            detailedAuthorization.authorization_configs_data?.[0] ?? null
        );

        populateTargetsAndEvidences({
            authorization_id: detailedAuthorization._id,
            project_id: project._id,
            groups_ids: detailedAuthorization.target_group_ids ?? [],
        }).then((groups) => {
            setTargetGroups(
                Object.entries(groups).map(([identifier, tg]) => ({
                    ...tg,
                    uploading: false,
                    identifier,
                }))
            );

            setAuthorization({
                _id: detailedAuthorization._id,
                name: detailedAuthorization.name,
            });
            setDocuments(
                detailedAuthorization.documents_data?.map((d) => ({
                    data: d,
                    custom_identifier: d._id,
                })) ?? []
            );

            setTimeout(() => setEditSynced(true), 300);
        });

        setTags(() => {
            const tags: FormDataInsert.Tags = {};
            detailedAuthorization.tags_data?.forEach(({ name, category }) => {
                if (!category) return;
                if (!tags[category]) tags[category] = [];
                tags[category]?.push(name);
            });
            return tags;
        });
    }, [isEdit, detailedAuthorization, project._id]);

    const [internalDocs, setInternalDocs] = useState<EitherFile[]>([]);

    const onFileRemove = (file: BaseFile) => {
        setDocuments((old) =>
            old.filter((d) => d.custom_identifier !== file.custom_identifier)
        );
        setInternalDocs((old) =>
            old.filter((f) => f.custom_identifier !== file.custom_identifier)
        );
    };

    const { insertDocuments, uploadStatus, cancelUpload } = useUpsertDocument({
        onUpload: ({ okDocuments }) => {
            const docsToAppend = okDocuments
                .filter((e) =>
                    internalDocs.find(
                        (f) =>
                            f.status === 'uploading' &&
                            f.custom_identifier === e.custom_identifier
                    )
                )
                .map((e) => ({
                    data: e.data,
                    custom_identifier: e.custom_identifier,
                }));
            setDocuments((old) => [...old, ...docsToAppend]);
        },
        onSettled: () => setInternalDocs([]),
    });

    const onAuthInsert = () => {
        if (onUpsert) onUpsert();
        props.onHide();
    };

    const { authorizationInsertMutation, authorizationEditMutation } =
        useAuthorizationMutation({
            onUpdate: onAuthInsert,
            onInsert: onAuthInsert,
        });

    const documentIsLoading = uploadStatus === 'pending';

    useEffect(() => {
        const uploading = internalDocs.length > 0;
        setIsDocsUploading(uploading);
        if (documentIsLoading && !uploading) cancelUpload();
    }, [cancelUpload, documentIsLoading, internalDocs.length]);

    const areEvidencesUploading = Object.values(targetGroups).some(
        (tg) => tg.uploading
    );
    const areFilesUploading = isDocsUploading || areEvidencesUploading;
    const isLoading =
        authorizationInsertMutation.isPending ||
        authorizationEditMutation.isPending;
    const isFormOkToSend =
        !areFilesUploading &&
        !isLoading &&
        authorizationFlow !== null &&
        authorizationConfig !== null &&
        authorization.name !== '' &&
        targetGroups.every((tg) => tg.targets.length > 0);

    const generateTargetGroupsTab = () => {
        return targetGroups.map((targetGroup, groupIndex) => {
            const header = `${localization.models.targetsGroup.singular} ${
                groupIndex + 1
            }`;
            return (
                <AccordionTab
                    key={groupIndex}
                    disabled={isLoading}
                    header={
                        <div className='flex flex-row items-center w-full'>
                            <span>{header}</span>
                            {targetGroup.targets.length <= 0 && (
                                <span className='ml-2 p-error'>
                                    {
                                        localization.validations.target
                                            .oneTargetAtLeast
                                    }
                                </span>
                            )}
                            {targetGroups.length > 1 && (
                                <Button
                                    rounded
                                    text
                                    icon='pi pi-times'
                                    className='ml-auto'
                                    tooltip={
                                        localization.components.models
                                            .targetsGroup.button.remove
                                    }
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onTargetGroupRemove(groupIndex);
                                    }}
                                />
                            )}
                        </div>
                    }
                >
                    <TargetGroup
                        authorizationConfigId={authorizationConfig?._id ?? ''}
                        targets={targetGroup.targets}
                        evidences={targetGroup.evidences}
                        setTargets={(targets) =>
                            setTargetGroups((old) => {
                                const targetGroup = old[groupIndex];
                                if (!targetGroup) return old;
                                const newTargetGroups = [...old];
                                newTargetGroups[groupIndex] = {
                                    ...targetGroup,
                                    evidences: [...targetGroup.evidences],
                                    targets: targets,
                                };
                                return newTargetGroups;
                            })
                        }
                        addEvidences={(newEvidences) =>
                            setTargetGroups((old) => {
                                const targetGroup = old[groupIndex];
                                if (!targetGroup) return old;
                                const newTargetGroups = [...old];
                                newTargetGroups[groupIndex] = {
                                    ...targetGroup,
                                    targets: [...targetGroup.targets],
                                    evidences: [
                                        ...targetGroup.evidences,
                                        ...newEvidences,
                                    ],
                                };
                                return newTargetGroups;
                            })
                        }
                        removeEvidence={(indexToRemove) =>
                            setTargetGroups((old) => {
                                const targetGroup = old[groupIndex];
                                if (!targetGroup) return old;
                                const { evidences } = targetGroup;
                                const newTargetGroups = [...old];
                                const newEvidences = [...evidences];
                                const removedEvidences = newEvidences.splice(
                                    indexToRemove,
                                    1
                                );
                                const deletedEvidencesIds =
                                    removedEvidences.map((e) => e._id);
                                setEvidencesToExclude((old) => [
                                    ...old,
                                    ...deletedEvidencesIds,
                                ]);

                                newTargetGroups[groupIndex] = {
                                    ...targetGroup,
                                    targets: [...targetGroup.targets],
                                    evidences: newEvidences,
                                };
                                return newTargetGroups;
                            })
                        }
                        showEvidenceMetadataSelection={
                            authorizationConfig?.evidences_metadata_form &&
                            authorizationConfig.evidences_metadata_form.length >
                                0
                        }
                        onUploadStart={() =>
                            setTargetGroups((old) => {
                                const targetGroup = old[groupIndex];
                                if (!targetGroup) return old;
                                const newTargetGroups = [...old];
                                newTargetGroups[groupIndex] = {
                                    ...targetGroup,
                                    uploading: true,
                                };
                                return newTargetGroups;
                            })
                        }
                        onUploadEnd={() =>
                            setTargetGroups((old) => {
                                const targetGroup = old[groupIndex];
                                if (!targetGroup) return old;
                                const newTargetGroups = [...old];
                                newTargetGroups[groupIndex] = {
                                    ...targetGroup,
                                    uploading: false,
                                };
                                return newTargetGroups;
                            })
                        }
                        disabled={isLoading}
                    />
                </AccordionTab>
            );
        });
    };

    const onTargetGroupAdd = () =>
        setTargetGroups((old) => [...old, getNewGroup()]);

    const onTargetGroupRemove = (index: number) => {
        setTargetGroups((old) => {
            const newTargetsGroups = [...old];
            if (newTargetsGroups[index]) newTargetsGroups.splice(index, 1);
            return newTargetsGroups;
        });
    };

    const onFormSubmit = ({ isDraft }: { isDraft: boolean }) => {
        handleUpdate(isDraft ? 'draft' : 'pending');
    };

    const handleUpdate = (status: 'draft' | 'pending') => {
        const groups = {
            targets: {},
            evidences: {},
        } as {
            targets: {
                [key: string]: InsertAuthorizationEP.TargetWithTag[];
            };
            evidences: { [key: string]: string[] };
        };
        const targetFileIds: string[] = [];
        const mappedTargetFileIds: Record<string, true> = {};

        targetGroups.forEach((tg) => {
            const key = `group_${tg.identifier}`;
            groups.targets[key] = tg.targets.map((t) => {
                if (
                    t.target_file_id &&
                    !(t.target_file_id in mappedTargetFileIds)
                ) {
                    mappedTargetFileIds[t.target_file_id] = true;
                    targetFileIds.push(t.target_file_id);
                }
                const newTagsObjects: Record<string, string[]> = {};
                Object.entries(t.tags).forEach(([key, value]) => {
                    newTagsObjects[key] =
                        value == null
                            ? []
                            : Array.isArray(value)
                            ? value
                            : [value];
                });
                return {
                    value: t.value,
                    tags: newTagsObjects,
                    meta: t.meta,
                };
            });
            groups.evidences[key] = tg.evidences.map((e) => e._id);
        });

        if (!authorizationConfig || !authorizationFlow) return;

        const finalData = {
            project_id: project._id,
            targets: groups.targets,
            evidence_ids: groups.evidences,
            document_ids: documents.map((d) => d.data._id),
            authorization: {
                _id: authorization._id,
                name: authorization.name,
                status: status,
                authorization_config_id: authorizationConfig._id,
                authorization_flow_id: authorizationFlow._id,
                operation_id: operation,
                tags: tags,
            },
            target_file_ids: targetFileIds,
        };

        if (finalData.authorization._id !== null) {
            authorizationEditMutation.mutate({
                ...(finalData as EditAuthorizationEP.Data),
                evidenceIdsToRemove: evidencesToExclude,
            });
        } else authorizationInsertMutation.mutate(finalData);
    };

    const isEditLoading = isEdit && !editSynced;

    return (
        <LightDialog
            {...props}
            header={
                authorizationToEdit
                    ? localization.components.models.authorization.button.edit
                    : localization.components.models.authorization.button.new
            }
            className='w-8/12'
            footer={
                <div className='flex flex-col gap-1'>
                    {isLoading && (
                        <ProgressBar mode='indeterminate' className='w-' />
                    )}
                    <div className='flex flex-row gap-1 justify-end'>
                        {areFilesUploading && (
                            <div className='text-s mr-2'>
                                {
                                    localization.components.views.file.preview
                                        .fileBeingUploaded
                                }
                            </div>
                        )}
                        <Button
                            label={localization.components.common.button.save}
                            onClick={() => onFormSubmit({ isDraft: true })}
                            disabled={!isFormOkToSend || isLoading}
                        />
                        <Button
                            label={localization.components.common.button.cancel}
                            severity='danger'
                            onClick={props.onHide}
                        />
                    </div>
                </div>
            }
        >
            {reviewZipDialog}
            {uploadZipDialog}
            {isEditLoading ? (
                <LoadingMessage>{localization.common.loading}</LoadingMessage>
            ) : (
                <div className='flex gap-2 w-full flex-col'>
                    <h3>{localization.models.authorization.singular}</h3>
                    <InputText
                        required
                        label={
                            localization.components.models.authorization.views
                                .insert.authorizationName
                        }
                        disabled={isLoading}
                        value={authorization.name}
                        onChange={(e) =>
                            setAuthorization((old) => ({
                                ...old,
                                name: e.target.value,
                            }))
                        }
                        validations={[
                            {
                                validationError:
                                    localization.validations.generic
                                        .requiredName,
                                validate: (value) => {
                                    if (!value || value === '') return false;
                                    return true;
                                },
                            },
                        ]}
                    />
                    <Divider />
                    <SelectOneAuthorizationFlow
                        required
                        disabled={isLoading}
                        value={authorizationFlow?._id}
                        onLoad={(flows) => {
                            if (!startingFlowId) return;
                            setAuthorizationFlow(
                                flows.find((f) => f._id === startingFlowId) ??
                                    null
                            );
                        }}
                        onChange={(flow) => {
                            setAuthorizationFlow(flow ?? null);
                            setAuthorizationConfig(null);
                            setDocuments([]);
                            setTargetGroups([getNewGroup()]);
                        }}
                    />
                    {!!authorizationFlow && (
                        <>
                            <Divider />
                            <SelectOneAuthorizationConfig
                                value={authorizationConfig?._id}
                                onChange={(config) => {
                                    setAuthorizationConfig(config);
                                    setDocuments([]);
                                    setTargetGroups([getNewGroup()]);
                                }}
                                queryOptions={{
                                    devFilters: {
                                        authorization_flow_ids:
                                            authorizationFlow._id,
                                    },
                                }}
                                required
                                disabled={isLoading}
                            />
                            <Divider />
                            <SelectOrCreateOperation
                                operationId={operation}
                                setOperation={(op) =>
                                    setOperation(op?._id ?? null)
                                }
                                isDefaultOperation={isDefaultOperation}
                                setIsDefaultOperation={setIsDefaultOperation}
                                ignoreDefaultInit={isEdit}
                                disabled={isLoading}
                                fixedAuthorizationFlowType={
                                    authorizationFlow.type
                                }
                            />
                        </>
                    )}
                    {!!authorizationConfig && (
                        <>
                            <Divider />
                            <h3>
                                {
                                    localization.components.models.authorization
                                        .views.insert.targetAndEvidence
                                }
                            </h3>
                            <Button
                                label={
                                    localization.components.models.target.views
                                        .targetParseZip.uploadDialog.title
                                }
                                onClick={openUploadDialog}
                                icon='pi pi-upload'
                            />
                            <Accordion multiple activeIndex={[0]}>
                                {generateTargetGroupsTab()}
                            </Accordion>
                            <Button
                                icon='pi pi-plus'
                                label={
                                    localization.components.models.targetsGroup
                                        .button.new
                                }
                                onClick={onTargetGroupAdd}
                                loading={isLoading}
                            />
                            {tagConfig && Object.keys(tagConfig).length > 0 && (
                                <>
                                    <Divider />
                                    <h3>{localization.models.tag.plural}</h3>
                                    {Object.entries(tagConfig).map(
                                        ([category, type]) => {
                                            const handleChange = (
                                                value:
                                                    | string
                                                    | string[]
                                                    | null
                                                    | undefined
                                            ) => {
                                                let finalValue: string[] = [];
                                                if (typeof value === 'string')
                                                    finalValue = [value];
                                                else if (value)
                                                    finalValue = value;
                                                setTags((old) => ({
                                                    ...old,
                                                    [category]: finalValue,
                                                }));
                                            };
                                            const tagEntry = tags[category];
                                            if (!tagEntry) return null;

                                            const filters: ListTagEP.Filters.Dev =
                                                {
                                                    category: category,
                                                    model_type: 'authorization',
                                                };
                                            const sharedProps = {
                                                optionValue: 'name',
                                                hideTitle: true,
                                                key: category,
                                                queryOptions: {
                                                    devFilters: filters,
                                                },
                                                label: _.capitalize(category),
                                            };
                                            if (type === 'multi')
                                                return (
                                                    <SelectManyTags
                                                        {...sharedProps}
                                                        value={tagEntry}
                                                        onChange={(t) =>
                                                            handleChange(
                                                                t.map(
                                                                    (t) =>
                                                                        t.name
                                                                )
                                                            )
                                                        }
                                                    />
                                                );
                                            return (
                                                <SelectOneTag
                                                    {...sharedProps}
                                                    value={tagEntry[0] ?? null}
                                                    onChange={(t) =>
                                                        handleChange(t?.name)
                                                    }
                                                />
                                            );
                                        }
                                    )}
                                </>
                            )}
                            <Divider />
                            <h3>{localization.models.document.plural}</h3>
                            <CMFileUpload
                                value={[
                                    ...documents.map((d) => ({
                                        status: 'done' as 'done',
                                        _id: d.data._id,
                                        custom_identifier: d.custom_identifier,
                                        meta: {
                                            name: d.data.file.name ?? '',
                                            size: d.data.file.size,
                                        },
                                    })),
                                    ...internalDocs,
                                ]}
                                removeFile={onFileRemove}
                                customUpload
                                onFileSelection={({ files }) => {
                                    setInternalDocs((old) => {
                                        const filesToUpload = files.map(
                                            (f) => ({
                                                _id: null,
                                                custom_identifier:
                                                    new ObjectId().toHexString(),
                                                status: 'uploading' as 'uploading',
                                                file: f,
                                                meta: {
                                                    name: f.name,
                                                    size: f.size,
                                                },
                                            })
                                        );
                                        const newFiles = [
                                            ...old,
                                            ...filesToUpload,
                                        ];
                                        insertDocuments({
                                            project_id: project._id,
                                            files: filesToUpload,
                                            authorization_config_id:
                                                authorizationConfig._id,
                                            authorization_flow_id:
                                                authorizationFlow?._id ??
                                                undefined,
                                        });
                                        return newFiles;
                                    });
                                }}
                                disabled={documentIsLoading || isLoading}
                            />
                        </>
                    )}
                </div>
            )}
        </LightDialog>
    );
};

export default InsertAuthorizationDialog;
