import LightDialog from 'components/display/LightDialog';
import { Datepicker, InputText } from 'components/ethercity-primereact';
import SelectOneAuthorizationFlowType from 'components/models/AuthorizationFlow/SelectOneAuthorizationFlowType';
import { useProject } from 'hooks/context/project/useProject';
import { useLocalization } from 'hooks/context/useLocalization';
import useUpsertOperation from 'hooks/mutations/operation/useUpsertOperation';
import { Button } from 'primereact/button';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

export type UseUpsertOperationDialogOptions = {
    fixedAuthorizationFlowType?: Ether.CaseManager.AuthorizationFlow.Type | null;
    editData?: Ether.CaseManager.Operation | null;
    onCreate?: (id: string) => void;
};
type UpsertOperationDialogProps = UseUpsertOperationDialogOptions & {
    onHide: () => void;
};

type UpsertForm = {
    name: string;
    type: Ether.CaseManager.AuthorizationFlow.Type | null;
    startDate: Date | null;
    endDate: Date | null;
};

type ValidatedUpsertForm = NonNullableProperties<UpsertForm>;

function validateForm(formData: UpsertForm): formData is ValidatedUpsertForm {
    const { name, type, startDate, endDate } = formData;
    return name !== '' && !!type && !!startDate && !!endDate;
}

const UpsertOperationDialog: React.FC<UpsertOperationDialogProps> = ({
    fixedAuthorizationFlowType,
    editData,
    onCreate,
    onHide,
}) => {
    const project = useProject();
    const { insertOperation, updateOperation, status } = useUpsertOperation({
        onInsert: (data) => {
            onCreate?.(data._id);
            onHide();
        },
    });

    const { handleSubmit, control, watch } = useForm<UpsertForm>({
        mode: 'onChange',
        defaultValues: {
            name: editData?.name ?? '',
            type: editData?.authorization_flow_type ?? null,
            startDate: editData?.start_date ?? null,
            endDate: editData?.end_date ?? null,
        },
    });

    const [localization] = useLocalization();

    const modalLocalization =
        localization.components.models.operation.views.upsertModal;

    const invalidDatesMessage = modalLocalization.errors.invalidDates;
    const equalDatesMessage = modalLocalization.errors.equalDates;

    const formData = watch();
    const { startDate, endDate } = formData;

    const datesInvalid = (startDate && endDate && endDate < startDate) ?? false;
    const datesEqual =
        (startDate && endDate && startDate.getTime() === endDate.getTime()) ??
        false;

    const dateError = datesInvalid || datesEqual;
    const isFormValid = useMemo(() => validateForm(formData), [formData]);
    const invalidForm = dateError || !isFormValid;
    const isLoading = status === 'pending';
    const isDisabled = invalidForm || isLoading;

    const onSubmit = useCallback(
        (data: UpsertForm) => {
            if (!validateForm(data)) return;
            if (editData?._id) {
                updateOperation({
                    _id: editData._id,
                    project_id: project._id,
                    name: data.name,
                    authorization_flow_type: data.type,
                    start_date: data.startDate,
                    end_date: data.endDate,
                    is_active: true,
                });
            } else {
                insertOperation({
                    project_id: project._id,
                    name: data.name,
                    authorization_flow_type: data.type,
                    start_date: data.startDate,
                    end_date: data.endDate,
                    is_active: true,
                });
            }
        },
        [editData?._id, insertOperation, project._id, updateOperation]
    );

    return (
        <LightDialog
            visible={true}
            onHide={onHide}
            header={localization.components.models.operation.button.new}
            footer={
                <>
                    <Button
                        label={localization.components.common.button.save}
                        disabled={isDisabled}
                        loading={isLoading}
                        onClick={handleSubmit(onSubmit)}
                    />
                    <Button
                        label={localization.components.common.button.cancel}
                        severity='danger'
                        onClick={onHide}
                    />
                </>
            }
        >
            <Controller
                rules={{ required: true }}
                control={control}
                name='name'
                disabled={isLoading}
                render={({ field: { name, value, onChange, disabled } }) => (
                    <InputText
                        name={name}
                        label={localization.fields.operation.name}
                        value={value}
                        onChange={onChange}
                        wrapperStyle={{
                            width: '100%',
                        }}
                        disabled={disabled}
                    />
                )}
            />
            <Controller
                rules={{ required: true }}
                control={control}
                name='type'
                disabled={!!fixedAuthorizationFlowType || isLoading}
                render={({ field: { value, onChange, disabled } }) => (
                    <SelectOneAuthorizationFlowType
                        value={value}
                        onChange={(type) => onChange(type?.value ?? null)}
                        hideTitle
                        required
                        disabled={disabled}
                    />
                )}
            />
            <div className='flex flex-row gap-2'>
                <Controller
                    rules={{ required: true }}
                    control={control}
                    name='startDate'
                    disabled={isLoading}
                    render={({
                        field: { name, value, onChange, disabled },
                    }) => (
                        <Datepicker
                            name={name}
                            label={localization.fields.operation.startDate}
                            type='datetime-local'
                            onChange={(date) => onChange(date)}
                            value={value}
                            required
                            disabled={disabled}
                        />
                    )}
                />
                -
                <Controller
                    rules={{ required: true }}
                    control={control}
                    name='endDate'
                    disabled={isLoading}
                    render={({
                        field: { name, value, onChange, disabled },
                    }) => (
                        <Datepicker
                            name={name}
                            label={localization.fields.operation.endDate}
                            type='datetime-local'
                            onChange={(date) => onChange(date)}
                            value={value}
                            required
                            disabled={disabled}
                        />
                    )}
                />
            </div>
            {datesInvalid && (
                <span className='p-error'>{invalidDatesMessage}</span>
            )}
            {datesEqual && <span className='p-error'>{equalDatesMessage}</span>}
        </LightDialog>
    );
};

const useUpsertOperationDialog = (
    options?: UseUpsertOperationDialogOptions
) => {
    const [editData, setEditData] =
        useState<Ether.CaseManager.Operation | null>(null);
    const [visible, setVisible] = useState(false);

    const hideDialog = () => setVisible(false);
    const showUpdateDialog = (editData: Ether.CaseManager.Operation) => {
        setEditData(editData);
        setVisible(true);
    };
    const showInsertDialog = () => {
        setEditData(null);
        setVisible(true);
    };

    const dialog = useMemo(() => {
        return (
            visible && (
                <UpsertOperationDialog
                    onHide={hideDialog}
                    onCreate={options?.onCreate}
                    fixedAuthorizationFlowType={
                        options?.fixedAuthorizationFlowType
                    }
                    editData={editData}
                />
            )
        );
    }, [
        visible,
        options?.onCreate,
        options?.fixedAuthorizationFlowType,
        editData,
    ]);

    return {
        dialog,
        showCreate: showInsertDialog,
        showEdit: showUpdateDialog,
        hide: hideDialog,
    };
};

export default useUpsertOperationDialog;
