import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import useCreateOperationDialog, {
    UseUpsertOperationDialogOptions,
} from 'hooks/models/operation/useUpsertOperationDialog';
import { Button } from 'primereact/button';
import React from 'react';

/**
 * Button to show the search targets dialog, with simplified view
 * Respects permissions and hides if user has no permissions to view and search targets
 * @param props.fixedAuthorizationFlowId - Fix operations to specific flow if provided. Useful for creating operations whithin chosen authorization flow
 * @param props.onCreate - Callback after operation is created, returns the ID of created operation
 * @returns Button element
 */
const InsertOperationButton: React.FC<
    UseUpsertOperationDialogOptions & {
        disabled?: boolean;
    }
> = ({ disabled, ...props }) => {
    const [localization] = useLocalization();
    const { dialog, showCreate } =
        useCreateOperationDialog(props);
    const { permissions } = useAuth();

    if (!permissions.insertOperation) return null;

    return (
        <>
            {dialog}
            <Button
                icon='pi pi-plus'
                label={localization.components.models.operation.button.new}
                onClick={showCreate}
                disabled={disabled}
            />
        </>
    );
};

export default InsertOperationButton;
