import { useState } from 'react';
import {
    InputMask as PrimeReactInputMask,
    InputMaskProps as PrimeReactInputMaskProps,
} from 'primereact/inputmask';

import './style.css';
import renderIsRequired from '../utils/renderIsRequired';

export interface IInputMask extends PrimeReactInputMaskProps {
    label?: string;
    labelStyle?: React.CSSProperties;
    error?: string | null;
    value?: string;
    validations?: {
        validate: (value: string | undefined | null) => boolean;
        validationError?: string;
    }[];
    validationCallback?(isValid: boolean, error: string | null): void;
    required?: boolean;
    wrapperStyle?: React.CSSProperties;
}

export const InputMask: React.FC<IInputMask> = ({
    label,
    name,
    labelStyle,
    error,
    validations,
    validationCallback,
    required,
    wrapperStyle,
    ...rest
}) => {
    const [validationError, setValidationError] = useState<string | null>(null);

    const validate = (value?: string | null) => {
        if (!validations) return true;
        const notValid = validations.find((v) => {
            return !v.validate(value);
        });
        const errorMessage = notValid
            ? notValid.validationError ?? 'Validation error'
            : null;
        if (errorMessage) {
            setValidationError(errorMessage);
        } else setValidationError(null);
        if (validationCallback) validationCallback(!notValid, errorMessage);
    };

    return (
        <div className='custom-input-mask' style={wrapperStyle}>
            <span>
                <label
                    htmlFor={name}
                    style={{
                        marginRight: '8px',
                        display: label ? 'inherit' : 'none',
                        ...labelStyle,
                    }}
                >
                    {label}
                    {renderIsRequired(required)}
                </label>
                <PrimeReactInputMask
                    {...rest}
                    onChange={(e) => {
                        validate(e.target.value);
                        if (rest.onChange) rest.onChange(e);
                    }}
                    className={
                        (rest.className ?? '') +
                        (validationError || error ? '  p-invalid' : '')
                    }
                />
                {error && (
                    <small
                        id={`${name}-help`}
                        className='p-error block input-mask-error'
                    >
                        {error}
                    </small>
                )}
                {validationError && (
                    <small
                        id={`${name}-help`}
                        className='p-error block input-mask-error'
                    >
                        {validationError}
                    </small>
                )}
            </span>
        </div>
    );
};
