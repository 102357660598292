import { useLocalization } from 'hooks/context/useLocalization';
import { Badge, BadgeProps } from 'primereact/badge';

const BlockOrderTypeBadge: React.FC<{
    blockOrder: Ether.CaseManager.BlockOrder;
    badgeProps?: BadgeProps;
}> = ({ blockOrder, badgeProps }) => {
    const type = blockOrder.type;
    const [localization] = useLocalization();
    if (!type) return '-';
    badgeProps = {
        ...badgeProps,
        severity: 'info',
        value: type.toUpperCase(),
    };
    switch (type) {
        case 'administrative':
            badgeProps.value =
                localization.components.models.blockOrder.badge.type.administrative.toLocaleUpperCase();
            break;
        case 'judicial':
            badgeProps.value =
                localization.components.models.blockOrder.badge.type.judicial.toLocaleUpperCase();
            break;
    }
    return <Badge {...badgeProps} />;
};

export default BlockOrderTypeBadge;
