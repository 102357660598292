import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { DateBadge } from 'components/ethercity-primereact';
import { Column } from 'primereact/column';
import { getUserDisplayName } from 'utils/models/user';
import { useAuth } from 'hooks/context/useAuth';
import { useRef } from 'react';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { Button } from 'primereact/button';
import { useLocalization } from 'hooks/context/useLocalization';
import CMDataTable from 'components/datatable/CMDataTable';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import { isBlockOrderRespondable } from 'utils/models/blockOrder';
import { useBlockOrderRespond } from 'hooks/models/blockOrder/useBlockOrderRespond';
import useExportBlockOrderFiles from 'hooks/queries/block-order/useExportBlockOrderFiles';
import BlockOrderValidationStatusBadge from 'components/models/BlockOrder/BlockOrderValidationStatusBadge';
import useShowBlockOrderStats from 'hooks/dialogs/blockOrder/useShowBlockOrderStats';
import useShowObjectDisplayModal from 'hooks/dialogs/general/useShowObjectDisplayModal';
import useBlockOrderColumnFields from 'hooks/columns/models/useBlockOrderColumnFields';
import BlockOrderStatusBadge from 'components/models/BlockOrder/BlockOrderStatusBadge';
import BlockOrderNotificationStatusBadge from 'components/models/BlockOrder/BlockOrderNotificationStatusBadge';

type DetailedModel = Ether.CaseManager.BlockOrder.Detailed;

const RowMenu: React.FC<{
    item: DetailedModel;
    onShowDetail: (target: Ether.CaseManager.BlockOrder) => void;
    onRespond?: () => void;
    onShowEdit?: (id: string) => void;
    onShowStats?: (item: { id: string; name: string }) => void;
}> = ({ item, onShowDetail, onRespond, onShowEdit, onShowStats }) => {
    const { permissions, user } = useAuth();
    const [localization] = useLocalization();

    const menuRef = useRef<Menu>(null);

    const [respondOrder] = useBlockOrderRespond({
        onRespond,
    });

    const isRespondable = isBlockOrderRespondable({
        permissions: permissions,
        blockOrder: item,
        userId: user.data?._id,
    });

    const { exportFile, isExporting } = useExportBlockOrderFiles(item._id);
    const {
        exportFile: exportCumulativeFiles,
        isExporting: isExportingCumulative,
    } = useExportBlockOrderFiles(item._id, true);

    const menuItems: MenuItem[] = [
        ...(permissions.getBlockOrderStats && onShowStats
            ? [
                  {
                      id: 'stats',
                      icon: 'pi pi-chart-bar',
                      label: localization.components.common.stats.header,
                      command: () =>
                          onShowStats({
                              id: item._id,
                              name: item.name,
                          }),
                  },
              ]
            : []),
        ...(permissions.debugBlockOrders
            ? [
                  {
                      id: 'debug',
                      label: 'Detail data',
                      command: () => onShowDetail(item),
                  },
              ]
            : []),
        ...(permissions.insertBlockOrders &&
        onShowEdit &&
        item.status === 'draft'
            ? [
                  {
                      id: 'edit',
                      label: localization.components.common.button.edit,
                      command: () => onShowEdit(item._id),
                      icon: 'pi pi-pencil',
                  },
              ]
            : []),
        ...(isRespondable
            ? [
                  {
                      id: 'accept',
                      label: localization.components.common.button.confirm,
                      command: () => respondOrder(item, true),
                      icon: 'pi pi-check',
                  },
              ]
            : []),
        ...(permissions.exportBlockOrderFiles
            ? [
                  {
                      id: 'export-files',
                      icon: 'pi pi-download',
                      label: localization.components.common.button.exportFiles,
                      command: exportFile,
                      disabled: isExporting,
                  },
                  {
                      id: 'export-files',
                      icon: 'pi pi-download',
                      label: localization.components.models.blockOrder.button
                          .exportCumulative,
                      command: exportCumulativeFiles,
                      disabled: isExportingCumulative,
                  },
              ]
            : []),
    ];

    if (menuItems.length <= 0) return null;

    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

const BlockOrderDataTable: React.FC<
    CMDataTableProps<DetailedModel> & {
        onRespond?: () => void;
        onShowEdit?: (id: string) => void;
    }
> = ({ onRespond, onShowEdit, ...props }) => {
    const [localization] = useLocalization();
    const { user } = useAuth();
    const { columnFields, filterOptions } = useBlockOrderColumnFields();

    const { dialog: statsDialog, show: showStats } = useShowBlockOrderStats();
    const { dialog: debugDialog, show: showDebug } =
        useShowObjectDisplayModal();

    const datatableLocale = localization.components.models.blockOrder.datatable;

    const hideStatusColumn = user.role === 'operator';
    const showValidationStatusColumn = user.role === 'operator';

    return (
        <>
            {debugDialog}
            {statsDialog}
            <CMDataTable
                lazy
                emptyMessage={datatableLocale.empty}
                columnConfigName='blockOrder'
                filterOptions={filterOptions}
                {...props}
            >
                <Column
                    field='_cm_name_id'
                    body={(data: Ether.CaseManager.BlockOrder) => (
                        <NameIdAggregator
                            name={data.name}
                            id={data._id}
                            navigateTo={`/block-orders/${data._id}`}
                        />
                    )}
                />
                {!hideStatusColumn && (
                    <Column
                        field={columnFields['status'].name}
                        header={localization.fields.blockOrder.status}
                        body={(data: Ether.CaseManager.BlockOrder) => (
                            <BlockOrderStatusBadge blockOrder={data} />
                        )}
                    />
                )}
                {showValidationStatusColumn && (
                    <Column
                        field={columnFields['validation_status'].name}
                        header={localization.fields.blockOrder.validationStatus}
                        body={(data: DetailedModel) => (
                            <BlockOrderValidationStatusBadge
                                blockOrder={data}
                            />
                        )}
                    />
                )}
                <Column
                    field={columnFields.notification_status.name}
                    header={localization.fields.blockOrder.notificationStatus}
                    body={(data: DetailedModel) => (
                        <BlockOrderNotificationStatusBadge blockOrder={data} />
                    )}
                />
                <Column
                    field={columnFields['authorizations_count'].name}
                    header={localization.models.authorization.plural}
                    body={(data: DetailedModel) => data.total_authorizations}
                />
                <Column
                    field={columnFields['targets_count'].name}
                    header={localization.models.target.plural}
                    body={(data: DetailedModel) => data.total_targets}
                />
                <Column
                    field={columnFields['created_at'].name}
                    header={localization.fields.blockOrder.created}
                    dataType='date'
                    body={(data: Ether.CaseManager.BlockOrder) => (
                        <DateBadge value={data.created_at} />
                    )}
                />
                <Column
                    field={columnFields['created_by'].name}
                    header={localization.fields.blockOrder.createdBy}
                    body={(data: DetailedModel) =>
                        getUserDisplayName(data.created_by_data?.[0])
                    }
                />
                <Column
                    field={columnFields['registered_at'].name}
                    header={localization.fields.blockOrder.registeredAt}
                    dataType='date'
                    body={(data: Ether.CaseManager.BlockOrder) =>
                        data.registered_at ? (
                            <DateBadge value={data.registered_at} />
                        ) : (
                            '-'
                        )
                    }
                />
                <Column
                    field='actions'
                    body={(data: DetailedModel) => (
                        <RowMenu
                            item={data}
                            onShowDetail={(item) =>
                                showDebug({
                                    data: item,
                                    header: item.name,
                                })
                            }
                            onRespond={onRespond}
                            onShowEdit={onShowEdit}
                            onShowStats={showStats}
                        />
                    )}
                />
            </CMDataTable>
        </>
    );
};

export default BlockOrderDataTable;
