import { UseMutationResult, useMutation } from '@tanstack/react-query';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { getErrorToast } from 'utils/errorHandler';
import { UpsertOperationEP } from 'services/ether/case-manager/operation/types';
import { upsertOperation } from 'services/ether/case-manager/operation';

const useUpsertOperation = (options?: {
    onInsert?: (operation: UpsertOperationEP.Result) => void;
}): {
    insertOperation: (data: UpsertOperationEP.InsertData) => void;
    updateOperation: (data: UpsertOperationEP.UpdateData) => void;
    status: UseMutationResult['status'];
} => {
    const [localization] = useLocalization();
    const toast = useToast();

    const { hardRefresh } = useQueryRefresh();

    const handleUpdate = ({
        data,
        params,
    }: {
        data: UpsertOperationEP.Result;
        params: UpsertOperationEP.UpsertData;
    }) => {
        hardRefresh(['operation']);
        const isEdit = '_id' in params;
        const locale = localization.endpoints.operation.upsert_operation;
        toast.show({
            severity: 'success',
            life: 5000,
            summary: isEdit ? locale.updateSuccess : locale.createSuccess,
        });
        if (options?.onInsert) options.onInsert(data);
    };

    const upsertMutation = useMutation<
        UpsertOperationEP.Result,
        Error,
        UpsertOperationEP.UpsertData
    >({
        mutationFn: (data) => upsertOperation(data),
        onSuccess: (data, params) => handleUpdate({ data, params }),
        onError: (e: Error) =>
            toast.show(getErrorToast(e.message, localization)),
    });

    return {
        insertOperation: (data) => upsertMutation.mutate(data),
        updateOperation: (data) => upsertMutation.mutate(data),
        status: upsertMutation.status,
    };
};

export default useUpsertOperation;
