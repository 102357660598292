import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { getMetrics } from 'services/ether/case-manager/authorizations';

const useMetricsQuery = (
    projectId: string,
    queryOptions?: CaseManagerApp.DefaultQueryOptions
) => {
    const queryKey = ['metrics', projectId];

    const query = useQueryWithRefresh<Ether.CaseManager.DashboardMetrics>({
        queryKey,
        queryFn: ({ signal }) =>
            getMetrics(projectId, { signal }),
        ...queryOptions,
    });

    return query;
};

export default useMetricsQuery;
