import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { detailManyTargetsUnwindedAuthorization } from 'services/ether/case-manager/targets';
import { DetailTargetEP } from 'services/ether/case-manager/targets/types';

/**
 * Hook that fetches the judicial targets info using useQueryRefresh, unwinding and bringing each target of authorization for a row
 * Prefer to use this instead of writing your own useQuery hook or using useDetailTarget
 * @param params - Various options that can filter, sort and paginate the detailManyTargets method
 * @param queryOptions - Additional query options that may be given to the Tanstack's useQuery hook
 * @returns query - Query object containing all of the useQueryWithRefresh properties
 */
const useDetailTargetsUnwindedAuthorization = (
    params: DetailTargetEP.Params.Many,
    queryOptions?: CaseManagerApp.DefaultQueryOptions
) => {
    const queryKey = ['target', 'detail', params];

    const query = useQueryWithRefresh({
        queryKey,
        queryFn: ({ signal }) =>
            detailManyTargetsUnwindedAuthorization({
                ...params,
                options: {
                    ...params.options,
                    devFilters: {
                        ...params.options?.devFilters,
                    },
                },
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useDetailTargetsUnwindedAuthorization;
