import { useState } from 'react';
import { Button } from 'primereact/button';
import { TabMenu } from 'primereact/tabmenu';
import { Link } from 'react-router-dom';

import DisplayCountBox from 'components/display/DisplayCountBox';

import { useAuth } from 'hooks/context/useAuth';
import useMetricsQuery from 'hooks/queries/metrics/useMetricsQuery';
import { useProject } from 'hooks/context/project/useProject';
import { useLocalization } from 'hooks/context/useLocalization';
import { getUserDisplayName } from 'utils/models/user';

import useDetailRecentBlockOrder from 'hooks/queries/block-order/useDetailRecentBlockOrder';

import BlockOrderDataTable from 'components/datatable/models/blockOrder/BlockOrderDataTable';
import useUpsertBlockOrderDialog from 'hooks/models/blockOrder/useUpsertBlockOrderDialog';

import AdminHome from './roles/AdminHome';
import AnalystHome from './roles/AnalystHome';
import AuthorizerHome from './roles/AuthorizerHome';
import AuthorityHome from './roles/AuthorityHome';
import OperatorHome from './roles/OperatorHome';
import InsertOperationButton from 'components/models/Operation/InsertOperationButton';
import { HomeViewSet } from './components/HomeViewSet';
import { ValidHomeViews } from '../../../hooks/home/useHomeViews';
import { HomeButtons } from './components/HomeButtonSet';
import useAppConfig from 'hooks/appConfig/useAppConfig';

// const ShowChart: React.FC<{
//     title: string;
//     result: string;
//     resultLabel: string;
// }> = ({ title, result, resultLabel }) => {
//     return (
//         <div>
//             <span className='text-sm'>{title.toLocaleUpperCase()}</span>
//             <div className='flex flex-row gap-4 items-center'>
//                 <div className='flex flex-col text-justify items-center gap-4'>
//                     <strong className='text-3xl'>{result}</strong>
//                     <span>{resultLabel.toLocaleUpperCase()}</span>
//                 </div>
//                 <FakeChartSvg />
//             </div>
//         </div>
//     );
// };

// const SimpleBox: React.FC<{
//     topText: string;
//     bottomText: string;
//     lastText?: string;
// }> = ({ topText, bottomText, lastText }) => {
//     return (
//         <div className='flex flex-col justify-start items-center text-center w-full'>
//             <span className='text-base'>{topText.toLocaleUpperCase()}</span>
//             <strong className='text-3xl'>{bottomText}</strong>
//             {lastText && <span className='text-sm'>{lastText}</span>}
//         </div>
//     );
// };

export const BlockOrderDashboard: React.FC<{
    hideTitle?: boolean;
    hideCount?: boolean;
}> = ({ hideTitle, hideCount }) => {
    const project = useProject();
    const { permissions, user } = useAuth();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [localization] = useLocalization();
    const { element: UpsertDialog, showCreate } = useUpsertBlockOrderDialog();

    const showDraft = user.role !== 'operator';

    const doneBlockOrders = useDetailRecentBlockOrder(project._id, 'done');
    const draftBlockOrders = useDetailRecentBlockOrder(
        project._id,
        'draft',
        showDraft
    );

    const metricsQuery = useMetricsQuery(project._id);

    const pageLocale = localization.components.views.homeDashboard;

    return (
        <section>
            <UpsertDialog />
            {!hideTitle && <h3>{localization.models.blockOrder.plural}</h3>}
            {!hideCount && (
                <div>
                    {metricsQuery.isLoading ? (
                        <h4>{pageLocale.metricsLoading}</h4>
                    ) : (
                        <div className='flex items-start gap-2'>
                            <DisplayCountBox
                                label={localization.models.blockOrder.plural.toLocaleUpperCase()}
                                count={
                                    metricsQuery.data?.total_block_orders ?? 0
                                }
                            />
                            <DisplayCountBox
                                label={localization.components.models.blockOrder.badge.status.doneWithName.toLocaleUpperCase()}
                                count={
                                    metricsQuery.data
                                        ?.block_order_status_done ?? 0
                                }
                            />
                            {showDraft && (
                                <DisplayCountBox
                                    label={localization.components.models.blockOrder.badge.status.draftWithName.toLocaleUpperCase()}
                                    count={
                                        metricsQuery.data
                                            ?.block_order_status_draft ?? 0
                                    }
                                />
                            )}
                        </div>
                    )}
                </div>
            )}
            <div className='flex gap-2 justify-between items-center'>
                <h3>{pageLocale.recentBlockOrders}</h3>
                {permissions.insertBlockOrders && (
                    <Button
                        label={
                            localization.components.models.blockOrder.button.new
                        }
                        icon='pi pi-plus'
                        onClick={() => showCreate()}
                        style={{ marginLeft: 'auto' }}
                    />
                )}
                {user.role === 'authorizer-user' && <InsertOperationButton />}
                <Link to='/block-orders'>
                    <Button
                        label={
                            localization.components.models.blockOrder.button
                                .viewAll
                        }
                    />
                </Link>
            </div>
            <TabMenu
                activeIndex={selectedIndex}
                onTabChange={(e) => setSelectedIndex(e.index)}
                model={[
                    {
                        label:
                            localization.components.models.blockOrder.badge
                                .status.doneWithName +
                            (metricsQuery.data && !hideCount
                                ? ` (${
                                      metricsQuery.data
                                          ?.block_order_status_done ?? 0
                                  })`
                                : ''),
                    },
                    ...(showDraft
                        ? [
                              {
                                  label:
                                      localization.components.models.blockOrder
                                          .badge.status.draftWithName +
                                      (metricsQuery.data && !hideCount
                                          ? ` (${
                                                metricsQuery.data
                                                    ?.block_order_status_draft ??
                                                0
                                            })`
                                          : ''),
                              },
                          ]
                        : []),
                ]}
            />
            <BlockOrderDataTable
                value={
                    (selectedIndex === 0
                        ? doneBlockOrders.data
                        : draftBlockOrders.data
                    )?.payload
                }
                loading={
                    draftBlockOrders.isFetching || doneBlockOrders.isFetching
                }
            />
        </section>
    );
};

const Home = () => {
    const { user } = useAuth();
    const [localization] = useLocalization();

    const config = useAppConfig();

    if (config.home_views) {
        return (
            <>
                <h2>
                    {localization.components.views.homeDashboard.welcome.replace(
                        '{username}',
                        getUserDisplayName(user.data)
                    )}
                </h2>
                <HomeViewSet
                    views={config.home_views.map((view) => ({
                        key: view.key,
                        tabs: view.tabs as ValidHomeViews[],
                        buttons: view.buttons as HomeButtons[],
                        header: view.header,
                    }))}
                />
            </>
        );
    }

    return (
        <>
            <h2>
                {localization.components.views.homeDashboard.welcome.replace(
                    '{username}',
                    getUserDisplayName(user.data)
                )}
            </h2>
            {user.role === 'admin' && <AdminHome />}
            {user.role === 'analyst' && <AnalystHome />}
            {user.role === 'authorizer-user' && <AuthorizerHome />}
            {user.role === 'authorizer-strict' && <AuthorityHome />}
            {user.role === 'operator' && <OperatorHome />}
        </>
    );
};

export default Home;
