import { CMDataTableColumnFields } from 'components/datatable/CMDataTable/types';
import { CMLocalization } from 'static/language';
import {
    getTargetMeta,
    objetifyNestedTagList,
    translateSafeIpReasons,
} from 'utils/models/target';
import ReservedIpBadge from '../../../models/Targets/ReservedIpBadge';
import { Badge } from 'primereact/badge';
import AdaptableTooltip from 'components/misc/AdaptableTooltip';
import { localizeDistance } from 'utils/dateUtils';
import { Column, ColumnProps } from 'primereact/column';
import _ from 'lodash';
import {
    BlockCheckerEffectivenessBadge,
    BlockCheckerProgressBadge,
} from 'components/models/Targets/BlockCheckerBadge';
import { renderUnidentifiedDataType } from 'components/datatable/utils';
import TargetTypeBadge from 'components/models/Targets/TargetTypeBadge';
import TargetEnrichmentField, {
    EnrichDisplayError,
    EnrichDisplayPending,
} from 'components/models/Targets/TargetEnrichmentField';
import {
    filterCreateOrExcludeArrayList,
    grabValidKeyMatchingUrl,
    parseSingleOrArrayIntoArray,
} from 'utils/object';
import TargetDelistingStatusBadge from 'components/models/Targets/TargetDelistingStatusBadge';
import TargetSimilarWebVisits from 'components/models/Targets/TargetSimilarWebVisits';

const DEFAULT_TAG_KEYS = ['compiled_tags', 'authorization_tags'] as const;

type DetailedModel = Ether.CaseManager.Target.Detailed;
type KeyColumnProps = ColumnProps & { key: string };
type TagFieldType = (typeof DEFAULT_TAG_KEYS)[number];

type ModelColumns =
    | keyof CaseManagerApp.ModelColumns['targets']
    | CaseManagerApp.DefaultNameColumn;

type DataTable = CaseManagerApp.AppConfig.Target.DataTable;
type ColumnField = CaseManagerApp.AppConfig.Target.DataTable.Fields;
type LocaleField = CaseManagerApp.AppConfig.Target.DataTable.LocaleFields;

const assertIsValidTag = (key: string | undefined): key is TagFieldType =>
    !!key && !!DEFAULT_TAG_KEYS.find((tagKey) => tagKey === key);

const isValidTagField = (key: string | undefined) => {
    if (!key) return false;
    return DEFAULT_TAG_KEYS.some((supposedKey) => {
        if (key === supposedKey) return true;
        if (key.startsWith(`${supposedKey}.`)) return true;
        return false;
    });
};

/**
 * Get the column props for a meta field
 * @param key - The meta field key, without the prefix 'meta.'
 * @param refAuthorization - The referencing authorization, if present
 */
const getMetaFieldProps = (
    key: string,
    refAuthorization?: Ether.CaseManager.Authorization
) => {
    const metaKey = 'meta.' + key;
    return {
        key: metaKey,
        field: metaKey,
        header: _.upperFirst(key),
        body: (data: Ether.CaseManager.Target) => {
            const meta = getTargetMeta({
                target: data,
                field: metaKey,
                refAuthorization,
            });
            return renderUnidentifiedDataType(meta);
        },
        sortable: false,
    } as KeyColumnProps;
};

/**
 * Get the tags with a set of allowed tags (or all if not provided) to show on that field
 * @param param.key - Key for props referencing
 * @param param.header - Column's header
 * @param param.target - Target's data
 * @param param.fieldType - Tags may come from different sources, so it is important to specify where from
 * @param param.filteredTags - Tags which will appear on this column. If only one, no tag indication will appear before its name. If not given, it will show all tags available
 * @param param.refAuthorization - Referencing authorization, if existing
 * @returns Props to be given to the Column
 */
const getTagFieldProps = ({
    key,
    header,
    fieldType,
    filteredTags,
    refAuthorization,
}: {
    key: string;
    header: string;
    fieldType: TagFieldType;
    filteredTags?: string[];
    refAuthorization?: Ether.CaseManager.Authorization;
}) => {
    return {
        key: key,
        field: key,
        header: header,
        body: (data: Ether.CaseManager.Target) => {
            let tags: Record<string, string[]> | null;
            if (fieldType === 'authorization_tags')
                tags = getAuthorizationTags(data);
            else {
                const targetTags = getTargetTags(data, refAuthorization);
                tags = {};
                Object.entries(targetTags ?? {}).forEach(([key, value]) => {
                    if (!tags) tags = {};
                    tags[key] = Array.isArray(value) ? value : [value];
                });
            }
            if (!tags) return '-';

            const { values: allowedTags, operation } =
                filterCreateOrExcludeArrayList(
                    filteredTags ?? [],
                    Object.keys(tags)
                );

            if (!allowedTags.length) return '-';
            if (allowedTags.length === 1 && operation === 'allow') {
                const specificKey = allowedTags[0] as string;
                let specificTags: string[] | undefined = tags[specificKey];
                if (!specificTags) return '-';
                return <pre>{specificTags?.join(', ')}</pre>;
            }
            const elementsList = allowedTags
                .map((key) => {
                    const value = tags?.[key];
                    if (!key) return null;
                    return (
                        <span key={key + value}>{`${key} : ${
                            Array.isArray(value) ? value.join(', ') : value
                        }`}</span>
                    );
                })
                .filter((e) => !!e);
            if (!elementsList.length) return '-';
            return <pre className='flex flex-col'>{elementsList}</pre>;
        },
        sortable: false,
    } as KeyColumnProps;
};

/**
 * Get the authorization exclusive tags
 * @param target - The target data
 * @returns Objetified tags
 */
const getAuthorizationTags = (target: DetailedModel) => {
    const compiledTags = target.compiled_authorizations_tags;
    if (compiledTags && compiledTags.length > 0)
        return objetifyNestedTagList(compiledTags);
    return null;
};

/**
 * Get the target tags, also getting relationship tags if present
 * @param target - The target data
 * @param refAuthorization - The referencing authorization
 * @returns Objetified tags
 */
const getTargetTags = (
    target: DetailedModel,
    refAuthorization?: Ether.CaseManager.Authorization.Detailed
) => {
    if (target.compiled_tags && Object.keys(target.compiled_tags).length > 0) {
        return target.compiled_tags;
    }
    if (!refAuthorization) return null;
    let targetAuth = parseSingleOrArrayIntoArray(target?.authorizations)?.find(
        (a) => a?._id === refAuthorization._id
    );
    if (!targetAuth) {
        targetAuth = target.removed_authorizations?.find(
            (a) => a?._id === refAuthorization?._id
        );
    }
    if (!targetAuth?.tags) return null;
    return targetAuth.tags;
};

/**
 * Generates columns dynamically for the TargetsDataTable
 * @param object.columns - The target available columns to transform into elements
 * @param object.project - The currenct project in context
 * @param object.refAuthorization - Referencing authorization, if existing
 * @param object.tableConfig - The targets datatable configuration, listing all visible columns. If not provided or omitted, will default to a generic column rendering
 * @param object.localization - The localization object
 * @param object.sortable - If the table is sortable
 * @param object.extraMetaFields Which extra meta fields should be considered
 * @returns columns - All columns in a element format
 */
export const generateTargetColumnsAndFilters = ({
    columns,
    project,
    refAuthorization,
    tableConfig,
    localization,
    sortable,
    extraMetaFields,
}: {
    columns: CMDataTableColumnFields<ModelColumns>;
    project: Ether.CaseManager.Project;
    refAuthorization: Ether.CaseManager.Authorization.Detailed | undefined;
    tableConfig: DataTable | null;
    localization: CMLocalization.Localization;
    sortable: boolean;
    extraMetaFields?: string[];
}) => {
    const metaFieldsMap: Record<string, { key: string; assigned: boolean }> =
        {};
    extraMetaFields?.forEach(
        (meta) =>
            (metaFieldsMap[`meta.${meta}`] = { key: meta, assigned: false })
    );
    Object.values(tableConfig ?? {}).forEach((key) => {
        if (!key.startsWith('meta.')) return;
        const meta = key.split('meta.')[1] as string;
        metaFieldsMap[key] = { key: meta, assigned: false };
    });

    const fields = localization.fields.target;
    const mapLocale: Record<LocaleField, string> = {
        type: fields.type,
        tag: fields.tags,
        tags: fields.tags,
        compiled_tags: fields.tags,
        enrichment_status: fields.enrichment,
        grade: fields.grading,
        hosted_domains: fields.hostingHistoryDns,
        domains_count: fields.domainsCount,
        br_domains_count: fields.domainsBrCount,
        country: fields.country,
        asn: fields.asn,
        accessible_ports: fields.accessiblePorts,
        safe_blocking: fields.safeIp,
        reserved_ip: fields.reservedIp,
        official_domain: fields.officialDomain,
        dns_ns: fields.dnsNameservers,
        domain_age: fields.domainAge,
        ips_list: fields.ipsList,
        whois_owner: fields.whoisOwner,
        whois_registrar: fields.whoisRegistrar,
        type_order: fields.typeOrder,
        pirate_brand: localization.models.tag.types['pirate-brand'].singular,
        source: localization.models.tag.types.source.singular,
        block_checker_progress: fields.blockCheckerProgress,
        block_checker_efficiency: fields.blockCheckerEffiency,
        similarweb: fields.similarWeb,
        delisting_checker_status: fields.delistingChecker,
        onoff: fields.onoffStatus,
        created_at: fields.created,
        notified: fields.notified,
        hosting_companies: fields.hostingCompanies,
        authorizations: localization.models.authorization.plural,
        block_orders: localization.models.blockOrder.plural,
        found_in: fields.foundIn,
        status: fields.status,
        product: fields.product,
        environment: fields.environment,
        program: fields.program,
        term: fields.term,
        seller: fields.seller,
    };

    // Overrides to default table config
    if (!tableConfig)
        tableConfig = {
            type: 'type',
            created_at: 'created_at',
        };

    const elementColumns: JSX.Element[] = Object.entries(tableConfig)
        .map((entry) => {
            const localeKey = entry[0] as LocaleField;
            const possibleKey = grabValidKeyMatchingUrl<ColumnField>(
                entry[1].split(',')
            );
            if (!possibleKey) return null;
            const fieldKey =
                possibleKey.startsWith('meta.') || isValidTagField(possibleKey)
                    ? possibleKey
                    : columns[possibleKey]?.field ?? columns[possibleKey]?.name;
            if (!fieldKey) return null;
            const header = mapLocale[localeKey];

            let props: KeyColumnProps;
            if (fieldKey.startsWith('meta.')) {
                // Order meta fields
                const meta = metaFieldsMap[fieldKey];
                if (!meta) return null;
                meta.assigned = true;
                props = getMetaFieldProps(meta.key, refAuthorization);
                if (header) props.header = header;
            } else if (isValidTagField(fieldKey)) {
                // tags -> show every tag
                // compiled_tags.pirate_brand -> show only pirate brand tags
                // authorization_tags.pirate_brand,country -> show pirate_brand and country tags, only
                // authorization_tags.!pirate_brand -> do not show pirate_brand, show rest
                const [tagField, allowedTags] = fieldKey.split('.');
                if (!assertIsValidTag(tagField)) return null;
                props = getTagFieldProps({
                    key: fieldKey,
                    fieldType: tagField,
                    header: header,
                    filteredTags: allowedTags?.split(',').map((t) => t.trim()),
                    refAuthorization: refAuthorization,
                });
            } else
                props = {
                    key: fieldKey,
                    field: fieldKey,
                    header: header,
                    body: (data: any) => {
                        const value = fieldKey
                            .split('.')
                            .reduce((a, b) => (a ?? {})[b], data);
                        return renderUnidentifiedDataType(value);
                    },
                    sortable: false,
                };
            switch (possibleKey) {
                case columns.type.name:
                    props.body = (data: DetailedModel) => (
                        <TargetTypeBadge target={data} />
                    );
                    break;
                case columns.type_order.name:
                    props.body = (data: DetailedModel) => {
                        const typesLocale =
                            localization.components.models.blockOrder.badge
                                .type;
                        const typeOrderMap = {
                            administrative: typesLocale.administrative,
                            judicial: typesLocale.judicial,
                        };
                        const set = new Set<string>([]);
                        Object.values(
                            parseSingleOrArrayIntoArray(data.block_orders_data)
                        ).forEach((b) =>
                            b?.type ? set.add(typeOrderMap[b.type]) : null
                        );
                        const items = Array.from(set);
                        if (items.length <= 0) return '-';
                        return Array.from(set).join(', ');
                    };
                    break;
                case columns.enrichment_status.name:
                    props.body = (data: DetailedModel) => (
                        <TargetEnrichmentField target={data} />
                    );
                    break;
                case columns.authorizations_count.name:
                    props.body = (data: DetailedModel) =>
                        parseSingleOrArrayIntoArray(data.authorizations)
                            ?.length ?? '-';
                    break;
                case columns.block_orders_count.name:
                    props.body = (data: DetailedModel) =>
                        data.block_orders?.length ?? '-';
                    break;
                case columns['list_data.highest_grading'].name:
                    props.sortable = sortable;
                    break;
                case columns['list_data.hosted_domains_list'].name:
                    props.body = (data: DetailedModel) => {
                        const domains = data.list_data?.hosted_domains_list;
                        if (!domains || domains.length <= 0) return '-';
                        return (
                            <div className='flex flex-col gap-1 items-start'>
                                {domains.map((dns, index) => (
                                    <span key={dns + '@' + index}>{dns}</span>
                                ))}
                            </div>
                        );
                    };
                    break;
                case columns['list_data.hosted_domains_count'].name:
                    props.sortable = sortable;
                    break;
                case columns['list_data.br_domains_count'].name:
                    props.sortable = sortable;
                    break;
                case columns['list_data.country'].name:
                    props.body = (data: DetailedModel) => {
                        if (data.list_data?.reserved_ip)
                            return <ReservedIpBadge />;
                        return data.list_data?.country ?? '-';
                    };
                    break;
                case columns['list_data.hosting_companies'].name:
                    props.body = (data: DetailedModel) => {
                        if (data.list_data?.reserved_ip)
                            return <ReservedIpBadge />;
                        if (!data.list_data?.hosting_companies?.length)
                            return '-';
                        return data.list_data.hosting_companies.join(', ');
                    };
                    break;
                case columns['list_data.asn'].name:
                    props.body = (data: DetailedModel) => {
                        if (data.list_data?.reserved_ip)
                            return <ReservedIpBadge />;
                        return data.list_data?.asn ?? '-';
                    };
                    break;
                case columns.asns.name:
                    props.body = (data: DetailedModel) => {
                        if (data.list_data?.reserved_ip)
                            return <ReservedIpBadge />;
                        return (
                            data.list_data?.asn ??
                            data.list_data?.hosting_companies?.join(', ') ??
                            '-'
                        );
                    };
                    break;
                case columns['list_data.safe_ip'].name:
                    props.body = (data: DetailedModel) => {
                        const locale =
                            localization.components.models.target.badge.safeIp;
                        const isSafe: boolean | null | undefined =
                            data.list_data?.safe_ip;
                        const safeIpReasons = translateSafeIpReasons(
                            data,
                            localization
                        );
                        const classN = `span-safeip-target-${data._id}`;

                        return (
                            <>
                                <div style={{ minWidth: '40px' }}>
                                    {isSafe != null ? (
                                        isSafe ? (
                                            <Badge
                                                severity={'success'}
                                                value={locale.safe}
                                            />
                                        ) : (
                                            <>
                                                <AdaptableTooltip
                                                    target={'.' + classN}
                                                >
                                                    <div className='flex flex-col gap-1'>
                                                        {safeIpReasons.map(
                                                            (r) => (
                                                                <span key={r}>
                                                                    {r}
                                                                </span>
                                                            )
                                                        )}
                                                    </div>
                                                </AdaptableTooltip>
                                                <Badge
                                                    className={classN}
                                                    severity={'warning'}
                                                    value={locale.unsafe}
                                                />
                                            </>
                                        )
                                    ) : (
                                        '-'
                                    )}
                                </div>
                            </>
                        );
                    };
                    break;
                case columns['list_data.domain_age'].name:
                    props.body = (data: DetailedModel) => {
                        if (!data.list_data?.domain_age) return '-';
                        const now = new Date();
                        const text = localizeDistance(
                            now,
                            data.list_data.domain_age,
                            localization
                        );
                        return <div className='max-w-full w-max'>{text}</div>;
                    };
                    break;
                case columns['external_services_data.delisting_checker'].name:
                    if (!refAuthorization) return null;
                    props.body = (item: DetailedModel) => (
                        <TargetDelistingStatusBadge target={item} />
                    );
                    break;
                case columns['external_services_data.block_checker.progress']
                    .name:
                    if (!refAuthorization) return null;
                    props.body = (item: DetailedModel) => (
                        <BlockCheckerProgressBadge target={item} />
                    );
                    break;
                case columns['external_services_data.block_checker.efficiency']
                    .name:
                    if (!refAuthorization) return null;
                    props.body = (item: DetailedModel) => (
                        <BlockCheckerEffectivenessBadge target={item} />
                    );
                    break;
                case columns['external_services_data.similarweb'].name:
                    props.body = (item: DetailedModel) => (
                        <TargetSimilarWebVisits target={item} />
                    );
                    props.sortable = true;
                    break;
                case columns['external_services_data.onoff'].name:
                    props.body = (item: DetailedModel) => {
                        const data = item.external_services_data?.onoff?.[0];
                        if (!data) return <Badge value='NEW' severity={null} />;
                        if (data.status === 'pending')
                            return <EnrichDisplayPending />;
                        if (data.status === 'error' || !data.values)
                            return <EnrichDisplayError />;
                        const value = data.values.status;
                        if (!value) return '-';
                        return (
                            <Badge
                                value={value.toLocaleUpperCase()}
                                severity={
                                    value === 'online' ? 'success' : 'danger'
                                }
                            />
                        );
                    };
                    break;
            }
            return <Column {...props} />;
        })
        .filter<JSX.Element>((c): c is JSX.Element => !!c);

    if (refAuthorization)
        Object.values(metaFieldsMap)?.forEach(({ assigned, key }) => {
            if (assigned) return;
            const props = getMetaFieldProps(key, refAuthorization);
            elementColumns.push(<Column {...props} />);
        });

    return elementColumns;
};
