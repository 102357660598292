import useAppConfig from 'hooks/appConfig/useAppConfig';
import { useLocalization } from 'hooks/context/useLocalization';
import { BreadCrumb } from 'primereact/breadcrumb';
import { MenuItem } from 'primereact/menuitem';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ValidTagCategory } from 'services/ether/case-manager/tags/types';
import isValidOid from 'utils/isValidOid';
import { getObjectPathValue } from 'utils/object';

const getTemplate = (item: MenuItem) => {
    const MenuText = <span className='p-menuitem-text'>{item.label}</span>;
    if (item.url) {
        return (
            <Link to={item.url} className='p-menuitem-link'>
                {MenuText}
            </Link>
        );
    }
    return MenuText;
};

const CMBreadCrumb: React.FC<{
    projectLabel?: string;
    authorizationLabel?: string;
    authorizationConfigLabel?: string;
    blockOrderLabel?: string;
    unblockOrderLabel?: string;
    monitoringLabel?: string;
    operatorLabel?: string;
}> = ({
    projectLabel,
    authorizationLabel,
    authorizationConfigLabel,
    blockOrderLabel,
    unblockOrderLabel,
    monitoringLabel,
    operatorLabel,
}) => {
    const location = useLocation();
    const [localization] = useLocalization();

    const config = useAppConfig().menu_names;

    const params = useParams<{
        projectId: string;
    }>();

    const projectUrl = params.projectId ? `/projects/${params.projectId}` : '';

    const paths = location.pathname.split('/').filter((i) => i !== '');

    const menuItems: MenuItem[] = paths
        .map((path, index) => {
            const validUrl = index + 1 < paths.length;
            const isTagPath = paths[0] === 'tags';
            const previousPath = index === 0 ? undefined : paths[index - 1];

            if (isValidOid(path)) {
                switch (previousPath) {
                    case 'projects':
                        path = ':projectId';
                        break;
                    case 'authorizations':
                        path = ':authorizationId';
                        break;
                    case 'block-orders':
                        path = ':blockOrderId';
                        break;
                    case 'unblock-orders':
                        path = ':unblockOrderId';
                        break;
                    case 'monitoring':
                        path = ':monitorId';
                        break;
                    case 'operators':
                        path = ':operatorId';
                        break;
                    case 'authorization-config':
                        path = ':authConfigId';
                        break;
                }
            }

            if (isTagPath) {
                if (index === 1) {
                    switch (path) {
                        case 'target':
                            return {
                                label: localization.models.target.singular,
                                url: undefined,
                            };
                        case 'authorization':
                            return {
                                label: localization.models.authorization
                                    .singular,
                                url: undefined,
                            };
                    }
                } else if (index === 2) {
                    return {
                        label: localization.models.tag.types[
                            path as ValidTagCategory
                        ].plural,
                        url: undefined,
                    };
                }
            }

            const localizationPath =
                config?.[path as Ether.CaseManager.AppConfig.NavbarMenuItem];
            const pathLabel = localizationPath
                ? getObjectPathValue(localization, localizationPath)
                : 'UNHANDLED';

            switch (path) {
                case 'authorizations':
                    return {
                        label: pathLabel,
                        url: validUrl
                            ? projectUrl + '/authorizations'
                            : undefined,
                    };
                case 'targets':
                    return {
                        label: pathLabel,
                        url: validUrl ? projectUrl + '/targets' : undefined,
                    };
                case 'authorization-targets':
                    return {
                        label: pathLabel,
                        url: validUrl ? projectUrl + '/targets' : undefined,
                    };
                case 'authorization-targets-pending':
                    return {
                        label: pathLabel,
                        url: validUrl ? projectUrl + '/targets' : undefined,
                    };
                case 'evidences':
                    return {
                        label: pathLabel,
                        url: validUrl ? projectUrl + '/evidences' : undefined,
                    };
                case 'block-orders':
                    return {
                        label: pathLabel,
                        url: validUrl
                            ? projectUrl + '/block-orders'
                            : undefined,
                    };
                case 'unblock-orders':
                    return {
                        label: pathLabel,
                        url: validUrl
                            ? projectUrl + '/unblock-orders'
                            : undefined,
                    };
                case 'users':
                    return {
                        label: pathLabel,
                        url: validUrl ? '/users' : undefined,
                    };
                case 'projects':
                    return {
                        label: localization.models.project.plural,
                        url: validUrl ? '/projects' : undefined,
                    };
                case 'monitoring':
                    return {
                        label: localization.models.monitoring.plural,
                        url: validUrl ? '/monitoring' : undefined,
                    };
                case 'documents':
                    return {
                        label: pathLabel,
                        url: validUrl ? '/documents' : undefined,
                    };
                case 'operators':
                    return {
                        label: pathLabel,
                        url: validUrl ? '/operators' : undefined,
                    };
                case 'authorization-config':
                    return {
                        label: pathLabel,
                        url: undefined,
                    };
                case 'operations':
                    return {
                        label: pathLabel,
                        url: validUrl ? projectUrl + '/targets' : undefined,
                    };
                case 'tags':
                    return {
                        label: localization.models.tag.plural,
                        url: undefined,
                    };

                case ':projectId':
                    return {
                        label: projectLabel,
                    };
                case ':authorizationId':
                    return {
                        label: authorizationLabel,
                    };
                case ':blockOrderId':
                    return {
                        label: blockOrderLabel,
                    };
                case ':unblockOrderId':
                    return {
                        label: unblockOrderLabel,
                    };
                case ':monitorId':
                    return {
                        label: monitoringLabel,
                    };
                case ':authConfigId':
                    return {
                        label: authorizationConfigLabel,
                    };

                default:
                    return {
                        label: 'UNHANDLED',
                    };
            }
        })
        .map((i) => ({ ...i, template: getTemplate }));

    return <BreadCrumb model={menuItems} />;
};

export default CMBreadCrumb;
