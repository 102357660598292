import api from 'services/ether/api';
import {
    ParseDataTableParams,
    getFilterMeta,
    mapDevFilters,
    parseDataTableFilterMetaForAPI,
} from 'services/ether/utils';
import {
    DetailBlockOrderEP,
    GetBlockOrderStatsEP,
    ListBlockOrderEP,
    ListBlockOrdersUnblockEP,
    UpsertBlockOrderEP,
} from './types';
import { fileDownloadBase, getApiBase, listBase } from 'services/ether/base';
import _ from 'lodash';
import { FilterMatchMode } from 'primereact/api';
import { checkTagFilterMatching } from 'utils/tag';

const handleBlockOrderParams = (params: ParseDataTableParams) => {
    const tagKeys = {
        authorization: 'compiled_data.authorizations_tags._id|all@oid',
        target: 'compiled_data.targets_tags._id|in@oid',
    };
    const tagFilters: Record<string, string[]> = {
        authorization: [],
        target: [],
    };
    Object.keys(params.filters ?? {}).forEach((key) => {
        if (!params.filters?.[key]) return;
        const value = params.filters[key];
        const match = checkTagFilterMatching(key);
        if (!match.matches) return;
        const filterMeta = getFilterMeta(value);
        delete params.filters[key];
        if (!filterMeta.value) return;
        const oids: string[] =
            match.filterType === 'dropdown'
                ? [filterMeta.value]
                : filterMeta.value;
        tagFilters[match.modelType]?.push(`(${oids.join('|')})`);
    });
    Object.entries(tagKeys).forEach(([key, value]) => {
        if (!params.filters) return;
        if (!tagFilters[key]?.length) return;
        params.filters[value] = tagFilters[key]?.join('&');
    });
    return parseDataTableFilterMetaForAPI({
        ...params,
        idFields: [
            {
                key: 'authorization_config_ids',
                matchMode: FilterMatchMode.IN,
            },
        ],
    });
};

export const listBlockOrder = ({
    project_id,
    options,
    signal,
}: ListBlockOrderEP.Params.Many) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: ListBlockOrderEP.Filters.Map = {
        _id: ['_id', devFilters._id],
        status: ['status', devFilters.status],
        authorization_config_ids: [
            'authorization_config_ids',
            typeof devFilters.authorization_config_ids == 'string'
                ? [devFilters.authorization_config_ids]
                : devFilters.authorization_config_ids,
        ],
        authorization_flow_id: [
            'authorization_flow_id',
            devFilters.authorization_flow_id,
        ],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge(
        {
            project_id,
        },
        options?.rawFilters,
        mappedFilters
    );
    return listBase<Ether.CaseManager.BlockOrder[]>({
        endpoint: '/list-block-order',
        handleParams: handleBlockOrderParams,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

export const listBlockOrdersUnblock = ({
    project_id,
    authorization_config_ids,
    authorization_flow_id,
    signal,
    options,
}: ListBlockOrdersUnblockEP.Params.Many) => {
    const filters = _.merge(
        {
            project_id,
            authorization_config_ids,
            authorization_flow_id,
        },
        options?.rawFilters,
        {
            status: 'done',
        }
    );
    return listBase<Ether.CaseManager.BlockOrder[]>({
        endpoint: '/list-block-orders-unblock',
        handleParams: handleBlockOrderParams,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

const _detailBlockOrder = ({
    project_id,
    options,
    signal,
}: DetailBlockOrderEP.Params.Restricted) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: DetailBlockOrderEP.Filters.Map = {
        _id: ['_id', devFilters._id],
        status: ['status', devFilters.status],
        authorization_config_ids: [
            'authorization_config_ids',
            devFilters.authorization_config_ids,
        ],
        authorization_flow_id: [
            'authorization_flow_id',
            devFilters.authorization_flow_id,
        ],
        get_target_info: ['get_target_info', devFilters.get_target_info],
        unblock_order_id: [
            'unblock_orders._id@oid',
            devFilters.unblock_order_id,
        ],
        additional_fields: [
            'additional_fields',
            devFilters.additional_fields?.join(','),
        ],
        operation_id: ['operation_id', devFilters.operation_id],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge(
        {
            project_id,
        },
        options?.rawFilters,
        mappedFilters
    );
    return listBase<Ether.CaseManager.BlockOrder.Detailed[]>({
        endpoint: '/detail-block-order',
        handleParams: handleBlockOrderParams,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

export const detailManyBlockOrder = ({
    project_id,
    options,
    signal,
}: DetailBlockOrderEP.Params.Many) => {
    return _detailBlockOrder({
        project_id,
        options: {
            ...options,
            devFilters: {
                ...options?.devFilters,
                get_target_info: true,
                additional_fields: [
                    'total_authorizations',
                    'total_targets',
                    'created_by_data',
                    'validated_targets_count',
                ],
            },
        },
        signal,
    });
};

export const detailManyBlockOrderForHistory = ({
    project_id,
    options,
    signal,
}: DetailBlockOrderEP.Params.Many) => {
    return _detailBlockOrder({
        project_id,
        options: {
            ...options,
            devFilters: {
                ...options?.devFilters,
                get_target_info: true,
                additional_fields: [
                    'created_by_data',
                    'authorization_configs_data',
                ],
            },
        },
        signal,
    });
};

export const detailManyBlockOrderForSelect = ({
    project_id,
    options,
    signal,
}: DetailBlockOrderEP.Params.Many) => {
    return _detailBlockOrder({
        project_id,
        options: {
            ...options,
            devFilters: {
                ...options?.devFilters,
                get_target_info: true,
                additional_fields: ['authorizations_data'],
            },
        },
        signal,
    });
};

export const detailOneBlockOrder = async ({
    _id,
    project_id,
    signal,
}: DetailBlockOrderEP.Params.One) => {
    const { payload } = await _detailBlockOrder({
        project_id,
        options: {
            devFilters: {
                _id: _id,
                get_target_info: true,
                additional_fields: [
                    'created_by_data',
                    'authorizations_data',
                    'operation_data',
                    // 'total_authorizations',
                    'total_targets',
                    'total_targets_review',
                    'total_documents',
                    'total_operators',

                    'operators_order_data.operators_data.users_data',
                    'operators_order_data.operators_data.last_notification_data',
                    'authorization_configs_data',
                    'authorizations_data.authorization_flows_data',
                ],
            },
        },
        signal,
    });
    return payload[0] ?? null;
};

export const upsertBlockOrder = (data: UpsertBlockOrderEP.UpsertData) => {
    const postData = {
        ...data,
        authorization_config_ids: data.authorization_config_ids.map((id) => ({
            $oid: id,
        })),
        authorization_ids: data.authorization_ids.map((id) => ({ $oid: id })),
        start_date: data.start_date?.toISOString() ?? null,
        complete_date: data.complete_date?.toISOString() ?? null,
        end_date: data.end_date?.toISOString() ?? null,
    };
    return new Promise<UpsertBlockOrderEP.Result>((resolve, reject) => {
        api.post<Ether.ApiResponse<UpsertBlockOrderEP.ApiResponse>>(
            '/upsert-block-order',
            postData
        )
            .then((res) => {
                const data = res.data.payload[0];
                if (!data)
                    throw new Error('upsert-block-order did not return data');
                if ('error' in data) reject(new Error(data.error));
                else resolve(data);
            })
            .catch((err) => reject(err));
    });
};

export const getBlockOrderHistory = (
    blockOrderId: string,
    options?: {
        signal?: AbortSignal;
    }
) => {
    return new Promise<Ether.CaseManager.BlockOrderHistory | null>(
        (resolve, reject) => {
            api.get<
                Ether.ApiResponse<
                    Ether.CaseManager.BlockOrderHistory | { error: string }
                >
            >('/get-order-history', {
                signal: options?.signal,
                params: {
                    order_id: blockOrderId,
                    order_type: 'block_order',
                },
            })
                .then((res) => {
                    if ('error' in res.data.payload) {
                        if (res.data.payload.error.includes('not found'))
                            resolve(null);
                        else reject(new Error(res.data.payload.error));
                    } else resolve(res.data.payload);
                })
                .catch((err) => reject(err));
        }
    );
};

export const respondBlockOrder = ({
    accepted,
    ...rest
}: {
    accepted: boolean;
} & (
    | {
          token: string;
      }
    | {
          _id: string;
      }
)) => {
    const _id = '_id' in rest ? rest._id : null;
    const token = 'token' in rest ? rest.token : null;
    return new Promise<Ether.CaseManager.BlockOrder>((resolve, reject) => {
        api.post<
            Ether.ApiResponse<
                (
                    | {
                          error: string;
                      }
                    | Ether.CaseManager.BlockOrder
                )[]
            >
        >(
            `/accept-order`,
            {
                accepted,
                order_id: _id,
                order_type: 'block_order',
            },
            {
                params: {
                    token: token,
                },
            }
        )
            .then((res) => {
                const data = res.data.payload[0];
                if (!data) throw new Error('accept-order did not return data');
                if ('error' in data) throw new Error(data.error);
                resolve(data);
            })
            .catch((err) => reject(err));
    });
};

export const exportBlockOrderFiles = (
    blockOrderId: string,
    cumulative?: boolean,
    signal?: AbortSignal
) => {
    return fileDownloadBase({
        endpoint: '/export-block-order-files',
        params: {
            block_order_id: blockOrderId,
            cumulative : cumulative?.toString(),
        },
        filename:
            new Date().toISOString().split('T')[0] + `_order_${blockOrderId}`,
        fallbackExtension: 'zip',
        signal,
    });
};

export const getBlockOrderStats = (options: {
    params: GetBlockOrderStatsEP.Params;
    signal?: AbortSignal;
}) => {
    return getApiBase<GetBlockOrderStatsEP.Result>({
        endpoint: '/get-block-order-stats/' + options.params.block_order_id,
        signal: options.signal,
    });
};
