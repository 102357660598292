import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { Column } from 'primereact/column';
import { MenuItem } from 'primereact/menuitem';
import { Menu } from 'primereact/menu';
import { useMemo, useRef } from 'react';
import { Button } from 'primereact/button';
import { useLocalization } from 'hooks/context/useLocalization';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';
import useTagColumnFields from 'hooks/columns/models/useTagColumnFields';
import { generateTagColumns } from '../utils';
import useAppConfig from 'hooks/appConfig/useAppConfig';

type DetailedModel = Ether.CaseManager.Tag.Detailed;

const RowMenu: React.FC<{
    item: DetailedModel;
    // onShowDetail: (authorization: Ether.CaseManager.Tag) => void;
}> = ({ item }) => {
    // const { user, permissions } = useAuth();
    // const navigate = useNavigate();
    // const [localization] = useLocalization();

    const menuRef = useRef<Menu>(null);

    const menuItems: MenuItem[] = [
        // ...(permissions.debugAuthorizations
        //     ? [
        //           {
        //               id: 'debug',
        //               label: 'Detail data',
        //               command: () => onShowDetail(authorization),
        //           },
        //       ]
        //     : []),
    ];

    if (menuItems.length <= 0) return null;

    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

const TagDataTable: React.FC<CMDataTableProps<DetailedModel>> = ({
    ...props
}) => {
    const [localization] = useLocalization();
    const config = useAppConfig().tag;

    const { columnFields, filterOptions } = useTagColumnFields();

    const columnElements = useMemo(
        () =>
            generateTagColumns({
                columns: columnFields,
                tableConfig: config?.datatable ?? null,
                localization: localization,
                sortable: false,
            }),
        [columnFields, config?.datatable, localization]
    );

    const baseFilterOptions = {
        showAddButton: false,
        showFilterOperator: false,
    };

    return (
        <>
            <CMDataTable
                lazy
                columnConfigName='tag'
                emptyMessage={
                    localization.components.models.tag.datatable.empty
                }
                filterOptions={filterOptions}
                {...props}
            >
                <Column
                    field='_cm_name_id'
                    body={(data: DetailedModel) => (
                        <NameIdAggregator name={data.name} id={data._id} />
                    )}
                    {...baseFilterOptions}
                />
                {columnElements}
                <Column
                    field='actions'
                    body={(data: DetailedModel) => <RowMenu item={data} />}
                />
            </CMDataTable>
        </>
    );
};

export default TagDataTable;
