import { exportBlockOrderFiles } from 'services/ether/case-manager/block-orders';
import useBaseFileExport from '../useBaseFileExport';

const useExportBlockOrderFiles = (
    blockOrderId: string,
    cumulative?: boolean
) => {
    const queryKey = [blockOrderId, cumulative];

    const { exportFile, isExporting } = useBaseFileExport(
        'export-block-order-files',
        queryKey,
        ({ signal }) => exportBlockOrderFiles(blockOrderId, cumulative, signal)
    );

    return { exportFile: () => exportFile({ params: null }), isExporting };
};

export default useExportBlockOrderFiles;
