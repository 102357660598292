import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useLocalization } from 'hooks/context/useLocalization';
import InsertUnblockOrderForm from '../InsertUnblockOrderForm';
import { InsertUnblockOrderModalProps } from './types';
import { useProject } from 'hooks/context/project/useProject';
import useUpsertUnblockOrder from 'hooks/mutations/unblockOrder/useUpsertUnblockOrder';
import { BlockOrderTypes } from 'components/models/BlockOrder/SelectOneBlockOrderType';
import { updateUserDefaultOperation } from 'services/ether/case-manager/operation';
import LoadingMessage from 'components/misc/LoadingMessage';
import LightDialog from 'components/display/LightDialog';
import useDetailBlockOrderSelect from 'hooks/queries/block-order/useDetailManyBlockOrderSelect';

const InsertUnblockOrderModal: React.FC<InsertUnblockOrderModalProps> = ({
    unblockOrderToEdit,
    startingBlockOrder,
    onInsert,
    ...props
}) => {
    const project = useProject();
    const [localization] = useLocalization();

    const isEdit = !!unblockOrderToEdit;

    const blockOrdersQuery = useDetailBlockOrderSelect(
        {
            project_id: project._id,
            options: {
                devFilters: {
                    unblock_order_id: unblockOrderToEdit?._id,
                },
            },
        },
        { enabled: isEdit }
    );

    const [name, setName] = useState(unblockOrderToEdit?.name ?? '');
    const [type, setType] = useState<BlockOrderTypes | null>(
        (unblockOrderToEdit?.type as BlockOrderTypes) ?? null
    );
    const [authorizationFlow, setAuthorizationFlow] =
        useState<Ether.CaseManager.AuthorizationFlow | null>(null);
    const [authorizationConfigIds, setAuthorizationConfigIds] = useState<
        string[]
    >(unblockOrderToEdit?.authorization_config_ids ?? []);
    const [blockOrders, setBlockOrders] = useState<
        Ether.CaseManager.BlockOrder[]
    >(startingBlockOrder ? [startingBlockOrder] : []);
    const [operatorTags, setOperatorTags] = useState<string[]>(
        unblockOrderToEdit?.operator_tags ?? []
    );
    const [startDate, setStartDate] = useState<Date | null>(
        unblockOrderToEdit?.start_date ?? null
    );
    const [operationId, setOperationId] = useState<string | null>(
        unblockOrderToEdit?.operation_id ?? null
    );
    const [isDefaultOperation, setIsDefaultOperation] = useState(false);

    useEffect(() => {
        if (!isEdit || !blockOrdersQuery.data) return;
        setBlockOrders(blockOrdersQuery.data.payload);
    }, [blockOrdersQuery.data, isEdit]);

    const isEditLoading = isEdit ? blockOrdersQuery.isFetching : false;

    const onInsertOrder = () => {
        if (onInsert) onInsert();
        props.onHide();
    };

    const { insertUnblockOrder, updateUnblockOrder, status } =
        useUpsertUnblockOrder({
            onInsert: onInsertOrder,
        });

    const handleInsert = (status: 'draft' | 'done') => {
        if (!authorizationFlow)
            throw new Error('authorization flow id required');
        if (!startDate) throw new Error('start date required');
        if (!type) throw new Error('type required');

        if (isDefaultOperation && operationId)
            updateUserDefaultOperation({
                operation_id: operationId,
            });

        const insertData = {
            project_id: project._id,
            name: name,
            authorization_config_ids: authorizationConfigIds,
            authorization_flow_id: authorizationFlow._id,
            block_order_ids: blockOrders.map((bo) => bo._id),
            operator_tags: operatorTags,
            status: status,
            start_date: startDate,
            type: type,
            operation_id: operationId,
        };

        if (isEdit)
            updateUnblockOrder({
                ...insertData,
                _id: unblockOrderToEdit._id,
            });
        else insertUnblockOrder(insertData);
    };

    const upsertLoading = status === 'pending';

    const isFormValid =
        name !== '' && blockOrders.length > 0 && !!startDate && !!type;

    return (
        <LightDialog
            {...props}
            header={
                isEdit
                    ? localization.components.models.unblockOrder.button.edit
                    : localization.components.models.unblockOrder.button.new
            }
            style={{ minWidth: '64%', maxWidth: '80%' }}
            footer={
                <>
                    <Button
                        label={
                            localization.components.common.button.saveAndSubmit
                        }
                        severity='success'
                        onClick={() => handleInsert('done')}
                        disabled={!isFormValid || upsertLoading}
                    />
                    <Button
                        label={
                            localization.components.common.button.saveAndDraft
                        }
                        onClick={() => handleInsert('draft')}
                        disabled={!isFormValid || upsertLoading}
                    />
                    <Button
                        label={localization.components.common.button.cancel}
                        severity='danger'
                        onClick={props.onHide}
                    />
                </>
            }
        >
            {isEditLoading ? (
                <LoadingMessage>{localization.common.loading}</LoadingMessage>
            ) : (
                <InsertUnblockOrderForm
                    name={{ value: name, set: setName }}
                    authorizationFlow={{
                        value: authorizationFlow,
                        set: setAuthorizationFlow,
                    }}
                    authorizationConfigIds={{
                        value: authorizationConfigIds,
                        set: setAuthorizationConfigIds,
                    }}
                    blockOrders={{ value: blockOrders, set: setBlockOrders }}
                    operatorTags={{ value: operatorTags, set: setOperatorTags }}
                    startDate={{ value: startDate, set: setStartDate }}
                    type={{ value: type, set: setType }}
                    operationId={{ value: operationId, set: setOperationId }}
                    isDefaultOperation={{
                        value: isDefaultOperation,
                        set: setIsDefaultOperation,
                    }}
                    disabled={upsertLoading}
                    startingValues={{
                        authorizationFlowId:
                            unblockOrderToEdit?.authorization_flow_id,
                    }}
                />
            )}
        </LightDialog>
    );
};

export default InsertUnblockOrderModal;
