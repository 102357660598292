import { useLocalization } from 'hooks/context/useLocalization';

/**
 * Retrieves the target safe IP valid options with localized labels
 */
const useTargetSafeIpSelectOptions = () => {
    const [localization] = useLocalization();
    return [
        {
            label: localization.components.models.target.badge.safeIp.safe,
            value: 'true',
        },
        {
            label: localization.components.models.target.badge.safeIp.unsafe,
            value: 'false',
        },
    ];
};

export default useTargetSafeIpSelectOptions;