import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';

/**
 * Retrieves the authorization status valid options with localized labels
 */
const useAuthorizationStatusSelectOptions = () => {
    const [localization] = useLocalization();
    const { user } = useAuth();
    const authorizationBadge =
        localization.components.models.authorization.badge.status;

    return [
        {
            label: authorizationBadge.pending.toLocaleUpperCase(),
            value: 'pending',
        },
        {
            label: authorizationBadge.approved.toLocaleUpperCase(),
            value: 'approved',
        },
        {
            label: authorizationBadge.done.toLocaleUpperCase(),
            value: 'done',
        },
        ...(user.role?.startsWith('authorizer')
            ? []
            : [
                  {
                      label: authorizationBadge.draft.toLocaleUpperCase(),
                      value: 'draft',
                  },
              ]),
        {
            label: authorizationBadge.ignored.toLocaleUpperCase(),
            value: 'ignored',
        },
    ];
};

export default useAuthorizationStatusSelectOptions;
