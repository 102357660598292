import { useLocalization } from 'hooks/context/useLocalization';
import {
    EnrichDisplayError,
    EnrichDisplayPending,
} from '../TargetEnrichmentField';
import NoWrapBadge from 'components/display/NoWrapBadge';
import { BadgeProps } from 'primereact/badge';

const TargetDelistingStatusBadge: React.FC<{
    target: Ether.CaseManager.Target.Detailed;
}> = ({ target }) => {
    const [localization] = useLocalization();
    const locale = localization.components.models.target.badge.delistingStatus;
    const data = target.external_services_data?.delisting_checker?.[0];
    if (!data) return <NoWrapBadge value='NEW' severity={null} />;
    if (data.status === 'pending') return <EnrichDisplayPending />;
    if (data.status === 'error' || !data.values) return <EnrichDisplayError />;
    if (!data.values.status) return '-';
    const value = data.values.status.toLocaleUpperCase();
    const badgeProps: BadgeProps = {
        value: value.toLocaleUpperCase(),
        severity: 'info',
    };
    switch (value) {
        case 'online':
            badgeProps.value = locale.online.toLocaleUpperCase();
            badgeProps.severity = 'success';
            break;
        case 'offline':
            badgeProps.value = locale.offline.toLocaleUpperCase();
            badgeProps.severity = 'danger';
            break;
    }
    return <NoWrapBadge {...badgeProps} />;
};

export default TargetDelistingStatusBadge;