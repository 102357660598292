import AuthorizationDataTable from 'components/datatable/models/authorization/AuthorizationDataTable';
import AuthorizationConfigDataTable from 'components/datatable/models/authorizationConfig/AuthorizationConfigDataTable';
import DocumentDataTable from 'components/datatable/models/document/DocumentDataTable';
import TagDataTable from 'components/datatable/models/tag/TagDataTable';
import SimplifiedTargetsDataTable from 'components/datatable/models/target/SimplifiedTargetsDataTable';
import TargetsDataTable from 'components/datatable/models/target/TargetsDataTable';
import { TableWithTabView } from 'components/display/ViewTablesWithTabs/types';
import LoadingMessage from 'components/misc/LoadingMessage';
import DashboardIframe from 'components/models/Dashboard/DashboardIframe';
import { useProject } from 'hooks/context/project/useProject';
import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import useGetMultipleDashboardURLs from 'hooks/queries/dashboard/useGetMultipleDashboardURLs';
import { SortOrder } from 'primereact/api';
import { useMemo } from 'react';
import { detailManyAuthorizationConfig } from 'services/ether/case-manager/authorization-config';
import {
    detailManyAuthorization,
    detailManyAuthorizationGroupedByConfig,
    getMetrics,
} from 'services/ether/case-manager/authorizations';
import { detailManyDocuments } from 'services/ether/case-manager/documents';
import {
    countTags,
    detailManyTags,
    tagPirateBrandKey,
} from 'services/ether/case-manager/tags';
import {
    detailManyTargets,
    detailManyTargetsUnwindedAuthorization,
} from 'services/ether/case-manager/targets';

export type ValidHomeViews =
    | 'authorizations'
    | 'recentAuthorizations'
    | 'recentAuthorizations:draft'
    | 'recentAuthorizations:pending'
    | 'recentAuthorizations:approved'
    | 'authorityAuthorizations:pending'
    | 'authorityAuthorizationsByConfig:pending'
    | 'authorityAuthorizations:done'
    | 'authorityAuthorizationsByConfig:done'
    | 'authorizationConfigs'
    | 'officialDocuments'
    | 'documents'
    | 'targets'
    | 'targetsAuthorization'
    | 'judicialTargets'
    | 'tags:pirate-brand'
    | 'tags:source'
    | 'overviewDashboard';
type ViewsRecord = Partial<Record<ValidHomeViews, TableWithTabView>>;

const DashBoardElement = () => {
    const [localization] = useLocalization();
    const { isDisabled: dashboardDisabled, query: dashboardQuery } =
        useGetMultipleDashboardURLs({
            filterKeys: ['overview'],
        });

    const dashboardElement = useMemo(() => {
        if (dashboardQuery.isLoading)
            return (
                <LoadingMessage>{localization.common.loading}</LoadingMessage>
            );
        if (dashboardQuery.isError)
            return <p className='p-error'>{localization.common.error}</p>;
        if (dashboardQuery.data)
            return dashboardQuery.data.map((dash) => (
                <DashboardIframe
                    key={dash.url}
                    title={localization.models.dashboard.singular}
                    data={dash}
                />
            ));
        return null;
    }, [
        localization,
        dashboardQuery.data,
        dashboardQuery.isError,
        dashboardQuery.isLoading,
    ]);

    if (dashboardDisabled) return null;
    return dashboardElement;
};

/**
 * Get all available home views that may be appended to a home page
 * Views without the permission will not be returned
 * @returns object.views - All available views
 * @returns object.isLoading - If the views metrics are still loading
 */
const useHomeViews = () => {
    const project = useProject();
    const { permissions } = useAuth();
    const [localization] = useLocalization();
    const metricsPromise = useMemo(
        () => getMetrics(project._id),
        [project._id]
    );
    const sourcePromise = useMemo(
        () =>
            countTags({
                project_id: project._id,
                options: {
                    devFilters: {
                        category: 'source',
                        model_type: 'target',
                    },
                },
            }),
        [project._id]
    );
    const pirateBrandPromise = useMemo(
        () =>
            countTags({
                project_id: project._id,
                options: {
                    devFilters: {
                        category: tagPirateBrandKey,
                        model_type: 'target',
                    },
                },
            }),
        [project._id]
    );

    const authorizationViews: ViewsRecord = useMemo(() => {
        const authorizationBadge =
            localization.components.models.authorization.badge.status;
        if (!permissions.viewAuthorizations) return {};
        const views: ViewsRecord = {
            authorizations: {
                fetchData: (params) =>
                    detailManyAuthorization({
                        project_id: project._id,
                        options: params.options,
                        signal: params.signal,
                    }),
                label: localization.models.authorization.plural,
                totalCount: metricsPromise.then((m) => m.total_authorizations),
                tableElement: AuthorizationDataTable,
                model: 'authorization',
            },
            'authorityAuthorizations:pending': {
                fetchData: (params) =>
                    detailManyAuthorization({
                        ...params,
                        project_id: project._id,
                        options: {
                            ...params.options,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.ASC,
                            },
                            devFilters: {
                                authority_data_status: 'pending',
                            },
                        },
                    }).then((query) => ({
                        ...query,
                        payload: query.payload.map((a) => {
                            a.status = 'pending';
                            return a;
                        }),
                    })),
                label: authorizationBadge.pendingWithName,
                totalCount: metricsPromise.then(
                    (m) => m.authority_data_status_pending
                ),
                tableElement: (props) => {
                    const { sortField, paginatorProps, ...rest } = props;
                    return <AuthorizationDataTable {...rest} />;
                },
                filters: {},
                model: 'authorization',
            },
            'authorityAuthorizationsByConfig:pending': {
                fetchData: (params) =>
                    detailManyAuthorizationGroupedByConfig({
                        ...params,
                        project_id: project._id,
                        options: {
                            ...params.options,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.ASC,
                            },
                            devFilters: {
                                authority_data_status: 'pending',
                            },
                        },
                    }).then((query) => ({
                        ...query,
                        payload: query.payload.map((a) => {
                            a.status = 'pending';
                            return a;
                        }),
                    })),
                label: authorizationBadge.pendingWithName,
                totalCount: null,
                tableElement: (props) => {
                    const { sortField, paginatorProps, ...rest } = props;
                    return (
                        <AuthorizationDataTable {...paginatorProps} {...rest} />
                    );
                },
                filters: {},
                model: 'authorization',
            },
            'authorityAuthorizations:done': {
                fetchData: (params) =>
                    detailManyAuthorization({
                        ...params,
                        project_id: project._id,
                        options: {
                            ...params.options,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.DESC,
                            },
                            devFilters: {
                                authority_data_status: 'done',
                            },
                        },
                    }),
                label: authorizationBadge.doneWithName,
                totalCount: metricsPromise.then(
                    (m) => m.authority_data_status_done
                ),
                tableElement: (props) => {
                    const { sortField, ...noSortProps } = props;
                    return <AuthorizationDataTable {...noSortProps} />;
                },
                filters: {},
                model: 'authorization',
            },
            'authorityAuthorizationsByConfig:done': {
                fetchData: (params) =>
                    detailManyAuthorizationGroupedByConfig({
                        ...params,
                        project_id: project._id,
                        options: {
                            ...params.options,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.DESC,
                            },
                            devFilters: {
                                authority_data_status: 'done',
                            },
                        },
                    }),
                label: authorizationBadge.doneWithName,
                totalCount: null,
                tableElement: (props) => {
                    const { sortField, ...noSortProps } = props;
                    return <AuthorizationDataTable {...noSortProps} />;
                },
                filters: {},
                model: 'authorization',
            },
            recentAuthorizations: {
                fetchData: (params) =>
                    detailManyAuthorization({
                        project_id: project._id,
                        ...params,
                        options: {
                            ...params.options,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.DESC,
                            },
                            limit: 5,
                        },
                    }),
                label: localization.models.authorization.plural,
                totalCount: metricsPromise.then((m) => m.total_authorizations),
                tableElement: ({
                    sortField,
                    paginatorProps,
                    ...noSortProps
                }) => <AuthorizationDataTable {...noSortProps} />,
                model: 'authorization',
                filters: {},
            },
            'recentAuthorizations:draft': {
                fetchData: (params) =>
                    detailManyAuthorization({
                        project_id: project._id,
                        ...params,
                        options: {
                            ...params.options,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.DESC,
                            },
                            limit: 5,
                            devFilters: {
                                status: 'draft',
                            },
                        },
                    }),
                label: authorizationBadge.draftWithName,
                totalCount: metricsPromise.then(
                    (m) => m.authorization_status_draft
                ),
                tableElement: ({
                    sortField,
                    paginatorProps,
                    ...noSortProps
                }) => <AuthorizationDataTable {...noSortProps} />,
                model: 'authorization',
                filters: {},
            },
            'recentAuthorizations:pending': {
                fetchData: (params) =>
                    detailManyAuthorization({
                        ...params,
                        project_id: project._id,
                        options: {
                            ...params.options,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.ASC,
                            },
                            limit: 5,
                            devFilters: {
                                status: 'pending',
                            },
                        },
                    }),
                label: authorizationBadge.pendingWithName,
                totalCount: metricsPromise.then(
                    (m) => m.authorization_status_pending
                ),
                tableElement: ({
                    sortField,
                    paginatorProps,
                    ...noSortProps
                }) => <AuthorizationDataTable {...noSortProps} />,
                model: 'authorization',
                filters: {},
            },
            'recentAuthorizations:approved': {
                fetchData: (params) =>
                    detailManyAuthorization({
                        project_id: project._id,
                        ...params,
                        options: {
                            ...params.options,
                            sort: {
                                field: 'created_at',
                                order: SortOrder.DESC,
                            },
                            limit: 5,
                            devFilters: {
                                status: 'approved',
                            },
                        },
                    }),
                label: authorizationBadge.doneWithName,
                totalCount: metricsPromise.then(
                    (m) => m.authorization_status_approved
                ),
                tableElement: ({
                    sortField,
                    paginatorProps,
                    ...noSortProps
                }) => <AuthorizationDataTable {...noSortProps} />,
                model: 'authorization',
                filters: {},
            },
        };
        return views;
    }, [project._id, localization, permissions, metricsPromise]);
    const authorizationConfigViews: ViewsRecord = useMemo(() => {
        if (!permissions.viewAuthorizationConfigs) return {};
        return {
            authorizationConfigs: {
                fetchData: (params) =>
                    detailManyAuthorizationConfig({
                        project_id: project._id,
                        options: {
                            ...params.options,
                        },
                        signal: params.signal,
                    }),
                label: localization.models.authorizationConfig.plural,
                totalCount: metricsPromise.then(
                    (m) => m.total_authorization_configs
                ),
                tableElement: AuthorizationConfigDataTable,
                model: 'authorizationConfig',
            },
        };
    }, [project._id, localization, permissions, metricsPromise]);
    const documentViews: ViewsRecord = useMemo(() => {
        if (!permissions.viewDocuments) return {};
        return {
            officialDocuments: {
                fetchData: (params) =>
                    detailManyDocuments({
                        project_id: project._id,
                        options: {
                            ...params.options,
                            devFilters: {
                                category: 'official_document',
                            },
                        },
                        signal: params.signal,
                    }),
                label: localization.models.oficio.plural,
                totalCount: metricsPromise.then(
                    (m) => m.total_official_documents
                ),
                tableElement: (props) => (
                    <DocumentDataTable
                        documentType='official_document'
                        {...props}
                    />
                ),
                model: 'document',
            },
            documents: {
                fetchData: (params) =>
                    detailManyDocuments({
                        project_id: project._id,
                        ...params,
                    }),
                label: localization.models.document.plural,
                totalCount: metricsPromise.then((m) => m.total_documents),
                tableElement: (props) => (
                    <DocumentDataTable documentType='document' {...props} />
                ),
                model: 'document',
            },
        };
    }, [project._id, localization, permissions, metricsPromise]);
    const targetViews: ViewsRecord = useMemo(() => {
        if (!permissions.viewTargets) return {};
        return {
            targets: {
                fetchData: (params) =>
                    detailManyTargets({
                        project_id: project._id,
                        options: params.options,
                        signal: params.signal,
                    }),
                label: localization.models.target.plural,
                totalCount: metricsPromise.then((m) => m.total_targets),
                tableElement: TargetsDataTable,
                model: 'targets',
            },
            targetsAuthorization: {
                fetchData: (params) =>
                    detailManyTargetsUnwindedAuthorization({
                        project_id: project._id,
                        options: params.options,
                        signal: params.signal,
                    }),
                label: localization.models.target.plural,
                totalCount: metricsPromise.then((m) => m.total_targets),
                tableElement: TargetsDataTable,
                model: 'targets',
            },
            judicialTargets: {
                fetchData: (params) =>
                    detailManyTargets({
                        project_id: project._id,
                        options: {
                            ...params.options,
                            devFilters: {
                                in_type_order: 'judicial',
                            },
                        },
                        signal: params.signal,
                    }),
                label: localization.models.judicialTargets.plural,
                totalCount: metricsPromise.then(
                    (m) => m.total_judicial_targets
                ),
                tableElement: (props) => (
                    <SimplifiedTargetsDataTable
                        targetsTableConfig={{
                            type: 'type',
                            notified: 'notified',
                            authorizations: 'authorizations_count',
                            block_orders: 'block_orders_count',
                            created_at: 'created_at',
                        }}
                        {...props}
                    />
                ),
                model: 'targets',
            },
        };
    }, [project._id, localization, permissions, metricsPromise]);
    const tagViews: ViewsRecord = useMemo(() => {
        if (!permissions.viewTags) return {};
        const views: ViewsRecord = {
            'tags:pirate-brand': {
                fetchData: (params) =>
                    detailManyTags({
                        project_id: project._id,
                        options: {
                            ...params.options,
                            devFilters: {
                                category: 'pirate-brand',
                            },
                        },
                        signal: params.signal,
                    }),
                label: localization.models.tag.types['pirate-brand'].plural,
                totalCount: pirateBrandPromise.then((t) => t.payload),
                tableElement: TagDataTable,
                model: 'tag',
            },
            'tags:source': {
                fetchData: (params) =>
                    detailManyTags({
                        project_id: project._id,
                        options: {
                            ...params.options,
                            devFilters: {
                                category: 'source',
                                model_type: 'target',
                            },
                        },
                        signal: params.signal,
                    }),
                label: localization.models.tag.types.source.plural,
                totalCount: sourcePromise.then((t) => t.payload),
                tableElement: TagDataTable,
                model: 'tag',
            },
        };
        return views;
    }, [
        project._id,
        localization,
        permissions,
        pirateBrandPromise,
        sourcePromise,
    ]);

    const dashboardViews: ViewsRecord = useMemo(() => {
        if (!permissions.getDashboard) return {};
        const dashboardElement = <DashBoardElement />;
        if (!dashboardElement) return {};
        const views: ViewsRecord = {
            overviewDashboard: {
                label: localization.models.dashboard.singular,
                customElement: <>{dashboardElement}</>,
            },
        };
        return views;
    }, [localization, permissions]);

    const views: ViewsRecord = useMemo(
        () => ({
            ...authorizationConfigViews,
            ...authorizationViews,
            ...documentViews,
            ...tagViews,
            ...targetViews,
            ...dashboardViews,
        }),
        [
            authorizationViews,
            authorizationConfigViews,
            documentViews,
            tagViews,
            targetViews,
            dashboardViews,
        ]
    );

    return {
        views,
    };
};

export default useHomeViews;
