import { useMutation } from '@tanstack/react-query';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { ToastMessage } from 'primereact/toast';
import {
    validateTargetsOnAuthorization,
    validateTargetsOnOrder,
} from 'services/ether/case-manager/targets';
import {
    ValidateTargetAuthorization,
    ValidateTargetBase,
    ValidateTargetOrder,
} from 'services/ether/case-manager/targets/types';
import { getErrorToast } from 'utils/errorHandler';
import { useState } from 'react';
import { Datepicker } from 'components/ethercity-primereact';
import { Button } from 'primereact/button';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';
import useShowTargetActionDisambiguation from 'hooks/dialogs/target/useShowTargetActionDisambiguation';
import LightDialog from 'components/display/LightDialog';

type ValidateTargetParams = {
    targets: ({
        _id: string;
        value: string;
    } & (
        | {
              authorization_id: string;
              accepted: boolean;
          }
        | {
              block_order_id: string;
              blocked: boolean;
          }
        | {
              unblock_order_id: string;
              blocked: boolean;
          }
    ))[];
    checkIfAll?: boolean;
};

type ApiResponse = (
    | ValidateTargetAuthorization.SingleResult
    | ValidateTargetOrder.SingleResult
    | ValidateTargetBase.Error
)[];
type Params = ValidateTargetAuthorization.Data | ValidateTargetOrder.Data;

const useValidateTarget = ({
    token,
    onRespond,
    defaultBlockDate,
}: {
    token?: string;
    onRespond?: () => void;
    defaultBlockDate?: Date;
}): {
    validateTargets: (options: ValidateTargetParams) => void;
    isLoading: boolean;
    error: Error | null;
    dialogElement: JSX.Element;
} => {
    const [localization] = useLocalization();
    const toast = useToast();
    const [persistedParams, setPersistedParams] =
        useState<ValidateTargetParams | null>(null);
    const [blockDate, setBlockDate] = useState<Date | null>(
        defaultBlockDate ?? null
    );

    const { hardRefresh, softRefresh } = useQueryRefresh();

    const hideDialog = () => {
        setBlockDate(null);
        setPersistedParams(null);
    };

    const handleUpdate = ({
        data,
        params,
    }: {
        data: ApiResponse;
        params: Params;
    }) => {
        const firstItem = data[0];
        const isAuthorization = firstItem && 'accepted' in firstItem;
        const isOrder = firstItem && 'blocked' in firstItem;
        const accepted = isAuthorization
            ? firstItem.accepted
            : isOrder
            ? firstItem.blocked
            : false;
        const isAll = 'all_targets' in params.data && params.data.all_targets;

        const firstEntry = data[0];

        const successTargets = data.filter(
            (t) => 'error' in t && t.error === null
        );
        const errorTargets = data.filter(
            (t) => 'error' in t && t.error != null
        );

        const totalCount =
            isAll && firstEntry && 'modified_count' in firstEntry
                ? firstEntry.modified_count
                : successTargets.length;

        const toastMessage: ToastMessage = {
            life: 5000,
        };
        const mode: 'approved' | 'rejected' | 'blocked' | 'notBlocked' =
            isAuthorization
                ? accepted
                    ? 'approved'
                    : 'rejected'
                : accepted
                ? 'blocked'
                : 'notBlocked';
        const quantity: 'plural' | 'singular' =
            totalCount > 1 ? 'plural' : 'singular';
        if (errorTargets.length <= 0) {
            toastMessage.severity = 'success';
            toastMessage.summary =
                localization.endpoints.target.validate_target[mode][
                    quantity
                ].replace('{count}', totalCount.toString());
        } else if (successTargets.length <= 0) {
            toastMessage.severity = 'error';
            toastMessage.summary =
                localization.endpoints.target.validate_target.failed;
        } else {
            toastMessage.severity = 'warn';
            toastMessage.summary =
                localization.endpoints.target.validate_target[mode][
                    quantity
                ].replace('{count}', totalCount.toString());
            toastMessage.detail =
                localization.endpoints.target.validate_target.partial;
        }

        toast.show(toastMessage);
    };

    const validateTargetMutation = useMutation<ApiResponse, Error, Params>({
        mutationFn: (data) => {
            if (data.type === 'authorization')
                return validateTargetsOnAuthorization(data);
            return validateTargetsOnOrder(data);
        },
        onSuccess: (data, params) => {
            hardRefresh(['target']);
            softRefresh([
                'block-order',
                'authorization',
                'unblock-order',
                'metrics',
            ]);
            handleUpdate({ data, params });
            if (onRespond) onRespond();
        },
        onError: (err) => toast.show(getErrorToast(err.message, localization)),
    });

    const isLoading = validateTargetMutation.isPending;

    const showDisambiguation = useShowTargetActionDisambiguation();

    const validateTargetsWithDate = (
        params: ValidateTargetParams,
        date: Date | null
    ) => {
        type PayloadMany =
            | ValidateTargetAuthorization.DataMany
            | ValidateTargetOrder.DataMany;
        type PayloadAll =
            | ValidateTargetAuthorization.DataAll
            | ValidateTargetOrder.DataAll;
        let payloadMany: PayloadMany;
        let payloadAll: PayloadAll;

        const firstItem = params.targets[0];

        if (!firstItem) return;

        let positiveResponse: boolean;
        let positiveLabel: string;
        let negativeLabel: string;
        if ('authorization_id' in firstItem) {
            positiveResponse = firstItem.accepted;
            positiveLabel = localization.components.common.button.approve;
            negativeLabel = localization.components.common.button.reject;
            payloadMany = {
                type: 'authorization',
                token,
                data: params.targets
                    .map((t) => {
                        if (!('authorization_id' in t)) return null;
                        return {
                            authorization_id: t.authorization_id,
                            accepted: t.accepted,
                            target_id: t._id,
                        };
                    })
                    .filter(
                        (
                            t
                        ): t is ValidateTargetAuthorization.DataMany['data'][0] =>
                            !!t
                    ),
            };
            payloadAll = {
                type: 'authorization',
                token,
                data: {
                    all_targets: true,
                    accepted: firstItem.accepted,
                    authorization_id: firstItem.authorization_id,
                },
            };
        } else {
            positiveResponse = !firstItem.blocked;
            positiveLabel = localization.components.common.button.notBlock;
            negativeLabel = localization.components.common.button.block;
            const orderType: ValidateTargetOrder.DataAll['data']['order_type'] =
                'block_order_id' in firstItem ? 'block_order' : 'unblock_order';
            payloadMany = {
                type: 'order',
                token,
                blocked_at: date,
                data: params.targets
                    .map((t) => {
                        if ('authorization_id' in t) return null;
                        return {
                            order_id:
                                'block_order_id' in t
                                    ? t.block_order_id
                                    : t.unblock_order_id,
                            blocked: t.blocked,
                            order_type: orderType,
                            target_id: t._id,
                        };
                    })
                    .filter(
                        (t): t is ValidateTargetOrder.DataMany['data'][0] => !!t
                    ),
            };
            payloadAll = {
                type: 'order',
                token,
                blocked_at: date,
                data: {
                    all_targets: true,
                    order_id:
                        'block_order_id' in firstItem
                            ? firstItem.block_order_id
                            : firstItem.unblock_order_id,
                    blocked: true,
                    order_type: orderType,
                },
            };
        }

        const validateCurrent = () =>
            validateTargetMutation.mutate(payloadMany);
        const validateAll = () => validateTargetMutation.mutate(payloadAll);

        const answer = (
            positiveResponse ? positiveLabel : negativeLabel
        ).toLocaleLowerCase();
        if (params.checkIfAll)
            showDisambiguation({
                answer: answer,
                isRevert: false,
                onApproveAll: validateAll,
                onApprovePage: validateCurrent,
            });
        else validateCurrent();
    };

    const validateTargets = (params: ValidateTargetParams) => {
        if ('blocked' in params && params.blocked) {
            setPersistedParams(params);
        } else validateTargetsWithDate(params, null);
    };

    const dialogOk = !!blockDate && !!persistedParams;

    const defineTimeDialog = (
        <LightDialog
            header={
                localization.components.models.target.views.targetValidation
                    .selectBlockDatetime
            }
            visible={!!persistedParams}
            onHide={hideDialog}
            footer={
                <div className='flex flex-row gap-2'>
                    <Button
                        label={localization.components.common.button.save}
                        onClick={() => {
                            if (!dialogOk) return;
                            validateTargetsWithDate(persistedParams, blockDate);
                        }}
                        severity='success'
                        disabled={!dialogOk}
                        loading={validateTargetMutation.isPending}
                    />
                    <Button
                        label={localization.components.common.button.cancel}
                        onClick={hideDialog}
                    />
                </div>
            }
        >
            <Datepicker
                value={blockDate}
                onChange={(value) => setBlockDate(value)}
                type='datetime-local'
                required
            />
        </LightDialog>
    );

    return {
        validateTargets,
        error: validateTargetMutation.error,
        isLoading: isLoading,
        dialogElement: defineTimeDialog,
    };
};

export default useValidateTarget;
