import useBaseFileExport from '../useBaseFileExport';
import { exportUnblockOrderFiles } from 'services/ether/case-manager/unblock-orders';
import { ExportUnblockOrderFilesEP } from 'services/ether/case-manager/unblock-orders/types';

const useExportUnblockOrderFiles = () => {
    const { exportFile, isExporting } = useBaseFileExport<ExportUnblockOrderFilesEP.Params>(
        // TODO: what in tarnation
        'export-unblock-order-files',
        [],
        ({ params, signal }) => exportUnblockOrderFiles(params, signal)
    );

    return { exportFile, isExporting };
};

export default useExportUnblockOrderFiles;
