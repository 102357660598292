import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { Column } from 'primereact/column';
import {
    isAuthorizationFilesExportable,
    isAuthorizationRespondable,
} from 'utils/models/authorization';
import { useAuth } from 'hooks/context/useAuth';
import { MenuItem } from 'primereact/menuitem';
import { useNavigate } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import { useMemo, useRef } from 'react';
import { Button } from 'primereact/button';
import { useLocalization } from 'hooks/context/useLocalization';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';
import useExportAuthorizationFiles from 'hooks/queries/authorization/useExportAuthorizationFiles';
import { useAuthorizationRespond } from 'hooks/models/authorization/useAuthorizationRespond';
import useDownloadEvidencesFromAuthorization from 'hooks/queries/evidence/useDownloadEvidencesFromAuthorization';
import useShowOperationStats from 'hooks/dialogs/operation/useShowOperationStats';
import useAuthorizationColumnFields from 'hooks/columns/models/useAuthorizationColumnFields';
import useUpsertAuthorizationDialog from 'hooks/models/authorization/useUpsertAuthorizationDialog';
import useShowObjectDisplayModal from 'hooks/dialogs/general/useShowObjectDisplayModal';
import { generateAuthorizationColumns } from '../utils';
import useAppConfig from 'hooks/appConfig/useAppConfig';

type DetailedModel = Ether.CaseManager.Authorization.Detailed;

const RowMenu: React.FC<{
    item: DetailedModel;
    onRespondAuthorization: (item: DetailedModel, answer: boolean) => void;
    onShowDetail: (item: DetailedModel) => void;
    onStartEdit?: (item: DetailedModel) => void;
    onShowStats?: (item: { id: string; name: string }) => void;
}> = ({
    item,
    onRespondAuthorization,
    onStartEdit,
    onShowDetail,
    onShowStats,
}) => {
    const { user, permissions } = useAuth();
    const navigate = useNavigate();
    const [localization] = useLocalization();

    const menuRef = useRef<Menu>(null);

    const isFilesExportable = isAuthorizationFilesExportable({
        authorization: item,
        permissions: permissions,
        token: undefined,
    });

    const { exportFile, isExporting } = useExportAuthorizationFiles();
    const {
        downloadFile: downloadEvidences,
        isDownloading: isDownloadingEvidences,
    } = useDownloadEvidencesFromAuthorization({
        authorization_id: item._id,
    });

    const isRespondable = isAuthorizationRespondable({
        permissions: permissions,
        authorization: item,
        userId: user.data?._id,
        userRole: user.role,
    });
    const isEditable =
        item.status === 'draft' &&
        permissions.editAuthorizations &&
        !!onStartEdit &&
        item.target_group_ids &&
        item.target_group_ids.length > 0;
    const menuItems: MenuItem[] = [
        ...(permissions.getAuthorizationStats && onShowStats
            ? [
                  {
                      icon: 'pi pi-chart-bar',
                      label: localization.components.common.stats.header,
                      command: () =>
                          onShowStats({
                              id: item._id,
                              name: item.name,
                          }),
                  },
              ]
            : []),
        {
            id: 'targets',
            // icon: 'pi pi-sign-out',
            label: localization.models.target.plural,
            command: () =>
                navigate(`/authorizations/${item._id}?start_view=targets`),
        },
        {
            id: 'evidences',
            // icon: 'pi pi-sign-out',
            label: localization.models.evidence.plural,
            command: () =>
                navigate(`/authorizations/${item._id}?start_view=evidences`),
        },
        ...(isFilesExportable
            ? [
                  {
                      id: 'export-files',
                      icon: 'pi pi-download',
                      label: localization.components.common.button.exportFiles,
                      command: () =>
                          exportFile({
                              filename: null,
                              authorization_id: item._id,
                          }),
                      disabled: isExporting,
                  },
              ]
            : []),
        ...(permissions.downloadEvidencesAuthorization &&
        (item.count_data.evidences_count ?? 0) > 0
            ? [
                  {
                      id: 'download-evidences',
                      icon: 'pi pi-download',
                      label: localization.components.models.evidence.button
                          .downloadMany,
                      command: downloadEvidences,
                      disabled: isDownloadingEvidences,
                  },
              ]
            : []),
        ...(isEditable
            ? [
                  {
                      id: 'edit',
                      label: localization.components.common.button.edit,
                      icon: 'pi pi-pencil',
                      command: () => onStartEdit(item),
                  },
              ]
            : []),
        ...(isRespondable
            ? [
                  {
                      id: 'accept',
                      label: !user.role?.startsWith('operator')
                          ? localization.components.common.button.approve
                          : localization.components.common.button.confirm,
                      command: () => onRespondAuthorization(item, true),
                      icon: 'pi pi-check',
                      style: {
                          backgroundColor: 'var(--semaphore-green)',
                      },
                  },
              ]
            : []),
        ...(permissions.debugAuthorizations
            ? [
                  {
                      id: 'debug',
                      label: 'Detail data',
                      command: () => onShowDetail(item),
                  },
              ]
            : []),
    ];

    if (menuItems.length <= 0) return;

    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

/**
 * DataTable for the Authorization model, currently does not support dynamic columns system per page
 * @param props.showApproved - If the approval column for the complaint should be shown. In the future it should be remade into dynamic columns system
 * @param props - Most props shared from CMDataTableProps
 */
const AuthorizationDataTable: React.FC<
    CMDataTableProps<DetailedModel> & {
        showApproved?: boolean;
    }
> = ({ showApproved, ...props }) => {
    const [localization] = useLocalization();
    const { user } = useAuth();
    const config = useAppConfig().authorization;

    const { dialog: statsDialog, show: showStats } = useShowOperationStats();
    const { columnFields, filterOptions } = useAuthorizationColumnFields();
    const { RespondDialog, respondAuthorization } = useAuthorizationRespond();
    const { element: UpsertDialog, showEdit } = useUpsertAuthorizationDialog();
    const { dialog: objectDisplayDialog, show: showObjectDisplay } =
        useShowObjectDisplayModal();

    const sortable = 'sortField' in props;
    const hideSignedTab = user.role === 'analyst';

    const columnElements = useMemo(
        () =>
            generateAuthorizationColumns({
                columns: columnFields,
                tableConfig: config?.datatable ?? null,
                localization: localization,
                sortable: sortable,
                hideSignedTab,
                showApproved: !!showApproved,
            }),
        [
            columnFields,
            config?.datatable,
            hideSignedTab,
            localization,
            showApproved,
            sortable,
        ]
    );

    return (
        <>
            <RespondDialog />
            <UpsertDialog />
            {statsDialog}
            {objectDisplayDialog}
            <CMDataTable
                columnConfigName='authorization'
                lazy
                emptyMessage={
                    localization.components.models.authorization.datatable.empty
                }
                filterOptions={filterOptions}
                {...props}
            >
                <Column
                    field='_cm_name_id'
                    body={(data: DetailedModel) => (
                        <NameIdAggregator
                            name={data.name}
                            id={data._id}
                            navigateTo={`/authorizations/${data._id}?start_view=targets`}
                        />
                    )}
                />
                {columnElements}
                <Column
                    field='actions'
                    body={(data: DetailedModel) => (
                        <RowMenu
                            item={data}
                            onRespondAuthorization={respondAuthorization}
                            onShowDetail={(authorization) => {
                                showObjectDisplay({
                                    header: authorization.name,
                                    data: authorization,
                                });
                            }}
                            onStartEdit={showEdit}
                            onShowStats={showStats}
                        />
                    )}
                />
            </CMDataTable>
        </>
    );
};

export default AuthorizationDataTable;
