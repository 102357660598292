import { HomeViewSet } from '../../components/HomeViewSet';
import { ValidHomeViews } from 'hooks/home/useHomeViews';
import useAppConfig from 'hooks/appConfig/useAppConfig';

/**
 * Home page view for the user with Authority role, as known as Autoridade/Authorizer Strict
 * @returns Functional Component for the home
 */
const AuthorityHome = () => {
    const config = useAppConfig();
    const viewApprovedGrouped = config.authorization?.group_view?.approved;
    const viewPendingGrouped = config.authorization?.group_view?.pending;

    const tabsView: ValidHomeViews[] = [];
    if (viewPendingGrouped)
        tabsView.push('authorityAuthorizationsByConfig:pending');
    else tabsView.push('authorityAuthorizations:pending');

    if (viewApprovedGrouped)
        tabsView.push('authorityAuthorizationsByConfig:done');
    else tabsView.push('authorityAuthorizations:done');

    tabsView.push('officialDocuments');
    tabsView.push('judicialTargets');
    tabsView.push('overviewDashboard');

    return (
        <HomeViewSet
            views={[
                {
                    key: 'authorizer-strict-home',
                    tabs: tabsView,
                },
            ]}
        />
    );
};

export default AuthorityHome;
