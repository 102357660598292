import CompanyDataTable from 'components/datatable/models/company/CompanyDataTable';
import CacheControl from 'controller/cache/cacheController';
import useListCompany from 'hooks/queries/company/useListCompany';
import { useAuth } from 'hooks/context/useAuth';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import { useLocalization } from 'hooks/context/useLocalization';
import { useProject } from 'hooks/context/project/useProject';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useDetailManyUsers from 'hooks/queries/user/useDetailManyUsers';
import GroupedUserProfileDataTable from 'components/models/User/GroupedUserProfileDataTable';
import SideVerticalMenu from 'components/page/SideVerticalMenu';
import useCompanyColumnFields from 'hooks/columns/models/useCompanyColumnFields';

const CompanyView = () => {
    const [localization] = useLocalization();
    const { permissions } = useAuth();
    const project = useProject();
    const navigate = useNavigate();

    const { filterMetaData : companyFilters } = useCompanyColumnFields();

    const { pageOptions, setPageOptions, filters, setFilters } =
        useInitDataTableState({
            filters: companyFilters,
            pageOptions: {
                startingRow:
                    CacheControl.UserConfig.get()['paginatorRows']?.company,
            },
        });

    const companyQuery = useListCompany({
        project_id: project._id,
        options: {
            rawFilters: filters,
            limit: pageOptions.rows,
            offset: pageOptions.offset,
        },
    });

    return (
        <section>
            <div className='flex flex-row justify-between'>
                <strong className='text-3xl'>
                    {localization.components.views.adminPanel.tabCompanies}
                </strong>
                <div>
                    {permissions.upsertCompanies && (
                        <Link to='create-company'>
                            <Button
                                icon='pi pi-plus'
                                label={
                                    localization.components.models.company
                                        .button.new
                                }
                            />
                        </Link>
                    )}
                </div>
            </div>
            <CompanyDataTable
                filters={filters}
                setFilters={(e) => setFilters(e)}
                paginatorProps={{
                    ...pageOptions,
                    onPageChange: (e) => setPageOptions(e),
                    disableNext:
                        (companyQuery.data?.payload.length ?? 9999) < pageOptions.rows,
                    onRefresh: companyQuery.refresh,
                }}
                loading={companyQuery.isFetching}
                value={companyQuery.data?.payload}
                onEdit={(company) =>
                    navigate('edit-company/' + company._id)
                }
            />
        </section>
    );
};

const UserView = () => {
    const [localization] = useLocalization();
    const { permissions } = useAuth();

    const { pageOptions, setPageOptions, filters, setFilters } =
        useInitDataTableState({
            pageOptions: {
                startingRow:
                    CacheControl.UserConfig.get()['paginatorRows']
                        ?.groupedUserProfile,
            },
        });

    const usersQuery = useDetailManyUsers({
        options: {
            rawFilters: filters,
            limit: pageOptions.rows,
            offset: pageOptions.offset,
        },
    });

    return (
        <section>
            <div className='flex flex-row justify-between'>
                <strong className='text-3xl'>
                    {localization.components.views.adminPanel.tabUsers}
                </strong>
                <div>
                    {permissions.upsertUsers && (
                        <Link to='create-user'>
                            <Button
                                icon='pi pi-plus'
                                label={
                                    localization.components.models.user.button
                                        .new
                                }
                            />
                        </Link>
                    )}
                </div>
            </div>
            <GroupedUserProfileDataTable
                filters={filters}
                setFilters={(e) => setFilters(e)}
                paginatorProps={{
                    ...pageOptions,
                    onPageChange: (e) => setPageOptions(e),
                    disableNext:
                        (usersQuery.data?.payload.length ?? 9999) < pageOptions.rows,
                    onRefresh: usersQuery.refresh,
                }}
                loading={usersQuery.isFetching}
                value={usersQuery.data?.payload}
            />
        </section>
    );
};

const MenuViews = {
    USERS: 'users',
    COMPANIES: 'companies',
};

type MenuKey = keyof typeof MenuViews;

const AdminManagementLanding = () => {
    const [localization] = useLocalization();
    const { permissions } = useAuth();

    const views = {
        // [MenuViews.USERS]: permissions.upsertUsers
        //     ? {
        //           label: localization.components.views.adminPanel.tabUsers,
        //           icon: 'pi pi-user',
        //       }
        //     : null,
        [MenuViews.COMPANIES]: permissions.upsertCompanies
            ? {
                  label: localization.components.views.adminPanel.tabCompanies,
                  icon: 'pi pi-building',
              }
            : null,
    };
    const validViews = Object.entries(views)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => ({
            ...value,
            id: key as MenuKey,
            label: value?.label || '',
        }));
    const [selectedMenu, setSelectedMenu] = useState(
        Object.keys(views)[0] as keyof typeof MenuViews
    );

    return (
        <section>
            <h1>{localization.components.views.adminPanel.title}</h1>
            <section className='grid grid-cols-10 gap-4'>
                <div className='col-span-2'>
                    <SideVerticalMenu
                        menuItems={validViews}
                        activeId={selectedMenu}
                        onChange={({ id }) => id ? setSelectedMenu(id as MenuKey) : null}
                    />
                </div>
                <div className='col-span-8'>
                    {selectedMenu === MenuViews.USERS && <UserView />}
                    {selectedMenu === MenuViews.COMPANIES && <CompanyView />}
                </div>
            </section>
        </section>
    );
};

export default AdminManagementLanding;
