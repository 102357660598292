import { Dropdown, MultiSelect } from 'components/ethercity-primereact';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { SelectInternalProps } from '../types';
import { useLocalization } from 'hooks/context/useLocalization';

function BaseSelectItems<T extends Record<string, any>>({
    value,
    onChange,
    type,

    items,
    itemsLoading,
    onLoad,
    additionalItems,
    overrideItems,
    excludeItems,

    optionLabel = 'name',
    optionValue = '_id',

    mapItems,

    label,
    title,
    placeholder,
    hideTitle,

    hidden,
    loading,
    required,
    disabled,
    showClear,
}: React.PropsWithChildren<
    SelectInternalProps<T> &
        (
            | {
                  type: 'one';
                  value: string | undefined | null;
                  onChange: (items: T | null, options: T[]) => void;
              }
            | {
                  type: 'many';
                  value: string[] | undefined;
                  onChange: (items: T[], options: T[]) => void;
              }
        )
>) {
    const [localization] = useLocalization();
    const didLoad = useRef(false);

    const additional = useMemo(() => additionalItems ?? [], [additionalItems]);
    const finalItems = useMemo(
        () => overrideItems ?? items ?? [],
        [overrideItems, items]
    );

    const transformItems = useCallback(
        (items: T[]) => {
            let transformedItems: any[] = items;
            if (mapItems) transformedItems = mapItems(items);
            return transformedItems;
        },
        [mapItems]
    );

    const options = useMemo(
        () =>
            transformItems(
                [
                    ...additional,
                    ...finalItems.filter(
                        (i1) =>
                            !additional.find(
                                (i2) => i1[optionValue] === i2[optionValue]
                            )
                    ),
                ].filter((i) => {
                    if (!excludeItems) return true;
                    return !excludeItems.find((id) => i[optionValue] === id);
                })
            ),
        [additional, finalItems, excludeItems, optionValue, transformItems]
    );

    useEffect(() => {
        if (itemsLoading) didLoad.current = false;
        if (didLoad.current) return;
        if (!onLoad || !options || itemsLoading) return;
        didLoad.current = true;
        onLoad(options);
    }, [options, itemsLoading, onLoad]);

    // Becomes unavailable when there is a desync on selected values vs
    const isUnavailable = value
        ? type === 'many'
            ? !value.every(
                  (v) => !!options.find((item) => v === item[optionValue])
              )
            : !options.find((item) => value === item[optionValue])
        : false;
    const isLoading = itemsLoading || loading;
    const isDisabled = isLoading || disabled;

    const sharedProps = {
        value: isLoading || isUnavailable ? undefined : value,
        className: 'w-full',
        label,
        placeholder: isLoading
            ? localization.common.loading
            : isUnavailable
            ? localization.common.unavailable
            : placeholder,
        options,
        optionLabel,
        optionValue,
        required,
        loading: isLoading,
        disabled: isDisabled,
        showClear,
    };

    if (hidden) return null;

    return (
        <>
            {title && !hideTitle && <h3>{title}</h3>}
            {type === 'many' ? (
                <MultiSelect
                    {...sharedProps}
                    display='chip'
                    onChange={(e) => {
                        const validIds = e.target.value as string[];
                        const newItems = options?.filter(
                            (item) =>
                                !!validIds.find(
                                    (id) => id === item[optionValue]
                                )
                        );
                        onChange(newItems ?? [], options);
                    }}
                />
            ) : (
                <Dropdown
                    {...sharedProps}
                    onChange={(e) => {
                        const id = e.target.value as string;
                        const item = options?.find(
                            (item) => id === item[optionValue]
                        );
                        onChange(item ?? null, options);
                    }}
                />
            )}
        </>
    );
}

export default BaseSelectItems;
