import {
    CMDataTableColumnFields,
    CMDataTableFiltersFields,
} from 'components/datatable/CMDataTable/types';
import { useLocalization } from 'hooks/context/useLocalization';
import { useMemo } from 'react';
import { getFilterData } from 'utils/datatable';
import useGenerateFilters from 'hooks/columns/useGenerateFilters';
import { FilterMatchMode } from 'primereact/api';
import useYesNoSelectOptions from 'hooks/selectOptions/generic/useYesNoSelectOptions';
import useTargetOnOffSelectOptions from 'hooks/selectOptions/target/useTargetOnOffSelectOptions';
import useTargetSafeIpSelectOptions from 'hooks/selectOptions/target/useTargetSafeIpSelectOptions';
import useTargetTypeSelectOptions from 'hooks/selectOptions/target/useTargetTypeSelectOptions';
import { grabValidKeyMatchingUrl } from 'utils/object';
import { checkTagFilterMatching } from 'utils/tag';
import useAppConfig from 'hooks/appConfig/useAppConfig';
import { useSharedVariables } from 'hooks/context/useSharedVariables';

type ModelColumns =
    | keyof CaseManagerApp.ModelColumns['targets']
    | CaseManagerApp.DefaultNameColumn;
type ModelFilters =
    | keyof CaseManagerApp.ModelFilters['targets']
    | CaseManagerApp.DefaultNameColumn;

/**
 * Hook to get all available columns and filters for the Target DataTable model
 * @returns object.columnFields - The object describing each column by its field name and available filters (if existing)
 * @returns object.filterOptions - All available filter options to be used by the CMDataTable model
 * @returns object.filterMetaData - All available filter metadata, each value is a DataTableOperatorFilterMetaData for the primereact DataTable component
 */
const useTargetColumnFields = () => {
    const [localization] = useLocalization();

    const config = useAppConfig().target;

    const typeSelectOptions = useTargetTypeSelectOptions();
    const yesNoSelectOptions = useYesNoSelectOptions();
    const onOffSelectOptions = useTargetOnOffSelectOptions();
    const safeIpSelectOptions = useTargetSafeIpSelectOptions();

    const { values } = useSharedVariables();
    const targetCountry = values.target_similarweb_country;

    const columnFields = useMemo(() => {
        const columnFields: CMDataTableColumnFields<ModelColumns> = {
            _cm_name_id: {
                name: '_cm_name_id',
            },
            type: {
                name: 'type',
            },
            enrichment_status: {
                name: 'enrichment_status',
            },
            created_at: {
                name: 'created_at',
            },
            authorizations_count: {
                name: 'authorizations_count',
            },
            block_orders_count: {
                name: 'block_orders_count',
            },
            type_order: {
                name: 'type_order',
            },
            asns: {
                name: 'asns',
            },
            answer: {
                name: 'answer',
            },
            answered_at: {
                name: 'answered_at',
            },
            blocked_at: {
                name: 'blocked_at',
            },
            notified: {
                name: 'notified',
            },

            authorization_tags: {
                name: 'authorization_tags',
            },
            compiled_tags: {
                name: 'compiled_tags',
            },

            'list_data.accessible_ports': {
                name: 'list_data.accessible_ports',
            },
            'list_data.asn': {
                name: 'list_data.asn',
            },
            'list_data.br_domains_count': {
                name: 'list_data.br_domains_count',
            },
            'list_data.country': {
                name: 'list_data.country',
            },
            'list_data.highest_grading': {
                name: 'list_data.highest_grading',
            },
            'list_data.hosted_domains_count': {
                name: 'list_data.hosted_domains_count',
            },
            'list_data.hosted_domains_list': {
                name: 'list_data.hosted_domains_list',
            },
            'list_data.official_domain': {
                name: 'list_data.official_domain',
            },
            'list_data.reserved_ip': {
                name: 'list_data.reserved_ip',
            },
            'list_data.safe_ip': {
                name: 'list_data.safe_ip',
            },
            'list_data.whois_owner': {
                name: 'list_data.whois_owner',
            },
            'list_data.whois_registrar': {
                name: 'list_data.whois_registrar',
            },
            'list_data.domain_age': {
                name: 'list_data.domain_age',
            },
            'list_data.dns_ns': {
                name: 'list_data.dns_ns',
            },
            'list_data.ips_list': {
                name: 'list_data.ips_list',
            },
            'list_data.hosting_companies': {
                name: 'list_data.hosting_companies',
            },

            'external_services_data.block_checker.efficiency': {
                name: 'external_services_data.block_checker.efficiency',
            },
            'external_services_data.block_checker.progress': {
                name: 'external_services_data.block_checker.progress',
            },
            'external_services_data.delisting_checker': {
                name: 'external_services_data.delisting_checker',
            },
            'external_services_data.similarweb': {
                name: 'external_services_data.similarweb',
                field: `list_data.external_services.similarweb.visits.${targetCountry}`,
            },
            'external_services_data.onoff': {
                name: 'external_services_data.onoff',
            },
        };
        return columnFields;
    }, [targetCountry]);

    const filterFields = useMemo(() => {
        const fields = localization.fields.target;
        const datatableLocale = localization.components.models.target.datatable;
        const filterConfig = config?.filters;

        const defaultFilters: string[] = [
            '_cm_name_id',
            'type',
            'list_data.country',
            'list_data.highest_grading',
            'list_data.official_domain',
            'list_data.reserved_ip',
            'list_data.safe_ip',
            'external_services_data.onoff',
            'omit_pre_reprove',
            'notified',
            '_cmapp_tag_target:pirate-brand:multiselect',
        ];

        const configFilters = filterConfig ?? defaultFilters;

        const filterFields: CMDataTableFiltersFields<ModelFilters> = {
            _cm_name_id: {
                meta: getFilterData(),
                options: {
                    placeholder: datatableLocale.selectValue,
                    type: 'string',
                    filterKey: columnFields._cm_name_id.name,
                },
            },
            type: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: fields.type,
                    placeholder: datatableLocale.selectType,
                    type: 'dropdown',
                    selectOptions: typeSelectOptions,
                    filterKey: columnFields.type.name,
                },
            },
            'list_data.country': {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.fields.target.country,
                    type: 'string',
                    hideMatchMode: true,
                    filterKey: columnFields['list_data.country'].name,
                },
            },
            'list_data.highest_grading': {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: fields.grading,
                    placeholder: datatableLocale.inputGrading,
                    type: 'number',
                    filterKey: columnFields['list_data.highest_grading'].name,
                },
            },
            'list_data.official_domain': {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: fields.officialDomain,
                    type: 'dropdown',
                    selectOptions: yesNoSelectOptions,
                    filterKey: columnFields['list_data.official_domain'].name,
                },
            },
            'list_data.reserved_ip': {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.fields.target.reservedIp,
                    type: 'dropdown',
                    selectOptions: yesNoSelectOptions,
                    filterKey: columnFields['list_data.reserved_ip'].name,
                },
            },
            'list_data.safe_ip': {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.fields.target.safeIp,
                    type: 'dropdown',
                    selectOptions: safeIpSelectOptions,
                    filterKey: columnFields['list_data.safe_ip'].name,
                },
            },
            'external_services_data.onoff': {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: fields.onoffStatus,
                    type: 'dropdown',
                    selectOptions: onOffSelectOptions,
                    filterKey:
                        columnFields['external_services_data.onoff'].name,
                },
            },
            'external_services_data.similarweb': {
                meta: getFilterData(FilterMatchMode.GREATER_THAN_OR_EQUAL_TO),
                options: {
                    label: localization.fields.target.similarWeb,
                    type: 'number',
                    filterKey:
                        columnFields['external_services_data.similarweb']
                            .field ?? '',
                    renderWithColumn:
                        columnFields['external_services_data.similarweb']
                            .field ?? '',
                },
            },

            omit_pre_reprove: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    type: 'multiselect',
                    label: localization.fields.target.omitPreRejected,
                    selectOptions: [
                        {
                            label: localization.components.models.target.badge
                                .preReprovedOmitOptions.recently_notified,
                            value: 'recently_notified',
                        },
                        {
                            label: localization.components.models.target.badge
                                .preReprovedOmitOptions.same_operation,
                            value: 'same_operation',
                        },
                        {
                            label: localization.components.models.target.badge
                                .preReprovedOmitOptions.unreversible,
                            value: 'can_revert',
                        },
                    ],
                    filterKey: 'omit_pre_reprove',
                    renderWithColumn: columnFields._cm_name_id.name,
                },
            },
            notified: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: localization.fields.target.notified,
                    type: 'dropdown',
                    selectOptions: yesNoSelectOptions,
                    filterKey: columnFields.notified.name,
                },
            },

            // TODO: localize filters labels
            'meta.term': {
                meta: getFilterData(FilterMatchMode.CONTAINS),
                options: {
                    label: fields.term,
                    type: 'string',
                    filterKey: 'authorizations.meta.term',
                    renderWithColumn: 'meta.term',
                },
            },
            'meta.owner-name': {
                meta: getFilterData(FilterMatchMode.CONTAINS),
                options: {
                    label: fields.seller,
                    type: 'string',
                    filterKey: 'authorizations.meta.owner-name',
                    renderWithColumn: 'meta.owner-name',
                },
            },
            'meta.environment': {
                meta: getFilterData(FilterMatchMode.CONTAINS),
                options: {
                    label: fields.type,
                    type: 'string',
                    filterKey: 'authorizations.meta.environment',
                    renderWithColumn: 'meta.environment',
                },
            },
            'meta.tag-product': {
                meta: getFilterData(FilterMatchMode.CONTAINS),
                options: {
                    label: fields.product,
                    type: 'string',
                    filterKey: 'authorizations.meta.tag-product',
                    renderWithColumn: 'meta.tag-product',
                },
            },
            'meta.notices.0.status': {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: fields.notified,
                    type: 'dropdown',
                    selectOptions: yesNoSelectOptions,
                    filterKey: 'authorizations.meta.notices.0.status',
                    renderWithColumn: 'meta.notices.0.status',
                },
            },
            'meta.url-status': {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    label: fields.status,
                    type: 'dropdown',
                    selectOptions: [
                        {
                            label: 'Online',
                            value: 'online',
                        },
                        {
                            label: 'Offline',
                            value: 'offline',
                        },
                    ],
                    filterKey: 'authorizations.meta.url-status',
                    renderWithColumn: 'meta.url-status',
                },
            },
            'meta.input-date': {
                meta: getFilterData(FilterMatchMode.DATE_IS),
                options: {
                    label: fields.foundIn,
                    type: 'date',
                    filterKey: 'authorizations.meta.input-date',
                    renderWithColumn: 'meta.input-date',
                },
            },
        };

        const visibleFilterFields: CMDataTableFiltersFields<ModelFilters> = {};

        configFilters.forEach((filter) => {
            const matchedFilter = grabValidKeyMatchingUrl<ModelFilters>(
                filter.split(',')
            );
            if (!matchedFilter) return;
            const tagMatchingObj = checkTagFilterMatching(
                matchedFilter,
                localization
            );
            if (tagMatchingObj.matches) {
                visibleFilterFields[matchedFilter] = tagMatchingObj.filter;
                return;
            }
            if (!filterFields[matchedFilter]) return;
            if (matchedFilter in visibleFilterFields) return;
            visibleFilterFields[matchedFilter] = filterFields[matchedFilter];
        });

        return visibleFilterFields;
    }, [
        config,
        localization,
        columnFields,
        yesNoSelectOptions,
        typeSelectOptions,
        onOffSelectOptions,
        safeIpSelectOptions,
    ]);

    const { filterOptions, filterMetaData } =
        useGenerateFilters<ModelFilters>(filterFields);

    return { columnFields, filterOptions, filterMetaData };
};
export default useTargetColumnFields;
