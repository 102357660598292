import { useLocalization } from 'hooks/context/useLocalization';
import { useProject } from 'hooks/context/project/useProject';
import useListOperatorTags from 'hooks/queries/operator/useListOperatorTags';
import { SelectManyItemsProps } from 'components/form/SelectItems/types';
import { ListOperatorEP } from 'services/ether/case-manager/operator/types';
import SelectManyItems from 'components/form/SelectItems/SelectManyItems';

const SelectManyOperatorTags: React.FC<
    SelectManyItemsProps<{ value: string }, ListOperatorEP.Filters.Options>
> = ({ queryOptions, ...props }) => {
    const project = useProject();
    const [localization] = useLocalization();

    const query = useListOperatorTags(
        {
            project_id: project._id,
        },
        {
            enabled: !props.overrideItems,
        }
    );

    return (
        <SelectManyItems
            title={localization.models.operatorTag.plural}
            label={localization.models.operatorTag.plural}
            placeholder={
                localization.components.models.operatorTag.form
                    .selectManyPlaceholder
            }
            optionLabel='value'
            optionValue='value'
            items={query.data?.payload.map((t) => ({ value: t }))}
            itemsLoading={query.isFetching}
            {...props}
        />
    );
};

export default SelectManyOperatorTags;
