import { useProject } from 'hooks/context/project/useProject';
import CMBreadCrumb from 'components/page/CMBreadCrumb';
import { useLocalization } from 'hooks/context/useLocalization';
import GoBackButton from 'components/misc/GoBackButton';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import CacheControl from 'controller/cache/cacheController';
import useTargetColumnFields from 'hooks/columns/models/useTargetColumnFields';
import TargetsDataTable from 'components/datatable/models/target/TargetsDataTable';
import useDetailTargetsUnwindedAuthorization from 'hooks/queries/target/useDetailTargetsUnwindedAuthorization';

const ListTargetsInAuthorizationPending = () => {
    const project = useProject();
    const [localization] = useLocalization();
    const { filterMetaData: targetsFilters } = useTargetColumnFields();

    const { filters, setFilters, pageOptions, setPageOptions, sort, setSort } =
        useInitDataTableState({
            filters: targetsFilters,
            pageOptions: {
                startingRow:
                    CacheControl.UserConfig.get()['paginatorRows']?.targets,
            },
        });

    const targetsQuery = useDetailTargetsUnwindedAuthorization({
        project_id: project._id,
        options: {
            rawFilters: {
                ...filters,
                'authorizations.authorizations_data.status': 'pending',
                'authorizations.accepted|isnull': true,
            },
            sort,
            limit: pageOptions.rows,
            offset: pageOptions.offset,
        },
    });

    return (
        <section>
            <CMBreadCrumb projectLabel={project.name} />
            <GoBackButton />
            <div className='flex gap-2 items-center'>
                <h2>
                    {project.name} -{' '}
                    {
                        localization.components.models.target.badge.status
                            .pendingWithName
                    }
                </h2>
            </div>
            <TargetsDataTable
                loading={targetsQuery.isFetching}
                value={targetsQuery.data?.payload}
                filters={filters}
                setFilters={(e) => setFilters(e)}
                paginatorProps={{
                    page: pageOptions.page,
                    rows: pageOptions.rows,
                    onPageChange: (options) => setPageOptions(options),
                    disableNext:
                        (targetsQuery.data?.payload.length ?? 9999) <
                        pageOptions.rows,
                    onRefresh: targetsQuery.refresh,
                }}
                sortField={sort?.field}
                sortOrder={sort?.order}
                onSort={(e) =>
                    setSort({
                        field: e.sortField,
                        order: e.sortOrder,
                    })
                }
            />
        </section>
    );
};

export default ListTargetsInAuthorizationPending;
