import {
    mapDevFilters,
    parseDataTableFilterMetaForAPI,
} from 'services/ether/utils';
import { DetailSubtagEP, DetailTagEP, ListTagEP } from './types';
import { listBase } from 'services/ether/base';
import _ from 'lodash';

export const tagPirateBrandKey = 'pirate-brand';

export const listTags = ({
    project_id,
    options,
    signal,
}: ListTagEP.Params.Many) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: ListTagEP.Filters.Map = {
        _id: ['_id', devFilters._id],
        category: ['category', devFilters.category],
        model_type: ['model_type', devFilters.model_type],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge(
        {
            project_id,
        },
        options?.rawFilters,
        mappedFilters
    );
    return listBase<Ether.CaseManager.Tag[]>({
        endpoint: '/list-tag',
        handleParams: parseDataTableFilterMetaForAPI,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

const _detailTags = ({
    project_id,
    options,
    signal,
}: DetailTagEP.Params.Restricted) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: DetailTagEP.Filters.Map = {
        _id: ['_id', devFilters._id],
        category: ['category', devFilters.category],
        model_type: ['model_type', devFilters.model_type],
        authorization_config_id: [
            'authorization_config_id',
            devFilters.authorization_config_id,
        ],
        additional_fields: [
            'additional_fields',
            devFilters.additional_fields?.join(','),
        ],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge({}, options?.rawFilters, mappedFilters, {
        project_id,
    });
    return listBase<Ether.CaseManager.Tag.Detailed[]>({
        endpoint: '/detail-tag',
        handleParams: parseDataTableFilterMetaForAPI,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

const _countTags = ({
    project_id,
    options,
    signal,
}: DetailTagEP.Params.Restricted) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: DetailTagEP.Filters.Map = {
        _id: ['_id', devFilters._id],
        category: ['category', devFilters.category],
        model_type: ['model_type', devFilters.model_type],
        authorization_config_id: [
            'authorization_config_id',
            devFilters.authorization_config_id,
        ],
        additional_fields: [
            'additional_fields',
            devFilters.additional_fields?.join(','),
        ],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge({}, options?.rawFilters, mappedFilters, {
        project_id,
    });
    return listBase<number>({
        endpoint: '/count-tag',
        handleParams: parseDataTableFilterMetaForAPI,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

const _detailSubtags = ({
    project_id,
    options,
    signal,
}: DetailSubtagEP.Params.Restricted) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: DetailSubtagEP.Filters.Map = {
        _id: ['_id', devFilters._id],
        category: ['category', devFilters.category],
        model_type: ['model_type', devFilters.model_type],
        authorization_config_id: [
            'authorization_config_id',
            devFilters.authorization_config_id,
        ],
        additional_fields: [
            'additional_fields',
            devFilters.additional_fields?.join(','),
        ],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge({}, options?.rawFilters, mappedFilters, {
        project_id,
    });
    return listBase<Ether.CaseManager.Subtag.Detailed[]>({
        endpoint: '/detail-subtag',
        handleParams: parseDataTableFilterMetaForAPI,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

export const detailManyTags = ({
    project_id,
    options,
    signal,
}: DetailTagEP.Params.Many) => {
    return _detailTags({
        project_id,
        options: {
            ...options,
            devFilters: {
                ...options?.devFilters,
                additional_fields: ['documents_data', 'total_targets'],
            },
        },
        signal,
    });
};

export const detailManySubtags = ({
    project_id,
    options,
    signal,
}: DetailSubtagEP.Params.Many) => {
    return _detailSubtags({
        project_id,
        options,
        signal,
    });
};

export const countTags = ({
    project_id,
    options,
    signal,
}: ListTagEP.Params.Many) => {
    return _countTags({
        project_id,
        options,
        signal,
    });
};