import { ProgressSpinner } from 'primereact/progressspinner';
import renderIsRequired from '../utils/renderIsRequired';

import './style.css';
import { format as formatDate, parseISO } from 'date-fns';

interface IDatepicker {
    label?: string;
    labelStyle?: React.CSSProperties;
    loading?: boolean;
    error?: string | boolean;
    value?: Date | null;
    onChange?(value: Date | null): void;
    name?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    required?: boolean;
    wrapperStyle?: React.CSSProperties;
    type?: 'date' | 'datetime-local' | 'time';
}

export const Datepicker: React.FC<IDatepicker> = ({
    label,
    labelStyle,
    value,
    loading = false,
    error,
    name,
    onChange,
    required,
    type = 'date',
    wrapperStyle,
    ...rest
}) => {
    const parseInputValue = () => {
        if (value === undefined) return undefined;
        if (value === null) return '';
        if (type === 'date') {
            return formatDate(value, `yyyy-MM-dd`);
        }
        if (type === 'datetime-local') {
            return formatDate(value, `yyyy-MM-dd'T'HH:mm`);
        }
        if (type === 'time') {
            return formatDate(value, 'HH:mm');
        }
        return '';
    };

    const onValueChange = (value: string | null) => {
        if (!onChange) return;
        if (!value) {
            onChange(null);
            return;
        }
        if (type === 'date' || type === 'datetime-local') {
            onChange(parseISO(value));
            return;
        }
        if (type === 'time') {
            const [hour, minute] = value.split(':');
            // Create a date today and just set time;
            const date = parseISO(new Date().toISOString());
            date.setHours(Number(hour), Number(minute), 0, 0);
            onChange(date);
            return;
        }
    };

    return (
        <div className='datepicker' style={wrapperStyle}>
            <span>
                <label
                    htmlFor={name}
                    style={{
                        marginRight: '8px',
                        display: label ? 'inherit' : 'none',
                        ...labelStyle,
                    }}
                >
                    {label}
                    {renderIsRequired(required)}
                </label>
                <div className='wrap-content'>
                    <input
                        className={[
                            'ether-datetime',
                            rest.disabled || loading ? 'p-disabled' : null,
                            error ? 'p-invalid' : null,
                        ].join(' ')}
                        type={type}
                        disabled={rest.disabled || loading}
                        value={parseInputValue()}
                        onChange={(e) => onValueChange(e.target.value)}
                        {...rest}
                    />
                    {loading && (
                        <ProgressSpinner
                            style={{
                                width: '20px',
                                height: '20px',
                                margin: '0 0',
                            }}
                            strokeWidth='6'
                            className='custom-spinner'
                        />
                    )}
                </div>
                {error && typeof error === 'string' && (
                    <small className='p-error block input-text-error'>
                        {error}
                    </small>
                )}
            </span>
        </div>
    );
};
