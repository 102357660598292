/**
 * Get all representatives and default representatives from a operation, filtering optional with the authorization flow ID
 */
export function getOperationRepresentatives({
    operation,
    flowId,
}: {
    operation: Ether.CaseManager.Operation;
    flowId: string;
}) {
    const targetRepresentatives =
        operation.app_data?.representatives.filter(
            (r) => !flowId || r.authorization_flow._id === flowId
        ) ?? [];
    const representativeOptions =
        targetRepresentatives.map((r) => r.representative_options).flat() ?? [];
    const defaultValues =
        targetRepresentatives
            .map((r) => r.default_representative_options ?? [])
            .flat() ?? [];
    return {
        representatives: representativeOptions,
        defaultRepresentatives: defaultValues,
    };
}
