import { useMutation } from '@tanstack/react-query';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { getErrorToast } from 'utils/errorHandler';
import { UpdateOperationAuthorizationEP } from 'services/ether/case-manager/operation/types';
import { updateOperationAuthorization } from 'services/ether/case-manager/operation';

const useUpdateOperationAuthorization = (options?: {
    onUpdate?: () => void;
}): {
    mutateUpdateOperationAuthorization: (
        data: UpdateOperationAuthorizationEP.Data
    ) => void;
    isLoading: boolean;
    error: Error | null;
} => {
    const [localization] = useLocalization();
    const toast = useToast();

    const { hardRefresh, softRefresh } = useQueryRefresh();

    const onUpdate = () => {
        softRefresh(['me']);
        hardRefresh(['authorization']);
        toast.show({
            summary:
                localization.endpoints.operation.upsert_operation.updateSuccess,
            severity: 'success',
            life: 10000,
        });
        if (options?.onUpdate) options.onUpdate();
    };

    const mutation = useMutation<
        UpdateOperationAuthorizationEP.Result,
        Error,
        UpdateOperationAuthorizationEP.Data
    >({
        mutationFn: (data) => updateOperationAuthorization(data),
        onSuccess: () => onUpdate(),
        onError: (err) => toast.show(getErrorToast(err.message, localization)),
    });

    return {
        mutateUpdateOperationAuthorization: (data) => mutation.mutate(data),
        error: mutation.error,
        isLoading: mutation.isPending,
    };
};

export default useUpdateOperationAuthorization;
