import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { Column } from 'primereact/column';
import { DateBadge } from 'components/ethercity-primereact';
import { MenuItem } from 'primereact/menuitem';
import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import { Button } from 'primereact/button';
import { useLocalization } from 'hooks/context/useLocalization';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';
import useAuthorizationConfigColumnFields from 'hooks/columns/models/useAuthorizationConfigColumnFields';

type DetailedModel = Ether.CaseManager.AuthorizationConfig.Detailed;

const RowMenu: React.FC<{
    item: DetailedModel;
    // onShowDetail: (authorization: Ether.CaseManager.AuthorizationConfig) => void;
}> = ({ item }) => {
    // const { user, permissions } = useAuth();
    // const navigate = useNavigate();
    // const [localization] = useLocalization();

    const menuRef = useRef<Menu>(null);

    const menuItems: MenuItem[] = [
        // ...(permissions.debugAuthorizations
        //     ? [
        //           {
        //               id: 'debug',
        //               label: 'Detail data',
        //               command: () => onShowDetail(authorization),
        //           },
        //       ]
        //     : []),
    ];

    if (menuItems.length <= 0) return null;

    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

const AuthorizationConfigDataTable: React.FC<
    CMDataTableProps<DetailedModel>
> = ({ ...props }) => {
    const [localization] = useLocalization();

    const { columnFields, filterOptions } =
        useAuthorizationConfigColumnFields();

    return (
        <>
            <CMDataTable
                lazy
                columnConfigName='authorizationConfig'
                emptyMessage={
                    localization.components.models.authorizationConfig.datatable
                        .empty
                }
                filterOptions={filterOptions}
                {...props}
            >
                <Column
                    field='_cm_name_id'
                    body={(data: DetailedModel) => (
                        <NameIdAggregator
                            name={data.name}
                            id={data._id}
                            navigateTo={`/authorization-config/${data._id}`}
                        />
                    )}
                />
                <Column
                    field={columnFields['authorizers_count'].name}
                    header={localization.fields.authorizationConfig.authorizers}
                    body={(data: DetailedModel) => data.authorizer_ids.length}
                />
                <Column
                    field={columnFields['pirate_brands_count'].name}
                    header={
                        localization.models.tag.types['pirate-brand'].plural
                    }
                    body={(data: DetailedModel) => data.pirate_brands.length}
                />
                <Column
                    field={columnFields['documents_count'].name}
                    header={localization.models.document.plural}
                    body={(data: DetailedModel) => data.total_documents}
                />
                <Column
                    field={columnFields['targets_count'].name}
                    header={localization.models.target.plural}
                    body={(data: DetailedModel) => data.total_targets}
                />
                <Column
                    field={columnFields['created_at'].name}
                    header={localization.fields.authorizationConfig.created}
                    body={(data: DetailedModel) => (
                        <DateBadge value={data.created_at} />
                    )}
                />
                <Column
                    field='actions'
                    body={(rowData: DetailedModel) => (
                        <RowMenu item={rowData} />
                    )}
                />
            </CMDataTable>
        </>
    );
};

export default AuthorizationConfigDataTable;
