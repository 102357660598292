import { scan } from "react-scan"; // must be imported before React and React DOM
import ReactDOM from 'react-dom/client';
import App from './App';

import './index.css';

import 'primeicons/primeicons.css';
import './primereact-theme-ether.css';
import 'primereact/resources/primereact.min.css';
import 'config/chartjs/plugins';
import { PrimeReactProvider } from 'primereact/api';
import { StrictMode } from 'react';

scan({
  enabled: window.ENABLE_REACT_SCAN === 'true',
});

const container = document.getElementById('root') as Element;

const root = ReactDOM.createRoot(container);

root.render(
    <StrictMode>
        <PrimeReactProvider value={{ pt: {} }}>
            <App />
        </PrimeReactProvider>
    </StrictMode>
);
