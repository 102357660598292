/**
 * Retrieves the target On/Off valid options with localized labels
 */
const useTargetOnOffSelectOptions = () => {
    return [
        {
            label: 'Online',
            value: 'online',
        },
        {
            label: 'Offline',
            value: 'offline',
        },
    ];
};

export default useTargetOnOffSelectOptions;
