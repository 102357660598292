import useAppConfig from 'hooks/appConfig/useAppConfig';
import { useMemo } from 'react';

/**
 * Get all user available dashboards on the company's config
 * @returns object.dashboards - All available dashboards for the user
 * @returns object.dashboardsPermission - A permission map checking if the user has permission to view specified dash. Quick and easy way to avoid array iteration
 */
const useUserDashboards = () => {
    const config = useAppConfig();

    const userDashboards = useMemo(() => {
        const dashboardsPermissionMap: Partial<
            Record<CaseManagerApp.DashboardConfig.Slug, boolean>
        > = {};
        config?.dashboards?.forEach((d) => {
            dashboardsPermissionMap[d.slug] = true;
        });
        return {
            dashboards: config.dashboards,
            dashboardsPermissionMap,
        };
    }, [config]);

    return userDashboards;
};

export default useUserDashboards;
