import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { Column } from 'primereact/column';
import {
    DataTableExpandedRows,
    DataTableValueArray,
} from 'primereact/datatable';
import { MenuItem } from 'primereact/menuitem';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useLocalization } from 'hooks/context/useLocalization';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';
import { useAuth } from 'hooks/context/useAuth';
import TargetsCompanySummary from 'components/models/Operators/TargetsCompanySummary';
import OperatorsCompanySummary from 'components/models/Operators/OperatorsCompanySummary';
import useCompanyColumnFields from 'hooks/columns/models/useCompanyColumnFields';
import useShowObjectDisplayModal from 'hooks/dialogs/general/useShowObjectDisplayModal';
import useCompanyRoleSelectOptions from 'hooks/selectOptions/company/useCompanyRoleSelectOptions';

type DetailedModel = Ether.CaseManager.Company;

const RowMenu: React.FC<{
    item: DetailedModel;
    onShowDetail: (item: DetailedModel) => void;
    onEdit?: (item: DetailedModel) => void;
}> = ({ item, onEdit, onShowDetail }) => {
    const { permissions } = useAuth();
    const [localization] = useLocalization();

    const menuRef = useRef<Menu>(null);

    const menuItems: MenuItem[] = [
        ...(permissions.upsertCompanies && onEdit
            ? [
                  {
                      id: 'edit',
                      label: localization.components.common.button.edit,
                      command: () => onEdit(item),
                      //   command: () => navigate('edit-company/' + company._id),
                  },
              ]
            : []),
    ];

    if (menuItems.length <= 0) return null;
    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

const CompanyDataTable: React.FC<
    CMDataTableProps<DetailedModel> & {
        onEdit?: (company: DetailedModel) => void;
        onDeleteCompany?: (company: DetailedModel) => void;
        showOperatorCount?: boolean;
        showOperatorValidationCount?: boolean;
        authorizationConfigId?: string;
        blockOrder?: Ether.CaseManager.BlockOrder.Detailed;
        unblockOrder?: Ether.CaseManager.UnblockOrder.Detailed;
        showExpand?: 'operator' | 'target';
    }
> = ({
    onEdit,
    onDeleteCompany,
    showOperatorCount,
    showOperatorValidationCount,
    authorizationConfigId,
    blockOrder,
    unblockOrder,
    showExpand,
    ...props
}) => {
    const [localization] = useLocalization();
    const { permissions } = useAuth();
    const { columnFields, filterOptions } = useCompanyColumnFields();

    const defaultTypeOptions = useCompanyRoleSelectOptions();

    const { dialog: objectDisplayDialog, show: showObjectDisplay } =
        useShowObjectDisplayModal();

    const [expandedRows, setExpandedRows] = useState<
        DataTableExpandedRows | DataTableValueArray | undefined
    >(undefined);

    const datatableLocale = localization.components.models.company.datatable;

    const expandable = showExpand && permissions.viewOperators;

    return (
        <>
            {objectDisplayDialog}
            <CMDataTable
                columnConfigName='company'
                lazy
                emptyMessage={datatableLocale.empty}
                filterOptions={filterOptions}
                expandedRows={showExpand ? expandedRows : undefined}
                onRowToggle={
                    showExpand ? (e) => setExpandedRows(e.data) : undefined
                }
                rowExpansionTemplate={(data: DetailedModel) =>
                    showExpand === 'target' ? (
                        <TargetsCompanySummary
                            companyId={data._id}
                            authorizationConfigId={authorizationConfigId}
                            blockOrder={blockOrder}
                            unblockOrder={unblockOrder}
                        />
                    ) : showExpand === 'operator' ? (
                        <OperatorsCompanySummary
                            companyId={data._id}
                            authorizationConfigId={authorizationConfigId}
                            blockOrderId={blockOrder?._id}
                            unblockOrderId={unblockOrder?._id}
                        />
                    ) : undefined
                }
                {...props}
            >
                {expandable && <Column expander={true} />}
                <Column
                    field='_cm_name_id'
                    body={(data: DetailedModel) => (
                        <NameIdAggregator
                            name={data.fantasy_name}
                            id={data._id}
                        />
                    )}
                />
                {showOperatorCount && (
                    <Column
                        field={columnFields['users_count'].name}
                        header={localization.fields.company.users}
                        body={(data: Ether.CaseManager.Company.Operator) =>
                            data.users_count != null ? data.users_count : '-'
                        }
                    />
                )}
                {showOperatorValidationCount && (
                    <Column
                        field={columnFields['target_progress'].name}
                        header={
                            localization.fields.company.targetValidationCount
                        }
                        body={(data: Ether.CaseManager.Company.Operator) => {
                            const validations = data.company_order_validations;
                            if (!validations) return '-';
                            const locale =
                                localization.components.models.company.views
                                    .targetValidation;
                            return (
                                <div className='flex flex-col gap-1'>
                                    <span>
                                        {locale.validated.replace(
                                            '{count}',
                                            validations.validated_count.toString()
                                        )}
                                    </span>
                                    <span>
                                        {locale.pending.replace(
                                            '{count}',
                                            validations.pending_count.toString()
                                        )}
                                    </span>
                                </div>
                            );
                        }}
                    />
                )}
                <Column
                    field={columnFields['type'].name}
                    header={localization.fields.company.type}
                    body={(data: DetailedModel) =>
                        defaultTypeOptions.find((t) => t.value === data.type)
                            ?.label ?? '-'
                    }
                />
                <Column
                    field={columnFields['cnpj'].name}
                    header={localization.fields.company.cnpj}
                    body={(data: DetailedModel) =>
                        data.cnpj ? data.cnpj : '-'
                    }
                />
                <Column
                    field={columnFields['uf'].name}
                    header={localization.fields.company.uf}
                    body={(data: DetailedModel) =>
                        data.uf ? data.uf.toLocaleUpperCase() : '-'
                    }
                />
                <Column
                    field='actions'
                    body={(data: DetailedModel) => (
                        <RowMenu
                            item={data}
                            onShowDetail={(item) =>
                                showObjectDisplay({
                                    data: item,
                                    header: item.fantasy_name,
                                })
                            }
                        />
                    )}
                />
            </CMDataTable>
        </>
    );
};

export default CompanyDataTable;
