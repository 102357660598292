import { useLocalization } from 'hooks/context/useLocalization';
import { FilterOperator, LocaleOptions } from 'primereact/api';
import { DataTableFilterMeta } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { Chip } from 'primereact/chip';
import { FilterOption, IntermediateFilter, UpdateFilterAction } from './types';
import FilterBoxIDFilter from './components/FilterBoxIDFilter';
import FilterBoxComponent from './components/FilterBoxComponent';
import { format as formatDate } from 'date-fns';
import { useSharedVariables } from 'hooks/context/useSharedVariables';

/**
 * Format a label given the value and possible select values
 */
const formatLabel = (
    value: any,
    selectOptions?: { label: string; value: string }[]
): string => {
    if (value instanceof Date) {
        return formatDate(value, 'yyyy-MM-dd');
    } else if (selectOptions) {
        if (typeof value === 'string') {
            const foundOption = selectOptions.find((o) => o.value === value);
            if (foundOption) return foundOption.label;
        } else if (Array.isArray(value)) {
            const foundOptions = selectOptions.filter((o) =>
                value.includes(o.value)
            );
            if (foundOptions)
                return foundOptions.map((o) => o.label).join(', ');
        }
    }
    if (typeof value === 'string' || typeof value === 'number')
        return value.toString();
    if (Array.isArray(value)) return value.join(', ');
    return 'Filter display error';
};

/**
 * Component responsible for rendering the available DataTable filters. It dynamically checks the filterOptions and generates each element.
 * Both key in filters and filterOptions must exist for the filter to be rendered.
 * @param object.filters - The DataTable filters object which includes all the final filter values
 * @param object.setFilters - React setState action over the filters object
 * @param object.filterOptions - The customizable filter options which tells how each filter should be rendered
 * @param object.hideBorder - Whether the FilterBox border should be hidden
 * @returns
 */
const FilterBox: React.FC<{
    filters: DataTableFilterMeta;
    setFilters: React.Dispatch<React.SetStateAction<DataTableFilterMeta>>;
    filterOptions: { [key in string]?: FilterOption };
    hideBorder?: boolean;
}> = ({ filters, setFilters, filterOptions, hideBorder }) => {
    const [localization, { getPrimereactLocale }] = useLocalization();
    const primereactLocale = getPrimereactLocale();
    const { values, updateValue } = useSharedVariables();

    const syncIntermediateFilters = () => {
        const intermediateFilters: Record<string, IntermediateFilter> = {};
        Object.entries(filters).forEach(([key, filterMeta]) => {
            const filter =
                'constraints' in filterMeta
                    ? filterMeta.constraints[0]
                    : filterMeta;
            if (!filter) return;
            intermediateFilters[key] = {
                key,
                matchMode: filter.matchMode,
                value: filter.value,
            };
        });
        return intermediateFilters;
    };
    const [intermediateFilters, setIntermediateFilters] = useState(
        syncIntermediateFilters
    );
    // TODO: Bad useEffect usage
    useEffect(() => {
        const newIntermediateFilters: Record<string, IntermediateFilter> = {};
        Object.entries(filters).forEach(([key, filterMeta]) => {
            if (key in intermediateFilters) return;
            const filter =
                'constraints' in filterMeta
                    ? filterMeta.constraints[0]
                    : filterMeta;
            if (!filter) return;
            newIntermediateFilters[key] = {
                key,
                matchMode: filter.matchMode,
                value: filter.value,
            };
        });
        if (!Object.keys(newIntermediateFilters).length) return;
        setIntermediateFilters((old) => ({
            ...old,
            ...newIntermediateFilters,
        }));
    }, [filters, intermediateFilters]);

    const updateFilter: UpdateFilterAction = ({
        key,
        ...intermediateFilters
    }) => {
        setIntermediateFilters((old) => ({
            ...old,
            [key]: {
                key,
                ...intermediateFilters,
            },
        }));
        const finalValue =
            intermediateFilters.value === '' ? null : intermediateFilters.value;
        setFilters((old) => ({
            ...old,
            [key]: {
                constraints: [
                    {
                        matchMode: intermediateFilters.matchMode,
                        value: finalValue,
                    },
                ],
                operator: FilterOperator.AND,
            },
        }));
    };

    useEffect(() => {
        const filter = Object.values(intermediateFilters).find(
            (filter) =>
                filter?.key === '_cmapp_tag_authorization:country:dropdown'
        );
        if (!filter) return;
        const selectOptions = filter.selectOptions;
        if (!filter.value) return;
        const rightSideLabel = formatLabel(filter.value, selectOptions);
        if (values.target_similarweb_country !== rightSideLabel)
            updateValue('target_similarweb_country', rightSideLabel);
    }, [intermediateFilters, updateValue, values.target_similarweb_country]);

    if (!filters) return null;

    const filterElements = Object.entries(filterOptions)
        .filter(([key]) => key !== '_cm_name_id')
        .map(([key, options]) => {
            const filterKey = options?.filterKey;
            if (!filterKey) return null;
            const filterItem = intermediateFilters[filterKey];
            if (!filterItem) return null;
            return (
                <FilterBoxComponent
                    key={key}
                    filter={filterItem}
                    options={options}
                    onFilter={(f) => updateFilter(f)}
                />
            );
        })
        .filter((f) => !!f);

    const filterResults = Object.entries(intermediateFilters)
        .filter(([, filter]) => filter?.value != null && filter.value !== '')
        .map(([key, filter]) => {
            const options = Object.values(filterOptions).find(
                (filter) => filter?.filterKey === key
            );
            const leftSideLabel = options?.label;
            const { matchMode, value } = filter;

            const selectOptions = filter.selectOptions
                ? filter.selectOptions
                : options && 'selectOptions' in options
                ? options.selectOptions
                : undefined;

            const rightSideLabel = formatLabel(value, selectOptions);

            const defaultNull =
                options?.type === 'string'
                    ? ''
                    : options?.type === 'multiselect'
                    ? []
                    : null;

            const middleLabel =
                matchMode &&
                matchMode in primereactLocale &&
                options?.type !== 'dropdown' &&
                options?.type !== 'multiselect'
                    ? primereactLocale[matchMode as keyof LocaleOptions]
                    : '';

            const finalLabel =
                key === '_cm_name_id'
                    ? `${middleLabel} ${rightSideLabel}`
                    : !options ||
                      options.type === 'custom' ||
                      ('hideMatchMode' in options && !!options?.hideMatchMode)
                    ? `${leftSideLabel}: ${rightSideLabel}`
                    : `${leftSideLabel}: ${middleLabel} ${rightSideLabel}`;

            return (
                <Chip
                    key={key}
                    label={finalLabel}
                    removable
                    onRemove={() => {
                        if (
                            filter?.key ===
                            '_cmapp_tag_authorization:country:dropdown'
                        )
                            updateValue('target_similarweb_country', 'global');
                        updateFilter({
                            key,
                            value: defaultNull,
                            matchMode,
                        });
                        return true;
                    }}
                />
            );
        });

    const idFilter = intermediateFilters['_cm_name_id'];
    const idFilterOptions = filterOptions['_cm_name_id'];

    const wrapperClassNames = ['p-4', 'rounded-md', 'my-2'];
    if (!hideBorder)
        wrapperClassNames.push(
            'border',
            'border-solid',
            'border-gray-blue-900'
        );

    return (
        <div className={wrapperClassNames.join(' ')}>
            {idFilter && (
                <FilterBoxIDFilter
                    filter={idFilter}
                    placeholder={idFilterOptions?.placeholder}
                    onFilter={(filter) => updateFilter(filter)}
                />
            )}
            {filterElements.length > 0 && (
                <h3>{localization.components.common.datatable.filters}</h3>
            )}
            <div className='flex flex-row gap-1'>{filterElements}</div>
            {filterResults.length > 0 && (
                <h3>
                    {localization.components.common.datatable.appliedFilters}
                </h3>
            )}
            <div className='flex flex-row gap-1'>{filterResults}</div>
        </div>
    );
};

export default FilterBox;
