import {
    DataTableFilterMeta,
    DataTableOperatorFilterMetaData,
    SortOrder,
} from 'primereact/datatable';
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

type Options = {
    pageOptions?: {
        startingPage?: number;
        startingRow?: number;
    };
    sort?: {
        field: string;
        order: NonNullable<SortOrder>;
    };
    filters?: {
        [field: string]: DataTableOperatorFilterMetaData;
    };
};

/**
 * Hook to initialize various options for the datatable, such as pages, filters and sort options. Each option is configurable
 * @param options.pageOptions.startingPage - Starting page value, defaults to 1
 * @param options.pageOptions.startingRow - Starting rows count, defaults to 50
 * @param options.sort.field - Starting field to be sorted. Must be supplied alongside sort.order
 * @param options.sort.order - Starting order operation. Must be supplied alongside sort.field
 * @param options.filters - All filters that will be enabled. Each filter may be initialized with the getFilterData function for ease of use
 * @returns object.pageOptions - Page options state including page, row, limit and offset values
 * @returns object.setPageOptions - State callback to new page values
 * @returns object.sort - Sort state including field and order
 * @returns object.setSort - State callback to set new sort values
 * @returns object.filters - Filters state, includes non applied filters and applied filters
 * @returns object.setFilters - State callback to set filters
 * @returns object.isFiltersApplied - If any of the filters are applied (That is, any of the valid filters has a non-null value)
 */
const useInitDataTableState = (options?: Options) => {
    const [pageOptions, setPageOptions] = useState<{
        page: number;
        rows: number;
    }>({
        page: options?.pageOptions?.startingPage ?? 1,
        rows: options?.pageOptions?.startingRow ?? 50,
    });

    const [sort, setSort] = useState<{
        field: string;
        order: SortOrder | undefined;
    } | null>(options?.sort ?? null);

    const [stateFilters, setStateFilters] = useState<DataTableFilterMeta>(
        options?.filters ?? {}
    );

    // TODO: Bad useEffect usage
    useEffect(() => {
        if (!options?.filters) return;
        const newFilters: DataTableFilterMeta = {};
        Object.entries(options.filters).forEach(([key, value]) => {
            if (key in stateFilters) return;
            newFilters[key] = value;
        });
        if (!Object.keys(newFilters).length) return;
        setStateFilters((old) => ({
            ...old,
            ...newFilters,
        }));
    }, [options?.filters, stateFilters]);

    const onFiltersApply: Dispatch<SetStateAction<DataTableFilterMeta>> =
        useCallback((e) => {
            setPageOptions((old) => ({
                ...old,
                page: 1,
            }));
            setStateFilters(e);
        }, []);

    const filtersApplied = useMemo(
        () =>
            Object.values(stateFilters).some((filter) => {
                if ('operator' in filter)
                    return filter.constraints[0]?.value != null;
                return filter.value != null;
            }),
        [stateFilters]
    );

    return {
        pageOptions: {
            ...pageOptions,
            limit: pageOptions.rows,
            offset: (pageOptions.page - 1) * pageOptions.rows,
        },
        setPageOptions,
        sort,
        setSort,
        filters: stateFilters,
        setFilters: onFiltersApply,
        isFiltersApplied: filtersApplied,
    };
};

export default useInitDataTableState;
