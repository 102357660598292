import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import { useLocalization } from 'hooks/context/useLocalization';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';
import { MenuItem } from 'primereact/menuitem';
import { Button } from 'primereact/button';
import { DateBadge } from 'components/ethercity-primereact';
import { useAuth } from 'hooks/context/useAuth';
import useShowOperationStats from 'hooks/dialogs/operation/useShowOperationStats';
import useViewOperationDetailDialog from 'hooks/models/operation/useViewOperationDetailDialog';
import useOperationColumnFields from 'hooks/columns/models/useOperationColumnFields';
import OperationStatusBadge from 'components/models/Operation/OperationStatusBadge';
import { getUserDisplayName } from 'utils/models/user';
import OperationFlowTypeBadge from 'components/models/Operation/OperationFlowTypeBadge';
import useUpsertOperationDialog from 'hooks/models/operation/useUpsertOperationDialog';

type DetailedModel = Ether.CaseManager.Operation.Detailed;

const RowMenu: React.FC<{
    item: DetailedModel;
    onShowStats?: (item: { id: string; name: string }) => void;
    onShowEdit?: (item: Ether.CaseManager.Operation) => void;
}> = ({ item, onShowStats, onShowEdit }) => {
    const { user, permissions } = useAuth();
    const [localization] = useLocalization();
    const menuRef = useRef<Menu>(null);

    const menuItems: MenuItem[] = [
        ...(permissions.getOperationStats && onShowStats
            ? [
                  {
                      icon: 'pi pi-chart-bar',
                      label: localization.components.common.stats.header,
                      command: () =>
                          onShowStats({
                              id: item._id,
                              name: item.name,
                          }),
                  },
              ]
            : []),
        ...(onShowEdit && permissions.insertOperation && user.role === 'admin'
            ? [
                  {
                      icon: 'pi pi-pencil',
                      label: 'Edit',
                      command: () => onShowEdit(item),
                  },
              ]
            : []),
    ];
    if (menuItems.length <= 0) return null;
    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

const OperationDataTable: React.FC<CMDataTableProps<DetailedModel>> = ({
    ...props
}) => {
    const [localization] = useLocalization();

    const { dialog: statsDialog, show: showStats } = useShowOperationStats();
    const { dialog: detailDialog, show: showDetail } =
        useViewOperationDetailDialog();
    const { dialog: upsertDialog, showEdit } = useUpsertOperationDialog();
    const { columnFields, filterOptions } = useOperationColumnFields();

    const datatableLocalization =
        localization.components.models.operation.datatable;
    const fieldsLocalization = localization.fields.operation;

    return (
        <>
            {statsDialog}
            {detailDialog}
            {upsertDialog}
            <CMDataTable
                columnConfigName='operation'
                lazy
                emptyMessage={datatableLocalization.empty}
                filterOptions={filterOptions}
                {...props}
            >
                <Column
                    field={columnFields['_cm_name_id'].name}
                    body={(data: DetailedModel) => (
                        <NameIdAggregator
                            name={data.name}
                            id={data._id}
                            onClick={() => showDetail(data._id)}
                        />
                    )}
                />
                <Column
                    field={columnFields['status'].name}
                    header={fieldsLocalization.status}
                    body={(data: DetailedModel) => (
                        <OperationStatusBadge item={data} />
                    )}
                />
                <Column
                    field={columnFields['flow_type'].name}
                    header={localization.models.authorizationFlow.singular}
                    body={(data: DetailedModel) => (
                        <OperationFlowTypeBadge item={data} />
                    )}
                />
                <Column
                    field={columnFields['block_orders_count'].name}
                    header={localization.models.blockOrder.plural}
                />
                <Column
                    field={columnFields['authorizations_count'].name}
                    header={localization.models.authorization.plural}
                />
                <Column
                    field={columnFields['targets_count'].name}
                    header={localization.models.target.plural}
                    body={(data: DetailedModel) => {
                        return (
                            <div className='flex flex-col gap-1'>
                                <strong>
                                    {localization.common.total}:{' '}
                                    {data.targets_count}
                                </strong>
                                <span className='text-white-fade-400'>
                                    {fieldsLocalization.ipsCount}:{' '}
                                    {data.ips_count}
                                </span>
                                <span className='text-white-fade-400'>
                                    {fieldsLocalization.domainsCount}:{' '}
                                    {data.domains_count}
                                </span>
                            </div>
                        );
                    }}
                />
                <Column
                    field={columnFields['total_unique_countries'].name}
                    header={fieldsLocalization.countries}
                />
                <Column
                    field={columnFields['total_unique_hosting_companies'].name}
                    header={fieldsLocalization.hostingCompanies}
                />
                <Column
                    field={columnFields['created_by'].name}
                    header={fieldsLocalization.createdBy}
                    body={(data: DetailedModel) =>
                        getUserDisplayName(data.created_by_data?.[0])
                    }
                />
                <Column
                    field={columnFields['created_at'].name}
                    header={fieldsLocalization.created}
                    sortable
                    body={(data: DetailedModel) => (
                        <DateBadge value={data.created_at} />
                    )}
                />
                <Column
                    field='actions'
                    body={(data: DetailedModel) => (
                        <RowMenu
                            item={data}
                            onShowStats={showStats}
                            onShowEdit={showEdit}
                        />
                    )}
                />
            </CMDataTable>
        </>
    );
};

export default OperationDataTable;
