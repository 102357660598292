import { useLocalization } from 'hooks/context/useLocalization';
import { Button } from 'primereact/button';
import { UpsertCompanyFormProps } from './types';
import { useState } from 'react';
import { useProject } from 'hooks/context/project/useProject';
import { Dropdown, InputText } from 'components/ethercity-primereact';
import CMFileUpload, { CMFileType } from 'components/form/CMFileUpload';
import { ObjectId } from 'bson';
import { DropdownProps } from 'primereact/dropdown';
import useGoBack from 'hooks/helpers/useGoBack';
import InputEmail from 'components/form/InputEmail';
import InputPhone from 'components/form/InputPhone';
import InputCEP from 'components/form/InputCEP';
import useUpsertCompany from 'hooks/mutations/company/useUpsertCompany';
import { UpsertCompanyEP } from 'services/ether/case-manager/company/types';
import isValidEmail from 'utils/isValidEmail';
import useCompanyRoleSelectOptions from 'hooks/selectOptions/company/useCompanyRoleSelectOptions';

type FormItems = {
    _id: string | null;
    social_reason: string;
    fantasy_name: string;
    type: string | null;
    status: 'active' | 'inactive' | null;
    cnpj: string;
    municipal_registration: string;
    state_registration: string;
    address: string;
    number: string;
    complement: string;
    district: string;
    cep: string | null;
    city: string;
    uf: string;
    phone_1: string | null;
    phone_2: string | null;
    email: string;
    financial_responsible_name: string;
    financial_responsible_phone: string | null;
    financial_responsible_email: string;
    registration_documents: (
        | (CaseManagerApp.LocalFile.BaseDetails & {
              file: File;
          })
        | Ether.CaseManager.CompanyFile
    )[];
};

type ValidFormItems = FormItems & {
    type: string;
    status: 'active' | 'inactive';
    phone_1: string;
    financial_responsible_phone: string;
    cep: string;
};

const ActiveStatusDropdown: React.FC<
    Omit<DropdownProps, 'options'> & { label: string; required?: boolean }
> = (props) => {
    const [localization] = useLocalization();

    return (
        <Dropdown
            {...props}
            options={[
                { label: localization.common.active, value: 'active' },
                { label: localization.common.inactive, value: 'inactive' },
            ]}
        />
    );
};

const UpsertCompanyForm: React.FC<UpsertCompanyFormProps> = ({
    companyToEdit,
}) => {
    const [localization] = useLocalization();
    const project = useProject();
    const goBack = useGoBack();

    const isEdit = !!companyToEdit;

    const { insertCompany, updateCompany, isLoading } = useUpsertCompany({
        onInsert: goBack,
    });

    const [formData, setFormData] = useState<FormItems>({
        _id: companyToEdit?._id ?? null,
        social_reason: companyToEdit?.social_reason ?? '',
        fantasy_name: companyToEdit?.fantasy_name ?? '',
        type: companyToEdit?.type ?? null,
        status: companyToEdit?.status ?? null,
        cnpj: companyToEdit?.cnpj ?? '',
        municipal_registration: companyToEdit?.municipal_registration ?? '',
        state_registration: companyToEdit?.state_registration ?? '',
        address: companyToEdit?.address ?? '',
        number: companyToEdit?.number ?? '',
        complement: companyToEdit?.complement ?? '',
        district: companyToEdit?.district ?? '',
        cep: companyToEdit?.cep ?? null,
        city: companyToEdit?.city ?? '',
        uf: companyToEdit?.uf ?? '',
        phone_1: companyToEdit?.phone_1 ?? null,
        phone_2: companyToEdit?.phone_2 ?? null,
        email: companyToEdit?.email ?? '',
        financial_responsible_name:
            companyToEdit?.financial_responsible?.name ?? '',
        financial_responsible_phone:
            companyToEdit?.financial_responsible?.phone ?? null,
        financial_responsible_email:
            companyToEdit?.financial_responsible?.email ?? '',
        registration_documents: companyToEdit?.registration_documents
            ? Object.values(companyToEdit.registration_documents)
            : [],
    });

    const validateFormData = () => {
        if (!formData.type || !formData.status) return;
        if (formData.social_reason === '') return;
        if (formData.fantasy_name === '') return;
        if (formData.cnpj === '') return;
        if (formData.municipal_registration === '') return;
        if (formData.state_registration === '') return;
        if (formData.address === '') return;
        if (formData.number === '') return;
        if (formData.district === '') return;
        if (!formData.cep || formData.cep.includes('_')) return;
        if (formData.city === '') return;
        if (formData.uf === '') return;
        if (!formData.phone_1 || formData.phone_1.includes('_')) return;
        if (formData.phone_2?.includes('_')) return;
        if (formData.email === '' || !isValidEmail(formData.email)) return;
        if (formData.financial_responsible_name === '') return;
        if (
            !formData.financial_responsible_phone ||
            formData.financial_responsible_phone.includes('_')
        )
            return;
        if (
            formData.financial_responsible_email === '' ||
            !isValidEmail(formData.financial_responsible_email)
        )
            return;
        return formData as ValidFormItems;
    };

    const handleUpsert = () => {
        const validatedForm = validateFormData();
        if (!validatedForm) return;

        const documents: UpsertCompanyEP.UpsertData['registration_documents'] =
            {};

        validatedForm.registration_documents.forEach((data, index) => {
            const file =
                data.file instanceof File
                    ? data.file
                    : (data as Ether.CaseManager.CompanyFile);
            documents[`file${index}_identifier`] = file;
        });

        const data = {
            project_id: project._id,
            social_reason: validatedForm.social_reason,
            fantasy_name: validatedForm.fantasy_name,
            type: validatedForm.type,
            status: validatedForm.status,
            cnpj: validatedForm.cnpj,
            municipal_registration: validatedForm.municipal_registration,
            state_registration: validatedForm.state_registration,
            address: validatedForm.address,
            number: validatedForm.number,
            complement: validatedForm.complement,
            district: validatedForm.district,
            cep: validatedForm.cep,
            city: validatedForm.city,
            uf: validatedForm.uf,
            phone_1: validatedForm.phone_1,
            phone_2: validatedForm.phone_2,
            email: validatedForm.email,
            financial_responsible: {
                name: validatedForm.financial_responsible_name,
                phone: validatedForm.financial_responsible_phone,
                email: validatedForm.financial_responsible_email,
            },
            registration_documents: documents,
        };

        if (isEdit) {
            updateCompany({
                ...data,
                _id: companyToEdit._id,
            });
        } else {
            insertCompany(data);
        }
    };

    const locale = localization.components.models.company.views.insert;
    const fieldsNames = {
        ...localization.fields.company,
        ...locale.fields,
    };

    const isFormDataOk = !!validateFormData();
    const isFormDisabled = isLoading;

    const companyRoleSelectOptions = useCompanyRoleSelectOptions();

    return (
        <section>
            <h1>{isEdit ? locale.titleEdit : locale.titleCreate}</h1>
            {!isEdit && <p>{locale.description}</p>}
            <div className='grid grid-cols-4 gap-4'>
                <InputText
                    label={fieldsNames.socialReason}
                    value={formData.social_reason}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            social_reason: e.target.value,
                        }))
                    }
                    required
                    disabled={isFormDisabled}
                />
                <InputText
                    label={fieldsNames.fantasyName}
                    value={formData.fantasy_name}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            fantasy_name: e.target.value,
                        }))
                    }
                    required
                    disabled={isFormDisabled}
                />
                <Dropdown
                    label={fieldsNames.type}
                    value={formData.type}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            type: e.target.value,
                        }))
                    }
                    options={companyRoleSelectOptions}
                    className='w-full'
                    required
                    disabled={isFormDisabled}
                />
                <ActiveStatusDropdown
                    label={fieldsNames.status}
                    value={formData.status}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            status: e.target.value,
                        }))
                    }
                    className='w-full'
                    required
                    disabled={isFormDisabled}
                />
                <InputText
                    label={fieldsNames.cnpj}
                    value={formData.cnpj}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            cnpj: e.target.value,
                        }))
                    }
                    required
                    disabled={isFormDisabled}
                />
                <InputText
                    label={fieldsNames.municipalRegistration}
                    value={formData.municipal_registration}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            municipal_registration: e.target.value,
                        }))
                    }
                    required
                    disabled={isFormDisabled}
                />
                <InputText
                    label={fieldsNames.stateRegistration}
                    value={formData.state_registration}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            state_registration: e.target.value,
                        }))
                    }
                    required
                    disabled={isFormDisabled}
                />
                <span />
                <InputText
                    label={fieldsNames.address}
                    value={formData.address}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            address: e.target.value,
                        }))
                    }
                    required
                    disabled={isFormDisabled}
                />
                <InputText
                    label={fieldsNames.number}
                    value={formData.number}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            number: e.target.value,
                        }))
                    }
                    required
                    disabled={isFormDisabled}
                />
                <InputText
                    label={fieldsNames.complement}
                    value={formData.complement}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            complement: e.target.value,
                        }))
                    }
                    disabled={isFormDisabled}
                />
                <InputText
                    label={fieldsNames.district}
                    value={formData.district}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            district: e.target.value,
                        }))
                    }
                    required
                    disabled={isFormDisabled}
                />
                <InputCEP
                    label={fieldsNames.cep}
                    value={formData.cep ?? undefined}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            cep: e.target.value ?? null,
                        }))
                    }
                    required
                    disabled={isFormDisabled}
                />
                <InputText
                    label={fieldsNames.city}
                    value={formData.city}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            city: e.target.value,
                        }))
                    }
                    required
                    disabled={isFormDisabled}
                />
                <InputText
                    label={fieldsNames.uf}
                    value={formData.uf}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            uf: e.target.value,
                        }))
                    }
                    required
                    disabled={isFormDisabled}
                />
                <span />
                <InputPhone
                    label={fieldsNames.phone1}
                    value={formData.phone_1 ?? undefined}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            phone_1: e.target.value ?? null,
                        }))
                    }
                    required
                    disabled={isFormDisabled}
                />
                <InputPhone
                    label={fieldsNames.phone2}
                    value={formData.phone_2 ?? undefined}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            phone_2: e.target.value ?? null,
                        }))
                    }
                    disabled={isFormDisabled}
                />
                <InputEmail
                    label={fieldsNames.email}
                    value={formData.email}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            email: e.target.value,
                        }))
                    }
                    required
                    disabled={isFormDisabled}
                />
                <h2 className='col-span-4'>{fieldsNames.documents}</h2>
                <div className='col-span-2'>
                    <CMFileUpload
                        multiple
                        customUpload
                        onSelect={(e) => {
                            const newFiles = e.files.map((f) => ({
                                custom_identifier: new ObjectId().toHexString(),
                                meta: {
                                    name: f.name,
                                    size: f.size,
                                },
                                file: f,
                            }));
                            setFormData((old) => ({
                                ...old,
                                registration_documents: [
                                    ...old.registration_documents,
                                    ...newFiles,
                                ],
                            }));
                        }}
                        removeFile={(file) =>
                            setFormData((old) => {
                                const currentFiles =
                                    old.registration_documents.filter(
                                        (f) =>
                                            file.custom_identifier !==
                                            ('custom_identifier' in f
                                                ? f.custom_identifier
                                                : f.file.md5)
                                    );
                                return {
                                    ...old,
                                    registration_documents: currentFiles,
                                };
                            })
                        }
                        value={
                            formData.registration_documents.map((f, index) => {
                                if ('custom_identifier' in f) {
                                    return {
                                        meta: {
                                            name: f.file.name,
                                            size: f.file.size,
                                        },
                                        custom_identifier: f.custom_identifier,
                                        _id: f.custom_identifier,
                                        status: 'done',
                                    };
                                }
                                return {
                                    _id: f.file.md5,
                                    custom_identifier: index + f.file.md5,
                                    meta: {
                                        name: f.filename,
                                        size: f.size,
                                    },
                                    status: 'done',
                                };
                            }) as CMFileType[]
                        }
                        disabled={isFormDisabled}
                    />
                </div>
                <span className='col-span-2' />
                <h2 className='col-span-4'>
                    {locale.financialResponsibleTitle}
                </h2>
                <InputText
                    label={fieldsNames.financialResponsibleName}
                    value={formData.financial_responsible_name}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            financial_responsible_name: e.target.value,
                        }))
                    }
                    required
                    disabled={isFormDisabled}
                />
                <InputPhone
                    label={fieldsNames.financialResponsiblePhone}
                    value={formData.financial_responsible_phone ?? undefined}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            financial_responsible_phone: e.target.value ?? null,
                        }))
                    }
                    required
                    disabled={isFormDisabled}
                />
                <InputEmail
                    label={fieldsNames.financialResponsibleEmail}
                    value={formData.financial_responsible_email}
                    onChange={(e) =>
                        setFormData((old) => ({
                            ...old,
                            financial_responsible_email: e.target.value,
                        }))
                    }
                    required
                    disabled={isFormDisabled}
                />
            </div>
            <div className='flex flex-row align-center justify-center gap-4 mt-8'>
                <Button
                    size='large'
                    onClick={goBack}
                    label={localization.components.common.button.cancel}
                    disabled={isFormDisabled}
                />
                <Button
                    size='large'
                    outlined
                    label={localization.components.common.button.save}
                    loading={isFormDisabled}
                    disabled={!isFormDataOk}
                    onClick={handleUpsert}
                />
            </div>
        </section>
    );
};

export default UpsertCompanyForm;
