import LocalizedObjectDisplayModal from 'components/views/LocalizedObjectDisplayModal';
import { useMemo, useState } from 'react';

const useShowObjectDisplayModal = () => {
    const [options, setOptions] = useState<null | {
        header: string;
        data: any;
        sortKeys?: boolean
    }>(null);

    const hide = () => setOptions(null);
    const show = (o: NonNullable<typeof options>) => setOptions(o);

    const dialog = useMemo(
        () =>
            options && (
                <LocalizedObjectDisplayModal
                    visible
                    onHide={hide}
                    header={options.header}
                    displayData={options.data}
                    sortKeys={options.sortKeys ?? false}
                />
            ),
        [options]
    );

    return {
        dialog,
        show,
    };
};

export default useShowObjectDisplayModal;
