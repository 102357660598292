import { useProjectMaybe } from 'hooks/context/project/useProject';
import { useAuth } from 'hooks/context/useAuth';
import _ from 'lodash';
import { useMemo } from 'react';

/**
 * Gets the currently available app configuration for the current project, company and user's role
 * Prioritizes the user's company, then its project
 */
const useAppConfig = () => {
    const project = useProjectMaybe();
    const { user } = useAuth();

    const company = user.relatedCompany;

    const appConfig: Ether.CaseManager.ClientAppConfig = useMemo(() => {
        const currentAppConfig: Ether.CaseManager.ClientAppConfig = {
            navbar: [
                'authorizations',
                'targets',
                'block-orders',
                'unblock-orders',
                'operators',
                'operations',
                '@dashboards',
                '@files',
                '@files:documents',
                '@files:evidences',
            ],
            usermenu: [
                'changeproject',
                'adminpanel',
                'block-checker',
                'support',
                'switch-language',
            ],
            menu_names: {
                authorizations: 'models.authorization.plural',
                'authorization-configs' : 'models.authorizationConfig.plural',
                targets: 'models.target.plural',
                'authorization-targets': 'models.target.plural',
                'authorization-targets-pending':
                    'components.models.target.badge.status.pendingWithName',
                tags: 'models.tag.plural',
                'block-orders': 'models.blockOrder.plural',
                'unblock-orders': 'models.unblockOrder.plural',
                operators: 'models.operator.plural',
                operations: 'models.operation.plural',
                documents: 'models.document.plural',
                evidences: 'models.evidence.plural',
                '@dashboards': 'models.dashboard.plural',
                '@files': 'models.file.plural',
            },
        };
        const defaultObjs: Ether.CaseManager.ClientAppConfig = {
            authorization: {},
            block_order: {},
            dashboards: [],
            home_views: [],
            navbar: [],
            tag: {},
            target: {},
            usermenu: [],
            menu_names: currentAppConfig.menu_names,
        };

        const serverConfig = _.merge(
            {},
            project?.app_config,
            company?.app_config
        );

        Object.entries(serverConfig).forEach(([anyKey, value]) => {
            try {
                const key = anyKey as keyof Ether.CaseManager.ClientAppConfig;
            if (!value) return;
            if (
                Array.isArray(value) &&
                typeof value[0] == 'object' &&
                'roles' in value[0]
            ) {
                const targetRole = value.find(
                    (internalRoleValue) =>
                        internalRoleValue.roles === null ||
                        internalRoleValue.roles.includes(user.role)
                );
                if (!targetRole) return;
                currentAppConfig[key] = targetRole.value;
                return;
            }
            // If its rolesAppend, it will append each matching role (or nullish) to the array
            // If it's an array, it will append to it
            // If it's an object, it will extend to it
            if (
                Array.isArray(value) &&
                typeof value[0] == 'object' &&
                'rolesAppend' in value[0]
            ) {
                const validRoles = value.filter(
                    (internalRoleValue) =>
                        internalRoleValue.rolesAppend === null ||
                        internalRoleValue.rolesAppend.includes(user.role)
                );
                if (!validRoles.length) return;
                validRoles.forEach((item: any) => {
                    if (!currentAppConfig[key])
                        currentAppConfig[key] = defaultObjs[key] as any;
                    const currentItem = currentAppConfig[key];
                    if (Array.isArray(currentItem)) currentItem?.push(item.value);
                    currentAppConfig[key] = {
                        ...currentAppConfig[key],
                        ...item.value,
                    };
                });
                return;
            }
            if (typeof value === 'object' && !Array.isArray(value))
                currentAppConfig[key] = {
                    ...currentAppConfig[key],
                    ...value,
                } as any;
            else currentAppConfig[key] = value as any;
            } catch (err) {
                console.error('Error parsing useAppConfig')
                console.error(err);
            }
        });

        return currentAppConfig;
    }, [project, company, user.role]);

    return appConfig;
};

export default useAppConfig;
