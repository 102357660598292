import { useLocalization } from "hooks/context/useLocalization";
import LabelValuePair from "../LabelValuePair";
import { DateBadge } from "components/ethercity-primereact";
import { ModelEntriesData } from "./type";
import { Button } from "primereact/button";

/**
 * Component to display related model entries (such as authorizations, block orders, etc) as a organized list with link reference. 
 * This is mostly used for the target history but can also be used in other places.
 * @param object.data - Each model data in a array
 * @param object.header - The title to display in the component
 * @param object.route - What the model is referencing, so the link works properly when clicking on the related name (redirects to the page ID)
 * @returns ModelEntries component
 */
const ModelEntries: React.FC<{
    data: ModelEntriesData[];
    header?: string | JSX.Element;
    route: 'authorizations' | 'block-orders' | 'unblock-orders';
}> = ({ header, data, route }) => {
    const [localization] = useLocalization();
    const thisLocale = localization.components.views.modelEntries;
    const entries = data.map((entry) => {
        const startDate =
            entry.order_relation_data?.start_date ?? entry.start_date;
        return <section
            key={entry._id}
            className='ml-8 mr-4 p-4 border border-gray-blue-300 border-solid rounded-md'
        >
            <div className='flex flex-row gap-2 items-center mb-4'>
                <b>{entry.name}</b>
                {
                    <a
                        href={'/' + route + '/' + entry._id}
                        rel='noreferrer noopener'
                        target='_blank'
                    >
                        <Button icon='pi pi-external-link' size='small' />
                    </a>
                }
            </div>
            <div className='grid grid-cols-4 gap-2'>
                <LabelValuePair
                    label={thisLocale.fields.created}
                    value={<DateBadge value={entry.created_at} />}
                />
                <LabelValuePair
                    label={thisLocale.fields.createdBy}
                    value={entry.responsible}
                    className='col-span-3'
                />
                <LabelValuePair
                    label={thisLocale.fields.registeredAt}
                    value={
                        entry.registered_at ? (
                            <DateBadge value={entry.registered_at} />
                        ) : (
                            '-'
                        )
                    }
                />
                {entry.authorization_flow_data && (
                    <LabelValuePair
                        label={localization.models.authorizationFlow.singular}
                        className='col-span-3'
                        value={entry.authorization_flow_data.name}
                    />
                )}
                {entry.authorization_config_data && (
                    <LabelValuePair
                        className='col-span-4'
                        label={localization.models.authorizationConfig.singular}
                        value={entry.authorization_config_data.name}
                    />
                )}
                {entry.authorization_configs_data && (
                    <LabelValuePair
                        className='col-span-4'
                        label={localization.models.authorizationConfig.plural}
                        value={entry.authorization_configs_data
                            .map((c) => c.name)
                            .join(', ')}
                    />
                )}
                {startDate && (
                    <LabelValuePair
                        label={thisLocale.fields.startDate}
                        value={<DateBadge value={startDate} />}
                    />
                )}
            </div>
        </section>;
    });

    return (
        <div key={route} className='flex flex-col gap-2'>
            <h2>{header}</h2>
            <div className='flex flex-col gap-10'>{entries}</div>
        </div>
    );
};

export default ModelEntries