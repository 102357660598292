import { useLocalization } from 'hooks/context/useLocalization';
import { Badge, BadgeProps } from 'primereact/badge';

const BlockOrderNotificationStatusBadge: React.FC<{
    blockOrder: Ether.CaseManager.BlockOrder;
    badgeProps?: BadgeProps;
}> = ({ blockOrder, badgeProps }) => {
    const [localization] = useLocalization();

    const value = blockOrder.notification?.status;
    const locale =
        localization.components.models.blockOrder.badge.notificationStatus;
    if (!value) return '-';
    badgeProps = {
        ...badgeProps,
        severity: 'info',
        value: value.toUpperCase(),
    };
    switch (value) {
        case 'error':
            badgeProps.value = locale.error.toLocaleUpperCase();
            badgeProps.severity = 'danger';
            break;
        case 'pending':
            badgeProps.value = locale.pending.toLocaleUpperCase();
            badgeProps.severity = 'warning';
            break;
        case 'sent':
            badgeProps.value = locale.sent.toLocaleUpperCase();
            break;
    }
    return <Badge {...badgeProps} />;
};

export default BlockOrderNotificationStatusBadge;
