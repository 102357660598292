import { DataTable } from 'primereact/datatable';
import { CMDataTableBaseProps } from './types';
import { Children, useEffect, useMemo, useRef, useState } from 'react';
import CacheControl from 'controller/cache/cacheController';
import { useLocalization } from 'hooks/context/useLocalization';
import CMPaginator from '../CMPaginator';
import { MultiSelect } from 'primereact/multiselect';
import _ from 'lodash';
import FilterBox from '../FilterBox';
import { Column } from 'primereact/column';
import { FilterOption } from '../FilterBox/types';
import { useProject } from 'hooks/context/project/useProject';

const CMDataTable: React.FC<CMDataTableBaseProps> = ({
    children,
    columnConfigName,
    paginatorProps,
    filterOptions,
    setFilters,
    meta,
    hideTable,
    ...rest
}) => {
    const [localization] = useLocalization();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const dataTableRef = useRef<DataTable<any[]>>(null);
    const project = useProject();

    const columnsConfig = useMemo(
        () =>
            CacheControl.UserConfig.get().columnsVisibility?.[project._id]?.[
                columnConfigName
            ] ?? {},
        [columnConfigName, project._id]
    );

    const [visibleColumnsSync, setVisibleColumnsSync] =
        useState<Record<string, boolean>>(columnsConfig);

    const { onPageChange } = paginatorProps ?? {};

    const [paginatorRows, setPaginatorRows] = useState(() => {
        const config = CacheControl.UserConfig.get();
        const count = config.paginatorRows[columnConfigName];
        const paginatorRows = count ?? 50;
        return paginatorRows;
    });
    useEffect(() => {
        if (!onPageChange) return;
        onPageChange({
            page: 1,
            rows: paginatorRows,
        });
    }, [onPageChange, paginatorRows]);

    const [scrollWidth, setScrollWidth] = useState(0);

    if (!Array.isArray(children)) children = [children];

    const {
        filteredChildren,
        existingColumns,
        keyOrders,
        validOptions,
        visibleColumns,
    } = useMemo(() => {
        let index = 0;
        let keyOrders: { [key: string]: number } = {};
        const existingColumns: Record<string, boolean> = {};
        const validOptions = [] as { value: string; label: string }[];
        const visibleColumns: Record<string, boolean> = {};
        const filteredChildren = Children.toArray(children)
            .filter((child) => {
                if (typeof child !== 'object') return true;
                if (!('type' in child)) return true;
                // this exists, react is just too shy to tell
                const displayName = (child.type as any)?.displayName as string;
                if (displayName !== 'Column') return true;
                const { field, header } = child.props;
                existingColumns[field] = true;
                if (!header || header === '' || typeof header !== 'string')
                    return true;
                validOptions.push({
                    value: field,
                    label: header,
                });
                const visible =
                    field in visibleColumnsSync
                        ? visibleColumnsSync[field] ?? true
                        : field in columnsConfig
                        ? columnsConfig[field as keyof typeof columnsConfig]
                        : true;
                visibleColumns[field] = visible;
                keyOrders[field] = index;
                index += 1;
                return visible;
            })
            .map((child, index) => {
                const column = child as any as Column;
                const props = { ...column.props };
                return <Column key={index + (props.field ?? '')} {...props} />;
            });
        return {
            filteredChildren,
            keyOrders,
            existingColumns,
            validOptions,
            visibleColumns,
        };
    }, [children, columnsConfig, visibleColumnsSync]);

    // We filter out the filters which does not have the associate column in its name
    const filteredFilterOptions = useMemo(() => {
        const filteredFilterOptions: Record<string, FilterOption> = {};
        Object.entries(filterOptions ?? {}).forEach(([key, filter]) => {
            if (!filter) return;
            const columns = filter.renderWithColumn
                ? Array.isArray(filter.renderWithColumn)
                    ? filter.renderWithColumn
                    : [filter.renderWithColumn]
                : [filter.filterKey];
            const columnsExist = !!columns.find((c) => !!existingColumns[c]);
            if (!columnsExist) return;
            filteredFilterOptions[key] = filter;
        });
        return filteredFilterOptions;
    }, [existingColumns, filterOptions]);

    const scrollWrapperClassname = 'cm-datatable-scroll-wrapper';

    useEffect(() => {
        CacheControl.UserConfig.saveVisibleColumns(
            project._id,
            columnConfigName,
            visibleColumns
        );
    }, [project._id, columnConfigName, visibleColumns]);

    useEffect(() => {
        const updateScroll = () => {
            const scrollElement = wrapperRef.current?.querySelector(
                '.cm-datatable-scroll'
            );
            const dataTableElement =
                wrapperRef.current?.querySelector('.p-datatable-table');
            if (!scrollElement || !dataTableElement) return;
            const { scrollWidth } = dataTableElement;
            setScrollWidth(scrollWidth);
        };

        const scrollFn = (scroll: any) => {
            const scrollElement = wrapperRef.current?.querySelector(
                `.${scrollWrapperClassname}`
            );
            if (!scrollElement) return;
            scrollElement.scrollLeft = scroll.target?.scrollLeft;
        };

        const dataTableElement = wrapperRef.current?.querySelector(
            '.p-datatable-wrapper'
        );

        dataTableElement?.addEventListener('scroll', scrollFn);
        updateScroll();
        return () => {
            dataTableElement?.removeEventListener('scroll', scrollFn);
        };
    }, [rest.value]);

    const showFilterBox =
        setFilters &&
        rest.filters &&
        Object.keys(filteredFilterOptions).length > 0 &&
        Object.keys(rest.filters).length > 0;

    return (
        <section className='mt-4 cm-datatable-wrapper' ref={wrapperRef}>
            {showFilterBox && rest.filters && (
                <FilterBox
                    filterOptions={filteredFilterOptions}
                    filters={rest.filters}
                    setFilters={(e) => setFilters(e)}
                />
            )}
            {!hideTable && (
                <>
                    {!!rest.value && (
                        <i className='font-light'>
                            {localization.components.common.datatable.showingAmount.replace(
                                '{count}',
                                rest.value.length.toString()
                            )}
                        </i>
                    )}
                    <div className='flex flex-row justify-center items-center'>
                        {paginatorProps && (
                            <CMPaginator
                                {...paginatorProps}
                                onPageChange={(e) => {
                                    CacheControl.UserConfig.saveRows(
                                        columnConfigName,
                                        e.rows
                                    );
                                    setPaginatorRows(e.rows);
                                    paginatorProps.onPageChange(e);
                                }}
                                disableNext={
                                    !rest?.value?.length ||
                                    rest.value.length < paginatorRows
                                }
                            />
                        )}
                        <MultiSelect
                            style={{ marginLeft: '16px' }}
                            // selectAllLabel={
                            //     localization.components.common.datatable.columnsSelectAll
                            // }
                            placeholder={
                                localization.components.common.datatable
                                    .columnsSelect
                            }
                            showClear={false}
                            dropdownIcon='pi pi-eye-slash'
                            fixedPlaceholder
                            options={validOptions.sort((a, b) => {
                                const order1 = keyOrders[a.value] ?? 9999;
                                const order2 = keyOrders[b.value] ?? 9999;
                                if (order1 > order2) return 1;
                                else if (order1 < order2) return -1;
                                return 0;
                            })}
                            selectAllLabel={localization.common.selectAll}
                            value={Object.entries(visibleColumns)
                                .filter(([_, val]) => val)
                                .map(([key]) => key)}
                            onChange={(e) => {
                                const values =
                                    e.value as (keyof typeof visibleColumns)[];
                                const newVisible: { [key: string]: boolean } =
                                    _.merge({}, visibleColumns);
                                Object.keys(newVisible).forEach(
                                    (k) => (newVisible[k] = false)
                                );
                                values.forEach((v) => (newVisible[v] = true));
                                setVisibleColumnsSync(
                                    newVisible as typeof visibleColumns
                                );
                            }}
                        />
                    </div>
                    <div
                        className={`w-full overflow-x-auto overflow-y-hidden ${scrollWrapperClassname}`}
                        onScroll={(a) => {
                            const scrollElement = a.currentTarget;
                            const dataTableElement =
                                wrapperRef.current?.querySelector(
                                    '.p-datatable-wrapper'
                                );
                            if (!scrollElement || !dataTableElement) return;
                            dataTableElement.scrollLeft =
                                scrollElement.scrollLeft;
                        }}
                    >
                        <div
                            className='cm-datatable-scroll'
                            style={{
                                width: `${scrollWidth}px`,
                                display: 'block',
                            }}
                        >
                            <br />
                        </div>
                    </div>
                    <DataTable removableSort ref={dataTableRef} {...rest}>
                        {filteredChildren}
                    </DataTable>
                </>
            )}
        </section>
    );
};

export default CMDataTable;
