import { useLocalization } from 'hooks/context/useLocalization';
import { Badge, BadgeProps } from 'primereact/badge';

const UnblockOrderValidationStatusBadge: React.FC<{
    unblockOrder: Ether.CaseManager.UnblockOrder.Detailed;
    badgeProps?: BadgeProps;
}> = ({ unblockOrder, badgeProps }) => {
    const [localization] = useLocalization();

    const validatedCount = unblockOrder._user_fields?.validated_targets_count;
    const hasValidated = unblockOrder._user_fields?.has_confirmed;
    const totalCount = unblockOrder.count_data?.targets_count;
    if (hasValidated == null && (validatedCount == null || totalCount == null))
        return '-';

    badgeProps = {
        ...badgeProps,
    };

    if (validatedCount === 0) {
        badgeProps.value =
            localization.components.models.unblockOrder.badge.validationStatus.new.toLocaleUpperCase();
    } else if (hasValidated) {
        badgeProps.severity = 'success';
        badgeProps.value =
            localization.components.models.unblockOrder.badge.validationStatus.finished.toLocaleUpperCase();
    } else if (
        hasValidated === false ||
        (validatedCount && totalCount && validatedCount < totalCount)
    ) {
        badgeProps.severity = 'warning';
        badgeProps.value =
            localization.components.models.unblockOrder.badge.validationStatus.inProgress.toLocaleUpperCase();
    }
    return <Badge {...badgeProps} />;
};

export default UnblockOrderValidationStatusBadge;
