import {
    CMDataTableFiltersFields,
} from 'components/datatable/CMDataTable/types';
import { FilterOption } from 'components/datatable/FilterBox/types';
import { DataTableOperatorFilterMetaData } from 'primereact/datatable';
import { useMemo } from 'react';

/**
 * Hook to get generate the filterOptions and filterMetaData given a filtersFields object
 * @template ValidKeys - The valid keys which may be indexed in the filter object
 * @params object.filterFields - The object describing each filter with the meta and options properties
 * @returns object.filterOptions - All available filter options to be used by the CMDataTable model
 * @returns object.filterMetaData - All available filter metadata, each value is a DataTableOperatorFilterMetaData for the primereact DataTable component
 */
function useGenerateFilters<ValidKeys extends string>(
    filtersFields: CMDataTableFiltersFields<string>
): {
    filterOptions: { [key in ValidKeys]?: FilterOption };
    filterMetaData: Record<string, DataTableOperatorFilterMetaData>;
} {
    const [filterOptions, filterMetaData] = useMemo(() => {
        const filterOptions: { [key in ValidKeys]?: FilterOption } = {};
        const filterMetaData: Record<string, DataTableOperatorFilterMetaData> =
            {};
        Object.entries(filtersFields).forEach(([key, value]) => {
            if (!value) return;
            filterOptions[key as ValidKeys] = value.options;
            filterMetaData[value.options.filterKey] = value.meta;
        });
        return [filterOptions, filterMetaData];
    }, [filtersFields]);

    return { filterOptions, filterMetaData };
}
export default useGenerateFilters;
