import api from 'services/ether/api';
import {
    getFilterMeta,
    mapDevFilters,
    parseDataTableFilterMetaForAPI,
    ParseDataTableParams,
} from 'services/ether/utils';
import {
    RemoveTargetValidationEP,
    DetailTargetEP,
    ExportAuthorizationTargetParams,
    ListTargetEP,
    ParseTargetResponse,
    ParseZipFileEP,
    ValidateTargetAuthorization,
    ValidateTargetOrder,
    ParseTargetListEP,
    UpdateTargetOrderStatusEP,
    CheckActionTargetWarningEP,
    GetTargetHistoryEP,
} from './types';
import {
    fileDownloadBase,
    fileStreamingDownload,
    getApiBase,
    listBase,
    postApiBase,
} from 'services/ether/base';
import { BaseAbstractedApiParams } from 'services/ether/types';
import { checkFileSizeForEndpoint } from 'utils/errorHandler';
import _ from 'lodash';
import { nestTagList, objetifyNestedTagList } from 'utils/models/target';
import { DataTableFilterMetaData } from 'primereact/datatable';
import { getFilterData } from 'utils/datatable';
import { FilterMatchMode } from 'primereact/api';
import { checkTagFilterMatching } from 'utils/tag';

const parseTargetParams = (params: ParseDataTableParams) => {
    if (params.filters?.['omit_pre_reprove']) {
        const filterMeta = getFilterMeta(params.filters.omit_pre_reprove);
        if (filterMeta.value) {
            const newValues: string[] = [];
            (filterMeta.value as string[]).forEach((v) => {
                if (v === 'can_revert') {
                    if (!params.filters) return;
                    params.filters['can_revert'] = getFilterData(
                        FilterMatchMode.EQUALS,
                        'true'
                    );
                } else {
                    newValues.push(v);
                }
            });
            delete params.filters['omit_pre_reprove'];
            params.filters['pre_reprove_reasons'] = {
                ...filterMeta,
                value: newValues,
            };
        }
    }

    const tagKeys = {
        authorization: 'compiled_authorizations_tags._id|in@oid',
        target: 'authorizations.tags._id|in@oid',
    };
    const tagFilters: Record<string, string[]> = {
        authorization: [],
        target: [],
    };
    Object.keys(params.filters ?? {}).forEach((key) => {
        if (!params.filters?.[key]) return;
        const value = params.filters[key];
        const match = checkTagFilterMatching(key);
        if (!match.matches) return;
        const filterMeta = getFilterMeta(value);
        delete params.filters[key];
        if (!filterMeta.value) return;
        const oids: string[] =
            match.filterType === 'dropdown'
                ? [filterMeta.value]
                : filterMeta.value;
        tagFilters[match.modelType]?.push(`(${oids.join('|')})`);
    });
    Object.entries(tagKeys).forEach(([key, value]) => {
        if (!params.filters) return;
        if (!tagFilters[key]?.length) return;
        params.filters[value] = tagFilters[key]?.join('&');
    });

    const customKeys = {
        'external_services_data.onoff':
            'external_services_data.onoff.values.status',
    };
    Object.entries(customKeys).forEach(([key, filter]) => {
        if (params.filters?.[key]) {
            const filterMeta = getFilterMeta(params.filters[key]);
            if (filterMeta.value) {
                if (!params.filters[filter]) {
                    params.filters[filter] = {
                        ...filterMeta,
                        value: [],
                    };
                }
                (params.filters[filter] as DataTableFilterMetaData).value.push(
                    filterMeta.value
                );
            }
            delete params.filters[key];
        }
    });

    if (params.filters?.['authorizations.meta.notices.0.status']) {
        const filterMeta = getFilterMeta(
            params.filters['authorizations.meta.notices.0.status']
        );
        if (filterMeta.value) {
            if (filterMeta.value === 'true') {
                filterMeta.value = 'done';
            } else if (filterMeta.value === 'false') {
                filterMeta.value = 'done';
                filterMeta.matchMode = FilterMatchMode.NOT_EQUALS;
            }
        }
    }
    return parseDataTableFilterMetaForAPI({
        ...params,
        dateFields: [
            {
                key: 'authorizations.meta.input-date',
                matchMode: null,
            },
        ],
    });
};

export const listTargetsFromProject = ({
    project_id,
    options,
    signal,
}: ListTargetEP.Params.Many) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: ListTargetEP.Filters.Map = {
        _id: ['_id', devFilters._id],
        authorization_ids: [
            'authorizations._id@oid',
            Array.isArray(devFilters.authorization_ids)
                ? devFilters.authorization_ids.join(',')
                : devFilters.authorization_ids,
        ],
        block_order_id: ['block_orders._id|in@oid', devFilters.block_order_id],
        unblock_order_id: [
            'unblock_orders._id|in@oid',
            devFilters.unblock_order_id,
        ],
        removed_authorization_id: [
            'removed_authorizations._id@oid',
            devFilters.removed_authorization_id,
        ],
        safe_ip: ['list_data.safe_ip', devFilters.safe_ip],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge(
        {
            project_id,
        },
        options?.rawFilters,
        mappedFilters
    );
    return listBase<Ether.CaseManager.Target.Detailed[]>({
        endpoint: '/list-target',
        handleParams: (params) =>
            parseTargetParams({
                ...params,
                nameField: 'value',
            }),
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

const _detailTargets = ({
    project_id,
    options,
    signal,
}: DetailTargetEP.Params.Restricted) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: DetailTargetEP.Filters.Map = {
        _id: ['_id', devFilters._id],
        authorization_ids: [
            'authorizations._id@oid',
            Array.isArray(devFilters.authorization_ids)
                ? devFilters.authorization_ids.join(',')
                : devFilters.authorization_ids,
        ],
        block_order_id: ['block_orders._id|in@oid', devFilters.block_order_id],
        unblock_order_id: [
            'unblock_orders._id|in@oid',
            devFilters.unblock_order_id,
        ],
        removed_authorization_id: [
            'removed_authorizations._id@oid',
            devFilters.removed_authorization_id,
        ],
        safe_ip: ['list_data.safe_ip', devFilters.safe_ip],
        authorization_config_id: [
            'authorization_config_id',
            devFilters.authorization_config_id,
        ],
        company_id: ['company_id', devFilters.company_id],
        pre_reprove: [
            'removed_authorizations.pre_reprove@bool',
            devFilters.pre_reprove != null
                ? devFilters.pre_reprove
                    ? 'true'
                    : 'false'
                : undefined,
        ],
        get_evidence_parsed: [
            'get_evidence_parsed',
            devFilters.get_evidence_parsed != null
                ? devFilters.get_evidence_parsed
                    ? 'true'
                    : 'false'
                : undefined,
        ],
        additional_fields: [
            'additional_fields',
            devFilters.additional_fields?.join(','),
        ],
        in_type_order: ['in_type_order', devFilters.in_type_order],
        unwind: ['unwind', devFilters.unwind],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge(
        {
            project_id,
        },
        options?.rawFilters,
        mappedFilters
    );

    return listBase<DetailTargetEP.Data.Payload, DetailTargetEP.Data.Meta>({
        endpoint: '/detail-target',
        handleParams: (params) =>
            parseTargetParams({
                ...params,
                nameField: 'value',
            }),
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

export const detailOneTarget = async ({
    _id,
    project_id,
    signal,
}: DetailTargetEP.Params.One) => {
    const data = await _detailTargets({
        project_id,
        options: {
            devFilters: {
                _id: _id,
                additional_fields: [],
            },
        },
        signal,
    });
    return data.payload[0] ?? null;
};

export const detailManyTargets = async ({
    project_id,
    options,
    signal,
}: DetailTargetEP.Params.Many) => {
    return _detailTargets({
        project_id,
        options: {
            ...options,
            devFilters: {
                ...options?.devFilters,
                additional_fields: ['external_services_data'],
            },
        },
        signal,
    });
};

export const detailManyTargetsUnwindedAuthorization = async ({
    project_id,
    options,
    signal,
}: DetailTargetEP.Params.Many) => {
    const data = await _detailTargets({
        project_id,
        options: {
            ...options,
            devFilters: {
                ...options?.devFilters,
                unwind: 'authorizations',
            },
        },
        signal,
    });
    return {
        payload: data.payload,
        meta: data.meta,
    };
};

export const detailManyTargetsUnwindedBlockOrder = async ({
    project_id,
    options,
    signal,
}: DetailTargetEP.Params.Many) => {
    const data = await _detailTargets({
        project_id,
        options: {
            ...options,
            devFilters: {
                ...options?.devFilters,
                unwind: 'block_orders_data',
            },
        },
        signal,
    });
    return {
        payload: data.payload,
        meta: data.meta,
    };
};

export const updateTarget = (
    targetId: string,
    data: {
        value?: string;
    }
) => {
    return new Promise<boolean>((resolve, reject) => {
        api.post<Ether.ApiResponse<boolean[]>>(
            `/update-target/${targetId}`,
            data
        )
            .then(() => resolve(true))
            .catch((err) => reject(err));
    });
};

export const deleteTarget = (_id: string) => {
    return new Promise<void>((resolve, reject) => {
        api.delete(`/delete-target/${_id}`)
            .then(() => resolve())
            .catch((err) => reject(err));
    });
};

export const importTargets = async (projectId: string, file: File) => {
    const formData = new FormData();
    formData.append('project_id', projectId);
    formData.append('file', file);

    const result = await api.post<
        Ether.ApiResponse<
            {
                created: number;
                updated: number;
                errors: [number, string][];
            }[]
        >
    >('/import-target', formData);

    return result.data.payload;
};

export const exportTargetFromAuthorization = (
    authorizationId: string,
    isRemoved: boolean,
    options?: BaseAbstractedApiParams<(keyof Ether.CaseManager.Target)[]>,
    signal?: AbortSignal
) => {
    const params = parseTargetParams({
        filters: options?.rawFilters,
        sort: options?.sort,
        nameField: 'value',
    });
    params['limit'] = '0';
    if (isRemoved) params['removed_authorizations._id@oid'] = authorizationId;
    else params['authorization_id'] = authorizationId;
    return fileDownloadBase({
        endpoint: '/export-target',
        params,
        filename:
            new Date().toISOString().split('T')[0] +
            `_${authorizationId}_targets`,
        fallbackExtension: 'zip',
        signal,
    });
};

export const exportTargetFromBlockOrder = (
    blockOrderId: string,
    options?: BaseAbstractedApiParams<(keyof Ether.CaseManager.Target)[]>,
    signal?: AbortSignal
) => {
    const params = parseTargetParams({
        filters: options?.rawFilters,
        sort: options?.sort,
        nameField: 'value',
    });
    return fileDownloadBase({
        endpoint: '/export-target',
        params: {
            'block_orders._id|in@oid': blockOrderId,
            limit: '0',
            ...params,
        },
        filename:
            new Date().toISOString().split('T')[0] + `_${blockOrderId}_targets`,
        fallbackExtension: 'zip',
        signal,
    });
};

export const exportAuthorizationTarget = (
    params: ExportAuthorizationTargetParams
) => {
    return fileStreamingDownload({
        params: {
            project_id: params.project_id,
            'status|in': params?.status?.join(','),
        },
        filename:
            new Date().toISOString().split('T')[0] + `_authorization_targets`,
        endpoint: '/export-authorization-target',
        signal: params.signal,
    });
};

export const parseTargetFile = async (params: {
    targetsFile: File;
    projectId: string;
    authorizationConfigId: string;
}) => {
    checkFileSizeForEndpoint({
        endpoint: 'parse-target-file',
        size: params.targetsFile.size,
    });

    const formData = new FormData();
    formData.append('file', params.targetsFile);
    formData.append('project_id', params.projectId);
    formData.append('authorization_config_id', params.authorizationConfigId);

    const result = await api.post<Ether.ApiResponse<ParseTargetResponse[]>>(
        '/parse-target-file',
        formData
    );

    const firstResult = result.data.payload[0];

    if (!firstResult)
        throw new Error('parse-target-file failed to return payload data');

    return firstResult;
};

export const parseZipFile = async (
    params: ParseZipFileEP.Data & {
        options?: {
            onProgressUpdate?: (value: number) => void;
        };
    }
) => {
    checkFileSizeForEndpoint({
        endpoint: 'parse-zip-file',
        size: params.file.size,
    });

    const formData = new FormData();
    formData.append('file', params.file);
    formData.append('project_id', params.project_id);
    formData.append('authorization_config_id', params.authorization_config_id);
    formData.append('commit', params.commit ? 'true' : 'false');

    const handleProgress = (progress: number) => {
        if (!params.options?.onProgressUpdate) return;
        params.options.onProgressUpdate(progress);
    };

    const handleAnswer = (data: string) => {
        const regexData = /([^\n]*\n*$)/.exec(data);
        if (!regexData) throw new Error('parse-zip-file missing regexData');
        const jsonData = JSON.parse(regexData[0]);
        if ('progress' in jsonData) {
            handleProgress(jsonData.progress);
            return null;
        }
        return jsonData as ParseZipFileEP.Result;
    };

    const result = await api.post<string>('/parse-zip-file', formData, {
        responseType: 'text',
        onDownloadProgress: (data) => {
            const event = data.event as any;
            const text = event.target.responseText as string;
            handleAnswer(text);
        },
    });

    const text = result.data;
    const res = handleAnswer(text);
    if (!res) throw new Error('parse-zip-file unexpected result');

    return res;
};

export const validateTargetsOnAuthorization = (
    data: ValidateTargetAuthorization.Data
) => {
    const formData =
        'all_targets' in data.data
            ? [
                  {
                      all_targets: true,
                      accepted: data.data.accepted,
                      authorization_id: data.data.authorization_id,
                  },
              ]
            : data.data.map((item) => ({
                  target_id: item.target_id,
                  accepted: item.accepted,
                  authorization_id: item.authorization_id,
              }));
    return new Promise<ValidateTargetAuthorization.ApiResponse>(
        (resolve, reject) => {
            api.post<
                Ether.ApiResponse<ValidateTargetAuthorization.ApiResponse>
            >('/validate-target-authorization', formData, {
                params: {
                    token: data.token,
                },
            })
                .then((res) => {
                    const payload = res.data.payload;
                    if (!payload)
                        reject(
                            new Error(
                                'validate-target-authorization failed to return data'
                            )
                        );
                    resolve(payload);
                })
                .catch((err) => reject(err));
        }
    );
};

export const validateTargetsOnOrder = (data: ValidateTargetOrder.Data) => {
    if (!data.blocked_at)
        throw new Error(
            '[validateTargetsOnOrder] blocked_at must be present when blocking'
        );

    const baseData = {
        blocked_at: data.blocked_at?.toISOString(),
    };

    const formData =
        'all_targets' in data.data
            ? [
                  {
                      all_targets: true,
                      ...baseData,
                  },
              ]
            : data.data.map((item) => ({
                  target_id: item.target_id,
                  blocked: item.blocked,
                  order_id: item.order_id,
                  order_type: item.order_type,
                  ...baseData,
              }));
    return new Promise<ValidateTargetOrder.ApiResponse>((resolve, reject) => {
        api.post<Ether.ApiResponse<ValidateTargetOrder.ApiResponse>>(
            '/validate-target-order',
            formData,
            {
                params: {
                    token: data.token,
                },
            }
        )
            .then((res) => {
                const payload = res.data.payload;
                if (!payload)
                    reject(
                        new Error('validate-target-order failed to return data')
                    );
                resolve(payload);
            })
            .catch((err) => reject(err));
    });
};

export const removeTargetValidation = (data: RemoveTargetValidationEP.Data) => {
    return postApiBase<RemoveTargetValidationEP.Result>({
        data: data,
        endpoint: '/remove-target-validation',
        dontExtractArray: true,
    });
};

export const parseTargetList = (data: ParseTargetListEP.Data) => {
    const postData: ParseTargetListEP.PostData = {
        authorization_config_id: data.authorization_config_id,
        project_id: data.project_id,
        targets: data.targets.map((t) => {
            const nestedTags = nestTagList(t.tags);
            const tags = objetifyNestedTagList(nestedTags);
            return {
                value: t.value,
                ...tags,
            };
        }),
    };

    return postApiBase<ParseTargetListEP.Result>({
        data: postData,
        endpoint: '/parse-target-list',
    });
};

export const updateTargetOrderStatus = (data: UpdateTargetOrderStatusEP.Data) =>
    postApiBase<UpdateTargetOrderStatusEP.Result>({
        data: data,
        endpoint: '/update-target-order-status',
    });

export const checkActionTargetWarning = (
    data: CheckActionTargetWarningEP.Data
) => {
    return postApiBase<CheckActionTargetWarningEP.Result>({
        data: data,
        endpoint: '/check-action-target-warning',
        dontExtractArray: true,
    });
};

export const getTargetHistory = async (
    data: GetTargetHistoryEP.Params,
    signal?: AbortSignal
) => {
    const payload = await getApiBase<GetTargetHistoryEP.ApiResponse>({
        endpoint: '/get-target-history',
        params: data,
        signal,
    });
    return 'data' in payload ? payload.data : null;
};
