import NoWrapBadge from 'components/display/NoWrapBadge';
import { useLocalization } from 'hooks/context/useLocalization';
import { BadgeProps } from 'primereact/badge';

const AuthorizationNotificationStatusBadge: React.FC<{
    authorization: Ether.CaseManager.Authorization;
    badgeProps?: BadgeProps;
}> = ({ authorization, badgeProps }) => {
    const [localization] = useLocalization();
    const authorizationBadge =
        localization.components.models.authorization.badge.notificationStatus;
    const status = authorization.notification_status;
    badgeProps = {
        ...badgeProps,
        severity: 'info',
        value: status ? status.toUpperCase() : '-',
    };
    switch (status) {
        case 'new':
            badgeProps.severity = 'info';
            badgeProps.value = authorizationBadge.new.toLocaleUpperCase();
            break;
        case 'pending':
            badgeProps.severity = 'warning';
            badgeProps.value = authorizationBadge.pending.toLocaleUpperCase();
            break;
        case 'complete':
            badgeProps.severity = 'success';
            badgeProps.value = authorizationBadge.complete.toLocaleUpperCase();
            break;
        case 'partial':
            badgeProps.severity = 'warning';
            badgeProps.value = authorizationBadge.partial.toLocaleUpperCase();
            break;
        case 'not_applicable':
            badgeProps.severity = undefined;
            badgeProps.value = authorizationBadge.not_applicable.toLocaleUpperCase();
            break;
    }
    return <NoWrapBadge {...badgeProps} />;
};

export default AuthorizationNotificationStatusBadge;
