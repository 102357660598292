import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from 'hooks/context/useAuth';

import Login from 'pages/Login';
import Main from 'pages/Main';
import Home from 'pages/Main/Home';
import Projects from 'pages/Projects';
import ListProjects from 'pages/Projects/ListProjects';
import ListTargets from 'pages/Models/Targets/ListTargets';
import Targets from 'pages/Models/Targets';
import Authorizations from 'pages/Models/Authorizations';
import ListAuthorizations from 'pages/Models/Authorizations/ListAuthorizations';
import Evidences from 'pages/Models/Evidences';
import ListEvidences from 'pages/Models/Evidences/ListEvidences';
import AuthorizationTags from 'pages/Models/BlockOrders';
import ListBlockOrders from 'pages/Models/BlockOrders/ListBlockOrders';
import AcceptAuthorizationWrapper from 'pages/AcceptToken/AcceptAuthorization';
import ProjectSubpages from 'pages/Projects/ListProjects/ProjectSubpages';
import DetailedAuthorization from 'pages/Models/Authorizations/DetailAuthorization';
import DetailedBlockOrder from 'pages/Models/BlockOrders/DetailBlockOrder';
import ListRealTimeMonitors from 'pages/Models/Monitors/ListRealTimeMonitors';
import RealTimeMonitoring from 'pages/Models/Monitors';
import DetailRealTimeMonitoring from 'pages/Models/Monitors/DetailRealTimeMonitors';
import UnblockOrders from 'pages/Models/UnblockOrders';
import ListUnblockOrders from 'pages/Models/UnblockOrders/ListUnblockOrders';
import LoadingMessage from 'components/misc/LoadingMessage';
import Documents from 'pages/Models/Documents';
import ListDocuments from 'pages/Models/Documents/ListDocuments';
import DetailedUnblockOrder from 'pages/Models/UnblockOrders/DetailedUnblockOrder';
import Operators from 'pages/Models/Operators';
import AcceptBlockOrderWrapper from 'pages/AcceptToken/AcceptBlockOrder';
import AuthorizationConfigs from 'pages/Models/AuthorizationConfigs';
import DetailedAuthorizationConfig from 'pages/Models/AuthorizationConfigs/DetailAuthorizationConfig';
import AdminManagement from 'pages/AdminManagement';

import { useLocalization } from 'hooks/context/useLocalization';
import InsertCompanyPage from 'pages/AdminManagement/subpages/InsertCompanyPage';
import AdminManagementView from 'pages/AdminManagement/subpages/AdminManagementLanding';
import InsertUserPage from 'pages/AdminManagement/subpages/InsertUserPage';
import EditUserPage from 'pages/AdminManagement/subpages/EditUserPage';
import EditCompanyPage from 'pages/AdminManagement/subpages/EditCompanyPage';
import ListOperatorsCompany from 'pages/Models/Operators/ListOperatorsCompany';
import Operations from 'pages/Models/Operations';
import ListOperations from 'pages/Models/Operations/ListOperations';
import Dashboard from 'pages/Models/Dashboard';
import DetailDashboard from 'pages/Models/Dashboard/DetailDashboard';
import ListTargetsInAuthorization from 'pages/Models/Targets/ListTargetsInAuthorization';
import Tags from 'pages/Models/Tags';
import ListTagWithCategory from 'pages/Models/Tags/ListTagWithCategory';
import ListTargetsInAuthorizationPending from 'pages/Models/Targets/ListTargetsInAuthorizationPending';

const AuthNecessaryRoute: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const [localization] = useLocalization();
    const { status } = useAuth();
    if (status === 'loading')
        return (
            <LoadingMessage>
                {localization.components.views.login.validatingToken}
            </LoadingMessage>
        );
    if (status === 'error' || status === 'unauthenticated') {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.append('callback', window.location.href);
        return <Navigate to={`/login?${urlParams.toString()}`} />;
    }
    return children as ReactElement;
};

type BaseRoute = {
    element: JSX.Element;
};

export type ExtendedRoute =
    | ({
          index: true;
          path?: undefined;
          children?: undefined;
      } & BaseRoute)
    | ({
          index?: false;
          path: string;
          children?: ExtendedRoute[] | undefined;
      } & BaseRoute);

const modelRoutes: ExtendedRoute[] = [
    {
        path: 'targets',
        element: <Targets />,
        children: [
            {
                index: true,
                element: <ListTargets />,
            },
        ],
    },
    {
        path: 'authorization-targets',
        element: <Targets />,
        children: [
            {
                index: true,
                element: <ListTargetsInAuthorization />,
            },
        ],
    },
    {
        path: 'authorization-targets-pending',
        element: <Targets />,
        children: [
            {
                index: true,
                element: <ListTargetsInAuthorizationPending />,
            },
        ],
    },
    {
        path: 'authorizations',
        element: <Authorizations />,
        children: [
            {
                index: true,
                element: <ListAuthorizations />,
            },
            {
                path: ':authorizationId',
                element: <DetailedAuthorization />,
            },
        ],
    },
    {
        path: 'block-orders',
        element: <AuthorizationTags />,
        children: [
            {
                index: true,
                element: <ListBlockOrders />,
            },
            {
                path: ':blockOrderId',
                element: <DetailedBlockOrder />,
            },
        ],
    },
    {
        path: 'evidences',
        element: <Evidences />,
        children: [
            {
                index: true,
                element: <ListEvidences />,
            },
        ],
    },
    {
        path: 'tags',
        element: <Tags />,
        children: [
            {
                path: ':modelType/:category',
                element: <ListTagWithCategory />,
            },
        ],
    },
    {
        path: 'unblock-orders',
        element: <UnblockOrders />,
        children: [
            {
                index: true,
                element: <ListUnblockOrders />,
            },
            {
                path: ':unblockOrderId',
                element: <DetailedUnblockOrder />,
            },
        ],
    },
    {
        path: 'documents',
        element: <Documents />,
        children: [
            {
                index: true,
                element: <ListDocuments />,
            },
        ],
    },
    {
        path: 'operators',
        element: <Operators />,
        children: [
            {
                index: true,
                element: <ListOperatorsCompany />,
            },
        ],
    },
    {
        path: 'monitoring',
        element: <RealTimeMonitoring />,
        children: [
            {
                index: true,
                element: <ListRealTimeMonitors />,
            },
            {
                path: ':monitorId',
                element: <DetailRealTimeMonitoring />,
            },
        ],
    },
    {
        path: 'authorization-config',
        element: <AuthorizationConfigs />,
        children: [
            {
                path: ':authConfigId',
                element: <DetailedAuthorizationConfig />,
            },
        ],
    },
    {
        path: 'operations',
        element: <Operations />,
        children: [
            {
                index: true,
                element: <ListOperations />,
            },
        ],
    },
    {
        path: 'dashboard',
        element: <Dashboard />,
        children: [{ path: ':dashboardSlug', element: <DetailDashboard /> }],
    },
];

export const routes: ExtendedRoute[] = [
    {
        path: '/login',
        element: (
            <AuthProvider>
                <Login />
            </AuthProvider>
        ),
    },
    {
        path: '/accept-authorization',
        element: <AcceptAuthorizationWrapper />,
    },
    {
        path: '/accept-block-order',
        element: <AcceptBlockOrderWrapper />,
    },
    {
        path: '/',
        element: (
            <AuthProvider>
                <AuthNecessaryRoute>
                    <Main />
                </AuthNecessaryRoute>
            </AuthProvider>
        ),
        children: [
            {
                index: true,
                element: <Home />,
            },
            // {
            //     path: '/support',
            //     element: <Support />,
            // },
            {
                path: '/admin',
                element: <AdminManagement />,
                children: [
                    {
                        index: true,
                        element: <AdminManagementView />,
                    },
                    {
                        path: 'create-company',
                        element: <InsertCompanyPage />,
                    },
                    {
                        path: 'create-user',
                        element: <InsertUserPage />,
                    },
                    {
                        path: 'edit-user/:userId',
                        element: <EditUserPage />,
                    },
                    {
                        path: 'edit-company/:companyId',
                        element: <EditCompanyPage />,
                    },
                ],
            },
            {
                path: '/projects',
                element: <Projects />,
                children: [
                    {
                        index: true,
                        element: <ListProjects />,
                    },
                    {
                        path: ':projectId',
                        element: <ProjectSubpages />,
                        children: modelRoutes,
                    },
                ],
            },
            ...modelRoutes,
            {
                path: '*',
                element: <Navigate to='/' />,
            },
        ],
    },
];
