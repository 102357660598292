import useBaseFileExport from '../useBaseFileExport';
import { DownloadEvidencesFromAuthorizationParams } from 'services/ether/case-manager/evidences/types';
import { downloadEvidencesFromAuthorization } from 'services/ether/case-manager/evidences';

const useDownloadEvidencesFromAuthorization = (
    params: DownloadEvidencesFromAuthorizationParams
) => {
    const queryKey = [params];

    const { exportFile, isExporting: isDownloading } = useBaseFileExport(
        'download-evidences-authorization',
        queryKey,
        ({ signal }) =>
            downloadEvidencesFromAuthorization({
                ...params,
                signal,
            })
    );

    return { downloadFile: () => exportFile({ params: null }), isDownloading };
};

export default useDownloadEvidencesFromAuthorization;
