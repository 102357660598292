import { useLocalization } from 'hooks/context/useLocalization';

/**
 * Retrieves the yse/no options with localized labels
 */
const useYesNoSelectOptions = () => {
    const [localization] = useLocalization();
    return [
        {
            label: localization.common.yes,
            value: 'true',
        },
        {
            label: localization.common.no,
            value: 'false',
        },
    ];
};

export default useYesNoSelectOptions;
