import YesNoBadge from 'components/display/YesNoBadge';
import { DateBadge } from 'components/ethercity-primereact';

/**
 * Tries to identify the unknown data and render it properly within React's allowed borders
 * @param data
 */
export const renderUnidentifiedDataType = (data: unknown) => {
    if (data == null) return '-';
    if (typeof data === 'boolean') return <YesNoBadge value={data} />;
    if (data instanceof Date) return <DateBadge value={data} />;
    if (Array.isArray(data)) {
        const handledData = data.map((d) =>
            typeof d === 'object' ? JSON.stringify(d) : d
        );
        if (handledData.length <= 0) return '-';
        if (handledData.length <= 5)
            return (
                <div className='max-w-full w-max'>{handledData.join(', ')}</div>
            );
        return (
            <div className='max-w-full w-max'>
                {handledData.slice(0, 5).join(', ') + ' (...)'}
            </div>
        );
    }
    if (typeof data === 'object') return JSON.stringify(data);
    if (typeof data === 'string' || typeof data === 'number')
        return <div className='max-w-full w-max'>{data ?? '-'}</div>;
    return <span className='text-red-500'>Data type unidentified</span>;
};
