import { useLocalization } from 'hooks/context/useLocalization';
import SelectOneItem from 'components/form/SelectItems/SelectOneItem';
import { SelectOneItemProps } from 'components/form/SelectItems/types';

const SelectOneAuthorizationFlowType: React.FC<
    SelectOneItemProps<{
        label: string;
        value: Ether.CaseManager.AuthorizationFlow.Type;
    }>
> = ({ queryOptions, ...props }) => {
    const [localization] = useLocalization();

    return (
        <SelectOneItem
            title={localization.common.type}
            placeholder={
                localization.components.models.authorizationFlow.form
                    .selectOneTypePlaceholder
            }
            label={localization.common.type}
            items={[
                {
                    label: localization.components.models.authorizationFlow
                        .badge.type.duty,
                    value: 'duty',
                },
                {
                    label: localization.components.models.authorizationFlow
                        .badge.type.standard,
                    value: 'standard',
                },
            ]}
            optionValue='value'
            optionLabel='label'
            itemsLoading={false}
            {...props}
        />
    );
};

export default SelectOneAuthorizationFlowType;
