import { useProject } from 'hooks/context/project/useProject';
import CMBreadCrumb from 'components/page/CMBreadCrumb';
import { useLocalization } from 'hooks/context/useLocalization';
import useDetailDocument from 'hooks/queries/document/useDetailDocument';
import DocumentDataTable from 'components/datatable/models/document/DocumentDataTable';
import GoBackButton from 'components/misc/GoBackButton';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import CacheControl from 'controller/cache/cacheController';
import useDocumentColumnFields from 'hooks/columns/models/useDocumentColumnFields';

const ListDocuments = () => {
    const project = useProject();
    const [localization] = useLocalization();

    const { filterMetaData: documentFilters } = useDocumentColumnFields();

    const { filters, pageOptions, setFilters, setPageOptions, sort, setSort } =
        useInitDataTableState({
            filters: documentFilters,
            pageOptions: {
                startingRow:
                    CacheControl.UserConfig.get()['paginatorRows']?.document,
            },
        });

    const documentsQuery = useDetailDocument({
        project_id: project._id,
        options: {
            rawFilters: filters,
            limit: pageOptions.rows,
            offset: pageOptions.offset,
            sort,
        },
    });

    return (
        <section>
            <CMBreadCrumb projectLabel={project.name} />
            <GoBackButton />
            <h2>
                {project.name} - {localization.models.document.plural}
            </h2>
            <DocumentDataTable
                loading={documentsQuery.isFetching}
                value={documentsQuery.data?.payload}
                filters={filters}
                setFilters={(e) => setFilters(e)}
                paginatorProps={{
                    page: pageOptions.page,
                    rows: pageOptions.rows,
                    onPageChange: (options) => setPageOptions(options),
                    disableNext:
                        (documentsQuery.data?.payload.length ?? 9999) <
                        pageOptions.rows,
                    onRefresh: documentsQuery.refresh,
                }}
                onSort={(e) =>
                    setSort(
                        e.sortField
                            ? {
                                  field: e.sortField,
                                  order: e.sortOrder,
                              }
                            : null
                    )
                }
                sortField={sort?.field}
                sortOrder={sort?.order}
                documentType='document'
            />
        </section>
    );
};

export default ListDocuments;
