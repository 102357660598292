import { exportAuthorizationFiles } from 'services/ether/case-manager/authorizations';
import useBaseFileExport from '../useBaseFileExport';
import { ExportAuthorizationFilesEP } from 'services/ether/case-manager/authorizations/types';

const useExportAuthorizationFiles = (options?: { onDownload?: () => void }) => {
    const { exportFile, isExporting } = useBaseFileExport<
        ExportAuthorizationFilesEP.Params & { filename: string | null }
    >(
        'export-authorization-files',
        [],
        ({ params: { filename, ...params }, signal }) =>
            exportAuthorizationFiles({
                filename,
                params,
                signal,
            }),
        options?.onDownload
    );

    return {
        exportFile: (
            options: {
                filename: string | null;
            } & ExportAuthorizationFilesEP.Params
        ) =>
            exportFile({
                params: options,
            }),
        isExporting,
    };
};

export default useExportAuthorizationFiles;
