import { useCallback, useMemo } from 'react';
import { getDashboardEmbed } from 'services/ether/case-manager/dashboard';
import { GetDashboardEP } from 'services/ether/case-manager/dashboard/types';
import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import useUserDashboards from 'hooks/models/dashboard/useUserDashboards';

/**
 * Hook to get the various dashboards based on the user's allowed view from company
 * Automatically disables the query if the user has no slug configuration. don't have permission or filter results in no values
 * @param params.filterKeys - Valid dashboards that may be shown based on the key. Useful to filter for specific views only
 * @param params.queryOptions - Additional query options that may be given to the Tanstack's useQuery hook
 * @returns object.query - Query object containing all of the useQueryWithRefresh properties.
 * @returns object.isDisabled - If the query is available for use
 */
const useGetMultipleDashboardURLs = (params?: {
    filterKeys?: CaseManagerApp.DashboardConfig.View[];
    queryOptions?: { enabled?: boolean };
}) => {
    const { dashboards } = useUserDashboards();

    const filteredViews = dashboards?.filter(
        (view) => !params?.filterKeys || params?.filterKeys.includes(view.key)
    );

    const isDisabled =
        params?.queryOptions?.enabled === false || !filteredViews?.length;

    const dashboardCalls = useCallback(
        (signal: AbortSignal) => {
            return new Promise<GetDashboardEP.Result[]>((resolve) => {
                if (!filteredViews) {
                    resolve([]);
                    return;
                }
                const promises = filteredViews?.map(
                    (view) =>
                        getDashboardEmbed({
                            params: {
                                dashboard_slug: view.slug,
                            },
                        }),
                    signal
                );
                Promise.all(promises).then((results) => resolve(results));
            });
        },
        [filteredViews]
    );

    const queryKey = useMemo(
        () => filteredViews?.map((v) => v.slug) ?? [],
        [filteredViews]
    );

    const query = useQueryWithRefresh({
        queryKey,
        queryFn: ({ signal }) => dashboardCalls(signal),
        enabled: !isDisabled,
        gcTime: 1000 * 60 * 60,
    });

    return {
        isDisabled: isDisabled,
        query: query,
    };
};

export default useGetMultipleDashboardURLs;
