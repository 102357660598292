import SelectOneAuthorizationConfig from 'components/models/AuthorizationConfig/SelectOneAuthorizationConfig';
import SelectOneAuthorizationFlow from 'components/models/AuthorizationFlow/SelectOneAuthorizationFlow';
import { DialogProps } from 'primereact/dialog';
import { useState } from 'react';
import { Divider } from 'primereact/divider';
import SelectOneAuthorization from '../SelectOneAuthorization';
import { useLocalization } from 'hooks/context/useLocalization';
import { Button } from 'primereact/button';
import useJoinAuthorization from 'hooks/mutations/authorization/useJoinAuthorization';
import { InputText } from 'components/ethercity-primereact';
import { useProject } from 'hooks/context/project/useProject';
import LightDialog from 'components/display/LightDialog';
import SelectOrCreateOperation from 'components/models/Operation/SelectOrCreateOperation';

const MergeAuthorizationForm: React.FC<
    DialogProps & {
        onJoin?: () => void;
    }
> = (props) => {
    const [localization] = useLocalization();
    const project = useProject();

    const [joinAuths, joinStatus] = useJoinAuthorization({
        onJoin: props.onHide,
    });

    const [selectedFlow, setSelectedFlow] =
        useState<Ether.CaseManager.AuthorizationFlow | null>(null);
    const [selectedConfig, setSelectedConfig] = useState<string | null>(null);
    const [selectedOperation, setSelectedOperation] = useState<string | null>(
        null
    );
    const [name, setName] = useState<string>('');

    const getEmptyAuthorization = () =>
        Array.from(Array(maxAuths).keys()).map(() => null);

    const maxAuths = 5;
    const [selectedAuthorizations, setSelectedAuthorizations] = useState<
        (Ether.CaseManager.Authorization | null)[]
    >(getEmptyAuthorization());

    const clearAuthorizationArray = () => {
        setSelectedAuthorizations(getEmptyAuthorization());
    };

    const handleUpdateAuthSelection = (
        auth: Ether.CaseManager.Authorization | null,
        index: number
    ) => {
        setSelectedAuthorizations((old) => {
            const newArr = [...old];
            newArr[index] = auth;
            Array.from(Array(maxAuths - (index + 1)).keys()).forEach(
                (_, offset) => {
                    newArr[index + (offset + 1)] = null;
                }
            );
            return newArr;
        });
    };

    const validForm =
        name !== '' &&
        !!selectedConfig &&
        !!selectedFlow &&
        !!selectedAuthorizations[0] &&
        !!selectedAuthorizations[1];

    const handleJoin = () => {
        if (!validForm) return;
        if (!selectedAuthorizations[0] || !selectedAuthorizations[1]) return;
        joinAuths({
            authorization_config_id: selectedConfig,
            authorization_flow_id: selectedFlow._id,
            authorization_ids: selectedAuthorizations
                .filter((a) => a != null)
                .map((a) => a?._id ?? ''),
            operation_id: selectedOperation,
            name: name,
            project_id: project._id,
        });
    };

    return (
        <LightDialog
            {...props}
            header={
                localization.components.models.authorization.views.join.title
            }
            className='w-[50vw]'
            footer={
                <div>
                    <Button
                        label={localization.components.common.button.cancel}
                        onClick={props.onHide}
                    />
                    <Button
                        label={localization.components.common.button.finish}
                        disabled={!validForm || joinStatus.isLoading}
                        loading={joinStatus.isLoading}
                        onClick={handleJoin}
                        severity='success'
                    />
                </div>
            }
        >
            <h3>{localization.models.authorization.singular}</h3>
            <InputText
                wrapperStyle={{
                    width: '100%',
                }}
                label={localization.fields.authorization.name}
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
            />
            <SelectOneAuthorizationFlow
                required
                value={selectedFlow?._id}
                onChange={(a) => {
                    setSelectedFlow(a ?? null);
                    setSelectedConfig(null);
                }}
                queryOptions={{
                    devFilters: {
                        can_join_authorization: true,
                    },
                }}
            />
            {selectedFlow && (
                <>
                    <SelectOneAuthorizationConfig
                        required
                        value={selectedConfig}
                        onChange={(c) => {
                            setSelectedConfig(c?._id ?? null);
                            clearAuthorizationArray();
                        }}
                        queryOptions={{
                            devFilters: {
                                authorization_flow_ids: selectedFlow._id,
                            },
                        }}
                    />
                    <SelectOrCreateOperation
                        ignoreDefaultInit={true}
                        operationId={selectedOperation}
                        setOperation={(op) => {
                            setSelectedOperation(op?._id ?? null);
                            clearAuthorizationArray();
                        }}
                        required={false}
                        fixedAuthorizationFlowType={selectedFlow.type}
                    />
                </>
            )}
            {selectedConfig && (
                <>
                    <Divider />
                    <h2>{localization.models.authorization.plural}</h2>
                    <p>
                        {
                            localization.components.models.authorization.views
                                .join.description
                        }
                    </p>
                    <div className='flex flex-col gap-2'>
                        {selectedAuthorizations.map((auth, i) => {
                            return i !== 0 &&
                                selectedAuthorizations[i - 1] ===
                                    null ? null : (
                                <SelectOneAuthorization
                                    key={i}
                                    value={
                                        selectedAuthorizations[i]?._id ?? null
                                    }
                                    onChange={(a) =>
                                        handleUpdateAuthSelection(a ?? null, i)
                                    }
                                    excludeItems={selectedAuthorizations
                                        .slice(0, i)
                                        .filter((a) => a !== auth)
                                        .map((a) => (a === null ? '' : a._id))}
                                    hideTitle
                                    showClear
                                    required={i === 1 || i === 0}
                                    queryOptions={{
                                        devFilters: {
                                            authorization_config_id:
                                                selectedConfig,
                                            authorization_flow_id:
                                                selectedFlow?._id,
                                            operation_id:
                                                selectedOperation ?? undefined,
                                            operation_id_is_null:
                                                !selectedOperation,
                                            status:
                                                i === 0
                                                    ? undefined
                                                    : selectedAuthorizations[0]
                                                          ?.status,
                                        },
                                    }}
                                />
                            );
                        })}
                    </div>
                    <p>
                        {
                            localization.components.models.authorization.views
                                .join.max
                        }
                    </p>
                </>
            )}
        </LightDialog>
    );
};

export default MergeAuthorizationForm;
