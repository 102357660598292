import { CMDataTableFilter } from 'components/datatable/CMDataTable/types';
import { FilterMatchMode } from 'primereact/api';
import { CMLocalization } from 'static/language';
import { getFilterData } from './datatable';
import FilterBoxDropdownTag from 'components/datatable/FilterBox/components/customFilters/FilterBoxDropdownTag';
import FilterBoxMultiSelectTag from 'components/datatable/FilterBox/components/customFilters/FilterBoxMultiSelectTag';
import {
    ValidTagCategory,
    ValidTagModelType,
} from 'services/ether/case-manager/tags/types';

type ModelSingularPlural = {
    singular: string;
    plural: string;
};

/**
 * Resolves to the first found matching modelType/category or only category within the localization object and get its singular and plural name
 * @param param.localization - The localization object
 * @param param.modelType - The model_type of the tag
 * @param param.category - The category of the tag
 * @returns An object with singular and plural name for the given tag. If unresolved, returns null
 */
export const getTagLocalizationNames = ({
    localization,
    modelType,
    category,
}: {
    category: string;
    modelType: string;
    localization: CMLocalization.Localization;
}) => {
    const tagAnyLocalization = localization.models.tag as any;
    if (
        tagAnyLocalization?.modelType &&
        modelType in tagAnyLocalization.modelType
    ) {
        if (tagAnyLocalization.modelType[modelType]?.[category])
            return tagAnyLocalization.modelType[modelType][
                category
            ] as ModelSingularPlural;
    }
    if (tagAnyLocalization?.types && tagAnyLocalization.types?.[category])
        return tagAnyLocalization.types[category] as ModelSingularPlural;
    return null;
};

/**
 * Resolves to the first found matching modelType/category or only category within the localization object and get it localized values, if present
 * @param param.localization - The localization object
 * @param param.modelType - The model_type of the tag
 * @param param.category - The category of the tag
 * @returns An object mapping the tag's values to a localized name. If unresolved, returns null
 */
export const getTagLocalizationValues = ({
    localization,
    modelType,
    category,
}: {
    category: string;
    modelType: string;
    localization: CMLocalization.Localization;
}) => {
    const tagAnyLocalization = localization.components.models.tag as any;
    if (
        tagAnyLocalization?.modelType &&
        modelType in tagAnyLocalization.modelType
    ) {
        if (tagAnyLocalization.modelType[modelType]?.[category]?.values)
            return tagAnyLocalization.modelType[modelType][category]
                ?.values as Record<string, string>;
    }
    if (
        tagAnyLocalization?.types &&
        tagAnyLocalization.types?.[category]?.values
    )
        return tagAnyLocalization.types[category]?.values as Record<
            string,
            string
        >;
    return null;
};

/**
 * Checks if given tag string matches into a correct modelType and category for the tag, also resolving into a multiselect or dropdown filter
 * @param param.value - The given string to try and match for a tag
 * @param param.localization - The localization object
 * @returns object Object describing the matching result
 * @returns object.matches Whether it is a valid match or not
 * @returns object.filter If matching, it will return the complete filter options and meta to be used
 * @returns object.category If matching, returns the matched category
 * @returns object.modelType If matching, returns the matched model_type
 * @returns object.filterType If matching, returns the matched filterType
 */
export function checkTagFilterMatching(
    value: string,
    localization?: CMLocalization.Localization
):
    | {
          matches: false;
      }
    | {
          matches: true;
          filter: CMDataTableFilter;
          category: ValidTagCategory;
          modelType: ValidTagModelType;
          filterType: 'multiselect' | 'dropdown';
      } {
    if (!value.startsWith('_cmapp_tag_'))
        return {
            matches: false,
        };
    try {
        // Tag filters will have the following format: _cmapp_tag_modeltype:category:(multiselect|dropdown)
        const [, mainValues] = value.split('_cmapp_tag_');
        if (!mainValues) return { matches: false };
        const [modelType, category, filterType] = mainValues.split(':');
        if (!modelType || !category || !filterType) return { matches: false };
        if (filterType !== 'dropdown' && filterType !== 'multiselect')
            return { matches: false };
        const isDropdown = filterType === 'dropdown';
        const nameObj = localization
            ? getTagLocalizationNames({
                  category,
                  localization,
                  modelType,
              })
            : null;
        const name = isDropdown
            ? nameObj?.singular
            : nameObj?.plural ?? 'LOCALE-ERROR';
        return {
            matches: true,
            filter: {
                meta: getFilterData(FilterMatchMode.EQUALS),
                options: {
                    type: 'custom',
                    label: name,
                    element: (props) =>
                        isDropdown ? (
                            <FilterBoxDropdownTag
                                {...props}
                                category={category as ValidTagCategory}
                                modelType={modelType as ValidTagModelType}
                            />
                        ) : (
                            <FilterBoxMultiSelectTag
                                {...props}
                                category={category as ValidTagCategory}
                                modelType={modelType as ValidTagModelType}
                            />
                        ),
                    initialValue: isDropdown ? null : [],
                    filterKey: value,
                    renderWithColumn: '_cm_name_id',
                },
            },
            category: category as ValidTagCategory,
            filterType: filterType,
            modelType: modelType as ValidTagModelType,
        };
    } catch (err) {
        console.error(
            'Error parsing value for checkTagFilterMatching: ' + value
        );
    }
    return { matches: false };
}
